import { Route, Routes } from "react-router-dom";
import { Layout } from "../layouts/dashboard";
import { FC } from "react";
import { paths } from "../paths";
import { UserList } from "../modules/user/userList/ui";
import { UserEdit } from "../modules/user/userEdit/ui";
import { Tests } from "../modules/content/tests/testList/ui";
import { Quiz } from "../modules/content/quiz/quizList/ui";
import { Courses } from "../modules/content/courses/courseList/ui";
import { UserStudyHistory } from "../modules/user/userStudyHistory/ui";
import { UserOrderHistory } from "../modules/user/userOrderHistory/ui";
import { DocumentCategory } from "../modules/content/category/categoryList/ui";
import { DocumentsList } from "../modules/content/documents/documentsList/ui";
import { DocumentsCreate } from "../modules/content/documents/documentsCreate/ui";
import { AdvertList } from "../modules/advert/advertList/ui";
import { AdvertCreate } from "../modules/advert/advertCreate/ui";
import { AdvertEdit } from "../modules/advert/advertEdit/ui";
import { Backup } from "../modules/backup/ui";
import { DocumentsEdit } from "../modules/content/documents/documentsEdit/ui";
import { TestCreate } from "../modules/content/tests/testCreate/ui";
import { TestEdit } from "../modules/content/tests/testEdit/ui";
import Badges from '../modules/badge/badges/ui';
import UpsertBadge from '../modules/badge/upsertBadge/ui';
import { RolesList } from "../modules/roles/rolesList/ui";
import { RoleCreate } from "../modules/roles/roleCreate/ui";
import { RoleEdit } from "../modules/roles/roleEdit/ui";
import { AppPageList } from "../modules/appPage/appPageList/ui";
import { AppPageCreate } from "../modules/appPage/appPageCreate/ui";
import { AppPageEdit } from "../modules/appPage/appPageEdit/ui";
import { NotificationList } from "../modules/notification/notificationList/ui";
import { NotificationCreate } from "../modules/notification/notificationCreate/ui";
import { NotificationEdit } from "../modules/notification/notificationEdit/ui";
import { NotificationDetail } from "../modules/notification/notificationDetail/ui";
import { ProductsList } from "../modules/shop/productsList/ui";
import { ProductCreate } from "../modules/shop/productCreate/ui";
import { ProductEdit } from "../modules/shop/productEdit/ui";
import { QuizCreate } from "../modules/content/quiz/quizCreate/ui";
import { QuizEdit } from "../modules/content/quiz/quizEdit/ui";
import { OrdersList } from "../modules/order/ordersList/ui";
import { CourseCreate } from "../modules/content/courses/courseCreate/ui";
import { CourseEdit } from "../modules/content/courses/courseEdit/ui";
import { GeneralQuestionList } from "../modules/feedback/generalQuestionList/ui";
import { IsIdeaList } from "../modules/feedback/isIdeaList/ui";
import { AnonymousComplaintList } from "../modules/feedback/anonymousComplaint/ui";
import { GeneralAnalytics } from "../modules/analytics/general/ui";
import { CourseAnalytics } from "../modules/analytics/course/ui";
import { KnowledgeAnalytics } from "../modules/analytics/knowledge/ui";
import { UserAnalytics } from "../modules/analytics/user/ui";
import { ContentAnalytics } from "../modules/analytics/content/ui";
import { AdvertAnalytics } from "../modules/analytics/advert/ui";
import { ProcessAnalytics } from "../modules/analytics/process/ui";
import { ProductAnalytics } from "../modules/analytics/product/ui";
import { NoAccessContent } from "../modules/noAccessContent";
import { LeaderBoardAnalytics } from "../modules/analytics/leaderBoard/ui";
import { SingleTestAnalytics } from "../modules/analytics/singleTest/ui";
import { SingleCourseAnalytics } from "../modules/analytics/singleCourse/ui";
import { SingleDocumentAnalytics } from "../modules/analytics/singleDocument/ui";
import { SingleAdvertAnalytics } from "../modules/analytics/singleAdvert/ui";
import { TraineePositionList } from "../modules/trainee/traineePositionList/ui";
import { TraineePositionEdit } from "../modules/trainee/traneePositionEdit/ui";
import { EditAssignDocument } from "../ui-kit/editAssign/ui";
import { EditAccessDocument } from "../ui-kit/editAccess/ui";
import { EditAssignBadge } from "../modules/badge/editAssignBadge/ui";

export const PrivateRoutes: FC = () => {

    return (
        <Routes>
            <Route path='/' element={<Layout />}>
                <Route index element={<></>} />
                {/* -------- USER --------- */}
                <Route path={paths.dashboard.users.index} element={<UserList />} />
                <Route path={paths.dashboard.users.edit} element={<UserEdit />} />
                <Route path={paths.dashboard.users.studyHistory} element={<UserStudyHistory />} />
                <Route path={paths.dashboard.users.orderHistory} element={<UserOrderHistory />} />
                <Route path={paths.dashboard.users.roles} element={<RolesList />} />
                <Route path={paths.dashboard.users.rolesCreate} element={<RoleCreate />} />
                <Route path={paths.dashboard.users.rolesEdit} element={<RoleEdit />} />
                {/* -------- CONTENT --------- */}
                <Route path={paths.dashboard.content.category} element={<DocumentCategory />} />
                <Route path={paths.dashboard.content.document.list} element={<DocumentsList />} />
                <Route path={paths.dashboard.content.document.create} element={<DocumentsCreate />} />
                <Route path={paths.dashboard.content.document.edit} element={<DocumentsEdit />} />
                <Route path={paths.dashboard.content.document.editAssign} element={<EditAssignDocument type="document"/>} />
                <Route path={paths.dashboard.content.document.editAccess} element={<EditAccessDocument type="document"/>} />

                <Route path={paths.dashboard.content.test.list} element={<Tests />} />
                <Route path={paths.dashboard.content.test.create} element={<TestCreate />} />
                <Route path={paths.dashboard.content.test.edit} element={<TestEdit />} />
                <Route path={paths.dashboard.content.test.editAssign} element={<EditAssignDocument type="quiz"/>} />
                <Route path={paths.dashboard.content.test.editAccess} element={<EditAccessDocument type="quiz"/>} />

                <Route path={paths.dashboard.content.quiz.list} element={<Quiz />} />
                <Route path={paths.dashboard.content.quiz.create} element={<QuizCreate />} />
                <Route path={paths.dashboard.content.quiz.edit} element={<QuizEdit />} />
                <Route path={paths.dashboard.content.quiz.editAssign} element={<EditAssignDocument type="questionnaire"/>} />
                <Route path={paths.dashboard.content.quiz.editAccess} element={<EditAccessDocument type="questionnaire"/>} />

                <Route path={paths.dashboard.content.course.list} element={<Courses />} />
                <Route path={paths.dashboard.content.course.create} element={<CourseCreate />} />
                <Route path={paths.dashboard.content.course.edit} element={<CourseEdit />} />
                <Route path={paths.dashboard.content.course.editAssign} element={<EditAssignDocument type="course"/>} />
                <Route path={paths.dashboard.content.course.editAccess} element={<EditAccessDocument type="course"/>} />
                {/* --------ADVERT------------- */}
                <Route path={paths.dashboard.advert.index} element={<AdvertList />} />
                <Route path={paths.dashboard.advert.create} element={<AdvertCreate />} />
                <Route path={paths.dashboard.advert.edit} element={<AdvertEdit />} />
                <Route path={paths.dashboard.advert.editAssign} element={<EditAssignDocument type="announcement"/>} />
                <Route path={paths.dashboard.advert.editAccess} element={<EditAccessDocument type="announcement"/>} />
                {/* ---------BackUp------------ */}
                <Route path={paths.dashboard.backup.index} element={<Backup />} />
                {/* --------------Badge--------------- */}
                <Route path={paths.dashboard.badge.index} element={<Badges />} />
                <Route path={paths.dashboard.badge.create} element={<UpsertBadge />} />
                <Route path={paths.dashboard.badge.edit} element={<UpsertBadge />} />
                <Route path={paths.dashboard.badge.view} element={<UpsertBadge />} />
                <Route path={paths.dashboard.badge.editAssign} element={<EditAssignBadge />} />
                {/* --------------AppPage--------------- */}
                <Route path={paths.dashboard.appPage.index} element={<AppPageList />} />
                <Route path={paths.dashboard.appPage.create} element={<AppPageCreate />} />
                <Route path={paths.dashboard.appPage.edit} element={<AppPageEdit />} />
                {/* --------------Notification------------- */}
                <Route path={paths.dashboard.notification.index} element={<NotificationList />} />
                <Route path={paths.dashboard.notification.create} element={<NotificationCreate />} />
                <Route path={paths.dashboard.notification.edit} element={<NotificationEdit />} />
                <Route path={paths.dashboard.notification.detail} element={<NotificationDetail />} />
                {/* --------------Shop------------- */}
                <Route path={paths.dashboard.shop.index} element={<ProductsList />} />
                <Route path={paths.dashboard.shop.create} element={<ProductCreate />} />
                <Route path={paths.dashboard.shop.edit} element={<ProductEdit />} />
                <Route path={paths.dashboard.shop.orders.list} element={<OrdersList />} />
                {/* -------------Feedback------------- */}
                <Route path={paths.dashboard.feedback.generalQuestion} element={<GeneralQuestionList />} />
                <Route path={paths.dashboard.feedback.isIdea} element={<IsIdeaList />} />
                <Route path={paths.dashboard.feedback.anonymousComplaint} element={<AnonymousComplaintList />} />
                {/* --------------Analytics----------- */}
                <Route path={paths.dashboard.analytics.general} element={<GeneralAnalytics />} />
                <Route path={paths.dashboard.analytics.course} element={<CourseAnalytics />} />
                <Route path={paths.dashboard.analytics.knowledge} element={<KnowledgeAnalytics />} />
                <Route path={paths.dashboard.analytics.user} element={<UserAnalytics />} />
                <Route path={paths.dashboard.analytics.content} element={<ContentAnalytics />} />
                <Route path={paths.dashboard.analytics.advert} element={<AdvertAnalytics />} />
                <Route path={paths.dashboard.analytics.process} element={<ProcessAnalytics />} />
                <Route path={paths.dashboard.analytics.product} element={<ProductAnalytics />} />
                {/* ------------------Stats-------------- */}
                <Route path={paths.dashboard.analytics.leaderboard} element={<LeaderBoardAnalytics />} />
                <Route path={paths.dashboard.analytics.singleTest} element={<SingleTestAnalytics />} />
                <Route path={paths.dashboard.analytics.singleCourse} element={<SingleCourseAnalytics />} />
                <Route path={paths.dashboard.analytics.singleDocument} element={<SingleDocumentAnalytics />} />
                <Route path={paths.dashboard.analytics.singleAdvert} element={<SingleAdvertAnalytics />} />
                {/* ---------------NoContent------------ */}
                <Route path={paths.dashboard.access.index} element={<NoAccessContent />} />
                {/* ---------------Trainee---------------- */}
                <Route path={paths.dashboard.content.trainee.index} element={<TraineePositionList />} />
                <Route path={paths.dashboard.content.trainee.edit} element={<TraineePositionEdit />} />
            </Route>
        </Routes>
    );
};