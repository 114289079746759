import { FC, useMemo } from "react";
import { CircularProgress, InputAdornment, OutlinedInput, Stack, SvgIcon, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEditAssignBadge } from "../presenter/useEditAssignBadge";
import SearchIcon from '@mui/icons-material/Search';
import { getStyles } from "./styles";
import { EmptyPage } from "../../../content/category/categoryEmpty/ui";
import { tokens } from "../../../../locales/tokens";
import { AssignTable } from "../../../../ui-kit/editAssign/ui/components/assignTable";

export const EditAssignBadge: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { filters, searchTextRef, handleChangeSearch, handleDelete, pageAmount, handlePageChange,
        scrollRef, isProccess, assignList } = useEditAssignBadge();

    return (
        <Stack mt={2} ml={2}>
            <Typography variant="h5" fontWeight={600}>Редагувати призначення</Typography>
            <OutlinedInput
                    defaultValue=""
                    fullWidth
                    inputProps={{ ref: searchTextRef }}
                    onChange={(e)=>handleChangeSearch(e)}
                    placeholder={"Пошук користувачів"}
                    startAdornment={(
                        <InputAdornment position="start">
                            <SvgIcon>
                                <SearchIcon />
                            </SvgIcon>
                        </InputAdornment>
                    )}
                    size="small"
                    sx={{mt: 2}}
                />
            {!!assignList?.length &&
                <AssignTable
                    sortedBy="User"
                    scrollRef={scrollRef}
                    assignList={assignList}
                    page={filters.page}
                    pageAmount={pageAmount}
                    handleDelete={handleDelete}
                    handlePageChange={handlePageChange}
                />}
            {(!assignList?.length && isProccess) &&
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={styles.progress}
                >
                    <CircularProgress color="primary" />
                </Stack>
            }
            {(!assignList?.length && !isProccess) &&
                <Stack direction="row" alignItems="flex-start" justifyContent="center">
                    <EmptyPage
                        title={t(tokens.common.emptyState)}
                        description={t(tokens.common.emptyStateAssign)}
                    />
                </Stack>
            }
        </Stack>
    )
};