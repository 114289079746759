import { useTheme } from "@mui/material/styles";
import { ApexOptions } from "apexcharts";
import { format } from "date-fns";
import { uk } from 'date-fns/locale';

export type ChartSeries = {
    name: string;
    data: (number | null)[];
}[];

const now = new Date();

const createCategories = (): string[] => {
    const categories: string[] = [];
    const currentYear = new Date().getFullYear();
  
    for (let i = 0; i < 12; i++) {
      categories.push(format(new Date(currentYear, i), 'MMM', { locale: uk }));
    }
    
    return categories;
};

export const useAdvertChart = (): ApexOptions => {
    const theme = useTheme();
      const categories = createCategories();

    return {
        chart: {
            background: 'transparent',
            stacked: false,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            }
        },
        colors: [
            "#6366F1",
            "#4338CA",
            "#312E81",
        ],
        dataLabels: {
            enabled: false
        },
        fill: {
            opacity: 1,
            type: 'solid'
        },
        grid: {
            borderColor: theme.palette.divider,
            strokeDashArray: 2,
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            }
        },
        legend: {
            horizontalAlign: 'right',
            labels: {
                colors: theme.palette.text.secondary
            },
            position: 'top',
            show: true
        },
        markers: {
            hover: {
                size: undefined,
                sizeOffset: 2
            },
            radius: 2,
            shape: 'circle',
            size: 4,
            strokeWidth: 0
        },
        stroke: {
            curve: 'smooth',
            dashArray: [0, 3],
            lineCap: 'butt',
            width: 3
        },
        theme: {
            mode: theme.palette.mode
        },
        xaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            categories,
            labels: {
                style: {
                    colors: theme.palette.text.secondary
                }
            }
        },
        yaxis: [
            {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: false
                }
            },
            {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: false
                }
            }
        ]
    };
};