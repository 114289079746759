import { Box, Grid, Stack, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { getStyles } from "./styles";

interface IProps {
    item: number;
    color: string; 
    label: string;
};

export const ChartDescItem: FC<IProps> = ({ color, item, label }) => {
    const styles = useMemo(()=> getStyles(color), [color]);

    return (
        <Grid xs={12} sm={6} item>
            <Stack direction="row" justifyContent="space-between" flex={1}>
                <Stack alignItems="center" direction="row" spacing={1}>
                    <Box sx={styles.point} />
                    <Typography variant="body1">{label}</Typography>
                </Stack>
                <Typography color="GrayText">{item}</Typography>
            </Stack>
        </Grid>
    )
}