import { Box, Pagination, Stack, Table, Theme, useMediaQuery } from "@mui/material";
import { ChangeEvent, FC, MutableRefObject, useMemo } from "react";
import { TestTableBody } from "../testTableBody";
import { getStyles } from "./styles";

interface IProps {
    scrollRef: MutableRefObject<HTMLDivElement | null>;
    testList: any[];
    page: number;
    pageAmount: number;
    handleStatus: (id: number, status: string) => void;
    handleDelete: (id: number) => void;
    handleUpload: (id: number, name: string) => void;
    handlePageChange: (event: ChangeEvent<unknown>, page: number) => void;
};

export const TestTable: FC<IProps> = ({ testList, page, pageAmount, handlePageChange, handleDelete, handleStatus, handleUpload, scrollRef }) => {
    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const styles = useMemo(() => getStyles(lgDown), [lgDown]);
    
    return (
        <>
            <Box>
                <Box sx={styles.tableWrapper} ref={scrollRef}>
                    <Table>
                        <TestTableBody
                            testList={testList}
                            handleStatus={handleStatus}
                            handleDelete={handleDelete}
                            handleUpload={handleUpload}
                        />
                    </Table>
                </Box>
                <Stack direction='row' justifyContent='center' sx={styles.paginationWrapper}>
                    <Pagination
                        page={page}
                        count={pageAmount || 1}
                        color="primary"
                        onChange={handlePageChange}
                    />
                </Stack>
            </Box>
        </>
    )
};