import { Box, Table, TableBody } from "@mui/material";
import { FC, useMemo } from "react";
import { getStyles } from "./styles";
import { UserTableItem } from "../userTableItem";

interface IProps {
    userList: any[];
    handleStatus: (id: number, isBanned: boolean) => void;
};

export const UserTable: FC<IProps> = ({ userList, handleStatus }) => {
    const styles = useMemo(() => getStyles(), []);

    return (
        <Box sx={styles.userTable}>
            <Table>
                <TableBody >
                    {userList.map(user =>
                        <UserTableItem
                            user={user}
                            handleStatus={handleStatus}
                            key={user.uid}
                        />
                    )}
                </TableBody>
            </Table>
        </Box>
    )
};