import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setProductLoading, setGalleryFiles, setErrorGalleryFiles } from "../../repository/slice";

function* addFilesToProductWorker({ payload }: any): SagaIterator {
    try {
        yield put(setProductLoading(true));

        const formData = new FormData();
        for (const [key, value] of Object.entries(payload as Object)) {
            const isValue = value !== null && value !== undefined && value !== "";
            if (isValue) {
                if (Array.isArray(value)) {
                    value.forEach(file => {
                        formData.append(key, file);
                    });
                } else {
                    formData.append(key, value);
                }
            }
        };
        const { data, message, isError }: IResponse<any> = yield call(requester.post, `/api/shop/product/admin/add-images/` + payload.id, formData);
        if (isError) {
            yield put(setErrorGalleryFiles(message));
        } else {
            yield put(setErrorGalleryFiles(null));
            yield put(setGalleryFiles(data))
        }
    } catch (error) {
        console.error("addFilesToProductWorker: ", error);
    } finally {
        yield put(setProductLoading(false));
    };
};

export function* addFilesToProductWatcher() {
    yield takeLatest("@saga/ADD_FILES_TO_PRODUCT", addFilesToProductWorker);
};
