import { FC } from "react";
import { Stack, Table, TableBody } from "@mui/material";
import { IRole } from "../../../../../../store/role/types/IRole";
import { RoleTableItem } from "../roleTableItem";
import { EmptyPage } from "../../../../../content/category/categoryEmpty/ui";
import { tokens } from "../../../../../../locales/tokens";
import { useTranslation } from "react-i18next";

interface IProps {
    roles: IRole[];
    onDelete: (id: number) => void;
};

export const RolesTable: FC<IProps> = ({ roles, onDelete }) => {
    const { t } = useTranslation();

    if (roles.length) {
        return (
            <Table>
                <TableBody>
                    {roles.map(role => (
                        <RoleTableItem
                            key={role.id}
                            role={role}
                            onDelete={onDelete}
                        />
                    ))
                    }
                </TableBody>
            </Table>
        )
    } else {
        return (
            <Stack my={3}>
                <EmptyPage
                    title={t(tokens.documents.emptyListTitle)}
                    description={t(tokens.roles.emptyListDescription)}
                />
            </Stack>
        )
    }
};