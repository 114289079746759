export const getStyles = (color: string) => {
    const styles = {
        point: { 
            backgroundColor: color, 
            borderRadius: '50%', 
            height: 8, 
            width: 8 
        }
    };
    return styles;
};