export const swapRows = (
  firstId: string,
  secondId: string,
  name: string,
  changer: Function
) => {
  const firstElement = document.getElementById(name + firstId.toString());
  const secondElement = document.getElementById(name + secondId.toString());

  if (secondElement && firstElement) {
    firstElement.style.transition = "transform ease-in 0.4s";
    secondElement.style.transition = "transform ease-in 0.4s";

    const finalChildAStyle = {
      x: 0,
      y: 0,
    };
    const finalChildBStyle = {
      x: 0,
      y: 0,
    };

    finalChildAStyle.x =
      firstElement.getBoundingClientRect().left -
      secondElement.getBoundingClientRect().left;
    finalChildAStyle.y =
      secondElement.getBoundingClientRect().top -
      firstElement.getBoundingClientRect().top;
    finalChildBStyle.x =
      secondElement.getBoundingClientRect().left -
      firstElement.getBoundingClientRect().left;
    finalChildBStyle.y =
      firstElement.getBoundingClientRect().top -
      secondElement.getBoundingClientRect().top;

    firstElement.style.transform = `translate(${finalChildAStyle.x}px, ${finalChildAStyle.y}px)`;
    secondElement.style.transform = `translate(${finalChildBStyle.x}px, ${finalChildBStyle.y}px)`;

    setTimeout(() => {
      firstElement.parentElement?.insertBefore(secondElement, firstElement);

      firstElement.removeAttribute("style");
      secondElement.removeAttribute("style");

      changer();
    }, 400);
  }
};
