import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { styles } from './styles';
import { useNotificationEdit } from '../presenters/useNotificationEdit';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/tokens';
import { Audience } from '../../notificationCreate/ui/components/audience';
import { Content } from '../../notificationCreate/ui/components/content';
import { ResponsiveDateTimePickers } from '../../notificationCreate/ui/components/responsiveDateTimePicker';

export const NotificationEdit = () => {
    const { t } = useTranslation();

    const {
        title, text, jsonData, imageUrl, audience, userId, onChangeCountry, country, onChangeRegion, onChangeStatus, roleList, handleUserChange, user, 
        onChangeAudience, onChangImageUrl, onUpdateNotification, onChangTitle, onChangeSwitch, onChangeTime, checked, group, region, imageUrlValid, imageSrcValid,
        onChangText, onChangJsonData, onChangeUserId, onAcceptTime, time, onSearch, onSearchUsers, userList, status, imageType, onChangeImageType, onUserChange,
        imageTypeOptions, imageSrc, handleFileChange, handleClearImageClick, updateClick, titleValid, textValid, cancelCreate, isDeleted, navLinkValid
    } = useNotificationEdit(t);

    return (
        <>
            <Divider />
            <Box component="main" sx={styles.mainBox}>
                    <Typography variant="h4" fontWeight={600} mb={2} pl={3}>{t(tokens.notification.updatePush)}</Typography>
                    <Audience
                        country={country}
                        onChangeCountry={onChangeCountry}
                        region={region}
                        onChangeRegion={onChangeRegion}
                        audience={audience}
                        onChangAudience={onChangeAudience}
                        userStatus={status}
                        onChangeUserStatus={onChangeStatus}
                        users={userList}
                        roles={roleList}
                        onSearch={onSearch}
                        onSearchUsers={onSearchUsers}
                        groupUsers={group}
                        handleUserChange={handleUserChange}
                        isDisabled={isDeleted}
                        user={user}
                        onUserChange={onUserChange}
                    />
                    <Grid container spacing={3} sx={styles.data}>
                        <Grid item md={6} xs={12}>
                            <Content
                                title={title}
                                titleValid={titleValid}
                                onChangTitle={onChangTitle}
                                text={text}
                                textValid={textValid}
                                onChangText={onChangText}
                                imageUrl={imageUrl}
                                onChangImageUrl={onChangImageUrl}
                                linkTo={jsonData}
                                onChangLinkTo={onChangJsonData}
                                imageType={imageType}
                                onChangeImageType={onChangeImageType}
                                imageTypeOptions={imageTypeOptions}
                                imageSrc={imageSrc}
                                handleFileChange={handleFileChange}
                                handleClearImageClick={handleClearImageClick}
                                isDisabled={isDeleted}
                                navLinkValid={navLinkValid}
                                imageUrlValid={imageUrlValid}
                                imageSrcValid={imageSrcValid}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <ResponsiveDateTimePickers
                                time={time}
                                checked={checked}
                                onChangeSwitch={onChangeSwitch}
                                onChangeTime={onChangeTime}
                                onAcceptTime={onAcceptTime}
                                isDisabled={isDeleted}
                            />
                        </Grid>
                    </Grid>
                    {!isDeleted &&
                    <Stack direction='row' spacing={3} pl={3}>
                        <Button variant="contained" sx={styles.createButton} onClick={onUpdateNotification} disabled={updateClick}>
                            {t(tokens.common.save)}
                        </Button>
                        <Button variant="outlined" sx={styles.cancelButton} onClick={cancelCreate} disabled={updateClick}>
                            {t(tokens.common.cancel)}
                        </Button>
                    </Stack>}
            </Box>
        </>
    );
};