import { Box, Button, CircularProgress, Stack, SvgIcon, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../locales/tokens";
import AddIcon from '@mui/icons-material/Add';
import { Link } from "react-router-dom";
import { getStyles } from "./styles";
import { Filters } from "./components/filters";
import { useDocumentsList } from "../presenters/useDocumentsList";
import { SearchInput } from "../../../../../ui-kit/searchInput/searchInput";
import { TabsChanger } from "../../../../../ui-kit/tabsChanger";
import { DocumentTable } from "./components/documentTable";
import { paths } from "../../../../../paths";
import { EmptyPage } from "../../../category/categoryEmpty/ui";

export const DocumentsList: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { filters, searchText, handleTabsChange, handleSearchDocumentText, handleChangeCategory, changeType, changeStatus, handlePageChange,
        documentsList, categoryList, orgStructure, changeCategoryText, handleDateChange, handleStatus, onChangeInitiator, handleUploadReport,
        pageAmount, resetFilters, handleDelete, onSelectInitiator, deleteClick, documentId, isProccessDocument, scrollRef } = useDocumentsList();

    return (
        <Stack m={2}>
            <Stack>
                <Stack direction='row' justifyContent='space-between'>
                    <Typography variant="h5" fontWeight={600}>
                        {t(tokens.documents.listDocuments)}
                    </Typography>
                    <Button
                        startIcon={(<SvgIcon><AddIcon /></SvgIcon>)}
                        variant="contained"
                        sx={styles.createBtn}
                        component={Link}
                        to={paths.dashboard.content.document.create}
                    >
                        {t(tokens.common.create)}
                    </Button>
                </Stack>
                <Stack mt={3}>
                    <SearchInput
                        size="small"
                        searchText={searchText}
                        label={t(tokens.documents.searchByDocumentName)}
                        handleSearch={handleSearchDocumentText}
                    />
                </Stack>
                <Filters
                    filters={filters}
                    categoryList={categoryList}
                    orgStructure={orgStructure}
                    changeType={changeType}
                    changeStatus={changeStatus}
                    handleDateChange={handleDateChange}
                    resetFilters={resetFilters}
                    changeCategoryText={changeCategoryText}
                    handleChangeCategory={handleChangeCategory}
                    onSelectInitiator={onSelectInitiator}
                    onChangeInitiator={onChangeInitiator}
                />
                <TabsChanger
                    tab={filters.tab}
                    handleTabsChange={handleTabsChange}
                />
            </Stack>
            {documentsList?.length > 0 &&
                <DocumentTable
                    scrollRef={scrollRef}
                    page={filters.page}
                    documentList={documentsList}
                    handlePageChange={handlePageChange}
                    pageAmount={pageAmount}
                    isProcess={deleteClick}
                    documentId={documentId}
                    handleStatus={handleStatus}
                    handleDelete={handleDelete}
                    handleUpload={handleUploadReport}
                />}
            {(!documentsList?.length && isProccessDocument) &&
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={styles.progress}
                >
                    <CircularProgress color="primary" />
                </Stack>
            }
            {(!documentsList?.length && !isProccessDocument) &&
                <Stack direction="row" alignItems="flex-start" justifyContent="center">
                    <EmptyPage
                        title={t(tokens.documents.emptyListTitle)}
                        description={t(tokens.documents.emptyListDesc)}
                    />
                </Stack>
            }
        </Stack >
    )
};