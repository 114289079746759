import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setCreateDocumentError } from "../../repository/slice";
import { putDocumentInCategory } from "../putDocumentInCategory/action";

function* createDocumentWorker({ payload }: any): SagaIterator { 
    try {
        const formData = new FormData();
        for (const [key, value] of Object.entries(payload.body as Object)) {
            const isValue = value !== null && value !== undefined && value !== "";
            if (isValue) {
                if (Array.isArray(value)) {
                    if (typeof value[0] === 'number') {
                        formData.append(key, JSON.stringify(value))
                    } else if (typeof value[0] === 'string') {
                        formData.append(key, value.join(", "));
                    } else {
                        value.forEach(item => {
                            formData.append(key, item);
                        });
                    }
                } else {
                    formData.append(key, value);
                }
            }
        };

        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.post, "/api/admin/document/create", formData);
        if (isError) {
            yield put(setCreateDocumentError(message));
        } else {
            yield put(setCreateDocumentError(null));
            if (payload.category) {
                yield put(putDocumentInCategory({ document_id: data.id, category_id: payload.category.id }));
            }
        }
    } catch (error) {
        console.error("createDocumentWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* createDocumentWatcher() {
    yield takeLatest("@saga/CREATE_DOCUMENT", createDocumentWorker);
};
