import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { selectOrder } from "../../../../store/order/repository/selector";
import { ORDERS_PER_PAGE } from "../../../../store/order/useCases/getOrders/worker";
import { setOrderError, setOrderList } from "../../../../store/order/repository/slice";
import { updateOrderStatus } from "../../../../store/order/useCases/updateOrderStatus/action";
import { useDebounce } from "../../../../hooks/useDebounce";
import { getUserOrders } from "../../../../store/order/useCases/getUserOrder/action";
import { useParams } from "react-router-dom";
import { selectUser } from "../../../../store/user/repository/selector";

export const useOrderHistory = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState<'NEW' | 'PROCESSED'>('NEW');
    const dispatch = useDispatch();
    const [isUpdateClick, setIsUpdateClick] = useState(false);
    const [isRequestList, setIsRequestList] = useState(false);
    const [search, setSearch] = useState('');
    const [page, setPage] = useState(1)
    const { isOrderLoading, orderList, orderCount, orderError } = useSelector(selectOrder);
    const { singleUser } = useSelector(selectUser);
    const scrollRef = useRef<HTMLDivElement>(null);

    const pageAmount = useMemo(() => {
        const count = orderCount / ORDERS_PER_PAGE;
        return count > 1 ? Math.ceil(count) : 1;
    }, [orderCount]);

    useEffect(() => {
        if(id){
            dispatch(getUserOrders({ id: Number(id), page, perPage: ORDERS_PER_PAGE, status: activeTab, search }));
            scrollTopList();
        }
    }, [id, activeTab]);

    useEffect(() => {
        if (!isOrderLoading && isUpdateClick) {
            if (orderError) {
                toast.error(orderError)
                dispatch(setOrderError(null));
            } else {
                dispatch(getUserOrders({ id: Number(id), page, perPage: ORDERS_PER_PAGE, status: activeTab, search }));
                toast.success(t(tokens.order.messages.updated));
            }
            setIsUpdateClick(false);
        };
    }, [isOrderLoading]);

    useEffect(() => {
        if (!isOrderLoading && isRequestList) {
            if (orderError) {
                toast.error(orderError)
                dispatch(setOrderError(null));
            }
            setIsRequestList(false);
        };
    }, [isOrderLoading]);

    const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
        setIsRequestList(true);
        setPage(1);
        debouncedSearch(e.target.value);
    }

    const requestSearch = (search: string) => {
        dispatch(getUserOrders({ id: Number(id), page, perPage: ORDERS_PER_PAGE, status: activeTab, search }));
    }

    const { debouncedWrapper: debouncedSearch } = useDebounce(requestSearch, 700)

    const onToggleTab = () => {
        setIsRequestList(true);
        setPage(1);
        setActiveTab(prev => prev === 'NEW' ? 'PROCESSED' : 'NEW');
    };

    const onChangePage = (_: any, selectedPage: number) => {
        setIsRequestList(true);
        setPage(selectedPage);
        dispatch(getUserOrders({ id: Number(id), page: selectedPage, perPage: ORDERS_PER_PAGE, status: activeTab, search }));
        scrollTopList();
    };

    const onProcessedOrder = (orderId: number) => {
        if (orderId) {
            setIsUpdateClick(true);
            const updatedOrders = orderList.filter(order => order.id !== orderId);
            dispatch(setOrderList({ rows: updatedOrders, count: (orderCount - 1) }));
            dispatch(updateOrderStatus({ id: Number(orderId), status: 'ACCEPT' }));
        } else {
            toast.error('Something went wrong');
        };
    };

    const scrollTopList = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        };
    };

    return { activeTab, isOrderLoading, isRequestList, orderList, page, pageAmount, search, onSearch, onChangePage, onToggleTab, onProcessedOrder, singleUser, scrollRef };
};