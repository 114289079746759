import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { useNotificationCreation } from '../presenters/useNotificationCreation';
import { styles } from './styles';
import { ResponsiveDateTimePickers } from './components/responsiveDateTimePicker';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../locales/tokens';
import { Audience } from './components/audience';
import { Content } from './components/content';
import { FC } from 'react';

export const NotificationCreate: FC = () => {
    const { t } = useTranslation();
    const { title, text, linkTo, imageUrl, audience, userId, onChangeUserId, onChangAudience, checked, region, imageTypeOptions,
        onChangImageUrl, onCreateNotification, onChangTitle, onChangText, onChangLinkTo, onChangeRegion, roleList, time, onAcceptTime,
        onChangeCountry, onChangeUserStatus, country, userStatus, userList, onSearch, onSearchUsers, onChangeSwitch, onChangeTime, imageSrcValid,
        handleUserChange, imageType, onChangeImageType, imageSrc, handleFileChange, handleClearImageClick, createClick, titleValid,
        textValid, cancelCreate, navLinkValid, imageUrlValid, onUserChange, user } = useNotificationCreation(t);

    return (
        <>
            <Divider />
            <Box component="main" sx={styles.mainBox}>
                <Typography variant="h4" fontWeight={600} mb={2} pl={3}>{t(tokens.notification.createPush)}</Typography>
                <Audience
                    country={country}
                    onChangeCountry={onChangeCountry}
                    region={region}
                    onChangeRegion={onChangeRegion}
                    audience={audience}
                    onChangAudience={onChangAudience}
                    userStatus={userStatus}
                    onChangeUserStatus={onChangeUserStatus}
                    users={userList}
                    roles={roleList}
                    onSearch={onSearch}
                    handleUserChange={handleUserChange}
                    onSearchUsers={onSearchUsers}
                    onUserChange={onUserChange}
                    user={user}
                />
                <Grid container spacing={3} sx={styles.data}>
                    <Grid item md={6} xs={12}>
                        <Content
                            title={title}
                            titleValid={titleValid}
                            textValid={textValid}
                            onChangTitle={onChangTitle}
                            text={text}
                            onChangText={onChangText}
                            imageUrl={imageUrl}
                            onChangImageUrl={onChangImageUrl}
                            linkTo={linkTo}
                            onChangLinkTo={onChangLinkTo}
                            imageType={imageType}
                            onChangeImageType={onChangeImageType}
                            imageTypeOptions={imageTypeOptions}
                            imageSrc={imageSrc}
                            handleFileChange={handleFileChange}
                            handleClearImageClick={handleClearImageClick}
                            navLinkValid={navLinkValid}
                            imageUrlValid={imageUrlValid}
                            imageSrcValid={imageSrcValid}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <ResponsiveDateTimePickers
                            time={time}
                            checked={checked}
                            onChangeSwitch={onChangeSwitch}
                            onChangeTime={onChangeTime}
                            onAcceptTime={onAcceptTime}
                        />
                    </Grid>
                </Grid>
                <Stack direction='row' spacing={3} pl={3}>
                    <Button variant="contained" sx={styles.createButton} onClick={onCreateNotification} disabled={createClick}>
                        {t(tokens.common.create)}
                    </Button>
                    <Button variant="outlined" sx={styles.cancelButton} onClick={cancelCreate} disabled={createClick}>
                        {t(tokens.common.cancel)}
                    </Button>
                </Stack>
            </Box>
        </>
    );
};