import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { recoverPassword } from "../../../../store/auth/useCases/recoverPassword/action";
import { selectAuth } from "../../../../store/auth/repository/selector";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { useNavigate } from "react-router-dom";

export const useForgotPassword = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { recoverPasswordError, isLoading } = useSelector(selectAuth);
    const [phone, setPhone] = useState('');
    const [phoneValid, setPhoneValid] = useState(true);
    const phoneInputRef = useRef<ReactInputMask | null>(null);
    const [recoverClick, setRecoverClick] = useState(false);
    const [isShownModal, setShownModal] = useState(false);
    const [timerInSec, setTimerInSec] = useState(0);
    const [requestCount, setRequestCount] = useState(1);
    const [isHideError, setHideError] = useState(false);

    useEffect(() => {
        if (!isLoading && recoverClick) {
            if (recoverPasswordError) {
                // toast.error(t(tokens.auth.messages.userNotFound));
                setPhoneValid(false);
            } else {
                setShownModal(true);
                setTimerInSec(59);
            }
            setRecoverClick(false);
        }
    }, [recoverPasswordError, isLoading]);

    useEffect(() => {
        const timer = setInterval(() => {
            if (timerInSec > 0) {
                setTimerInSec(timerInSec - 1);
            }
        }, 1000);
        return () => {
            clearInterval(timer);
        };
    }, [timerInSec]);

    const onChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
        setPhoneValid(true);
        setPhone(event.target.value);
    };

    const handleEnter = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && phoneValid) {
            handleRecover();
        }
    };

    const handleRecover = () => {
        const isPhoneValid = validatePhone(phone);
        setPhoneValid(isPhoneValid);
        if(!isPhoneValid) return;

        // setHideError(false);

        if (requestCount < 4) {
            dispatch(recoverPassword({ login: formatPhone(phone) }));
            setRecoverClick(true);
            setRequestCount(requestCount + 1);
            setHideError(true);
        } else {
            setShownModal(false);
            toast.error(t(tokens.auth.messages.threeAttempts));
        }
    };

    const validatePhone = (phoneNumber: string) => {
        const phonePattern = /^\+380 \(\d{2}\)-\d{3}-\d{2}-\d{2}$/;
        return phonePattern.test(phoneNumber);
    };

    const formatPhone = (phone: string) => {
        return phone.replace(/\D/g, '');
    };

    const handleClose = useCallback(() => {
        setShownModal(false);
    }, []);

    const handleOk = useCallback(() => {
        navigate('/login');
    }, []);

    return { phone, onChangePhone, phoneValid, handleEnter, phoneInputRef, handleRecover, isShownModal, timerInSec, handleClose, handleOk, isHideError, isLoading };
};