export const TASK_TYPES = {
    DOCUMENT: "Document",
    TEST: "Test",
    QUESTIONNAIRE: "Questionnaire",
    COURSE: "Course",
};

export interface ITask {
    id: number;
    sortNumber: number;
    day: number;
    document?: ITaskItem;
    questionnaire?: ITaskItem;
    quiz?: ITaskItem;
};

export interface ITaskItem {
    id: number;
    name: string;
};

export interface IRequestBody{
    id?: number;
    name: string;
    deadline?: string | null;
    access?: string[];
    openNextDayImmediately: boolean;
    needInform: boolean;
    status: "Available" | "Hidden";
    description?: string | null;
    points?: number | null;
    badgeId?: number | null;
    assigns?: string[];
    initiatorId?: number;
    days_to_deadline?: number | null;
    accessDepartmentIds?: string[];
    accessPositionIds?: string[];
    assignDepartmentIds?: string[];
    assignPositionIds?: string[];
}