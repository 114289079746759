export const getStyles = () => {
    const styles = {
        loginBtn: {
            mt: 2,
            backgroundColor: "#F8B043",
            color: '#FFFFFF',
            height: '45px'
        }
    };
    return styles;
}