import React, { FC } from 'react';
import { Box, Card, Stack, Typography } from '@mui/material';
import { INotification } from '../../../../../../store/pushNotification/repository/INotification';
import { styles } from './styles';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../../locales/tokens';

interface IProps {
    item: INotification | null;
}

export const NotificationPreview: FC<IProps> = ({ item }) => {
    const { t } = useTranslation();

    return (
        <>
            <Typography color='inherit' variant='h6' fontWeight={600}>{t(tokens.notification.preview)}</Typography>
            <Stack sx={styles.container}>
                <Box sx={styles.header}>
                    <NotificationsIcon sx={styles.bell} />
                    <Typography variant='body2'>Pizza Way</Typography>
                </Box>
                <Box sx={styles.data}>
                    <Box sx={styles.dataBox}>
                        <Typography variant='body1' fontWeight={600} sx={styles.dataText}>{item?.title}</Typography>
                        <Typography variant='body2' sx={styles.dataText}>{item?.text}</Typography>
                    </Box>
                    {item?.image_url ? <Box component="img" sx={styles.image} alt="The notification image" src={item?.image_url} /> : null}
                </Box>
            </Stack>
        </>
    );
};
