export interface IUserOption {
    label: string;
    name: string;
    id: number;
}

export const flatUsersList: Function = (users: any[]) => {
    return users?.reduce((result: any, user: any) => {

        return [
            ...result,
            {
                label: user.first_name + " " + user.last_name,
                name: user.first_name + " " + user.last_name,
                id: user.id,
            },
        ];
    }, [] as IUserOption[]) ?? [];
};