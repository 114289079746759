import { SagaIterator } from "redux-saga";
import { put, call, takeLatest } from "redux-saga/effects";
import { setErrorsSendNotification, setPushNotificationLoading } from "../../repository/actions";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";

function* sendNotificationWorker({ payload }: any): SagaIterator {
    try {
        yield put(setPushNotificationLoading(true));
        const { isError, message }: IResponse<any> = yield call(requester.post, "/api/user-notification/send/" + payload.id);

        if (isError) {
            yield put(setErrorsSendNotification(message));
        } else {
            yield put(setErrorsSendNotification(null));
        }
    } catch (error) {
        console.error("sendNotificationWorker: ", error);
    } finally {
        yield put(setPushNotificationLoading(false));
    }
}

export function* sendNotificationWatcher() {
    yield takeLatest("@saga/SEND_NOTIFICATION", sendNotificationWorker);
}
