import { Button, CircularProgress } from "@mui/material";
import { FC, useMemo } from "react";
import { getStyles } from "./styles";
import { tokens } from "../../../../../../locales/tokens";
import { useTranslation } from "react-i18next";

interface LogInButtonProps {
    isLoading?: boolean;
    handleLogin: () => void;
}

export const LogInButton: FC<LogInButtonProps> = ({ handleLogin, isLoading }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    return (
        <Button
            fullWidth
            variant="contained"
            size="large"
            sx={styles.loginBtn}
            onClick={handleLogin}
            disabled={isLoading}
        >
            {isLoading ?
                <CircularProgress size={20}/>
                : t(tokens.auth.enter)}
        </Button>
    )
};