export interface IBadgeOption {
    label: string;
    name: string;
    id: number;
    imgUrl: string
};

export const collectBadgeOptionList: Function = (options: any[]) => {
    const result = options?.reduce((result: any, option: any) => {
        return [
            ...result,
            {
                label: option.name,
                name: option.name,
                id: option.id,
                imgUrl: option.image?.url
            },
        ];
    }, [] as IBadgeOption[]) ?? [];
    return result;
};