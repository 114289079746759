import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setProductLoading, setSelectedProduct, setProductError } from "../../repository/slice";
import { convertToFormData } from "../../../../utils";
import { IProduct } from "../../types/IProduct";

function* creteProductWorker({ payload }: any): SagaIterator {
    try {
        yield put(setProductLoading(true));
        const body = convertToFormData(payload)
        const { data, message, isError }: IResponse<IProduct> = yield call(requester.post, "/api/shop/product/admin/create", body);
        if (isError) {
            yield put(setProductError(message));
        } else {
            yield put(setSelectedProduct(data));
        }
    } catch (error) {
        console.error("creteProductWorker: ", error);
    } finally {
        yield put(setProductLoading(false));
    };
};

export function* createProductWatcher() {
    yield takeLatest("@saga/CREATE_PRODUCT", creteProductWorker);
};