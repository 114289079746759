export const getStyles = (lgDown: boolean) => {
    const styles = {
        tableWrapper: {
            height: "calc(100vh - 200px - 90px)",
            position: 'relative',
            overflowY: "scroll",
            width: "100%"
        },
        paginationWrapper: {
            position: 'fixed',
            bottom: 2,
            left: lgDown? 0 : '150px',
            right: 0,
            width: '100%',
            zIndex: 1000,
            height: '50px'
        }
    };
    return styles;
};