export const getStyles = (lgDown: boolean) => {
    const styles = {
        mainBox: {
            flexGrow: 1, 
            pt: 2,
            pb: 4,
            px: 2
        },
        backDrop: { 
            color: '#fff', 
            zIndex: 100, 
            ml: lgDown? 0 : '350px' 
        },  
    };
    return styles;
}
