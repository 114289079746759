import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { paths } from "../../../../paths";
import { getRoleById } from "../../../../store/role/useCases/getRoleById/action";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { selectRole } from "../../../../store/role/repository/selector";
import { setRoleError, setSelectedRole } from "../../../../store/role/repository/slice";
import { updateRole } from "../../../../store/role/useCases/updateRole/action";
import { tokens } from "../../../../locales/tokens";
import { useTranslation } from "react-i18next";
import { getPermissions } from "../../../../store/permission/useCases/getPermissions/action";
import { selectPermission } from "../../../../store/permission/repository/selector";
import { SelectChangeEvent } from "@mui/material";
import { IPermission } from "../../../../store/permission/types/IPermission";
import { addRolePermission } from "../../../../store/permission/useCases/addRolePermission/action";
import { deleteRolePermission } from "../../../../store/permission/useCases/deleteRolePermission/action";
import { setPermissionError } from "../../../../store/permission/repository/slice";

const initialForm = {
    name: ''
};

const initialFormErrors = {
    roleNameError: false
};

export const useRoleEdit = () => {
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const { t } = useTranslation();
    const [form, setForm] = useState(initialForm);
    const [formErrors, setFormErrors] = useState(initialFormErrors);
    const [isClick, setIsClick] = useState(false);
    const [isAddPermissionClick, setIsAddPermissionClick] = useState(false);
    const [isDeletePermissionClick, setIsDeletePermissionClick] = useState(false);
    const { id: roleId } = useParams();
    const { isRoleLoading, selectedRole, roleError } = useSelector(selectRole);
    const { isPermissionLoading, permissionList, permissionError } = useSelector(selectPermission);
    const isRoleDeleted = !!selectedRole?.deleted_at;

    useEffect(() => {
        if (roleId) {
            dispatch(getRoleById({ id: Number(roleId) }));
        } else {
            toast.error('Something went wrong');
        };
        if (!permissionList.length) {
            dispatch(getPermissions());
        }

        return () => { dispatch(setSelectedRole(null)) };
    }, []);

    useEffect(() => {
        if (selectedRole) {
            setForm({ ...form, name: selectedRole.name });
        };
    }, [selectedRole]);

    useEffect(() => {
        if (!isRoleLoading && isClick) {
            if (roleError) {
                toast.error(roleError)
                dispatch(setRoleError(null));
            } else {
                toast.success(t(tokens.roles.messages.roleUpdated));
                navigation(paths.dashboard.users.roles)
            }
            setIsClick(false);
        };
    }, [isRoleLoading]);

    useEffect(() => {
        if (!isPermissionLoading && isAddPermissionClick) {
            if (permissionError) {
                toast.error(permissionError)
                dispatch(setPermissionError(null));
            } else {
                toast.success(t(tokens.roles.messages.roleUpdated));
            }
            setIsAddPermissionClick(false);
        };
    }, [isPermissionLoading, isAddPermissionClick]);

    useEffect(() => {
        if (!isPermissionLoading && isDeletePermissionClick) {
            if (permissionError) {
                toast.error(permissionError)
                dispatch(setPermissionError(null));
            } else {
                toast.success(t(tokens.roles.messages.roleUpdated));
            }
            setIsAddPermissionClick(false);
        };
    }, [isPermissionLoading, isDeletePermissionClick]);

    const isValidForm = useCallback(() => {
        let valid = true;
        let errors = formErrors;
        const { name } = form;
        if (name.length) {
            errors = { ...formErrors, roleNameError: false };
        } else {
            valid = false;
            errors = { ...formErrors, roleNameError: true }
        };
        setFormErrors(errors)
        return valid;
    }, [form]);

    const onChangeRoleName = (e: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, name: e.target.value });
        if (formErrors.roleNameError) {
            setFormErrors({ ...formErrors, roleNameError: false });
        };
    };

    const onSelectPermission = (event: SelectChangeEvent<IPermission[]>) => {
        const permissionId: any = event.target.value[event.target.value.length - 1];
        const selectedPermission = permissionList.find(permission => permission.id === permissionId);
        const isPermissionExists = selectedRole?.permissions?.find(permission => permission.id === permissionId);

        if (selectedPermission && selectedRole) {
            if (isPermissionExists) {
                const updatedRolePermissions = selectedRole.permissions.filter(permission => permission.id !== permissionId)
                dispatch(setSelectedRole({ ...selectedRole, permissions: updatedRolePermissions }));
                dispatch(deleteRolePermission({ role_id: selectedRole.id, permission_id: permissionId }));
                setIsDeletePermissionClick(true);
            } else {
                const updatedSelectedRole = { ...selectedRole, permissions: [...selectedRole?.permissions || [], selectedPermission] }
                dispatch(setSelectedRole(updatedSelectedRole));
                dispatch(addRolePermission({ role_id: selectedRole.id, permission_id: permissionId }));
                setIsAddPermissionClick(true);
            }
        }
    }

    const onEditRole = () => {
        const isValid = isValidForm();
        if (isValid) {
            if (roleId) {
                setIsClick(true);
                dispatch(updateRole({ id: Number(roleId), ...form }));
            } else {
                toast.error('Something went wrong');
            };
        } else {
            toast.error(t(tokens.common.fillAllFields));
        };
    };

    const onCancel = () => navigation(paths.dashboard.users.roles);

    return { form, formErrors, isClick, selectedRole, permissionList, isRoleDeleted, onChangeRoleName, onSelectPermission, onEditRole, onCancel };
};