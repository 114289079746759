import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setDeleteTaskError } from "../../repository/slice";
import i18next from "i18next";
import toast from "react-hot-toast";
import { tokens } from "../../../../locales/tokens";
import { getSingleCourse } from "../getSingleCourse/action";

function* deleteTaskWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.delete, `/api/admin/courses/${payload.body.courseId}/remove-content`, payload.body);
        if (isError) {
            yield put(setDeleteTaskError(message));
        } else {
            yield put(setDeleteTaskError(null));
            yield put(getSingleCourse({ id: payload.body.courseId }))
            toast.success(i18next.t(tokens.course.message.taskRemoved));
        }
    } catch (error) {
        console.error("deleteTaskWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* deleteTaskWatcher() {
    yield takeEvery("@saga/DELETE_COURSE_TASK", deleteTaskWorker);
};
