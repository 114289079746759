import { IError } from "../../../types/error";

export const setPushNotificationLoading = (payload: boolean) => ({
    type: "SET_PUSH_LOADING",
    payload,
});

export const setPushNotifications = (payload: any) => ({
    type: "SET_PUSH_NOTIFICATIONS",
    payload,
});

export const setPushNotification = (payload: any) => ({
    type: "SET_PUSH_NOTIFICATION",
    payload,
});

export const setErrorsGetPushNotifications = (payload: IError) => ({
    type: "SET_ERRORS_GET_NOTIFICATIONS",
    payload,
});

export const setErrorsGetNotificationById = (payload: IError) => ({
    type: "SET_ERRORS_GET_NOTIFICATION_BY_ID",
    payload,
});

export const setErrorsCreatePushNotification = (payload: IError) => ({
    type: "SET_ERRORS_CREATE_NOTIFICATION",
    payload,
});

export const setErrorsDeletePushNotification = (payload: IError) => ({
    type: "SET_ERRORS_DELETE_NOTIFICATION",
    payload,
});

export const setErrorsSendNotification = (payload: IError) => ({
    type: "SET_ERRORS_SEND_NOTIFICATION",
    payload,
});

export const setErrorsUpdateNotification = (payload: IError) => ({
    type: "SET_ERRORS_UPDATE_NOTIFICATION",
    payload,
});
export const setCount = (payload: number) => ({
    type: "SET_COUNT",
    payload,
});