import { Stack } from "@mui/material";
import { ChangeEvent, FC, MutableRefObject, useMemo } from "react";
import { PasswordInput } from "../passwordInput";
import { VisibleButton } from "../visibleButton";
import { getStyles } from "./styles";
import { IError } from "../../../../../../types/error";

interface PasswordFieldProps {
    signInError?: IError;
    title: string;
    passwordInputRef: MutableRefObject<HTMLInputElement | null>;
    password: string;
    passwordValid: boolean;
    isHideError: boolean;
    isPassShown: boolean;
    onShowPassword: () => void;
    handleEnterPassword: (e: React.KeyboardEvent) => void;
    onChangePassword: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const PasswordField: FC<PasswordFieldProps> = ({ isPassShown, onChangePassword, onShowPassword, password, passwordValid, passwordInputRef,
    handleEnterPassword, isHideError, title, signInError }) => {
    const styles = useMemo(() => getStyles(), []);

    return (
        <Stack sx={styles.password}>
            <PasswordInput
                title={title}
                password={password}
                onChangePassword={onChangePassword}
                passwordValid={passwordValid}
                isHideError={isHideError}
                showPassword={isPassShown}
                passwordInputRef={passwordInputRef}
                handleEnterPassword={handleEnterPassword}
                signInError={signInError}
            />
            <VisibleButton isPassShown={isPassShown} onShowPassword={onShowPassword} />
        </Stack>
    )
};