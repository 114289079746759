
export const getStyles = () => {
    const styles = {
        logoContainer: {
            justifyContent: 'center',
            alignItems: 'center',
            p: 3
        },
        navContainer: {
            flexGrow: 1,
            px: 2,
            pt: 1,
            pb: 4
        },
        pattern: { 
            position: 'absolute', 
            top: '-5px', 
            zIndex: -1, 
            width: '100%', 
            height: '100%', 
            objectFit: 'cover' 
        }
    }
    return styles;
}