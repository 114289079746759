export const getStyles = () => {
    const styles = {
        clearImage: { 
            position: 'absolute', 
            top: '1px', 
            left: '70px', 
            backgroundColor: '#d3d3d3', 
            borderRadius: '50%', 
            '&:hover': { 
                backgroundColor: '#bfbfbf' 
            } 
        }
    };
    return styles;
};