import { FC, useMemo } from "react";
import { useDocumentEdit } from "../presenters/useDocumentsEdit";
import { Backdrop, Button, Card, CardMedia, Checkbox, CircularProgress, Dialog, Divider, FormControlLabel, Stack, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../locales/tokens";
import { ItemSelector } from "../../../../../ui-kit/itemSelector/itemSelector";
import { CheckboxAutocompleteSingle } from "../../../../../ui-kit/checkboxAutocompleteSingle";
import { CheckboxAutocompleteMultiple } from "../../../../../ui-kit/checkboxAutocompleteMultiple";
import { getStyles } from "./styles";
import { CategoryAutocomplete } from "../../../../../ui-kit/categoryAutocomplete";
import TextEditor from "../../../../../ui-kit/textEditor/textEditor";
import { FileDropzone } from "../../../../../ui-kit/fileDropZone";
import { FileSelector } from "./components/fileSelector";
import { updateFiles } from "../../../../../store/document/useCases/updateFiles/action";
import { CheckboxDepartmentAutocompleteMultiple } from "../../../../../ui-kit/checkboxDepartmentAutocompleteMultiple";
import { SelectCheckBox } from "../../../../../ui-kit/selectCheckbox";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Link } from "react-router-dom";
import { paths } from "../../../../../paths";
import { AutocompleteCheckBox } from "../../../../../ui-kit/autocompleteCheckBox";

export const DocumentsEdit: FC = () => {
    const { t } = useTranslation();
    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const styles = useMemo(() => getStyles(lgDown), [lgDown]);
    const { type, typeValid, documentTypeOptions, changeType, name, nameValid, onChangeName, orgStructure, initiator, initiatorValid, onSelectInitiator, onChangeInitiator,
        groupPermissions, receiverValid, onChangePermissions, receiver, onChangeReceiver, publishChecked, handlePublishChange, notifyChecked, handleNotifyChange,
        editDocument, cancelCreate, category, categoryValid, editorState, contentValid, setEditorState, onEditorStateChange, files, fileValid, setFiles, handleDeleteImageFromGallery,
        handleFilesDrop, handleFilesRemoveAll, handleFileChange, handleDeleteImage, fileType, fileTypeOptions, changeFileType, textVideo, onChangeTextVideo,
        link, linkValid, onChangeLink, onCategoryChange, editClick, isOpenImageFile, isOpenVideoFile, handleToggleImageFile, handleToggleVideoFile, isDeleted,
        onChangeDepartmentPermissions, departmentPermissions, handleChangePosition, positions, positionsList, onChangeAssignDepartment, assignDepartment,
        handleChangeAssignPosition, assignPositions, id, fileLoading } = useDocumentEdit();

    return (
        <Stack mt={2} ml={2} >
            <Typography variant="h4" fontWeight={600}>{t(tokens.documents.editDocument)}</Typography>
            <Stack my={5} spacing={3}>
                <Stack mt={3} direction="row" justifyContent="space-between" spacing={2}>
                    <Stack width="100%">
                        <Typography variant="h6" mb={1} color={typeValid ? 'inherit' : 'error'}>
                            {t(tokens.documents.type)}*
                        </Typography>
                        <ItemSelector
                            label={t(tokens.documents.type)}
                            value={type}
                            isDisabled={true}
                            sortOptions={documentTypeOptions}
                            onChange={changeType}
                        />
                    </Stack>
                    <Stack width="100%">
                        <Typography variant="h6" mb={1} color={nameValid ? 'inherit' : 'error'}>
                            {t(tokens.documents.title)}*
                        </Typography>
                        <TextField
                            fullWidth
                            value={name}
                            onChange={onChangeName}
                            label={t(tokens.documents.title)}
                            autoComplete="off"
                            disabled={isDeleted}
                        />
                    </Stack>
                </Stack>
                {type === 'Page' &&
                    <Stack flex={1}>
                        <Typography variant="h6" mb={1} color={contentValid ? 'inherit' : 'error'}>
                            {t(tokens.documents.content)}*
                        </Typography>
                        <TextEditor
                            disabled={isDeleted}
                            editorState={editorState}
                            onEditorStateChange={onEditorStateChange}
                        />
                    </Stack>}
                {type === 'Video' &&
                    <Stack flex={1}>
                        <Typography variant="h6" mb={2}>
                            {t(tokens.documents.textVideo)}
                        </Typography>
                        <TextField
                            placeholder={t(tokens.documents.textVideo)}
                            multiline
                            rows={6}
                            value={textVideo}
                            onChange={onChangeTextVideo}
                            disabled={isDeleted}
                        />
                    </Stack>}
                {(type !== 'Page' && type !== 'Gallery') &&
                    <Stack width="50%" pr={1}>
                        <Typography variant="h6" mb={1}>
                            {t(tokens.documents.file)}
                        </Typography>
                        <ItemSelector
                            label={t(tokens.documents.file)}
                            value={fileType}
                            sortOptions={fileTypeOptions}
                            onChange={changeFileType}
                            isDisabled={
                                (type === 'Video' && !isDeleted) || (!isDeleted && type !== 'Link') ? false : true
                            }
                        />
                    </Stack>}
                {(type === 'File' && fileType === 'File'
                    || type === 'Video' && fileType === 'File') &&
                    <FileSelector
                        files={files}
                        fileValid={fileValid}
                        type={type}
                        isDisabled={isDeleted}
                        handleDeleteImage={handleDeleteImage}
                        handleFileChange={handleFileChange}
                        handleToggleImageFile={handleToggleImageFile}
                        handleToggleVideoFile={handleToggleVideoFile}
                        fileLoading={fileLoading}
                    />}
                {(type === 'Link' || fileType === 'Link') &&
                    <Stack mt={3} >
                        <Typography variant="h6" mb={1} color={linkValid ? 'inherit' : 'error'}>
                            {t(tokens.documents.link)}*
                        </Typography>
                        <TextField
                            fullWidth
                            value={link}
                            onChange={onChangeLink}
                            label={t(tokens.documents.link)}
                            autoComplete="off"
                            type="url"
                            disabled={isDeleted}
                        />
                    </Stack>}
                <Stack direction="row" justifyContent="space-between" spacing={2}>
                    <Stack width="49.5%">
                        <Typography variant="h6" mb={1} color={categoryValid ? 'inherit' : 'error'}>
                            {t(tokens.documents.category)}*
                        </Typography>
                        <CategoryAutocomplete
                            label={t(tokens.documents.category)}
                            value={category}
                            onCategoryChange={onCategoryChange}
                            isDisabled={isDeleted}
                        />
                    </Stack>
                    <Stack width="49%">
                        <Typography variant="h6" mb={1} color={initiatorValid ? 'inherit' : 'error'}>
                            {t(tokens.documents.initiator)}*
                        </Typography>
                        <CheckboxAutocompleteSingle
                            title={t(tokens.documents.initiator)}
                            value={initiator}
                            optionList={orgStructure}
                            onSelect={onSelectInitiator}
                            onChange={onChangeInitiator}
                            isValid={true}
                            isDisabled={isDeleted}
                        />
                    </Stack>
                </Stack>
                <Card elevation={3} sx={styles.card}>
                    <Stack direction="row" justifyContent="space-between" spacing={2} mb={1}>
                        <Stack width="49.5%">
                            <Typography variant="h6" mb={1} color={receiverValid ? 'inherit' : 'error'}>
                                {t(tokens.documents.accessToUser)}
                            </Typography>
                            <CheckboxAutocompleteMultiple
                                title={t(tokens.documents.accessToUser)}
                                value={receiver}
                                optionList={orgStructure}
                                onChange={onChangeReceiver}
                                isDisabled={isDeleted}
                            />
                        </Stack>
                        <Stack width="49%">
                            <Typography variant="h6" mb={1}>{t(tokens.documents.accessToDepartment)}</Typography>
                            <CheckboxDepartmentAutocompleteMultiple
                                title={t(tokens.documents.accessToDepartment)}
                                value={departmentPermissions}
                                optionList={orgStructure}
                                onChange={onChangeDepartmentPermissions}
                                isDisabled={isDeleted}
                            />
                        </Stack>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" spacing={2} mb={1}>
                        <Stack width="49.2%" flexShrink={0}>
                            <Typography variant="h6" mb={1}>{t(tokens.documents.accessToPosition)}</Typography>
                            <AutocompleteCheckBox positionsList={positionsList} value={positions} handleChange={handleChangePosition} isDisabled={isDeleted} placeholder="Доступ за посадаю"/>
                        </Stack>
                        <Stack width="100%" pt={5}>
                            <Button
                                variant="contained"
                                sx={styles.editBtn}
                                disabled={editClick || isDeleted}
                                startIcon={<EditOutlinedIcon />}
                                component={Link}
                                to={paths.dashboard.content.document.editAccess.replace(":id", String(id))}
                            >
                                {t(tokens.common.editAccess)}
                            </Button>
                        </Stack>
                    </Stack>
                </Card>
                <Card elevation={3} sx={styles.card}>
                    <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2} mb={1}>
                        <Stack width="49.5%">
                            <Typography variant="h6" mb={1}>{t(tokens.documents.assignToUser)}</Typography>
                            <CheckboxAutocompleteMultiple
                                title={t(tokens.documents.assignToUser)}
                                value={groupPermissions}
                                optionList={orgStructure}
                                onChange={onChangePermissions}
                                isDisabled={isDeleted}
                            />
                        </Stack>
                        <Stack width="49%">
                            <Typography variant="h6" mb={1}>{t(tokens.documents.assignToDepartment)}</Typography>
                            <CheckboxDepartmentAutocompleteMultiple
                                title={t(tokens.documents.assignToDepartment)}
                                value={assignDepartment}
                                optionList={orgStructure}
                                onChange={onChangeAssignDepartment}
                                isDisabled={isDeleted}
                            />
                        </Stack>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" spacing={2} mb={1}>
                        <Stack width="49.2%" flexShrink={0}>
                            <Typography variant="h6" mb={1}>{t(tokens.documents.assignToPosition)}</Typography>
                            <AutocompleteCheckBox positionsList={positionsList} value={assignPositions} handleChange={handleChangeAssignPosition} isDisabled={isDeleted} placeholder="Доступ за посадаю"/>
                        </Stack>
                        <Stack width="100%" pt={5}>
                            <Button
                                variant="contained"
                                sx={styles.editBtn}
                                disabled={editClick || isDeleted}
                                startIcon={<EditOutlinedIcon />}
                                component={Link}
                                to={paths.dashboard.content.document.editAssign.replace(":id", String(id))}
                            >
                                {t(tokens.common.editAssign)}
                            </Button>
                        </Stack>
                    </Stack>
                </Card>
                <Stack direction='row' mt={3} spacing={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={publishChecked}
                                onChange={handlePublishChange}
                                disabled={isDeleted}
                            />
                        }
                        label={t(tokens.documents.publish)}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={notifyChecked}
                                onChange={handleNotifyChange}
                                disabled={isDeleted}
                            />
                        }
                        label={t(tokens.documents.inform)}
                    />
                </Stack>
                {!isDeleted &&
                    <Stack direction='row' mt={3} spacing={3}>
                        <Button
                            variant="contained"
                            sx={styles.editButton}
                            onClick={editDocument}
                            disabled={editClick}
                        >
                            {t(tokens.common.save)}
                        </Button>
                        <Button
                            variant="outlined"
                            sx={styles.cancelButton}
                            onClick={cancelCreate}
                            disabled={editClick}
                        >
                            {t(tokens.common.cancel)}
                        </Button>
                    </Stack>}
                {type === 'Gallery' &&
                    <Stack>
                        <Typography variant="h6" mb={1} color={fileValid ? 'inherit' : 'error'}>
                            {t(tokens.documents.gallery)}*
                        </Typography>
                        <FileDropzone
                            accept={{ "image/*": [] }}
                            caption={t(tokens.documents.imagesDNDSubtitle)}
                            files={files}
                            setFiles={setFiles}
                            onDrop={handleFilesDrop}
                            onRemove={handleDeleteImageFromGallery}
                            onRemoveAll={handleFilesRemoveAll}
                            isEdit={true}
                            disabled={isDeleted}
                            updateFiles={updateFiles}
                        />
                    </Stack>}
            </Stack>
            <Backdrop sx={styles.backDrop} open={editClick}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <CircularProgress color="primary" />
                    <Typography color="primary" variant="h6">
                        {t(tokens.documents.await)}
                    </Typography>
                </Stack>
            </Backdrop>
            {files.length > 0 &&
                <Dialog open={isOpenImageFile} onClose={handleToggleImageFile} >
                    <Stack
                        sx={styles.fileContainer}>
                        <img
                            src={'type' in files[0] ?
                                URL.createObjectURL(files[0])
                                : files[0].url
                            }
                            alt="image"
                        />
                    </Stack>
                </Dialog>
            }
            {(files.length > 0) &&
                <Dialog open={isOpenVideoFile} onClose={handleToggleVideoFile}>
                    <Stack sx={styles.fileContainer}>
                        <CardMedia
                            component="video"
                            controls
                            autoPlay
                            src={'type' in files[0] ?
                                URL.createObjectURL(files[0])
                                : files[0].url
                            }
                        />
                    </Stack>
                </Dialog>
            }
        </Stack>
    )
};