import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setCreateTraineeTaskError, setLoading } from "../../repository/slice";
import { getTraineePositionById } from "../getTraineePositionById/action";

function* createTraineeTaskWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.post, '/api/dynamic-destination/intern', payload.body);
        if (isError) {
            yield put(setCreateTraineeTaskError(message));
        } else {
            yield put(setCreateTraineeTaskError(null));
            yield put(getTraineePositionById({ id: payload.positionId }));
        }
    } catch (error) {
        console.error("createTraineeTaskWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* createTraineeTaskWatcher() {
    yield takeEvery("@saga/CREATE_TRAINEE_TASK", createTraineeTaskWorker);
};
