export const getStyles = () => {
    const styles = {
        background: {
            width: '100%',
            height: '100%',
            position: 'fixed',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundImage: "url('/images/logo/background.png')"
        },
        containerMd: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // width: '30%',
            width: '450px',
            borderRadius: '4px',
        },
        container: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70%',
            borderRadius: '4px',
        },
        content: {
            mt: 2,
        },
        logoContainer: {
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: 3,
            animation: 'zoomInAndOut 1s infinite',
        },
        logo: {
            maxWidth: '60%',
            maxHeight: '60%',
        },
    };

    return styles;
};

