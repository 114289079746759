import { Button, Stack, TableCell, TableRow, Typography } from "@mui/material";
import { CSSProperties, FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getStyles } from "./styles";
import { tokens } from "../../../../../locales/tokens";
import moment from "moment";

interface IProps {
    access: any;
    sortedBy: string;
    handleDelete: (id: number) => void;
};

export const AccessTableItem: FC<IProps> = ({ access, handleDelete, sortedBy }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const date = access?.created_at || access.createdAt 
    const formattedDate = moment(date).local().format('DD.MM.YYYY HH:mm'); 
       
    const deleteAccess = useCallback(() => handleDelete(access?.departmentGuid || access.id), [access]);
    const title: any = {
        User: access.user?.last_name + " " + access.user?.first_name + " " + access.user?.patronymic,
        Department: access.department,
        Position: access.name
    };

    return (
        <TableRow hover key={access.id}>
            <TableCell align="left" width='55%' sx={styles.cell}>
                <Stack direction="row" spacing={1} alignItems="center">
                    {sortedBy === "User" &&
                        <img
                            src={access.photo ? access.photo : "/images/user/user.png"}
                            alt=""
                            width={40}
                            height={40}
                            style={styles.avatar as CSSProperties}
                        />}
                    <Typography variant="body2" whiteSpace="nowrap">{title[sortedBy]}</Typography>
                   {sortedBy === "User" && <Typography variant="subtitle2" color="#579CFF">({access.user?.position})</Typography>}
                </Stack>
            </TableCell>
            <TableCell align="center" width='15%' sx={styles.cell}>
                <Typography variant="body2">
                    {formattedDate}
                </Typography>
            </TableCell>
            <TableCell align="right" width='30%' sx={styles.cell}>
                <Stack direction='row' alignItems='center' spacing={1} justifyContent='flex-end'>
                    <Button variant="contained" sx={styles.button} onClick={deleteAccess}>
                        {t(tokens.common.delete)}
                    </Button>
                </Stack>
            </TableCell>
        </TableRow>
    )
};