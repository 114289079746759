import { Button, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getStyles } from "./styles";
import { tokens } from "../../../../locales/tokens";
import { useTraineePositionEdit } from "../presenter/useTraineePositionEdit";
import { Link } from "react-router-dom";
import { paths } from "../../../../paths";
import AddIcon from '@mui/icons-material/Add';
import { TaskModal } from "./components/taskModal";
import { TraineeTaskList } from "./components/traineeTaskList";

export const TraineePositionEdit: FC = () => {
    const { t } = useTranslation();
    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const styles = useMemo(() => getStyles(lgDown), [lgDown]);
    const { handleOpenTaskModal, isTaskModalOpen, currentTask, handleCloseTaskModal, id, traineeTaskList, defineCurrentTask, removeTask, traineePositionById } = useTraineePositionEdit();

    return (
        <Stack m={2}>
            <Stack>
                <Typography variant="h5" fontWeight={600}>
                    {t(tokens.trainee.createTraineeTask)}
                </Typography>
                <Typography variant="h6">
                    {t(tokens.trainee.position)}: {traineePositionById?.name}
                </Typography>
            </Stack>
            <Stack pt={2} alignItems="flex-start">
                <Button
                    startIcon={<AddIcon />}
                    sx={styles.addQuestionButton}
                    onClick={handleOpenTaskModal}
                >
                    {t(tokens.course.addTask)}
                </Button>
            </Stack>
            <Stack mt={2} spacing={1} px={2}>
                <TraineeTaskList
                    taskList={traineeTaskList}
                    removeTask={removeTask}
                    defineCurrentTask={defineCurrentTask}
                />
            </Stack>
            <TaskModal
                positionId={id}
                isOpen={isTaskModalOpen}
                currentTask={currentTask}
                onClose={handleCloseTaskModal}
            />
            <Stack direction='row' mt={4} spacing={3}>
                <Button
                    variant="contained"
                    sx={styles.createButton}
                    component={Link}
                    to={paths.dashboard.content.trainee.index}
                >
                    Готово
                </Button>
            </Stack>
        </Stack>
    )
};