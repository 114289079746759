import { Stack, Typography } from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { getStyles } from "./styles";
import { ImageWithPlaceholder } from "../imageWithPlaceholder";
import { IUser } from "../../../../../../store/user/repository/IUser";

interface IProps {
    user: IUser;
    defineCurrentBadge: (badgeId: number) => void;
};

export const UserAchievements: FC<IProps> = ({ user, defineCurrentBadge }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    return (
        <Stack width='100%'>
            <Typography variant="body1" fontWeight={600}>{user?.last_name + " " + user?.first_name + " " + user?.patronymic}</Typography>
            <Typography variant="body1" flex={1} my={1}>{t(tokens.users.studyHistory.rating)}: {user?.rating}</Typography>
            <Stack direction="row" flexWrap="wrap" sx={styles.container}>
                {user?.badges?.map((badge: any) => {
                    const chooseBadge = () => defineCurrentBadge(badge?.id);
                    return (
                        <div key={badge.id} style={styles.wrapper}>
                            <ImageWithPlaceholder
                                src={badge.image.url}
                                alt=""
                                width={50}
                                height={50}
                                style={styles.image}
                                chooseBadge={chooseBadge}
                            />
                        </div>
                    )
                }
                )}
            </Stack>
        </Stack>
    )
};

