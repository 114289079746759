import { CSSProperties, FC, useState } from "react";
import { MenuSection, NavColor } from "../../../../../types/settings";
import { Stack, Typography, keyframes } from "@mui/material";
import { Link, useLocation } from 'react-router-dom';
import { SideNavSection } from "../side-nav-section/side-nav-section";
import { getStyles } from "./styles";
import { SideDrawer } from "../side-drawer/side-drawer";
import { paths } from "../../../../../paths";
const { version } = require('../../../../../../package.json');

interface IProps {
    color?: NavColor;
    sections?: MenuSection[];
    isOpen: boolean;
    onNavOpen: () => void;
};

const changeSize = keyframes({ '0%': { transform: 'rotateY(0deg)' }, '100%': { transform: 'rotateY(-360deg)', } })

export const SideNav: FC<IProps> = ({ color, sections = [], isOpen, onNavOpen }) => {
    const location = useLocation();
    const styles = getStyles();
    const [animate, setAnimate] = useState(false);

    const handleMouseEnter = () => {
        setAnimate(true);
    };

    const handleAnimationEnd = () => {
        setAnimate(false);
    };

    return (
        <SideDrawer isOpen={isOpen} onNavOpen={onNavOpen}>
            <Stack
                direction="column"
                spacing={2}
                sx={{ ...styles.logoContainer, animation: animate ? `${changeSize} 1s linear 1` : 'none' }}
                component={Link}
                to={paths.index}
                onMouseEnter={handleMouseEnter}
                onAnimationEnd={handleAnimationEnd}
            >
                <img src="/images/logo/logo.png" alt="logo" width={150} />
            </Stack>
            <Stack component="nav" spacing={2} sx={styles.navContainer} >
                {sections.map((section, index) => (
                    <SideNavSection
                        items={section.items}
                        key={index}
                        pathname={location.pathname}
                        subheader={section.subheader as string}
                    />
                ))}
            </Stack>
            <img src="/images/logo/background.png" alt="pattern" style={styles.pattern as CSSProperties} />
            <Typography px={4} pb={2} variant="subtitle1" fontWeight={600}>PizzaWay {version}</Typography>
        </SideDrawer>
    )
};