import { FC, useMemo } from "react";
import { useEditAccess } from "../presenter/useEditAccess";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { AccessSearch } from "./components/accessSearch";
import { AccessTable } from "./components/accessTable";
import { EmptyPage } from "../../../modules/content/category/categoryEmpty/ui";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";
import { getStyles } from "./styles";

interface IProps {
    type: string;
};

export const EditAccessDocument: FC<IProps> = ({ type }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { filters, searchTextRef, handleSortBy, handleChangeSearch, handleSearchTextChange, handleDelete, pageAmount, handlePageChange,
        scrollRef, accessList, searchTitle, isProccess } = useEditAccess(type);

    return (
        <Stack mt={2} ml={2}>
            <Typography variant="h5" fontWeight={600}>Редагувати доступи</Typography>
            <AccessSearch
                searchTitle={searchTitle}
                searchLabel="Доступи"
                sortBy={filters.sortBy}
                searchTextRef={searchTextRef}
                handleSortBy={handleSortBy}
                handleChangeSearch={handleChangeSearch}
                handleSearchTextChange={handleSearchTextChange}
            />
            {!!accessList.length &&
                <AccessTable
                    sortedBy={filters.sortBy}
                    scrollRef={scrollRef}
                    accessList={accessList}
                    page={filters.page}
                    pageAmount={pageAmount}
                    handleDelete={handleDelete}
                    handlePageChange={handlePageChange}
                />}
            {(!accessList.length && isProccess) &&
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={styles.progress}
                >
                    <CircularProgress color="primary" />
                </Stack>
            }
            {(!accessList.length && !isProccess) &&
                <Stack direction="row" alignItems="flex-start" justifyContent="center">
                    <EmptyPage
                        title={t(tokens.common.emptyState)}
                        description={t(tokens.common.emptyStateAccess)}
                    />
                </Stack>
            }
        </Stack>
    )
};