import { ChangeEvent, FC, useCallback } from "react";
import { getStyles } from "./styles";
import { Box, IconButton, Stack, SvgIcon, Theme, useMediaQuery } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { AccountButton } from "../../../../../ui-kit/account-button";
import { ThemeSwitcher } from "../../../../../ui-kit/theme-switcher/theme-switcher";
import { useSettings } from "../../../../../hooks/useSettings";

interface IProps {
    onNavOpen: () => void;
}

export const TopNav: FC<IProps> = ({ onNavOpen }) => {
    const styles = getStyles();
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
    const settings = useSettings();

    const handleFieldUpdate = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        const theme = event.target.checked? 'dark' : 'light';
        settings.handleUpdate({
            ...settings,
            paletteMode: theme
        });
    }, [settings]);

    const handleField = useCallback((): void => {
        settings.handleUpdate({
            ...settings,
            paletteMode: 'light'
        });
    }, [settings]);
    
    return (
        <Box component="header" sx={styles.header} >
            <Stack direction="row" spacing={2} sx={styles.headerContainer} >
                {!lgUp && (<IconButton onClick={onNavOpen}><SvgIcon><MenuIcon /></SvgIcon></IconButton>)}
                <Stack direction='row' alignItems='center' gap={1} pl={7}>
                </Stack>
                <Stack direction='row' spacing={1}>
                    <ThemeSwitcher handleFieldUpdate={handleFieldUpdate}/>
                    <AccountButton handleField={handleField}/>
                </Stack>
            </Stack>
        </Box>
    )
};
