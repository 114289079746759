import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { TestFilters } from "./types";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { updateOrgStructure } from "../../../../../store/orgStructure/useCases/updateOrgStructure/action";
import { selectOrgStructure } from "../../../../../store/orgStructure/repository/selector";
import { getTestList } from "../../../../../store/test/useCases/getTestList/action";
import { selectTest } from "../../../../../store/test/repository/selector";
import { updateTest } from "../../../../../store/test/useCases/updateTest/action";
import { deleteTest } from "../../../../../store/test/useCases/deleteTest/action";
import { uploadTestReport } from "../../../../../store/test/useCases/uploadTestReport/action";
import toast from "react-hot-toast";
import { tokens } from "../../../../../locales/tokens";
import dayjs from "dayjs";
import { setTestList } from "../../../../../store/test/repository/slice";

export const useTestList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [pageAmount, setPageAmount] = useState(0);
    const [updateClick, setUpdateClick] = useState(false);
    const [deleteClick, setDeleteClick] = useState(false);
    const { orgStructure } = useSelector(selectOrgStructure);
    const { testList, testsCount, isLoading, deleteTestError, updateTestError, getTestListError } = useSelector(selectTest);
    const [filters, updateFilters] = useState<TestFilters>({ page: 1, rowsPerPage: 30, search: '', tab: 'Active', status: null, initiator: null, date: null });
    const [isProccessTest, setProcessTest] = useState(false);
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        dispatch(getTestList(combineRequestBody()));
        setProcessTest(true);
        return () => {
            dispatch(setTestList([]));
        }
    }, [filters]);

    useEffect(() => {
        if (testsCount) {
            setPageAmount(Math.ceil(testsCount / filters.rowsPerPage));
        }
    }, [testsCount]);

    useEffect(() => {
        dispatch(updateOrgStructure());
    }, []);

    useEffect(() => {
        if (!isLoading && isProccessTest) {
            if (getTestListError) {
                toast.error(getTestListError)
            };
            setTimeout(() => setProcessTest(false), 400);
        }
    }, [getTestListError, isLoading]);

    useEffect(() => {
        if (!isLoading && updateClick) {
            if (updateTestError) {
                toast.error(updateTestError)
            } else {
                toast.success(t(tokens.test.message.testUpdated));
                dispatch(getTestList(combineRequestBody()));
            }
            setUpdateClick(false);
        }
    }, [updateTestError, isLoading]);

    useEffect(() => {
        if (!isLoading && deleteClick) {
            if (deleteTestError) {
                toast.error(deleteTestError)
            } else {
                toast.success(t(tokens.test.message.testRemoved));
                dispatch(getTestList(combineRequestBody()));
            }
            setDeleteClick(false);
        }
    }, [deleteTestError, isLoading]);

    const handleSearchTestText = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        debouncedHandleTestSeacrh(e.target.value);
    };

    const onSearchTest = (value: string) => {
        const search = value ? value : '';
        if (search.length !== 1) {
            updateFilters((prevState) => ({ ...prevState, search, page: 1 }));
            scrollTopList();
        }
    };

    const { debouncedWrapper: debouncedHandleTestSeacrh } = useDebounce(onSearchTest, 1000);

    const handleTabsChange = (event: ChangeEvent<{}>, tab: string): void => {
        updateFilters((prevState: any) => ({ ...prevState, tab, page: 1 }));
        scrollTopList();
    };

    const onSelectInitiator = (selectedKeysValue: string, node: any) => {
        if ('children' in node) {
            return;
        }
        updateFilters((prevState) => ({ ...prevState, page: 1, initiator: node }));
        scrollTopList();
    };

    const onChangeInitiator = () => {
        if (filters.initiator) {
            updateFilters((prevState) => ({ ...prevState, page: 1, initiator: null }));
        }
    };

    const handleDateChange = useCallback((date: Date | null): void => {
        const newDate = date?.valueOf();
        const currentDate = newDate || null;
        updateFilters((prevState) => ({ ...prevState, page: 1, date: currentDate }));
        scrollTopList();
    }, [updateFilters]);

    const changeStatus = useCallback((status: any) => {
        updateFilters((prevState) => ({ ...prevState, page: 1, status }));
        scrollTopList();
    }, [updateFilters]);

    const resetFilters = useCallback((): void => {
        updateFilters((prevState) => ({ ...prevState, page: 1, rowsPerPage: 30, status: null, initiator: null, date: null }));
        scrollTopList();
    }, [updateFilters]);

    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        updateFilters((prevState) => ({ ...prevState, page }));
        scrollTopList();
    }, [updateFilters]);

    const handleStatus = (id: number, status: string) => {
        const testStatus = status === 'Hidden' ? 'Available' : 'Hidden';
        // const newTestList = testList.map(test => test.id === id ? { ...test, status: testStatus } : test);
        // dispatch(setTestList({ testList: newTestList, count: testsCount }));
        dispatch(updateTest({ body: { id, status: testStatus } }));
        setUpdateClick(true);
    };

    const handleDelete = (id: number) => {
        dispatch(deleteTest({ id }));
        setDeleteClick(true);
    };

    const handleUploadReport = (id: number, name: string) => {
        dispatch(uploadTestReport({ id, name }));
    };

    const combineRequestBody = () => {
        const body: any = { page: filters.page, perPage: filters.rowsPerPage };
        if (filters.search) body.search = filters.search;
        if (filters.initiator) body.initiatorId = filters.initiator.value;
        if (filters.status) body.status = filters.status.value;
        if (filters.date) {
            const originalDate = dayjs(filters.date);
            const newDate = originalDate.add(3, 'hour');
            const new_timestamp_ms = newDate.valueOf();
            body.date = new_timestamp_ms;
        };
        body.isDeleted = filters.tab === 'Active' ? false : true;
        return body;
    };

    const scrollTopList = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        };
    };

    return {
        searchText, handleSearchTestText, filters, handleTabsChange, orgStructure, onSelectInitiator, onChangeInitiator, handleDateChange, scrollRef,
        changeStatus, resetFilters, testList, handlePageChange, pageAmount, handleStatus, handleDelete, handleUploadReport, isProccessTest
    }
};