import { Button, CircularProgress, Stack, SvgIcon, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useAppPageList } from "../presenters/useAppPageList";
import { tokens } from "../../../../locales/tokens";
import AddIcon from '@mui/icons-material/Add';
import { getStyles } from "./styles";
import { Link } from "react-router-dom";
import { paths } from "../../../../paths";
import { AppPageTable } from "./components/appPageTable";
import { TabSelector } from "../../../../ui-kit/tabSelector/tabSelector";

export const AppPageList: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { activeTab, appPageList, isAppPageLoading, isRequestList, onToggleTab, onDeleteAppPage, onChangeVisibilityPage } = useAppPageList();

    const tabOptions = [
        { label: t(tokens.common.activeTab), value: "ACTIVE" },
        { label: t(tokens.common.archiveTab), value: "ARCHIVE" },
    ];

    return (
        <Stack m={2}>
            <Stack direction='row' justifyContent='space-between'>
                <Typography variant="h5" fontWeight={600}>{t(tokens.appPage.title.list)}</Typography>
                <Button
                    startIcon={(<SvgIcon><AddIcon /></SvgIcon>)}
                    variant="contained"
                    sx={styles.createBtn}
                    component={Link}
                    to={paths.dashboard.appPage.create}
                >
                    {t(tokens.common.create)}
                </Button>
            </Stack>
            <Stack mt={1}><TabSelector tab={activeTab} tabOptions={tabOptions} handleTabsChange={onToggleTab} /></Stack>
            {isAppPageLoading && isRequestList
                ? <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={styles.progressWrapper}
                >
                    <CircularProgress color="primary" />
                </Stack>
                : <AppPageTable appPageList={appPageList} onChangeVisibility={onChangeVisibilityPage} onDelete={onDeleteAppPage} />
            }
        </Stack>
    )
};