import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { useKnowledgeAnalytics } from "../presenter/useKnowledgeAnalytics";
import { KnowledgeChart } from "./components/knowledgeChart";

export const KnowledgeAnalytics: FC = () => {
    const { t } = useTranslation();
    const { dates, handleRangeChange, disabledDate } = useKnowledgeAnalytics();

    return (
        <Stack m={2}>
            <Typography variant="h4" fontWeight={600}>
                {t(tokens.analytics.analyticsKnowledge)}
            </Typography>
            <Stack justifyContent="center" alignItems="center">
                <KnowledgeChart
                    chartSeries={[4, 1, 1, 1]}
                    labels={[t(tokens.analytics.documents), t(tokens.analytics.news), t(tokens.analytics.promotion), t(tokens.analytics.events)]}
                    dates={dates}
                    handleRangeChange={handleRangeChange}
                    disabledDate={disabledDate}
                />
            </Stack>
        </Stack>
    )
};