export const getStyles = () => {
    const styles = {
        password: { 
            position: "relative", 
            height: '85px', 
            width: '100%' 
        },
        input: {
            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                    borderColor: '#C5C5C5',
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#F8B043', 
                },
                '&.Mui-error fieldset': {
                    borderColor: 'red'
                  },
            }
        }
    };
    return styles;
};