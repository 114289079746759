import { Stack } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { TextAutocomplete } from "../../../../../../ui-kit/textAutocomplete";

interface IProps{
    test: any; 
    optionList: any[];
    handleChangeTest: (task: any) => void; 
    changeTestText: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const TestAutocomplete: FC<IProps> = ({test, handleChangeTest, optionList, changeTestText}) => {

    return (
        <Stack width="300px">
            <TextAutocomplete
                title="Тест"
                value={test}
                handleChangeValue={handleChangeTest}
                optionList={optionList}
                isMultiline={false}
                changeText={changeTestText}
                size="small"
            />
        </Stack>
    )
};