import { Box, InputAdornment, MenuItem, OutlinedInput, Stack, SvgIcon, TextField } from "@mui/material";
import { ChangeEvent, FC, FormEvent, MutableRefObject, useCallback } from "react"
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../locales/tokens";

interface IProps {
    searchTitle: string;
    searchLabel: string;
    sortBy: string;
    searchTextRef: MutableRefObject<HTMLInputElement | null>;
    handleSortBy: (sortBy: string) => void;
    handleChangeSearch: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, sortBy: string) => void;
    handleSearchTextChange: (event: FormEvent<HTMLFormElement>) => void;
};

export const AccessSearch: FC<IProps> = ({ searchTextRef, handleChangeSearch, handleSearchTextChange, handleSortBy, sortBy, searchTitle, searchLabel }) => {
    const { t } = useTranslation();

    const sortOptions = [
        { label: "Користувачi", value: 'User' },
        { label: "Департемант", value: 'Department' },
        { label: "Посада", value: 'Position' }
    ];

    const onSortChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
        const sortDir = event.target.value as string;
        handleSortBy(sortDir);
    }, [handleSortBy]);

    return (
        <Stack direction='row' spacing={1} alignItems='center' mt={3}>
            <Box component="form" onSubmit={handleSearchTextChange} flex={3} >
                <OutlinedInput
                    defaultValue=""
                    fullWidth
                    inputProps={{ ref: searchTextRef }}
                    onChange={(e)=>handleChangeSearch(e, sortBy)}
                    placeholder={searchTitle}
                    startAdornment={(
                        <InputAdornment position="start">
                            <SvgIcon>
                                <SearchIcon />
                            </SvgIcon>
                        </InputAdornment>
                    )}
                    size="small"
                />
            </Box>
            <TextField
                id="outlined-basic"
                label={searchLabel}
                select variant="outlined"
                value={sortBy}
                onChange={onSortChange}
                sx={{ width: '150px', flex: 1 }}
                size="small"
            >
                {sortOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>
        </Stack>
    )
};