import { SagaIterator } from "redux-saga";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setDeleteQuestionError, setQuestionList } from "../../repository/slice";
import i18next from "i18next";
import toast from "react-hot-toast";
import { tokens } from "../../../../locales/tokens";
import { getSingleTest } from "../getSingleTest/action";

function* deleteQuestionWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.delete, "/api/admin/question/delete/" + payload.id);
        if (isError) {
            yield put(setDeleteQuestionError(message));
        } else {
            yield put(setDeleteQuestionError(null));
            yield put(getSingleTest({ id: payload.testId }))
            toast.success(i18next.t(tokens.test.message.questionRemoved));
        }
    } catch (error) {
        console.error("deleteQuestionWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* deleteQuestionWatcher() {
    yield takeEvery("@saga/DELETE_QUESTION", deleteQuestionWorker);
};
