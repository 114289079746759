import { IconButton, Stack, SvgIcon, TextField, Typography } from "@mui/material";
import { ChangeEvent, FC } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { TEST_TYPES } from "../../../../../../../config";

interface IProps {
    type: any;
    replies: any[];
    repliesValid: any[];
    removeReply: (sortNumber: number) => void;
    onChangeReply: (event: ChangeEvent<HTMLInputElement>, index: number) => void;
};

export const RepliesList: FC<IProps> = ({ replies, repliesValid, onChangeReply, removeReply, type }) => {
    
    return (
        <>
            {replies?.map((reply: any, index: number) =>
                <Stack key={index} mt={2} pb={1} direction="row" alignItems="center" spacing={3}>
                    <Typography variant="h4" fontWeight={600}>{index + 1}</Typography>
                    <TextField
                        value={reply.title}
                        fullWidth
                        onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeReply(e, index)}
                        error={!repliesValid[index].isValid}
                    />
                    <IconButton onClick={() => removeReply(reply.sortNumber)} disabled={type?.value === TEST_TYPES.SCALE}>
                        <SvgIcon><DeleteIcon /></SvgIcon>
                    </IconButton>
                </Stack>
            )}
        </>
    )
};