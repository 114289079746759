import { FC, useMemo } from "react";
import { Box, Button, CircularProgress, Stack, keyframes } from "@mui/material";
import { getStyles } from "./styles";
import { Modal } from "../../../../ui-kit/modal/modal";
import { useTranslation } from "react-i18next";
import { useChangePassword } from "../presenters/useChangePassword";
import { PasswordInput } from "./components/passwordInput";
import { tokens } from "../../../../locales/tokens";

const changeSize = keyframes({ '0%': { transform: 'scale(1)', }, '50%': { transform: 'scale(1.05)', }, '100%': { transform: 'scale(1)', } })

interface IProps {
    id: number;
};

export const ChangePassword: FC<IProps> = ({ id }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { password, passwordValid, handleEnter, onChangePassword, isPassShown, onShowPassword, passwordInputRef, isOpen, isLoading,
        onChangeConfirmPassword, onShowConfirmPassword, confirmPassword, confirmPassValid, isConfirmPassShown, handleChangePassword } = useChangePassword(id);

    return (
        <Modal isOpen={isOpen} handleClose={() => { }} size='sm'>
            <Stack p={4}>
                <Box sx={{ ...styles.logoContainer, animation: `${changeSize} 5s infinite` }}>
                    <img src="/images/logo/logo_auth.png" style={styles.logo} alt="logo" />
                </Box>
                <PasswordInput
                    title={t(tokens.auth.newPassword)}
                    isHideError={true}
                    password={password}
                    passwordValid={passwordValid}
                    passwordInputRef={passwordInputRef}
                    isPassShown={isPassShown}
                    handleEnter={handleEnter}
                    onShowPassword={onShowPassword}
                    onChangePassword={onChangePassword}
                />
                <PasswordInput
                    title={t(tokens.auth.confirmPassword)}
                    isHideError={false}
                    password={confirmPassword}
                    passwordValid={confirmPassValid}
                    passwordInputRef={passwordInputRef}
                    isPassShown={isConfirmPassShown}
                    handleEnter={handleEnter}
                    onShowPassword={onShowConfirmPassword}
                    onChangePassword={onChangeConfirmPassword}
                />
                <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    sx={styles.button}
                    onClick={handleChangePassword}
                    disabled={isLoading}
                >
                    {isLoading ?
                    <CircularProgress size={20}/>
                    : t(tokens.auth.saveNewPassword)}
                </Button>
            </Stack>
        </Modal>

    )
};