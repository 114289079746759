import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { selectPushNotifications } from "../../../../store/pushNotification/repository/selectors";
import { deletePushNotification } from "../../../../store/pushNotification/useCases/deleteNotification/actions";
import { getPushNotificationById } from "../../../../store/pushNotification/useCases/getNotificationById/actions";
import { sendNotification } from "../../../../store/pushNotification/useCases/sendNotification/actions";

export const useNotificationDetail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { notificationById, errorsSendNotification, errorsDeletePushNotifications, isLoading } = useSelector(selectPushNotifications);
    const [deleteClick, setDeleteClick] = useState(false);
    const [sendClick, setSendClick] = useState(false);
        
    useEffect(() => {
        id && dispatch(getPushNotificationById({ id: Number(id) }))
    }, [id]);

    const onSend = (id: number) => {
        dispatch(sendNotification({ id }));
        setSendClick(true);
    };

    const onDelete = (id: number) => {
        dispatch(deletePushNotification({ id }));
        setDeleteClick(true);
    };

    return {
        notificationById, onSend, onDelete, errorsSendNotification, errorsDeletePushNotifications, isLoading, deleteClick, setDeleteClick,
        sendClick, setSendClick
    };
};