import { IconButton, Stack, SvgIcon, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { Link } from "react-router-dom";
import { getStyles } from "./styles";
import { paths } from "../../../../../../paths";

interface IProps {
    notification: any;
    handleDelete: (e: React.MouseEvent<HTMLButtonElement>, id: number) => void;
};

export const NotificationTableItem: FC<IProps> = ({ notification, handleDelete }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const deleteNotification = useCallback((e: React.MouseEvent<HTMLButtonElement>) => handleDelete(e, notification.id), [notification.id]);

    const audienceItems: any = {
        All: t(tokens.notification.all),
        UserID: t(tokens.notification.user),
        State: t(tokens.notification.region),
        Role: t(tokens.notification.role),
        Group: t(tokens.notification.group),
    };

    return (
        <TableRow hover key={notification.id} component={Link} to={!notification.deleted_at ? paths.dashboard.notification.detail.replace(":id", notification.id) :  "#"} sx={styles.tebleRow}>
            <TableCell align="left" width='35%' sx={styles.cell}>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <img src={notification.image_url ? notification.image_url : '/images/logo/notification.png'} width={45} height={45} alt="advert_logo" style={{ objectFit: 'cover', marginTop: 7 }} />
                    <Tooltip title={notification.title.length > 68 ? notification.title : ""} arrow>
                        <Typography sx={styles.title} pt={1} variant="body2">
                            {notification.title}
                        </Typography>
                    </Tooltip>
                </Stack>
            </TableCell>
            <TableCell align="center" width='30%' sx={styles.cell}>
                <Tooltip title={notification.text.length > 75 ? notification.text : ""} arrow>
                    <Typography sx={styles.text} pt={1} variant="body2">
                        {notification.text}
                    </Typography>
                </Tooltip>
            </TableCell>
            <TableCell align="center" width='25%' sx={styles.cell}>
                <Typography sx={{ textAlign: 'center', pt: 1 }} variant="body2">{audienceItems[notification.audienceType]}</Typography>
            </TableCell>
            <TableCell align="right" width='10%' sx={styles.cell}>
                <Stack direction='row' alignItems='center' spacing={1} justifyContent='flex-end'>
                    <IconButton size="small" component={Link} to={paths.dashboard.notification.edit.replace(":id", notification.id)}>
                        <Tooltip title={notification.deleted_at ? t(tokens.common.watch) : t(tokens.common.edit)}>
                            <SvgIcon style={styles.svgIcon}><EditOutlinedIcon /></SvgIcon>
                        </Tooltip>
                    </IconButton>
                    {!notification.deleted_at &&
                        <IconButton size="small" onClick={deleteNotification}>
                            <Tooltip title={t(tokens.common.delete)}>
                                <SvgIcon style={styles.svgIcon}><DeleteForeverIcon /></SvgIcon>
                            </Tooltip>
                        </IconButton>}
                </Stack>
            </TableCell>
        </TableRow>
    )
};