export const getStyles = () => {
    const styles = {
        progress: {
            position: 'relative', 
            bottom: '30px', 
            height: '300px'
        },
        fileContainer: {
            overflow: 'scroll',
            scrollbarWidth: 'none',
            '.dialog-content::-webkit-scrollbar': {
                display: 'none'
            }
        }
    };
    return styles;
};