import { FC } from 'react';
import { Box, Card, Stack, Typography } from '@mui/material';
import { INotification, TabValue, statusType, AudienceType } from '../../../../../../store/pushNotification/repository/INotification';
import { styles } from './styles';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../../locales/tokens';
import { DetailData } from '../detailData';

interface IProps {
    item: INotification | null;
}

export const NotificationAudienceInfo: FC<IProps> = ({ item }) => {
    const { t } = useTranslation();
    const date = new Date(item?.time as string);
    const time = format(date, 'dd/MM/yyyy HH:mm:ss');

    const statusOption: statusType = {
        Ready: t(tokens.notification.ready),
        Completed: t(tokens.notification.completed),
        Deleted: t(tokens.notification.error),
    };

    const audienceItems = {
        All: t(tokens.notification.all),
        UserID: t(tokens.notification.user),
        City: t(tokens.notification.region),
        Role: t(tokens.notification.role),
        Group: t(tokens.notification.group),
    };

    return (
        <Stack sx={styles.container} >
            <Box component="img" sx={styles.image} alt="The notification image" src={item?.image_url || '/images/logo/notification.png'} />
            <Box sx={{ px: 2, overflow: 'hidden' }} >
                <DetailData title={t(tokens.notification.title)} value={item?.title} />
                <DetailData title={t(tokens.notification.text)} value={item?.text} />
                {item?.navigation_url
                    && <DetailData title={t(tokens.notification.linkTo)} value={item?.navigation_url} />}
                {item?.image_url
                    && <DetailData title={t(tokens.notification.imageUrl)} value={item.image_url} />}
                <DetailData title={t(tokens.notification.status)} value={statusOption[item?.status as TabValue]} />
                <DetailData title={t(tokens.notification.audience)} value={audienceItems[item?.audienceType as AudienceType]} />
                {item?.audienceType === "UserID"
                    && <DetailData title={t(tokens.notification.user)} value={item?.user.first_name + " " + item?.user.last_name} />}
                {item?.audienceType === "City"
                    && <DetailData title={t(tokens.notification.region)} value={item?.city} />}
                {item?.audienceType === "Role"
                    && <DetailData title={t(tokens.notification.role)} value={item?.role} />}
                {item?.audienceType === "Group" && (
                    <Stack direction='row' alignItems='flex-start' spacing={1}>
                        <Typography variant='body1' fontWeight={600}>{t(tokens.notification.users)}:</Typography>
                        <Typography variant='body1'>
                            {item?.group?.map((user: any, index: number, array: any[]) => (
                                (index !== array.length - 1) ?
                                    (user.first_name + ' ' + user.last_name + ", ") :
                                    (user.first_name + ' ' + user.last_name + ".")
                            ))}
                        </Typography>
                    </Stack>
                )}
                {item?.time
                    && <DetailData title={t(tokens.notification.timeSending)} value={time} />}
            </Box>
        </Stack >
    );
};
