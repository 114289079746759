import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setDeleteFileError, setLoading } from "../../repository/slice";

function* deleteFileWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call( requester.delete, "/api/file/delete/" + payload.fileName );
        if (isError) {
            yield put(setDeleteFileError(message));
        } else {
            yield put(setDeleteFileError(null));
        }
    } catch (error) {
        console.error("deleteFileWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* deleteFileWatcher() {
    yield takeEvery("@saga/DELETE_FILE", deleteFileWorker);
};