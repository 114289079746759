import { TreeItem } from "@mui/x-tree-view";
import { Dispatch, FC, Fragment } from "react";
import { TreeCategoryItemLabel } from "../treeCategoryItemLabel";
import { useDragDrop } from "../../../presenters/useDragDrop";
import { ITreeProps } from "../treeCategory";
import { ICategory } from "../../../../../../../types/category";

interface IProps {
    node: ICategory;
    key: string;
    isPoster: boolean;
    index: number;
    sectionIndex: number | undefined;
    renderCategoryTree: FC<ITreeProps>;
    handleStatus: (id: number, isHidden: boolean) => void;
    setUpdateClick: Dispatch<boolean>;
    capturedCategory: ICategory | null;
    setCapturedCategory: Dispatch<any>;
    defineCategoryId: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => void; 
    swapSections: (direction: "UP" | "DOWN", index: number, firstSection: any) => void;
};

export const TreeCategorySection: FC<IProps> = ({ node, handleStatus, renderCategoryTree, isPoster, capturedCategory, setCapturedCategory, setUpdateClick, 
    swapSections, index, sectionIndex, defineCategoryId }) => {
    const { dragStartHandler, dragOverHandler, dragDropHandler, dragLeaveHandler, dragEndHandler } = useDragDrop(node, capturedCategory, setCapturedCategory, setUpdateClick);

    return (
        <div
            style={{ cursor: 'grab' }}
            // draggable
            // onDragStart={dragStartHandler}
            // onDragLeave={dragLeaveHandler}
            // onDragEnd={dragEndHandler}
            // onDragOver={dragOverHandler}
            // onDrop={dragDropHandler}
        >
            <TreeItem
                key={node.name}
                nodeId={node.name + node.id}
                label={
                    <TreeCategoryItemLabel
                        category={node}
                        isPoster={isPoster}
                        handleStatus={handleStatus}
                        swapSections={swapSections}
                        index={index}
                        sectionIndex={sectionIndex}
                        defineCategoryId={defineCategoryId}
                    />
                }
            >
                {Array.isArray(node.children) && node.children.length > 0 && node ?
                    <Fragment key={node.createdAt + node.name + node.sortNumber}>
                        {renderCategoryTree({
                            nodes: node.children,
                            keyCategory: node.name,
                            handleStatus: handleStatus,
                            isPoster: false,
                            setUpdateClick: setUpdateClick,
                            capturedCategory: capturedCategory,
                            setCapturedCategory: setCapturedCategory,
                            swapSections: swapSections,
                            sectionIndex: index,
                            defineCategoryId: defineCategoryId
                        })}
                    </Fragment>
                    :
                    null
                }
            </TreeItem>
        </div>
    )
};