export const getStyles = () => {
    const styles = {
        background: {
            width: '100%',
            height: '100%',
            position: 'fixed',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundImage: "url('/images/logo/background.png')"
        },
        container: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            borderRadius: '4px'
        },
        containerMd: {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            // width: '30%',
            width: '450px',
            borderRadius: '4px'
        },
        recoverBtn: {
            color: "#FFFFFF",
            height: '45px'
        },
        error: {
            pl: '10px',
            position: 'absolute',
            top: '115px',
        }
    }
    return styles;
}