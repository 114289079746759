export const getStyles = (isActive: boolean, isDark: boolean) => {
    const styles = {
        permissionsText: {
            maxWidth: 700,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        svgIcon: {
            fontSize: 26
        },
        processedBtn: {
            borderRadius: '10px',
            color: '#FFFFFF',
            fontWeight: 600,
            width: "190px",
            pointerEvents: isActive ?
                'auto' : 'none',
            backgroundColor: isActive ?
                '#F1594E' : '#008000',
            textTransform: 'none',
            '&:hover': {
                backgroundColor: isActive ?
                    '#d85046' : '#00800c'
            }
        },
        date: {
            backgroundColor: isDark ? '#7C7C7C' : '#E5E7EB',
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '10px',
            paddingRight: '10px',
            borderRadius: '5px'
        },
        name: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            lineHeight: 1.2,
        },
        cell: {
            py: 1
        }
    };
    return styles;
};