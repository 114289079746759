import { MenuSection } from "../../types/settings";
import { paths } from "../../paths";
import { TFunction } from "i18next";
import { tokens } from "../../locales/tokens";
import PeopleIcon from '@mui/icons-material/People';
import SourceIcon from '@mui/icons-material/Source';
import DescriptionIcon from '@mui/icons-material/Description';
import AlignHorizontalLeftIcon from '@mui/icons-material/AlignHorizontalLeft';
import QuizIcon from '@mui/icons-material/Quiz';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import CategoryIcon from '@mui/icons-material/Category';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import BackupIcon from '@mui/icons-material/Backup';
import BadgeIcon from '@mui/icons-material/Badge';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import MessageIcon from '@mui/icons-material/Message';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';

export const getMenuSections = (t: TFunction): MenuSection[] => [
    {
        items: [
            {
                title: t(tokens.users.handleUsers),
                icon: <PeopleIcon />,
                path: paths.dashboard.users.index,
                items: [
                    { title: t(tokens.common.list), path: paths.dashboard.users.index },
                    { title: t(tokens.common.roles), path: paths.dashboard.users.roles },
                ],
            },
            {
                title: t(tokens.nav.content),
                icon: <SourceIcon />,
                path: paths.dashboard.content.index,
                items: [
                    {
                        title: t(tokens.nav.category),
                        icon: <CategoryIcon />,
                        path: paths.dashboard.content.category
                    },
                    {
                        title: t(tokens.nav.documents),
                        icon: <DescriptionIcon />,
                        path: paths.dashboard.content.document.list,
                    },
                    {
                        title: t(tokens.nav.tests),
                        icon: <AlignHorizontalLeftIcon />,
                        path: paths.dashboard.content.test.list
                    },
                    {
                        title: t(tokens.nav.quiz),
                        icon: <QuizIcon />,
                        path: paths.dashboard.content.quiz.list
                    },
                    {
                        title: t(tokens.nav.courses),
                        icon: <CollectionsBookmarkIcon />,
                        path: paths.dashboard.content.course.list
                    },
                    {
                        title: t(tokens.trainee.trainees),
                        icon: <EmojiEmotionsIcon />,
                        path: paths.dashboard.content.trainee.index
                    },
                ],
            },
            {
                title: t(tokens.feedback.feedback),
                icon: <MessageIcon />,
                path: paths.dashboard.feedback.generalQuestion,
                items: [
                    { title: t(tokens.feedback.commonQuestion), path: paths.dashboard.feedback.generalQuestion },
                    { title: t(tokens.feedback.isAnIdea), path: paths.dashboard.feedback.isIdea },
                    { title: t(tokens.feedback.anonymousComplaint), path: paths.dashboard.feedback.anonymousComplaint },
                ],
            },
            {
                title: t(tokens.adverts.adverts),
                icon: <AutoStoriesOutlinedIcon />,
                path: paths.dashboard.advert.index,
                items: [
                    { title: t(tokens.common.list), path: paths.dashboard.advert.index },
                    { title: t(tokens.common.create), path: paths.dashboard.advert.create },
                ],
            },
            {
                title: t(tokens.badge.navBar.title),
                icon: <BadgeIcon />,
                path: paths.dashboard.badge.index,
                items: [
                    { title: t(tokens.badge.navBar.list), path: paths.dashboard.badge.index },
                    { title: t(tokens.badge.navBar.create), path: paths.dashboard.badge.create },
                ],
            },
            {
                title: t(tokens.appPage.navBar.title),
                icon: <ListAltIcon />,
                path: paths.dashboard.appPage.index,
                items: [
                    { title: t(tokens.appPage.navBar.list), path: paths.dashboard.appPage.index },
                ],
            },
            {
                title: t(tokens.notification.notification),
                icon: <CircleNotificationsIcon />,
                path: paths.dashboard.notification.index,
                items: [
                    { title: t(tokens.common.list), path: paths.dashboard.notification.index },
                ],
            },
            {
                title: t(tokens.shop.navBar.title),
                icon: <LocalMallIcon />,
                path: paths.dashboard.shop.index,
                items: [
                    { title: t(tokens.shop.navBar.list), path: paths.dashboard.shop.index },
                    { title: t(tokens.shop.navBar.ordersList), path: paths.dashboard.shop.orders.list },
                ],
            },
            {
                title: t(tokens.analytics.analytics),
                icon: <InsertChartIcon />,
                path: paths.dashboard.analytics.general,
                items: [
                    { title: t(tokens.analytics.generalAnalytics), path: paths.dashboard.analytics.general },
                    { title: t(tokens.analytics.analyticsUser), path: paths.dashboard.analytics.user },
                    { title: t(tokens.analytics.analyticsCourse), path: paths.dashboard.analytics.course },
                    { title: t(tokens.analytics.analyticsContent), path: paths.dashboard.analytics.content },
                    { title: t(tokens.analytics.analyticsAdvert), path: paths.dashboard.analytics.advert },
                    { title: t(tokens.analytics.analyticsBadge), path: paths.dashboard.analytics.product },
                    { title: t(tokens.analytics.analyticsKnowledge), path: paths.dashboard.analytics.knowledge },
                    { title: t(tokens.analytics.myProcess), path: paths.dashboard.analytics.process },

                    // { title: "Таблица результатов", path: paths.dashboard.analytics.leaderboard },
                    // { title: "Результати теста", path: paths.dashboard.analytics.singleTest },
                    // { title: "Результати курса", path: paths.dashboard.analytics.singleCourse },
                    // { title: "Статистика документа", path: paths.dashboard.analytics.singleDocument },
                    // { title: "Статистика оголошення", path: paths.dashboard.analytics.singleAdvert },
                    // { title: "Статистика документів", path: paths.dashboard.analytics.documents },
                    // { title: "Статистика оголошень", path: paths.dashboard.analytics.adverts },
                ],
            },
            // {
            //     title: t(tokens.backup.backup),
            //     icon: <BackupIcon />,
            //     path: paths.dashboard.backup.index
            // },
        ]
    }
];