import { SagaIterator } from "redux-saga";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setUpdateQuizError } from "../../repository/slice";

function* updateQuizWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.put, "/api/admin/questionnaires/" + payload.body.id, payload.body);
        if (isError) {
            yield put(setUpdateQuizError(message));
        } else {
            yield put(setUpdateQuizError(null));
        }
    } catch (error) {
        console.error("updateQuizWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* updateQuizWatcher() {
    yield takeLatest("@saga/UPDATE_QUIZ", updateQuizWorker);
};
