import { Pagination, Stack, Table } from "@mui/material"
import { ChangeEvent, FC } from "react"
import { HistoryTableHead } from "../historyTableHead"
import { HistoryTableBody } from "../historyTableBody"
import { IHistory } from "../../../../../../store/learningHistory/repository/IHistory";

interface IProps {
    historyList: IHistory[];
    pageAmount: number;
    currentPage: number;
    handlePageChange: (event: ChangeEvent<unknown>, page: number) => void;
};

export const HistoryTableList: FC<IProps> = ({ historyList, handlePageChange, pageAmount, currentPage }) => {
    
    return (
        <>
            <Table>
                <HistoryTableHead />
                <HistoryTableBody historyList={historyList} />
            </Table>
            <Stack direction='row' justifyContent='center' mt={4}>
                <Pagination
                    count={pageAmount || 1}
                    page={currentPage || 1}
                    color="primary"
                    onChange={handlePageChange}
                />
            </Stack>
        </>
    )
};