import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setBackupList, setLoading } from "../../repository/slice";

function* getBackupListWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.get, "/api/backup/get-list", payload);
        if (isError) {

        } else {
            yield put(setBackupList({ list: data.rows, count: data.count }));
        }
    } catch (error) {
        console.error("getBackupListWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* getBackupListWatcher() {
    yield takeLatest("@saga/GET_BACKUP_LIST", getBackupListWorker);
};
