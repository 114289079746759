import React, { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

interface IProps {
    name: string;
    control: Control<any>;
    children?: any;
    defaultValue?: string;
    placeholder?: string;
}

export const FormControlLayout: FC<IProps> = ({ name, defaultValue, control, placeholder, children }) => (
    <Controller name={name} control={control} defaultValue={defaultValue}
        render={({ field }) => (children && React.Children.map(children, child => React.isValidElement(child) ? React.cloneElement(child, { ...field }) : child))}
    />
);
