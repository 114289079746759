import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';
import { IProduct } from '../types/IProduct';

interface IState {
    isProductLoading: boolean;
    productList: IProduct[];
    selectedProduct: IProduct | null;
    productCount: number;
    productError: IError;
    errorGalleryFiles: IError;
    galleryFiles: any[];
};

const initialState: IState = {
    isProductLoading: false,
    productList: [],
    selectedProduct: null,
    productCount: 0,
    productError: null,
    errorGalleryFiles: null,
    galleryFiles: [],
};

const ProductSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setProductLoading: (state, action: PayloadAction<boolean>) => {
            state.isProductLoading = action.payload;
        },
        setProductList: (state, action: PayloadAction<{ rows: IProduct[], count: number }>) => {
            state.productList = action.payload.rows;
            state.productCount = action.payload.count;
        },
        setSelectedProduct: (state, action: PayloadAction<IProduct | null>) => {
            state.selectedProduct = action.payload;
        },
        setProductError: (state, action: PayloadAction<IError>) => {
            state.productError = action.payload;
        },
        setGalleryFiles: (state, action: PayloadAction<any[]>) => {
            state.galleryFiles = action.payload;
        },
        setErrorGalleryFiles: (state, action: PayloadAction<IError>) => {
            state.errorGalleryFiles = action.payload;
        },
    },
});

export const { setProductLoading, setProductList, setSelectedProduct, setProductError, setGalleryFiles, setErrorGalleryFiles } = ProductSlice.actions;
export const ProductReducer = ProductSlice.reducer;