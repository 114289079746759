import { Box, CircularProgress, IconButton, Stack, SvgIcon, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { getStyles } from "./styles";
import { paths } from "../../../../../../paths";

interface IProps {
    position: any;
    handleDelete: (id: number) => void;
};

export const PositionTableItem: FC<IProps> = ({ position, handleDelete }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const deleteDocument = useCallback(() => handleDelete(position.id), [position.id]);

    function extractAndFormatNames(data: any[]) {
        if(!data.length) return "Немає даних";
        const names = data.map(item => item.name);
        const result = names.join(', ') + '.';
        return result;
    };

    return (
        <TableRow hover key={position.id}>
            <TableCell align="left" width='25%' sx={styles.cell}>
                <Typography variant="body2">{position.name}</Typography>
            </TableCell>
            <TableCell align="left" width='50%' sx={styles.cell}>
                <Tooltip title={extractAndFormatNames(position.content)} placement="bottom-start">
                    <Typography variant="body2" sx={styles.tasks}>
                        {extractAndFormatNames(position.content)}
                    </Typography>
                </Tooltip>
            </TableCell>
            <TableCell align="right" width='25%' sx={styles.cell}>
                <Stack direction='row' alignItems='center' spacing={1} justifyContent='flex-end'>
                    <IconButton size="small" component={Link} to={paths.dashboard.content.trainee.edit.replace(':id', position.id)}>
                        <Tooltip title={position.deleted_at ? t(tokens.common.watch) : t(tokens.common.edit)}>
                            <SvgIcon style={styles.svgIcon}><EditOutlinedIcon /></SvgIcon>
                        </Tooltip>
                    </IconButton>
                    {/* {!position.deleted_at ? 
                            <IconButton size="small" onClick={deleteDocument}>
                                <Tooltip title={t(tokens.common.delete)}>
                                    <SvgIcon style={styles.svgIcon}><DeleteForeverIcon /></SvgIcon>
                                </Tooltip>
                            </IconButton>
                        
                     : null
                    } */}
                </Stack>
            </TableCell>
        </TableRow>
    )
};