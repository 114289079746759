import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading } from "../../repository/slice";
import { getSingleTest } from "../getSingleTest/action";

function* updateAnswerWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.put, "/api/admin/answer/update", payload.body);
        if (isError) {

        } else {
            if(payload?.testId){
                yield put(getSingleTest({id: Number(payload.testId)}));
            };
        }
    } catch (error) {
        console.error("updateAnswerWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* updateAnswerWatcher() {
    yield takeEvery("@saga/UPDATE_ANSWER", updateAnswerWorker);
};
