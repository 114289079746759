import { SelectChangeEvent } from "@mui/material";
import { ChangeEvent, ReactNode, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleUser } from "../../../../store/user/useCases/getSingleUser/action";
import { selectUser } from "../../../../store/user/repository/selector";
import { getRoles } from "../../../../store/role/useCases/getRoles/action";
import { selectRole } from "../../../../store/role/repository/selector";
import { setUsersRole } from "../../../../store/user/useCases/setUsersRole/action";
import { deleteUsersRole } from "../../../../store/user/useCases/deleteUserRole/action";
import toast from "react-hot-toast";

export const useUserEdit = () => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const { singleUser, isLoading, createRoleError, deleteRoleError } = useSelector(selectUser);
    const { roleList } = useSelector(selectRole);

    const [userRoles, setUserRoles] = useState<string[]>([]);
    const [imageSrc, setImageSrc] = useState('');
    const [createClick, setCreateClick] = useState(false);
    const [deleteClick, setDeleteClick] = useState(false);

    useEffect(() => {
        dispatch(getSingleUser({ id }));
        dispatch(getRoles({ isDeleted: false }));
    }, []);

    useEffect(() => {
        if (singleUser) {
            setUserRoles(singleUser.roles?.map((role: any) => role.name));
            setImageSrc(singleUser.photo || '');
        }
    }, [singleUser]);

    useEffect(() => {
        if (createClick) {
            toast.success("Роль додана");
        }
        setCreateClick(false);
    }, [createClick]);

    useEffect(() => {
        if (deleteClick) {
            toast.success("Роль видалена");
        }
        setDeleteClick(false);
    }, [deleteClick]);

    const roles = useMemo(() => roleList.filter(role => !role?.deleted_at), [roleList]);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];

        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const newImageSrc = e.target?.result as string;
                setImageSrc(newImageSrc);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleChangeRole = (event: SelectChangeEvent<string[]>, child: ReactNode) => {
        const value: any = event.target.value[event.target.value.length - 1];
        function isNameInArray(nameToCheck: string) {
            for (var i = 0; i < userRoles.length; i++) {
                if (userRoles[i] === nameToCheck) {
                    return true;
                }
            }
            return false;
        };

        if (!isNameInArray(value.name)) {
            setUserRoles(prevState => [...prevState, value.name]);
            dispatch(setUsersRole({ user_id: Number(id), role_id: value.id }));
            setCreateClick(true);
        } else {
            setUserRoles(userRoles.filter((item: any) => item !== value.name));
            dispatch(deleteUsersRole({ user_id: Number(id), role_id: value.id }));
            setDeleteClick(true);
        }
    };

    return { singleUser, userRoles, handleChangeRole, imageSrc, handleFileChange, roles }
};