export const getStyles = () => {
    const styles = {
        header: {
            backdropFilter: 'blur(6px)',
            backgroundColor: 'background.default',
            position: 'sticky',
            left: {
                lg: '350px'
            },
            top: 0,
            width: {
                lg: `calc(100% - 300px)`
            },
            paddingBottom: '10px',
            borderBottom: '1px solid #D3D3D3',
            zIndex: 100
        },
        headerContainer: {
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '40px',
            px: 2,
            pt: 1
        }
    }
    return styles;
}