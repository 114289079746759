import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateOrgStructure } from "../../../../../store/orgStructure/useCases/updateOrgStructure/action";
import { selectOrgStructure } from "../../../../../store/orgStructure/repository/selector";
import { paths } from "../../../../../paths";
import toast from "react-hot-toast";
import { tokens } from "../../../../../locales/tokens";
import { createQuiz } from "../../../../../store/quiz/useCases/createQuiz/action";
import { selectQuiz } from "../../../../../store/quiz/repository/selector";
import { IError } from "../../../../../types/error";
import { SelectChangeEvent } from "@mui/material";
import { getPositions } from "../../../../../store/user/useCases/getPositions/action";
import { selectUser } from "../../../../../store/user/repository/selector";

export const useQuizCreate = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [theme, setTheme] = useState('');
    const [themeValid, setThemeValid] = useState(true);
    const [content, setContent] = useState('');
    const [initiator, setInitiator] = useState<any>(null);
    const [initiatorValid, setInitiatorValid] = useState(true);
    const [groupPermissions, setGroupPermissions] = useState<any[]>([]);
    const [groupPermissionsValid, setGroupPermissionsValid] = useState(true);
    const [receiver, setReceiver] = useState<any[]>([]);
    const [publishChecked, setPublishChecked] = useState(false);
    const [notifyChecked, setNotifyChecked] = useState(false);
    const [namelessChecked, setNamelessChecked] = useState(false);
    const { orgStructure } = useSelector(selectOrgStructure);
    const [isQuestionModalOpen, setQuestionModalOpen] = useState(false);
    const [createClick, setCreateClick] = useState(false);
    const { createQuizError, isLoading, quizId } = useSelector(selectQuiz);
    const { positionsList } = useSelector(selectUser);
    const [departmentPermissions, setDepartmentPermissions] = useState<any[]>([]);
    const [positions, setPositions] = useState<any[]>([]);
    const [assignDepartment, setAssignDepartment] = useState<any[]>([]);
    const [assignPositions, setAssignPositions] = useState<any[]>([]);
    
    useEffect(() => {
        dispatch(updateOrgStructure());
        dispatch(getPositions());
    }, []);

    useEffect(() => {
        if (!isLoading && createClick) {
            if (createQuizError) {
                handleServerError(createQuizError);
            } else {
                toast.success(t(tokens.quiz.message.quizCreated));
                navigate(paths.dashboard.content.quiz.edit.replace(':id', String(quizId)));
            }
            setCreateClick(false);
        }
    }, [createQuizError, isLoading]);

    const handleServerError = (error: IError) => {
        if (error?.startsWith("value too long for type character varying(255)")) {
            toast.error(t(tokens.common.tooMuchSymbols));
            setThemeValid(false);
        } else if (error?.startsWith("value too long for type character varying(500)")) {
            toast.error(t(tokens.common.tooMuchSymbols));
        } else {
            toast.error(error);
        }
    };
    
    const onChangeTheme = (event: ChangeEvent<HTMLInputElement>) => {
        setTheme(event.target.value);
        setThemeValid(true);
    };

    const onChangeContent = (event: ChangeEvent<HTMLInputElement>) => {
        setContent(event.target.value);
    };

    const onSelectInitiator = (selectedKeysValue: string, node: any) => {
        if ('children' in node) {
            return;
        }
        setInitiator(node);
        setInitiatorValid(true);
    };

    const onChangeInitiator = () => {
        if (initiator) {
            setInitiator(null);
        }
    };

    const onChangePermissions = (newValue: string[]) => {
        setGroupPermissions(newValue);
        setGroupPermissionsValid(true);
    };

    const onChangeDepartmentPermissions = (newValue: string[]) => {
        setDepartmentPermissions(newValue);
    };

    const onChangeAssignDepartment = (newValue: string[]) => {
        setAssignDepartment(newValue);
    };

    const onChangeReceiver = (newValue: string[]) => {
        setReceiver(newValue);
    };

    const handlePublishChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPublishChecked(event.target.checked);
    };

    const handleNotifyChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNotifyChecked(event.target.checked);
    };

    const handleNamelessChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNamelessChecked(event.target.checked);
    };

    const handleOpenQuestionModal = () => {
        setQuestionModalOpen(true);
    };

    const cancelCreate = () => {
        navigate(paths.dashboard.content.quiz.list);
    };

    const handleQuiz = () => {
        const isPassedValidation = validateData();
        if (isPassedValidation) {
            createNewQuiz();
        } else {
            toast.error(t(tokens.adverts.fillAllFields))
        };
    };

    const validateData = () => {
        const data = [
            { field: theme, setField: setThemeValid },
            { field: initiator, setField: setInitiatorValid },
            // { field: groupPermissions.length, setField: setGroupPermissionsValid },
        ];
        let allFieldsValid = true;
        for (const { field, setField } of data) {
            if (!field) {
                setField(false);
                allFieldsValid = false;
            }
        };
        return allFieldsValid;
    };

    const createNewQuiz = () => {
        dispatch(createQuiz({ body: getRequestBody() }));
        setCreateClick(true);
    };

    const cleanedGuiIds = (guiIds: string[]): string[] => {
        return guiIds.map(permission => {
            const [cleaned] = permission.split('%');
            return cleaned;
        });
    };    

    const getRequestBody = () => {
        const body: any = {
            name: theme,
            initiatorId: initiator.value,
        };
        if (content.length) body.description = content;
        if (receiver.length) body.assigns = receiver;
        if (groupPermissions.length) body.access = groupPermissions;
        body.anonymous = namelessChecked;
        if(departmentPermissions.length) body.accessDepartmentIds = cleanedGuiIds(departmentPermissions);
        if(positions.length) body.accessPositionIds = positions.map(item => item.id);
        if(assignDepartment.length) body.assignDepartmentIds = cleanedGuiIds(assignDepartment);
        if(assignPositions.length) body.assignPositionIds = assignPositions.map(item => item.id); 
        // body.isInformed = notifyChecked;
        // body.status = publishChecked ? "Available" : "Hidden";
        return body;
    };

    const handleChangePosition = (event: any, newValue: readonly any[]) => {
        setPositions([...newValue]);
    };

    const handleChangeAssignPosition = (event: any, newValue: readonly any[]) => {
        setAssignPositions([...newValue]);
    };

    return {
        theme, content, onChangeTheme, onChangeContent, onSelectInitiator, onChangeInitiator, onChangePermissions, groupPermissions, receiver, onChangeReceiver,
        handlePublishChange, handleNotifyChange, themeValid, initiatorValid, groupPermissionsValid, orgStructure, initiator, handleOpenQuestionModal, publishChecked,
        notifyChecked, namelessChecked, handleNamelessChange, handleQuiz, cancelCreate, createClick, onChangeDepartmentPermissions, departmentPermissions,
        positionsList, positions, handleChangePosition, onChangeAssignDepartment, assignDepartment, handleChangeAssignPosition, assignPositions
    }
};