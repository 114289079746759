export const getStyles = (lgDown: boolean) => {
    const styles = {
        createButton: { 
            borderRadius: '10px', 
            color: '#FFFFFF',
            fontWeight: 600,
            backgroundColor: '#F1594E',
            textTransform: 'none',
            '&:hover':{
                backgroundColor: '#d85046'
            } 
        },
        cancelButton: {
            borderRadius: '10px', 
            color: '#F1594E',
            fontWeight: 600,
            borderColor: '#F1594E',
            textTransform: 'none',
            '&:hover':{
                borderColor: '#d85046'
            } 
        },
        content: { 
            borderRadius: '25px', 
            p: 2 
        },
        backDrop: { 
            color: '#fff', 
            zIndex: 100, 
            ml: lgDown? 0 : '350px' 
        },
        card: { 
            mt: 3,
            p: 2, 
            borderRadius: '15px' 
        }
    };
    return styles;
};