import { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../locales/tokens";
import { Stack } from "@mui/material";
import { TabSelector } from "../tabSelector/tabSelector";

interface IProps {
    tab: string;
    handleTabsChange: (event: ChangeEvent<{}>, tab: string) => void;
};

export const TabsChanger: FC<IProps> = ({ tab, handleTabsChange }) => {
    const { t } = useTranslation();

    const tabOptions = [
        { label: t(tokens.documents.active), value: "Active" },
        { label: t(tokens.common.archive), value: "Archive" },
    ];
    return (
        <Stack>
            <TabSelector
                tab={tab}
                tabOptions={tabOptions}
                handleTabsChange={handleTabsChange}
            />
        </Stack>
    )
};