export const getStyles = () => {
    const styles = {
        title: { 
            p: 3 
        },
        button: { 
            color: '#FFFFFF' 
        },
    }
    return styles;
}