import { Stack, TextField, Typography } from "@mui/material";
import { ChangeEvent, FC } from "react";

interface IProps {
    title: string;
    label: string;
    isValid: boolean;
    value: string;
    onChangeValue: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const TextInputModal: FC<IProps> = ({ isValid, value, onChangeValue, label, title }) => {

    return (
        <Stack width="100%">
            <Typography mb={1} color={isValid ? 'inherit' : 'error'}>
                {title}
            </Typography>
            <TextField
                fullWidth
                value={value}
                onChange={onChangeValue}
                label={label}
                autoComplete="off"
            />
        </Stack>
    )
};