import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setRoleLoading, setRoleError, setSelectedRole } from "../../repository/slice";
import { IRole } from "../../types/IRole";

function* creteRoleWorker({ payload }: any): SagaIterator {
    try {
        yield put(setRoleLoading(true));
        const { data, message, isError }: IResponse<IRole> = yield call(requester.post, "/api/role/create", payload);
        if (isError) {
            yield put(setRoleError(message));
        } else {
            yield put(setSelectedRole(data));
        }
    } catch (error) {
        console.error("creteRoleWorker: ", error);
    } finally {
        yield put(setRoleLoading(false));
    };
};

export function* createRoleWatcher() {
    yield takeLatest("@saga/CREATE_ROLE", creteRoleWorker);
};