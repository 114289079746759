import { ChangeEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { TFunction } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useDebounce } from "../../../../hooks/useDebounce";
import { tokens } from "../../../../locales/tokens";
import { paths } from "../../../../paths";
import { selectPushNotifications } from "../../../../store/pushNotification/repository/selectors";
import { createPushNotification } from "../../../../store/pushNotification/useCases/createNotification/actions";
import { useNavigate } from "react-router-dom";
import { selectRole } from "../../../../store/role/repository/selector";
import { getRoles } from "../../../../store/role/useCases/getRoles/action";
import { getUsers } from "../../../../store/user/useCases/getUsers/action";
import { selectUser } from "../../../../store/user/repository/selector";
import { FILE_SIZE } from "../../../../config";
import { IError } from "../../../../types/error";

export const useNotificationCreation = (t: TFunction<"translation", undefined>) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { roleList } = useSelector(selectRole);
    const { userList } = useSelector(selectUser);
    const { errorsCreatePushNotification, isLoading } = useSelector(selectPushNotifications);
    const [checked, setChecked] = useState(false);
    const [searchUsers, setSearchUsers] = useState('');
    const [createClick, setCreateClick] = useState(false);
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [linkTo, setLinkTo] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [audience, setAudience] = useState('All');
    const [userId, setUserId] = useState('');
    const [user, setUser] = useState<any>(null);
    const [country, setCountry] = useState('');
    const [region, setRegion] = useState('');
    const [userStatus, setUserStatus] = useState('');
    const [groupUsers, setGroupUsers] = useState<any[]>([]);
    const [time, setTime] = useState('');
    const [image, setImage] = useState<any>(null);
    const [imageSrc, setImageSrc] = useState('');
    const [imageSrcValid, setImageSrcValid] = useState(true);
    const [imageType, setImageType] = useState('Link');
    const [titleValid, setTitleValid] = useState(true);
    const [textValid, setTextValid] = useState(true);
    const [navLinkValid, setNavLinkValid] = useState(true);
    const [imageUrlValid, setImageUrlValid] = useState(true);

    useEffect(() => {
        dispatch(getUsers({ page: 1, perPage: 20 }));
        dispatch(getRoles());
    }, []);

    useEffect(() => {
        debouncedHandleUsersSelect(searchUsers);
    }, [searchUsers]);

    useEffect(() => {
        setImage(null);
        setImageSrc('');
        setImageUrl('');
    }, [imageType]);

    useEffect(() => {
        if (!isLoading && createClick) {
            if (errorsCreatePushNotification) {
                handleServerError(errorsCreatePushNotification);
            } else {
                toast.success(t(tokens.notification.pushCreated));
                navigate(paths.dashboard.notification.index);
            }
            setCreateClick(false);
        }
    }, [errorsCreatePushNotification, isLoading]);

    const handleServerError = (error: IError) => {
        if (error?.startsWith("value too long for type character varying(255)")) {
            toast.error(t(tokens.common.tooMuchSymbols));
            setTitleValid(false);
        } else if (error?.startsWith("value too long for type character varying(2000)")) {
            toast.error(t(tokens.common.tooMuchSymbols));
            setTextValid(false);
        } else {
            toast.error(error);
        }
    };
    
    const onSearchUsers = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchUsers(event.target.value);
    };

    const onSearch = (value: any) => {
        if (value === '') {
            dispatch(getUsers({ page: 1, perPage: 20 }));
            return;
        } else {
            getUsersByName(value);
        };
    };

    const onChangeImageType = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setImageType(e.target.value);
        setImageSrcValid(true);
        setImageUrlValid(true);
    };

    const getUsersByName = (value: any) => {
        if (!isNumber(value)) {
            dispatch(getUsers({ name: value, page: 1, perPage: 20 }));
            return
        } else {
            dispatch(getUsers({ id: Number(value), page: 1, perPage: 20 }));
            return
        }
    };

    const handleUserChange = (value: any) => {
        if (!isNumber(searchUsers)) {
            setGroupUsers(value);
        } else {
            setGroupUsers(prevState => [...prevState, ...value]);
        }
        setSearchUsers('');
    };

    const isNumber = (value: string) => {
        return /^\d+/.test(value);
    };

    const { debouncedWrapper: debouncedHandleUsersSelect } = useDebounce(onSearch, 1000);

    const onChangeSwitch = (event: ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        if (event.target.checked === false) setTime('');
    };

    const onChangeTime = (newDateTime: any) => {
        setTime(newDateTime);
    };

    const onAcceptTime = () => {
        toast.success(t(tokens.notification.timeIsSet))
    };

    const onChangTitle = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setTitle(e.target.value);
        setTitleValid(true);
    };

    const onChangText = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setText(e.target.value);
        setTextValid(true);
    };

    const onChangLinkTo = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setLinkTo(e.target.value);
        setNavLinkValid(true);
    };

    const onChangImageUrl = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setImageUrl(e.target.value);
        setImageUrlValid(true);
    };

    const onChangAudience = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setAudience(e.target.value);
        setUserId('');
        setCountry('');
        setRegion('');
        setUserStatus('');
        setGroupUsers([]);
    };

    const onChangeUserId = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setUserId(e.target.value);
    };

    const onChangeCountry = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setCountry(e.target.value);
    };

    const onChangeRegion = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setRegion(e.target.value);
    };

    const onChangeUserStatus = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setUserStatus(e.target.value);
    };

    const onCreateNotification = async () => {
        const isDataValid = validateFields();
        if (isDataValid) {
            createNotification();
        } else {
            toast.error(t(tokens.notification.fillFields))
        }
    };

    const validateFields = () => {
        const data = [
            { field: title, setField: setTitleValid },
            { field: text, setField: setTextValid },
        ];

        let allFieldsValid = true;
        for (const { field, setField } of data) {
            if (!field) {
                setField(false);
                allFieldsValid = false;
            }
        };

        return allFieldsValid;
    };

    const createNotification = () => {
        const isValidLinks = validateLinks();
        if (isValidLinks) {
            dispatch(createPushNotification(getRequestBody()));
            setCreateClick(true);
        };
    };

    const validateLinks = () => {
        let allFieldsValid = true;

        if (imageUrl) {
            const urlRegex = /(https?:\/\/[^\s]+\.[a-z]{2,})/gi;
            if (!urlRegex.test(imageUrl)) {
                toast.error(t(tokens.notification.imageUrlNotCorrect));
                setImageUrlValid(false);
                allFieldsValid = false;
            }
        };
        if (linkTo) {
            const urlRegex = /(https?:\/\/[^\s]+\.[a-z]{2,})/gi;
            if (!urlRegex.test(linkTo)) {
                toast.error(t(tokens.notification.linkNotCorrect));
                setNavLinkValid(false);
                allFieldsValid = false;
            }
        };

        return allFieldsValid;
    };

    const getRequestBody = () => {
        const body: any = { title, text, audienceType: audience };
        if (imageUrl) body.image_url = imageUrl;
        if (linkTo) body.navigation_url = linkTo;
        if (user) body.userId = user.id;
        if (region) body.city = region;
        if (userStatus) body.role = userStatus;
        if (image) body.image = image;
        if (time) body.time = new Date(time).toISOString();
        if (groupUsers.length) body.group = groupUsers.map(user => user.id);
        return body;
    };

    const imageTypeOptions: { label: string, value: string }[] = [
        { label: t(tokens.notification.link), value: 'Link' },
        { label: t(tokens.notification.image), value: 'Image' },
    ];

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];

        if (file) {
            // const maxSizeBytes = FILE_SIZE["1MB"];
            // if (file.size > maxSizeBytes) {
            //     toast.error(t(tokens.notification.maxSizeFile));
            //     setImageSrcValid(false);
            //     return;
            // };

            const reader = new FileReader();
            reader.onload = (e) => {
                const newImageSrc = e.target?.result as string;
                setImageSrc(newImageSrc);
            };
            reader.readAsDataURL(file);
        }
        setImage(file);
        setImageSrcValid(true);
    };

    const handleClearImageClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setImageSrc('');
        setImage(null);
    };

    const cancelCreate = () => {
        navigate(paths.dashboard.notification.index);
    };

    const onUserChange = (value: any) => {
        setUser(value);
    };

    return {
        title, text, linkTo, imageUrl, audience, userId, onChangeUserId, onChangAudience, onChangImageUrl, checked, region, imageSrcValid,
        onCreateNotification, onChangTitle, onChangText, onChangLinkTo, onChangeCountry, onChangeRegion, roleList, onAcceptTime, time,
        onChangeUserStatus, country, userStatus, groupUsers, userList, onSearch, onSearchUsers, onChangeSwitch, onChangeTime, imageTypeOptions,
        errorsCreatePushNotification, isLoading, createClick, setCreateClick, handleUserChange, imageType, onChangeImageType, imageSrc,
        handleFileChange, handleClearImageClick, titleValid, textValid, cancelCreate, navLinkValid, imageUrlValid, onUserChange, user
    };
};