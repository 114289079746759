import i18n from "../../locales/i18n";
import { IResponse } from "./IResponse";
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { store } from '../../store/store';
import { Dispatch } from "react";
import { setLogoutTo403, setRedirectTo403 } from "../../store/redirect/repository/slice";

interface Action {
    type: string;
    payload?: any;
};

interface ErrorResponse {
    message?: string;
};

class AxiosRequester {
    private dispatch: Dispatch<Action>;
    constructor(dispatch: Dispatch<Action>) {
        this.dispatch = dispatch;
    }

    private getHeaders = (headers: object = {}) => {
        const accessToken = globalThis.localStorage.getItem("accessToken");
        const language = i18n.language || navigator.language;

        return {
            Authorization: "Bearer " + accessToken,
            "Accept-Language": language,
            ...headers,
        };
    };

    private async handleRequestError<T>(error: AxiosError<T>, originalRequest: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        if (error.response?.status === 401) {
            const refreshToken = globalThis.localStorage.getItem("refreshToken");
            if (refreshToken) {
                const newAccessToken = await this.refreshAccessToken(refreshToken);
                if (typeof newAccessToken === "string") {
                    originalRequest.headers = originalRequest.headers || {};
                    originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                    return Axios(originalRequest);
                } else {
                    return this.handleRequestError(newAccessToken, originalRequest);
                }
            }
        } else if (error.response?.status === 403) {
            const errorData = error.response?.data as ErrorResponse;
            const accessToken = globalThis.localStorage.getItem("accessToken");
            if (accessToken) {
                this.handleRequestError403(errorData.message);
                return {} as AxiosResponse<T>;
            }
        }
        throw error;
    };

    private async refreshAccessToken(refreshToken: string): Promise<any> {
        try {
            const response = await Axios.get(
                process.env.REACT_APP_PUBLIC_URL + "/api/auth/refresh", { headers: { Authorization: "Bearer " + refreshToken } }
            );
            const newAccessToken = response.data.accessToken;
            const newRefreshToken = response.data.refreshToken;
            globalThis.localStorage.setItem("accessToken", newAccessToken);
            globalThis.localStorage.setItem("refreshToken", newRefreshToken);
            return newAccessToken;
        } catch (error) {
            console.error("Error refreshing access token:", error);
            return error;
        }
    };

    private handleRequestError403(message: string | undefined) {
        if (message !== 'Forbidden resource') {
            this.dispatch(setLogoutTo403());
        } else {
            this.dispatch(setRedirectTo403());
        }
    };

    post = async (url: string, data?: object, headers?: object, timeoutMS?: number): Promise<any> => {
        try {
            const config = { headers: this.getHeaders(headers) };
            const urlRequest = process.env.REACT_APP_PUBLIC_URL + url;
            const response = await Axios.post(urlRequest, data, config)
                .catch(async (error: AxiosError) => await this.handleRequestError(error, { url: urlRequest, data, headers, method: 'post' }));
            const result = this.processingResponse(response);
            return result;
        } catch (error: any) {
            console.warn("AxiosRequester -> post: ", error);
            const message = this.processingErrorMessage(error);
            return { isError: true, message };
        }
    };

    put = async (url: string, data?: object, headers?: object, timeoutMS?: number): Promise<any> => {
        try {
            const config = { headers: this.getHeaders(headers) };
            const urlRequest = process.env.REACT_APP_PUBLIC_URL + url;
            const response = await Axios.put(urlRequest, data, config)
                .catch(async (error: AxiosError) => await this.handleRequestError(error, { url: urlRequest, data, headers, method: 'put' }));
            const result = this.processingResponse(response);
            return result;
        } catch (error: any) {
            console.warn("AxiosRequester -> put: ", error);
            const message = this.processingErrorMessage(error);
            return { isError: true, message };
        }
    };

    delete = async (url: string, data?: object, headers?: object, timeoutMS?: number): Promise<any> => {
        try {
            const config = { headers: this.getHeaders(headers), data };
            const urlRequest = process.env.REACT_APP_PUBLIC_URL + url;
            const response = await Axios.delete(urlRequest, { ...config, data })
                .catch(async (error: AxiosError) => await this.handleRequestError(error, { url: urlRequest, data, headers, method: 'delete' }));
            const result = this.processingResponse(response);
            return result;
        } catch (error: any) {
            console.warn("AxiosRequester -> post: ", error);
            const message = this.processingErrorMessage(error);
            return { isError: true, message };
        }
    };

    get = async (url: string, params?: object, headers?: object, timeoutMS?: number): Promise<any> => {
        try {
            const config = { headers: this.getHeaders(headers), params };
            const urlRequest = process.env.REACT_APP_PUBLIC_URL + url;
            const response = await Axios.get(urlRequest, config)
                .catch(async (error: AxiosError) => await this.handleRequestError(error, { url: urlRequest, params, headers, method: 'get' }));
            const result = this.processingResponse(response);
            return result;
        } catch (error: any) {
            console.warn("AxiosRequester -> get: ", error);
            const message = this.processingErrorMessage(error);
            return { isError: true, message };
        }
    };

    private processingErrorMessage = (error: any): string => {
        let message = error.message || 'Unknown error';
        if (typeof error.response.data.message === 'string') message = error.response.data.message;
        if (typeof error.response.data.message === 'object' && error.response.data.message.length) message = error.response.data.message?.[0];
        return message;
    };

    private processingResponse = (response: any): IResponse<any> => {
        let result: any = { isError: true, message: "", detailMessage: '' };
        if (response?.status < 400) {
            result = { isError: false, data: response.data, message: "" };
        } else if (response?.data?.error === "validation") {
            result = {
                isError: true,
                message: JSON.stringify(typeof response?.data?.message !== 'string' ? response?.data?.message[0] : response?.data?.message),
                detailMessage: response.data.detailMessage || ''
            };
        } else {
            result = {
                isError: true,
                message: JSON.stringify(typeof response?.data?.message !== 'string' ? response?.data?.message[0] : response?.data?.message),
                detailMessage: response.data.detailMessage || ''
            };
        }
        return result;
    };
}

export const requester = new AxiosRequester(store.dispatch);
