import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setProductLoading, setProductError } from "../../repository/slice";
import { convertToFormData } from "../../../../utils";

function* updateProductWorker({ payload }: any): SagaIterator {
    try {
        yield put(setProductLoading(true));
        const { data, message, isError }: IResponse<number[]> = yield call(requester.put, "/api/shop/product/admin/update", payload);
        if (isError) {
            yield put(setProductError(message));
        }
    } catch (error) {
        console.error("updateProductWorker: ", error);
    } finally {
        yield put(setProductLoading(false));
    };
};

export function* updateProductWatcher() {
    yield takeLatest("@saga/UPDATE_PRODUCT", updateProductWorker);
};