import { IconButton, Stack, SvgIcon, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getStyles } from "./styles";
import dayjs from "dayjs";
import { tokens } from "../../../../../../../locales/tokens";
import { Link } from "react-router-dom";
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import moment from "moment";

interface IProps {
    test: any;
    handleStatus: (id: number, status: string) => void;
    handleDelete: (id: number) => void;
    handleUpload: (id: number, name: string) => void;
};

export const TestTableItem: FC<IProps> = ({ handleDelete, handleStatus, handleUpload, test }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const formattedDate = dayjs(test.updatedAt).subtract(3, 'hours').format('DD.MM.YYYY');
    // const formattedDate = moment.utc(test.updatedAt).local().format('DD.MM.YYYY');
    const changeStatus = useCallback(() => handleStatus(test.id, test.status), [test]);
    const deleteTest = useCallback(() => handleDelete(test.id), [test.id]);
    const uploadReport = useCallback(() => handleUpload(test.id, test.name), [test.id, test.name]);

    return (
        <TableRow hover key={test.id}>
            <TableCell align="left" width='50%' sx={styles.cell}>
                <Typography variant="body2">{test.name}</Typography>
            </TableCell>
            <TableCell align="center" width='25%' sx={styles.cell}>
                <Typography variant="body2">
                    {formattedDate}
                </Typography>
            </TableCell>
            <TableCell align="right" width='25%' sx={styles.cell}>
                <Stack direction='row' alignItems='center' spacing={1} justifyContent='flex-end'>
                    <IconButton size="small" onClick={uploadReport} disabled={!test.userAttempts.length}>
                        <Tooltip title={t(tokens.documents.uploadReport)}>
                            <SvgIcon style={styles.svgIcon}><SimCardDownloadOutlinedIcon /></SvgIcon>
                        </Tooltip>
                    </IconButton>
                    {!test.deleted_at &&
                        <IconButton size="small" onClick={changeStatus}>
                            {test.status === 'Hidden' ?
                                <Tooltip title={t(tokens.documents.notPublish)}>
                                    <SvgIcon style={styles.svgIcon}><VisibilityOffOutlinedIcon /></SvgIcon>
                                </Tooltip>
                                :
                                <Tooltip title={t(tokens.documents.publish)} >
                                    <SvgIcon style={styles.svgIcon}><VisibilityOutlinedIcon /></SvgIcon>
                                </Tooltip>
                            }
                        </IconButton>}
                    <IconButton size="small" component={Link} to={`/content-test-edit/${test.id}`}>
                        <Tooltip title={test.deleted_at ? t(tokens.common.watch) : t(tokens.common.edit)}>
                            <SvgIcon style={styles.svgIcon}><EditOutlinedIcon /></SvgIcon>
                        </Tooltip>
                    </IconButton>
                    {!test.deleted_at ?
                        <IconButton size="small" onClick={deleteTest}>
                            <Tooltip title={t(tokens.common.delete)}>
                                <SvgIcon style={styles.svgIcon}><DeleteForeverIcon /></SvgIcon>
                            </Tooltip>
                        </IconButton>
                        :
                        null}
                </Stack>
            </TableCell>
        </TableRow>
    )
};