import { SagaIterator } from "redux-saga";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setUpdateDocumentError } from "../../repository/slice";
import { putDocumentInCategory } from "../putDocumentInCategory/action";
import { addFiles } from "../addFiles/action";
import { deleteFile } from "../../../file/useCases/deleteFile/action";

function* updateDocumentWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.put, "/api/admin/document/update", payload.body);
        if (isError) {
            yield put(setUpdateDocumentError(message));
        } else {
            yield put(setUpdateDocumentError(null));
            if (payload.category) {
                yield put(putDocumentInCategory({ document_id: payload.body.id, category_id: payload.category.id }));
            };

            const addedFiles = payload?.files?.filter((file: any) => 'type' in file);
            if (addedFiles?.length) {
                yield put(addFiles({ id: payload.body.id, files: addedFiles }))
            };

            const deletedFiles: any[] = [];
            if (payload.deletedFiles?.length) {
                payload.deletedFiles.map((file: any) =>
                    deletedFiles.push(put(deleteFile({ fileName: file.filename })))
                );
                yield all(deletedFiles);
            };
            
        }
    } catch (error) {
        console.error("updateDocumentWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* updateDocumentWatcher() {
    yield takeLatest("@saga/UPDATE_DOCUMENT", updateDocumentWorker);
};
