import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setUpdateDocumentError } from "../../repository/slice";

function* putDocumentInCategoryWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.post, "/api/document-category/create", payload);
        if (isError) {
            
        } else {
           
        }
    } catch (error) {
        console.error("putDocumentInCategoryWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* putDocumentInCategoryWatcher() {
    yield takeLatest("@saga/PUT_DOCUMENT_IN_CATEGORY", putDocumentInCategoryWorker);
};
