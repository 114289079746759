import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';

interface IState {
    isLoading: boolean;
    backupList: any[];
    backupCount: number;
    restoreBackupError: IError;
    createBackupError: IError;
};

const initialState: IState = {
    isLoading: false,
    backupList: [],
    backupCount: 0,
    restoreBackupError: null,
    createBackupError: null,
};

const BackupSlice = createSlice({
    name: 'backup',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setBackupList: (state, action: PayloadAction<any>) => {
            state.backupList = action.payload.list;
            state.backupCount = action.payload.count;
        },
        setRestoreBackupError: (state, action: PayloadAction<IError>) => {
            state.restoreBackupError = action.payload;
        },
        setCreateBackupError: (state, action: PayloadAction<IError>) => {
            state.createBackupError = action.payload;
        },
    },
});

export const { setLoading, setBackupList, setRestoreBackupError, setCreateBackupError } = BackupSlice.actions;
export const BackupReducer = BackupSlice.reducer;