import { TextField, Typography } from "@mui/material";
import { ChangeEvent, FC, MutableRefObject, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { getStyles } from "./styles";
import { IError } from "../../../../../../types/error";

interface PasswordInputProps {
    signInError?: IError;
    title: string;
    password: string;
    passwordInputRef: MutableRefObject<HTMLInputElement | null>;
    passwordValid: boolean;
    isHideError: boolean;
    showPassword: boolean;
    handleEnterPassword: (e: React.KeyboardEvent) => void;
    onChangePassword: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const PasswordInput: FC<PasswordInputProps> = ({ password, onChangePassword, passwordValid, showPassword, passwordInputRef,
    handleEnterPassword, isHideError, title, signInError }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []); 
    const error = signInError === 'user_is_banned' ? "Відсутній доступ" : t(tokens.auth.messages.wrongData);

    return (
        <>
            <TextField
                ref={passwordInputRef}
                autoComplete="on"
                fullWidth
                placeholder={title}
                name="password"
                onChange={onChangePassword}
                onKeyDown={handleEnterPassword}
                value={password}
                error={!passwordValid}
                helperText={!passwordValid && !isHideError ? t(tokens.auth.messages.passIsRequired) : ''}
                type={showPassword ? 'text' : 'password'}
                sx={styles.password}
            />
            {(!passwordValid && isHideError) ?
                <Typography
                    color="error"
                    variant="caption"
                    sx={styles.error}
                >
                    {error}
                </Typography>
                :
                null
            }
        </>
    )
};