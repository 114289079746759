import { Dialog } from "@mui/material";
import { FC, ReactNode } from "react";

interface IProps{
    isOpen: boolean;
    children: ReactNode;
    size: 'md' | 'lg' | 'sm' | 'xs';
    handleClose: () => void;
};

export const Modal: FC<IProps> = ({isOpen, handleClose, children, size}) => {

    return(
        <Dialog onClose={handleClose} open={isOpen} maxWidth={size}>
            {children}
        </Dialog>
    )
};