import { ChangeEvent, FC, MutableRefObject, useMemo } from "react";
import { Box, Pagination, Stack, Table, Theme, useMediaQuery } from "@mui/material";
import { DocumentTableBody } from "../documentTableBody";
import { getStyles } from "./styles";

interface IProps {
    scrollRef: MutableRefObject<HTMLDivElement | null>;
    documentList: any[];
    page: number;
    pageAmount: number;
    isProcess: boolean;
    documentId: number;
    handleStatus: (id: number, status: string) => void;
    handleDelete: (id: number) => void;
    handleUpload: (id: number, name: string) => void;
    handlePageChange: (event: ChangeEvent<unknown>, page: number) => void;
};

export const DocumentTable: FC<IProps> = ({ documentList, handlePageChange, pageAmount, page, handleStatus, handleDelete, handleUpload, isProcess, documentId, scrollRef }) => {
    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const styles = useMemo(() => getStyles(lgDown), [lgDown]);

    return (
        <>
            <Box>
                <Box sx={styles.tableWrapper} ref={scrollRef}>
                    <Table>
                        <DocumentTableBody
                            documents={documentList}
                            isProcess={isProcess}
                            documentId={documentId}
                            handleStatus={handleStatus}
                            handleDelete={handleDelete}
                            handleUpload={handleUpload}
                        />
                    </Table>
                </Box>
                <Stack direction='row' justifyContent='center' sx={styles.paginationWrapper}>
                    <Pagination
                        page={page}
                        count={pageAmount || 1}
                        color="primary"
                        onChange={handlePageChange}
                    />
                </Stack>
            </Box>
        </>
    )
};