import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setParentCategories, setLoading } from "../../repository/slice";

function* getParentCategoriesWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.get, "/api/admin/category/get-list", payload);
        if (isError) {

        } else {
            yield put(setParentCategories({ parentCategories: data }));
        }
    } catch (error) {
        console.error("getParentCategoriesWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* getParentCategoriesWatcher() {
    yield takeLatest("@saga/GET_PARENT_CATEGORIES", getParentCategoriesWorker);
};
