import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';

interface IState {
    isLoading: boolean;
    signInError: IError;
    recoverPasswordError: IError;
    changePassError: IError;
};

const initialState: IState = {
    isLoading: false,
    signInError: null,
    recoverPasswordError: null,
    changePassError: null,
};

const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setSignInError: (state, action: PayloadAction<IError>) => {
            state.signInError = action.payload;
        },
        setChangePassError: (state, action: PayloadAction<IError>) => {
            state.changePassError = action.payload;
        },
        setRecoverPasswordError: (state, action: PayloadAction<IError>) => {
            state.recoverPasswordError = action.payload;
        },
        setLogout: (state) => {
            return { ...initialState }
        },
    },
});

export const { setLoading, setChangePassError, setLogout, setRecoverPasswordError, setSignInError} = AuthSlice.actions;
export const AuthReducer = AuthSlice.reducer;