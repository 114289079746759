import { TableBody } from "@mui/material";
import { FC } from "react"
import { HistoryTableItem } from "../historyTableItem";
import { IHistory } from "../../../../../../store/learningHistory/repository/IHistory";

interface IProps {
    historyList: IHistory[];
};

export const HistoryTableBody: FC<IProps> = ({ historyList }) => {

    return (
        <TableBody>
            {historyList.map((historyItem: IHistory, index: number) => (
                    <HistoryTableItem historyItem={historyItem} key={historyItem.id + historyItem.name + index}/>
                )
            )}
        </TableBody>
    )
}; 