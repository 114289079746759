import { Stack, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { getStyles } from "./styles";

export const NoAccessContent: FC = () => {
    // const styles = useMemo(() => getStyles(), []);

    return (
        <Stack /* sx={styles.container} */ justifyContent='center' alignItems="center" height="100vh">
            <img src="/images/user/noContent.png" alt="empty_page" width={155} height={155} />
            <Typography variant="h6" fontWeight={600} mt={2}>
                Відсутній доступ до даного розділу
            </Typography>
            <Typography variant="body1" width="570px" textAlign="center" mt={1}>
                
            </Typography>
        </Stack>
    )
};