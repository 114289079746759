import { Autocomplete, Box, Paper, Stack, TextField, Typography } from "@mui/material";
import { ChangeEvent, FC, ReactNode, useMemo } from "react";
import { IBadgeOption, collectBadgeOptionList } from "./types";
import { getStyles } from "./styles";

interface IProps {
    isDisabled?: boolean;
    title: string;
    value: any | null;
    handleChangeValue: any;
    optionList: any[];
    isMultiline: boolean;
    changeText?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const BadgeAutocomplete: FC<IProps> = ({ value, handleChangeValue, optionList, title, changeText, isMultiline, isDisabled }) => {
    const styles = useMemo(()=>getStyles(), []); 

    return (
        <Autocomplete
            disablePortal
            multiple={isMultiline}
            fullWidth
            onChange={(e, value) => handleChangeValue(value) as IBadgeOption[]}
            value={value}
            options={collectBadgeOptionList(optionList)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(params, option): JSX.Element => (
                <Box component="li" sx={styles.option} key={option.id} {...params}>
                    <img src={option.imgUrl} alt="badge" width={30} height={30} />
                    {option.label}
                </Box>
            )}
            renderInput={(params): JSX.Element => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={title}
                    onChange={changeText}
                />
            )}
            disabled={isDisabled}
        />
    )
};