import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { Box, Card, FormControlLabel, Stack, Switch, TextField, Typography } from '@mui/material';
import { ChangeEvent, FC, useEffect, useMemo } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import uaLocale from 'date-fns/locale/uk';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../../locales/tokens';
import { styles } from './styles';

interface IProp {
    isDisabled?: boolean;
    time: string;
    checked: boolean;
    onChangeSwitch: (event: ChangeEvent<HTMLInputElement>) => void;
    onChangeTime: (newDateTime: any) => void;
    onAcceptTime: () => void;
}

export const ResponsiveDateTimePickers: FC<IProp> = ({ time, checked, onChangeSwitch, onChangeTime, onAcceptTime, isDisabled }) => {
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const locale = useMemo(() => {
        switch (i18n.language) {
            case 'uk':
                return uaLocale;
        }
    }, [i18n.language])

    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }
    }, [window.history]);

    const maxDate = useMemo(() => {
        let currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 20);
        return new Date(currentDate);
    }, [time]);

    return (
        <Box >
            <Stack sx={styles.card}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
                    <Stack direction='row' spacing={3} sx={styles.timeHeader}>
                        <Typography color='inherit' variant='h5' fontWeight={600}>{t(tokens.notification.putOff)}</Typography>
                        <FormControlLabel control={<Switch checked={checked} onChange={onChangeSwitch} disabled={isDisabled}/>} label={t(tokens.notification.timeSending)} />
                    </Stack>
                    {checked &&
                        <StaticDateTimePicker
                            disablePast
                            renderInput={(props) => <TextField size="small" {...props} />}
                            value={dayjs(time)}
                            label={t(tokens.notification.chooseTime)}
                            onChange={onChangeTime}
                            onAccept={onAcceptTime}
                            maxDate={maxDate}
                            disabled={isDisabled}
                        />
                    }
                </LocalizationProvider>
            </Stack>
        </Box>
    );
};