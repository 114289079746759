import { Typography } from "@mui/material";
import { FC } from "react";

interface CustomTreeItemLabelProps {
    name: string;
};

export const TreeItemSectionLabel: FC<CustomTreeItemLabelProps> = ({ name }) => {

    return (
        <Typography py={1} variant="subtitle1">{name}</Typography>
    );
};