import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../locales/tokens";
import { useDispatch, useSelector } from "react-redux";
import { selectTest } from "../../../../../store/test/repository/selector";
import { createQuestion } from "../../../../../store/test/useCases/createQuestion/action";
import { updateQuestion } from "../../../../../store/test/useCases/updateQuestion/action";
import { deleteFileFromQuestion } from "../../../../../store/test/useCases/deleteFileFromQuestion/action";
import { addQuestionImage } from "../../../../../store/test/useCases/questionAddImage/action";
import { TEST_TYPES } from "../../../../../config";

export const useQuestionCreate = (onClose: () => void, currentQuestion: any, testId: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [type, setType] = useState<any>(null);
    const [typeValid, setTypeValid] = useState<any>(true);
    const [title, setTitle] = useState('');
    const [titleValid, setTitleValid] = useState<any>(true);
    const [questionPoints, setQuestionPoints] = useState('');
    const [questionPointsValid, setQuestionPointsValid] = useState<any>(true);
    const [imageSrc, setImageSrc] = useState('');
    const [image, setImage] = useState<any>(null);
    const [replies, setReplies] = useState<any[]>([]);
    const [repliesValid, setRepliesValid] = useState<any[]>([]);
    const { questionList, questionId, isLoading, createQuestionError, updateQuestionError } = useSelector(selectTest);
    const [isCorrectReplyChosen, setCorrectReplyChosen] = useState(false);
    const [createQuestionClick, setCreateQuestionClick] = useState(false);
    const [editQuestionClick, setEditQuestionClick] = useState(false);
    const [removedRepliesIds, setRemovedRepliesIds] = useState<any[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const typeOptions = [
        { id: 1, name: t(tokens.test.typeSingle), value: "Single" },
        { id: 2, name: t(tokens.test.typeMultiple), value: "Multiple" },
        { id: 3, name: t(tokens.test.typeOrder), value: "Order" },
        { id: 4, name: t(tokens.test.typeFree), value: "Free" },
    ];

    useEffect(() => {
        if (currentQuestion) {
            setType(currentQuestion.type);
            setTitle(currentQuestion.title);
            setQuestionPoints(currentQuestion.questionPoints);
            if (currentQuestion.replies.length > 0) {
                setReplies(currentQuestion.replies);
                currentQuestion.replies.forEach((reply: any) => {
                    if (reply.isRight === true) {
                        setCorrectReplyChosen(true);
                        return;
                    }
                });
                currentQuestion.replies.forEach((item: any) =>
                    setRepliesValid(prevState => [...prevState, { isValid: true, sortNumber: item.sortNumber }]))
            };
            if (currentQuestion.image) {
                setImage(currentQuestion.image);
                setImageSrc(currentQuestion.image.url);
            };
        }
    }, [currentQuestion]);

    useEffect(() => {
        if (!isLoading && createQuestionClick) {
            if (createQuestionError) {
                toast.error(createQuestionError)
            } else {
                toast.success(t(tokens.test.message.questionAdded));
                type.value === 'Free' && closeAndClearData();
            }
            setCreateQuestionClick(false);
        }
    }, [createQuestionError, isLoading]);

    useEffect(() => {
        if (!isLoading && editQuestionClick) {
            if (updateQuestionError) {
                toast.error(updateQuestionError)
            } else {
                toast.success(t(tokens.test.message.questionUpdated));
                closeAndClearData();
            }
            setEditQuestionClick(false);
        }
    }, [updateQuestionError, isLoading]);

    const changeType = useCallback((type: any) => {
        setType(type);
        setTypeValid(true);

        const newReplies = replies.map((reply: any) => ({ ...reply, isRight: false }));
        setReplies(newReplies);

        if (type?.value === TEST_TYPES.FREE) {
            setReplies([]);
        };
        setCorrectReplyChosen(false);
    }, [setType, replies]);

    const onChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
        setTitleValid(true);
    };

    const onChangeQuestionPoints = (event: ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        const regex = /^(\d*)$/;

        if (regex.test(input)) {
            setQuestionPoints(input);
            setQuestionPointsValid(true);
        }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => { 
        const file = event.target.files && event.target.files[0];

        if (file) {
            // const maxSizeBytes = 20 * 1024 * 1024;
            // if (file.size > maxSizeBytes) {
            //     toast.error(t(tokens.test.fileTooBig));
            //     return;
            // };

            if (questionId) {
                dispatch(addQuestionImage({ body: { id: questionId, image: file }, testId: Number(testId) }));
            } else {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const newImageSrc = e.target?.result as string;
                    setImageSrc(newImageSrc);
                };
                reader.readAsDataURL(file);
                setImage(file);
            }; 

            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            };
        }
    };

    const handleClearImageClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setImageSrc('');
        setImage(null);
        if (questionId && 'filename' in image) {
            dispatch(deleteFileFromQuestion({ fileName: image.filename, testId: Number(testId) }));
        };
    };

    const addReply = () => {
        const sortNumber = findMaxSortNumber(replies);
        setReplies(prevState => [...prevState, { title: '', isRight: false, sortNumber: sortNumber, questionId: Number(questionId) }]);
        setRepliesValid(prevState => [...prevState, { isValid: true, sortNumber: sortNumber }]);
    };

    const removeReply = (sortNumber: number) => {
        const filteredReplies = replies.filter((reply: any) => reply.sortNumber !== sortNumber);
        const updatedReplies = filteredReplies.map((reply, index) => {
            return {
                ...reply,
                sortNumber: index + 1
            };
        });
        setTimeout(() => setReplies(updatedReplies), 300);
        const removedReply: any = replies.find((reply: any) => 'id' in reply && reply.sortNumber === sortNumber);
        removedReply && setRemovedRepliesIds((prevState: any[]) => ([...prevState, removedReply.id]));

        const filteredRepliesValid = repliesValid.filter((reply: any) => reply.sortNumber !== sortNumber);
        const updatedRepliesValid = filteredRepliesValid.map((reply, index) => {
            return {
                ...reply,
                sortNumber: index + 1
            };
        });
        setTimeout(() => setRepliesValid(updatedRepliesValid), 300);
    };

    function findMaxSortNumber(replies: any[]) {
        if (replies.length === 0) {
            return 1;
        } else {
            let max = replies[0].sortNumber;
            for (let i = 1; i < replies.length; i++) {
                if (replies[i].sortNumber > max) {
                    max = replies[i].sortNumber;
                }
            }
            return max + 1;
        }
    };

    const onChangeReply = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        const updatedReplies = [...replies];
        const currentReply = { ...updatedReplies[index] };
        currentReply.title = event.target.value;
        updatedReplies[index] = currentReply;
        setReplies(updatedReplies);

        setRepliesValid(prevState => {
            const newState = [...prevState];
            newState[index] = {
                ...newState[index],
                isValid: true
            };
            return newState;
        });
    };

    const onChangeCorrectReply = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        const updatedReplies = [...replies];
        if (type?.value === 'Single') {
            updatedReplies.forEach((reply, idx) => {
                updatedReplies[idx] = { ...reply, isRight: false };
            });
        }
        const currentReply = { ...updatedReplies[index] };
        currentReply.isRight = event.target.checked;
        updatedReplies[index] = currentReply;
        setReplies(updatedReplies);
        setCorrectReplyChosen(true);
    };

    const closeAndClearData = () => {
        onClose();
        setTitle('');
        setType(null);
        setReplies([]);
        setRepliesValid([]);
        setQuestionPoints('');
        setImage(null);
        setImageSrc('');
        setCorrectReplyChosen(false);
    };

    const validateData = () => {
        const data = [
            { field: type, setField: setTypeValid },
            { field: title, setField: setTitleValid },
            // { field: questionPoints, setField: setQuestionPointsValid }
        ];
        let allFieldsValid = true;
        for (const { field, setField } of data) {
            if (!field) {
                setField(false);
                allFieldsValid = false;
            }
        };

        if (replies?.length) {
            replies.forEach((reply: any, index: number) => {
                if (reply.title === '') {
                    setRepliesValid(prevState => {
                        const newState = [...prevState];
                        newState[index] = { ...newState[index], isValid: false };
                        return newState;
                    });
                    allFieldsValid = false;
                };
            })
        };

        return allFieldsValid;
    };

    const getRequestBody = () => {
        const body: any = { type: type.value, title, points: Number(questionPoints) };
        body.sortNumber = currentQuestion ? currentQuestion.sortNumber : questionList.length + 1;
        if (questionId) {
            body.id = questionId;
        } else {
            body.quizId = Number(testId);
        }
        return body;
    };

    const createOrEditQuestion = () => {
        const isPassedValidation = validateData();
        if (isPassedValidation) {
            handleQuestion();
        } else {
            toast.error(t(tokens.adverts.fillAllFields));
        };
    };

    const handleQuestion = () => {
        if (questionId) {
            validateRepliesAndEdit();
        } else {
            createNewQuestion();
        }
    };

    const createNewQuestion = () => {
        let hiddenReply;
        if (type.value === TEST_TYPES.FREE) {
            hiddenReply = { title: TEST_TYPES.FREE, isRight: false, sortNumber: 1, questionId: Number(questionId) };
        }
        dispatch(createQuestion({ body: getRequestBody(), image, testId, hiddenReply }));
        setCreateQuestionClick(true);
    };

    const validateRepliesAndEdit = () => {
        const isEnoughtReplies = replies.length > 1 || type.value === TEST_TYPES.FREE;
        const isDefineCorrectReply = isCorrectReplyChosen || type.value === TEST_TYPES.FREE || type.value === TEST_TYPES.ORDER;

        if (!isEnoughtReplies) {
            toast.error(t(tokens.test.needTwoQuestions));
            return;
        };

        if (!isDefineCorrectReply) {
            toast.error(t(tokens.test.chooseCorrectReply));
            return;
        };
        editQuestion();
    };

    const editQuestion = () => {
        const newReplies = replies?.filter((answer: any) => !answer.id);
        const editedReplies = replies?.filter((answer: any) => answer.id);
        dispatch(updateQuestion({ body: getRequestBody(), newReplies, editedReplies, removedRepliesIds, testId }));
        setRemovedRepliesIds([]);
        setEditQuestionClick(true);
    };

    return {
        replies, typeOptions, createOrEditQuestion, type, typeValid, changeType, title, titleValid, onChangeTitle, addReply, removeReply, onChangeCorrectReply,
        questionPoints, questionPointsValid, onChangeQuestionPoints, handleFileChange, imageSrc, handleClearImageClick, onChangeReply, setReplies, closeAndClearData,
        questionId, repliesValid, setRepliesValid, createQuestionClick, editQuestionClick, fileInputRef
    };
};