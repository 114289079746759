import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../../../store/auth/useCases/changePassword/action";
import { selectAuth } from "../../../../store/auth/repository/selector";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";

export const useChangePassword = (id: number) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isLoading, changePassError } = useSelector(selectAuth);
    const [isOpen, setOpen] = useState(true);
    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(true);
    const [isPassShown, setPassShown] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPassValid, setConfirmPassValid] = useState(true);
    const [isConfirmPassShown, setConfirmPassShown] = useState(false);
    const [changeClick, setChangeClick] = useState(false);
    const passwordInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (!isLoading && changeClick) {
            if (!changePassError) {
                toast.success(t(tokens.auth.messages.passChangedSuccessfully))
            }
            setChangeClick(false);
        }
    }, [changePassError, isLoading]);

    const handleEnter = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleChangePassword();
        }
    };

    const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
        setPasswordValid(true);
        setPassword(event.target.value);
    };

    const onChangeConfirmPassword = (event: ChangeEvent<HTMLInputElement>) => {
        setConfirmPassValid(true);
        setConfirmPassword(event.target.value);
    };

    const onShowPassword = () => {
        setPassShown(!isPassShown)
    };

    const onShowConfirmPassword = () => {
        setConfirmPassShown(!isConfirmPassShown)
    };

    const handleChangePassword = () => {
        const isMatched = checkCoincidence();
        if (isMatched) {
            dispatch(changePassword({ password }));
            setOpen(false);
            setChangeClick(true);
        } else {
            setPasswordValid(false);
            setConfirmPassValid(false);
        }
    };

    const checkCoincidence = () => {
        if (password.length && confirmPassword.length) {
            return password === confirmPassword;
        }
        return false;
    };

    return {
        password, passwordValid, handleEnter, onChangePassword, isPassShown, onShowPassword, passwordInputRef, isOpen, isLoading,
        onChangeConfirmPassword, onShowConfirmPassword, confirmPassword, confirmPassValid, isConfirmPassShown, handleChangePassword
    };
};