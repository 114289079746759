import { Chip, FormControlLabel, Stack, SvgIcon, styled } from "@mui/material";
import { ChangeEvent, FC, useCallback } from "react";
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { useSettings } from "../../hooks/useSettings";
import { Pallete } from "../../types/settings";
import { getStyles } from "./styles";
import { ThemeOption } from "../../types/theme-switcher";
import { MaterialUISwitch } from "./materialUISwitch";

const options: ThemeOption[] = [
    { label: 'Light', value: 'light', icon: (<SvgIcon fontSize="small"> <LightModeIcon /> </SvgIcon>) },
    { label: 'Dark', value: 'dark', icon: (<SvgIcon fontSize="small"> <DarkModeIcon /> </SvgIcon>) }
];

interface IProps {
    handleFieldUpdate: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const ThemeSwitcher: FC<IProps> = ({ handleFieldUpdate }) => {
    const styles = getStyles();

    return (
        <FormControlLabel
            control={<MaterialUISwitch sx={{ m: 1 }} onChange={handleFieldUpdate} />}
            label=""
        />
    )
};