import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateOrgStructure } from "../../../../../store/orgStructure/useCases/updateOrgStructure/action";
import { selectOrgStructure } from "../../../../../store/orgStructure/repository/selector";
import { paths } from "../../../../../paths";
import toast from "react-hot-toast";
import { tokens } from "../../../../../locales/tokens";
import { selectQuiz } from "../../../../../store/quiz/repository/selector";
import { getSingleQuiz } from "../../../../../store/quiz/useCases/getSingleQuiz/action";
import { setQuestionId, setQuestionList, setSingleQuiz } from "../../../../../store/quiz/repository/slice";
import { updateQuiz } from "../../../../../store/quiz/useCases/updateQuiz/action";
import { DropResult } from "react-beautiful-dnd";
import { updateQuizQuestion } from "../../../../../store/quiz/useCases/updateQuestion/action";
import { deleteQuizQuestion } from "../../../../../store/quiz/useCases/deleteQuestion/action";
import { IError } from "../../../../../types/error";
import { SelectChangeEvent } from "@mui/material";
import { selectUser } from "../../../../../store/user/repository/selector";
import { getPositions } from "../../../../../store/user/useCases/getPositions/action";

export const useQuizEdit = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [theme, setTheme] = useState('');
    const [themeValid, setThemeValid] = useState(true);
    const [content, setContent] = useState('');
    const [initiator, setInitiator] = useState<any>(null);
    const [initiatorValid, setInitiatorValid] = useState(true);
    const [groupPermissions, setGroupPermissions] = useState<any[]>([]);
    const [groupPermissionsValid, setGroupPermissionsValid] = useState(true);
    const [receiver, setReceiver] = useState<any[]>([]);
    const [publishChecked, setPublishChecked] = useState(false);
    const [notifyChecked, setNotifyChecked] = useState(false);
    const [namelessChecked, setNamelessChecked] = useState(false);
    const { orgStructure } = useSelector(selectOrgStructure);
    const [isQuestionModalOpen, setQuestionModalOpen] = useState(false);
    const [editClick, setEditClick] = useState(false);
    const { updateQuizError, isLoading, quizId, singleQuiz, questionList } = useSelector(selectQuiz);
    const { positionsList } = useSelector(selectUser);
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [currentQuestion, setCurrentQuestion] = useState<any>(null);
    const typeOptions = [
        { id: 1, name: t(tokens.test.typeSingle), value: "Single" },
        { id: 2, name: t(tokens.test.typeMultiple), value: "Multiple" },
        { id: 4, name: t(tokens.test.typeFree), value: "Free" },
        { id: 5, name: t(tokens.test.typeScale), value: "Scale" },
    ];
    const [departmentPermissions, setDepartmentPermissions] = useState<any[]>([]);
    const [positions, setPositions] = useState<any[]>([]);
    const [assignDepartment, setAssignDepartment] = useState<any[]>([]);
    const [assignPositions, setAssignPositions] = useState<any[]>([]);

    useEffect(() => {
        id && dispatch(getSingleQuiz({ id }));
        return () => {
            dispatch(setSingleQuiz(null));
            setIsDeleted(false);
        }
    }, [id]);

    useEffect(() => {
        if(singleQuiz){
            setTheme(singleQuiz.name);
            setContent(singleQuiz.description || "");
            setInitiator(singleQuiz.initiator.id);
            setPublishChecked(singleQuiz.status === 'Available' ? true : false);
            setNamelessChecked(singleQuiz.anonymous);
            // setGroupPermissions(singleQuiz.access.map((item: any) => item.id));
            // setReceiver(singleQuiz.assigns.map((item: any) => item.id));
            const newQuestions = singleQuiz.questions.map((question: any) => {
                const questionReplies = question.answers.map((answer: any) => ({
                    id: answer.id,
                    questionId: answer.questionId,
                    sortNumber: answer.sortNumber,
                    isRight: answer.isRight,
                    title: answer.title,
                }));
                const currentType: any = typeOptions.find((type: any) => type.value === question.type);

                return {
                    quizId: question.quizId,
                    id: question.id,
                    sortNumber: question.sortNumber,
                    title: question.title,
                    type: { ...currentType, label: currentType.name },
                    questionPoints: String(question.points),
                    replies: questionReplies,
                    image: question.image
                }
            });
            dispatch(setQuestionList(newQuestions));
            if (singleQuiz.deleted_at) {
                setIsDeleted(true);
            };
        }
    }, [singleQuiz]);

    useEffect(() => {
        dispatch(updateOrgStructure());
        dispatch(getPositions());
    }, []);

    useEffect(() => {
        if (!isLoading && editClick) {
            if (updateQuizError) {
                handleServerError(updateQuizError);
            } else {
                toast.success(t(tokens.quiz.message.quizUpdated));
                navigate(paths.dashboard.content.quiz.list);
            }
            setEditClick(false);
        }
    }, [updateQuizError, isLoading]);

    const handleServerError = (error: IError) => {
        if (error?.startsWith("value too long for type character varying(255)")) {
            toast.error(t(tokens.common.tooMuchSymbols));
            setThemeValid(false);
        } else if (error?.startsWith("value too long for type character varying(500)")) {
            toast.error(t(tokens.common.tooMuchSymbols));
        } else {
            toast.error(error);
        }
    };
    
    const onChangeTheme = (event: ChangeEvent<HTMLInputElement>) => {
        setTheme(event.target.value);
        setThemeValid(true);
    };

    const onChangeContent = (event: ChangeEvent<HTMLInputElement>) => {
        setContent(event.target.value);
    };

    const onSelectInitiator = (selectedKeysValue: string, node: any) => {
        if ('children' in node) {
            return;
        }
        setInitiator(node);
        setInitiatorValid(true);
    };

    const onChangeInitiator = () => {
        if (initiator) {
            setInitiator(null);
        }
    };

    const onChangePermissions = (newValue: string[]) => {
        setGroupPermissions(newValue);
        setGroupPermissionsValid(true);
    };

    const onChangeDepartmentPermissions = (newValue: string[]) => {
        setDepartmentPermissions(newValue);
    };

    const onChangeAssignDepartment = (newValue: string[]) => {
        setAssignDepartment(newValue);
    };

    const onChangeReceiver = (newValue: string[]) => {
        setReceiver(newValue);
    };

    const handlePublishChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPublishChecked(event.target.checked);
    };

    const handleNotifyChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNotifyChecked(event.target.checked);
    };

    const handleNamelessChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNamelessChecked(event.target.checked);
    };

    const handleOpenQuestionModal = () => {
        setQuestionModalOpen(true);
    };

    const handleCloseQuestionModal = () => {
        setQuestionModalOpen(false);
        setCurrentQuestion(null);
        dispatch(setQuestionId(0));
    };

    const cancelCreate = () => {
        navigate(paths.dashboard.content.quiz.list);
    };

    const handleQuiz = () => {
        const isPassedValidation = validateData();
        if (isPassedValidation) {
            createNewQuiz();
        } else {
            toast.error(t(tokens.adverts.fillAllFields))
        };
    };

    const validateData = () => {
        const data = [
            { field: theme, setField: setThemeValid },
            { field: initiator, setField: setInitiatorValid },
            // { field: groupPermissions.length, setField: setGroupPermissionsValid },
        ];
        let allFieldsValid = true;
        for (const { field, setField } of data) {
            if (!field) {
                setField(false);
                allFieldsValid = false;
            }
        };
        return allFieldsValid;
    };

    const createNewQuiz = () => {
        dispatch(updateQuiz({ body: getRequestBody() }));
        setEditClick(true);
    };

    const cleanedGuiIds = (guiIds: string[]): string[] => {
        return guiIds.map(permission => {
            const [cleaned] = permission.split('%');
            return cleaned;
        });
    };    

    const getRequestBody = () => {
        const body: any = {
            id: Number(id),
            name: theme,
            initiatorId: initiator.value
        };
        body.description = content.length ? content : '';
        body.assigns = receiver.length ? receiver : null;
        body.access = groupPermissions.length ? groupPermissions : null;
        body.anonymous = namelessChecked;
        body.isInformed = notifyChecked;
        body.status = publishChecked ? "Available" : "Hidden";
        if(departmentPermissions.length) body.accessDepartmentIds = cleanedGuiIds(departmentPermissions);
        if(positions.length) body.accessPositionIds = positions.map(item => item.id);
        if(assignDepartment.length) body.assignDepartmentIds = cleanedGuiIds(assignDepartment);
        if(assignPositions.length) body.assignPositionIds = assignPositions.map(item => item.id); 
        return body;
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        };
        const items = reorder(questionList, result.source.index, result.destination.index);
        dispatch(setQuestionList(items));
    };
    
    const reorder = (questions: any[], startIndex: number, endIndex: number) => {
        let result = Array.from(questions);
        const currentQuestion = result[startIndex];
        const destinationQuestion = result[endIndex];
        dispatch(updateQuizQuestion({ body: { id: currentQuestion.id, sortNumber: destinationQuestion.sortNumber }, quizId }));
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        result = result.map((question: any, index: number) => ({
            ...question,
            sortNumber: index + 1
        }))
        return result;
    };

    const removeQuestion = (sortNumber: number) => {
        const removedQuestion = questionList.find((question: any) => question.sortNumber === sortNumber);
        dispatch(deleteQuizQuestion({ questionId: removedQuestion.id, quizId: Number(id) }));
    };

    const defineCurrentQuestion = (index: number, questionId: number) => {
        setCurrentQuestion(questionList[index]);
        setQuestionModalOpen(true);
        dispatch(setQuestionId(questionId));
    };

    const handleChangePosition = (event: any, newValue: readonly any[]) => {
        setPositions([...newValue]);
    };

    const handleChangeAssignPosition = (event: any, newValue: readonly any[]) => {
        setAssignPositions([...newValue]);
    };

    return {
        theme, content, onChangeTheme, onChangeContent, onSelectInitiator, onChangeInitiator, onChangePermissions, groupPermissions, receiver, onChangeReceiver,
        handlePublishChange, handleNotifyChange, themeValid, initiatorValid, groupPermissionsValid, orgStructure, initiator, handleOpenQuestionModal, publishChecked,
        notifyChecked, namelessChecked, handleNamelessChange, handleQuiz, cancelCreate, editClick, isDeleted, id, isQuestionModalOpen, handleCloseQuestionModal,
        currentQuestion, questionList, onDragEnd, removeQuestion, defineCurrentQuestion, onChangeDepartmentPermissions, departmentPermissions, positionsList,
        positions, handleChangePosition, onChangeAssignDepartment, assignDepartment, handleChangeAssignPosition, assignPositions
    }
};