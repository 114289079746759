import React, { FC } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { tokens } from '../../../../../../locales/tokens';
import { useTranslation } from 'react-i18next';
import { getStyles } from './styles';

interface Props {
    isDisabled?: boolean;
    title: string;
    value: string;
    isDetail?: boolean;
    placeholder: string;
    textArea: boolean;
    onFocus?: () => void;
    onBlur?: () => void;
    error?: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const NotificationInput: FC<Props> = ({ title, value, placeholder, error, onChange, onBlur, onFocus, textArea, isDetail, isDisabled }) => {
    const { t } = useTranslation();
    const styles = getStyles();
    
    return (
        <Box maxWidth="xl">
            <Typography variant='h6' color={error? 'error' : 'primary'} gutterBottom sx={styles.title}>{title}</Typography>
            {isDetail && <Typography variant='body2' color='gray' sx={styles.details}>{t(tokens.notification.imageDetail)}</Typography>}
            <TextField
                name={placeholder}
                placeholder={placeholder}
                sx={styles.textValue}
                value={value}
                onChange={onChange}
                rows={textArea ? 4 : 1}
                multiline={textArea ? true : false}
                // error={error}
                variant='outlined'
                disabled={isDisabled}
            />
        </Box>
    );
};
