import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setAppPageLoading, setAppPageError, setAppPage } from "../../repository/slice";
import { IAppPage } from "../../types/IAppPage";

function* getAppPagesWorker({ payload }: any): SagaIterator {
    try {
        yield put(setAppPageLoading(true));
        const { data, message, isError }: IResponse<{ count: number, rows: IAppPage[] }> = yield call(requester.get, "/api/admin/dynamic-pages/list", payload);
        if (isError) {
            yield put(setAppPageError(message));
        } else {
            yield put(setAppPage(data));
        }
    } catch (error) {
        console.error("getAppPagesWorker: ", error);
    } finally {
        yield put(setAppPageLoading(false));
    };
};

export function* getAppPagesWatcher() {
    yield takeLatest("@saga/GET_APP_PAGES", getAppPagesWorker);
};