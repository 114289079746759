import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setUpdateCategoryError } from "../../repository/slice";
import { addFileToCategory } from "../addFileToCategory/action";

function* updateCategoryWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.put, "/api/admin/category/update", payload.body);
        if (isError) {
            yield put(setUpdateCategoryError(message));
        } else {
            yield put(setUpdateCategoryError(null));
            if (payload.image){
                yield put(addFileToCategory({ id: payload.body.id, image: payload.image}));
            }
        }
    } catch (error) {
        console.error("updateCategoryWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* updateCategoryWatcher() {
    yield takeLatest("@saga/UPDATE_CATEGORY", updateCategoryWorker);
};
