import { Stack, TextField, Typography } from "@mui/material";
import { ChangeEvent, FC, MutableRefObject, useMemo } from "react";
import { VisibleButton } from "../../../../login/ui/components/visibleButton";
import { getStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";

interface IProps {
    title: string;
    isHideError: boolean;
    passwordValid: boolean;
    isPassShown: boolean;
    password: string;
    passwordInputRef: MutableRefObject<HTMLInputElement | null>;
    onShowPassword: () => void;
    handleEnter: (e: React.KeyboardEvent) => void;
    onChangePassword: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const PasswordInput: FC<IProps> = ({ title, isPassShown, onShowPassword, passwordInputRef, password, onChangePassword, passwordValid, handleEnter, isHideError }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    return (
        <Stack sx={styles.password}>
            <TextField
                ref={passwordInputRef}
                autoComplete="on"
                fullWidth
                placeholder={title}
                name="password"
                onChange={onChangePassword}
                onKeyDown={handleEnter}
                value={password}
                error={!passwordValid}
                helperText={(!passwordValid && !isHideError) ? t(tokens.auth.messages.passIsNotMatch) : ''}
                type={isPassShown ? 'text' : 'password'}
                size="medium"
                sx={styles.input}
            />
            <VisibleButton isPassShown={isPassShown} onShowPassword={onShowPassword} />
        </Stack>
    )
};