import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { useContentAnalytics } from "../presenter/useContentAnalytics";
import { ContentChart } from "./components/contentChart";

export const ContentAnalytics: FC = () => {
  const { t } = useTranslation();
  const { dates, handleRangeChange, disabledDate } = useContentAnalytics();

  return (
    <Stack m={2}>
      <Typography variant="h4" fontWeight={600}>
        {t(tokens.analytics.analyticsContent)}
      </Typography>
      <Stack justifyContent="center" alignItems="center">
        <ContentChart
          chartSeries={[
            {
              name: t(tokens.analytics.documents),
              data: [25, 72, 47, 78, 141, 115, 92, 105, 115, 131, 141, 171]
            },
            {
              name: t(tokens.analytics.test),
              data: [31, 72, 55, 73, 112, 80, 67, 80, 65, 80, 87, 125]
            },
            {
              name: t(tokens.analytics.quiz),
              data: [50, 72, 51, 60, 86, 54, 112, 94, 74, 105, 122, 101]
            },
            {
              name: t(tokens.analytics.course),
              data: [7, 72, 41, 58, 30, 42, 70, 38, 112, 87, 111, 92]
            },
          ]}
          dates={dates}
          handleRangeChange={handleRangeChange}
          disabledDate={disabledDate}
        />
      </Stack>
    </Stack>
  )
};