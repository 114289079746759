import { FC } from "react";
import { FileImage } from "../../../../documentsCreate/ui/components/fileImage";
import { FileDocument } from "../../../../documentsCreate/ui/components/fileDocument";

interface IProps {
    type: string;
    file: any;
    handleToggleImageFile: () => void;
    handleToggleVideoFile: () => void;
    handleDeleteImage: (name: string) => void;
};

export const AddedFiles: FC<IProps> = ({ type, file, handleDeleteImage, handleToggleImageFile, handleToggleVideoFile }) => {

    return (
        <>
            {
                file.type.startsWith('image/') ?
                    <FileImage
                        key={file.size}
                        file={file}
                        handleDeleteImage={handleDeleteImage}
                        handleToggleImageFile={handleToggleImageFile}
                    />
                    :
                    <FileDocument
                        key={file.size}
                        file={file}
                        type={type}
                        handleDeleteImage={handleDeleteImage}
                        handleToggleVideoFile={handleToggleVideoFile}
                    />
            }
        </>
    )
};