import { FC } from "react";
import { ProductTableHeader } from "../productTableHeader";
import { Table } from "@mui/material";
import { ProductTableBody } from "../productTableBody";

interface IProps {
    products: any[];
};

export const ProductTable: FC<IProps> = ({products}) => {

    return (
        <Table>
            <ProductTableHeader />
            <ProductTableBody
                products={products}
            />
        </Table>
    )
}