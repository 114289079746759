import { SelectChangeEvent, Stack, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { UserRole } from "../userRole";
import { tokens } from "../../../../../../locales/tokens";
import { useTranslation } from "react-i18next";

interface IProps {
    userRoles: string[];
    rolesList: any[];
    handleChangeRole: (event: SelectChangeEvent<string[]>, child: ReactNode) => void;
};

export const UserSettings: FC<IProps> = ({ userRoles, handleChangeRole, rolesList }) => {
    const { t } = useTranslation();

    return (
        <Stack>
            <Typography variant="h6" fontWeight={600}>{t(tokens.users.settings)}</Typography>
            <UserRole
                userRoles={userRoles}
                rolesList={rolesList}
                handleChangeRole={handleChangeRole}
            />
        </Stack>
    )
};