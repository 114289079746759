import { Button, Stack, SvgIcon, Tooltip } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../locales/tokens";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { TextAutocomplete } from "../../../../../ui-kit/textAutocomplete";
import { TimePicker } from "../../../../../ui-kit/timePicker";
import { CheckboxAutocompleteSingle } from "../../../../../ui-kit/checkboxAutocompleteSingle";

interface IProps {
    filters: any;
    orgStructure: any[];
    handleDateChange: (date: Date | null) => void;
    changeType: (type: any) => void;
    resetFilters: () => void;
    changeStatus: (status: any) => void;
    onChangeInitiator: () => void;
    onSelectInitiator: (selectedKeysValue: string, node: any) => void;
};

export const AdvertFilters: FC<IProps> = ({ filters, changeType, changeStatus, orgStructure, onChangeInitiator, onSelectInitiator,
    handleDateChange, resetFilters }) => {
    const { t } = useTranslation();

    const typeOptions = [
        { id: 1, name: t(tokens.adverts.news), value: "News" },
        { id: 2, name: t(tokens.adverts.promo), value: "Promo" },
        { id: 3, name: t(tokens.adverts.event), value: "Event" },
    ];

    const statusOptions = [
        { id: 1, name: t(tokens.documents.publish), value: "Available" },
        { id: 2, name: t(tokens.documents.notPublish), value: "Hidden" }
    ];

    return (
        <Stack direction='row' alignItems='center' spacing={1} mt={1}>
            <Stack width="15%">
                <TextAutocomplete
                    title={t(tokens.documents.type)}
                    value={filters.type}
                    handleChangeValue={changeType}
                    optionList={typeOptions}
                    isMultiline={false}
                    size="small"
                />
            </Stack>
            <Stack width="15%">
                <TimePicker
                    value={filters.date}
                    handleDate={handleDateChange}
                    size="small"
                />
            </Stack>
            <Stack width="20%">
                <TextAutocomplete
                    title={t(tokens.documents.status)}
                    value={filters.status}
                    handleChangeValue={changeStatus}
                    optionList={statusOptions}
                    isMultiline={false}
                    size="small"
                />
            </Stack>
            <Stack width="45%" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <CheckboxAutocompleteSingle
                    title={t(tokens.adverts.initiator)}
                    value={filters.initiator}
                    optionList={orgStructure}
                    onSelect={onSelectInitiator}
                    onChange={onChangeInitiator}
                    isValid={true}
                    isDisabled={false}
                    size="small"
                />
            </Stack>
            <Button
                size="small"
                onClick={resetFilters}
                variant="outlined"
                style={{ width: '5%' }}
            >
                <Tooltip title={t(tokens.documents.cancelFilters)}>
                    <SvgIcon style={{ fontSize: 32 }}>
                        <FilterAltOffIcon fontSize="small" />
                    </SvgIcon>
                </Tooltip>
            </Button>
        </Stack>
    )
};