import { Stack, Typography } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { BadgeAutocomplete } from "../badgeAutocomplete";

interface IProps {
    isDisabled?: boolean;
    title: string;
    value: any;
    bagesList: any[];
    handleChangeBadge: (badge: any) => void;
    changeBadgeText: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const BadgeInput: FC<IProps> = ({ bagesList, changeBadgeText, handleChangeBadge, title, value, isDisabled }) => {

    return (
        <Stack width="100%">
            <Typography variant="h6" mb={1}>
                {title}
            </Typography>
            <BadgeAutocomplete
                title={title}
                value={value}
                handleChangeValue={handleChangeBadge}
                optionList={bagesList}
                isMultiline={false}
                changeText={changeBadgeText}
                isDisabled={isDisabled}
            />
        </Stack>
    )
};