import { FC, useMemo } from "react"
import { useCourseCreate } from "../presenter/useCourseCreate"
import { Backdrop, Button, Card, CircularProgress, Stack, Switch, Theme, Typography, useMediaQuery } from "@mui/material";
import { TextInput } from "../../../tests/testCreate/ui/components/textInput";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../locales/tokens";
import { BadgeInput } from "../../../tests/testCreate/ui/components/badgeInput";
import { AutocompleteSingle } from "../../../tests/testCreate/ui/components/autocompleteSingle/index.";
import { AutocompleteMultiline } from "../../../tests/testCreate/ui/components/autocompleteMultiline/index.";
import { TimePicker } from "../../../../../ui-kit/timePicker";
import AddIcon from '@mui/icons-material/Add';
import { getStyles } from "./styles";
import { CheckBox } from "../../../tests/testCreate/ui/components/checkBox";
import { CheckboxDepartmentAutocompleteMultiple } from "../../../../../ui-kit/checkboxDepartmentAutocompleteMultiple";
import { SelectCheckBox } from "../../../../../ui-kit/selectCheckbox";
import { AutocompleteCheckBox } from "../../../../../ui-kit/autocompleteCheckBox";

export const CourseCreate: FC = () => {
    const { t } = useTranslation();
    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const styles = useMemo(() => getStyles(lgDown), [lgDown]);
    const { theme, onChangeTheme, themeValid, badge, badgesList, handleChangeBadge, changeBadgeText, content, contentValid, onChangeContent, receiver,
        onChangeInitiator, onSelectInitiator, initiator, initiatorValid, orgStructure, groupPermissions, groupPermissionsValid, onChangePermissions,
        onChangeReceiver, handleChangeDeadline, deadline, deadlineValid, handlePublishChange, publishChecked, handleNotifyChange,
        notifyChecked, handleCourse, cancelCreate, createClick, onChangePoints, points, isAvalableNextDay, handleChangeNextDay, onChangeDepartmentPermissions,
        departmentPermissions, positionsList, positions, handleChangePosition, onChangeAssignDepartment, assignDepartment, handleChangeAssignPosition,
        assignPositions, duration, onChangeDuration } = useCourseCreate();

    return (
        <Stack mt={2} ml={2} mb={4}>
            <Typography variant="h4" fontWeight={600}>{t(tokens.course.createCourse)}</Typography>
            <Stack mt={3} direction="row" justifyContent="space-between" spacing={2}>
                <TextInput
                    title={t(tokens.test.theme) + '*'}
                    label={t(tokens.test.theme)}
                    isValid={themeValid}
                    value={theme}
                    isMultiline={false}
                    onChangeValue={onChangeTheme}
                />
                <BadgeInput
                    title={t(tokens.test.badge)}
                    value={badge}
                    bagesList={badgesList}
                    handleChangeBadge={handleChangeBadge}
                    changeBadgeText={changeBadgeText}
                />
            </Stack>
            <Stack mt={2} direction="row" justifyContent="space-between" spacing={2}>
                <Stack width="100%" minWidth="49%" spacing={2}>
                    <Stack width="100%">
                        <TextInput
                            title={t(tokens.test.points)}
                            label={t(tokens.test.points)}
                            isValid={true}
                            value={points}
                            isMultiline={false}
                            onChangeValue={onChangePoints}
                        />
                    </Stack>
                    <AutocompleteSingle
                        testId={null}
                        isValid={initiatorValid}
                        title={t(tokens.course.initiator) + "*"}
                        label={t(tokens.course.initiator) + "*"}
                        value={initiator}
                        orgStructure={orgStructure}
                        onChangeInitiator={onChangeInitiator}
                        onSelectInitiator={onSelectInitiator}
                    />
                </Stack>
                <Stack width="100%" minWidth="49%">
                    <TextInput
                        title={t(tokens.course.shortlyAboutCourse)}
                        label={t(tokens.course.shortlyAboutCourse)}
                        isValid={contentValid}
                        value={content}
                        isMultiline={true}
                        onChangeValue={onChangeContent}
                    />
                </Stack>
            </Stack>
            <Stack direction="row" justifyContent="space-between" spacing={2} mt={2}>
                <Stack width="100%" spacing={.5}>
                    <TextInput
                        title={t(tokens.test.duration)}
                        label={t(tokens.test.duration)}
                        isValid={true}
                        value={duration}
                        isMultiline={false}
                        onChangeValue={onChangeDuration}
                    />
                    <Typography color="#F1594E" variant="caption">(для департаментів та посад)</Typography>
                </Stack>
                <Stack width="100%">
                    <Typography variant="h6" mb={1} color={deadlineValid ? 'inherit' : 'error'}>
                        {t(tokens.test.deadline)}
                    </Typography>
                    <Stack spacing={.5}>
                        <TimePicker
                            value={deadline}
                            handleDate={handleChangeDeadline}
                            isNotPast={true}
                        />
                        <Typography color="#F1594E" variant="caption">(для користувачів)</Typography>
                    </Stack>

                </Stack>
            </Stack>
            <Card elevation={3} sx={styles.card}>
                <Stack direction="row" justifyContent="space-between" spacing={2} mb={1}>
                    <AutocompleteMultiline
                        isValid={groupPermissionsValid}
                        title={t(tokens.documents.accessToUser)}
                        label={t(tokens.documents.accessToUser)}
                        value={groupPermissions}
                        orgStructure={orgStructure}
                        onChangePermissions={onChangePermissions}
                    />
                    <Stack width="100%">
                        <Typography variant="h6" mb={1}>{t(tokens.documents.accessToDepartment)}</Typography>
                        <CheckboxDepartmentAutocompleteMultiple
                            title={t(tokens.documents.accessToDepartment)}
                            value={departmentPermissions}
                            optionList={orgStructure}
                            onChange={onChangeDepartmentPermissions}
                            isDisabled={false}
                        />
                    </Stack>
                </Stack>
                <Stack direction="row" justifyContent="space-between" spacing={2} mb={1}>
                    <Stack width="49.2%" flexShrink={0}>
                        <Typography variant="h6" mb={1}>{t(tokens.documents.accessToPosition)}</Typography>
                        <AutocompleteCheckBox positionsList={positionsList} value={positions} handleChange={handleChangePosition} placeholder="Доступ за посадаю"/>
                    </Stack>
                    <Stack width="100%" />
                </Stack>
            </Card>
            <Card elevation={3} sx={styles.card}>
                <Stack direction="row" justifyContent="space-between" spacing={2} mb={1}>
                    <AutocompleteMultiline
                        testId={null}
                        isValid={true}
                        title={t(tokens.documents.assignToUser)}
                        label={t(tokens.documents.assignToUser)}
                        value={receiver}
                        orgStructure={orgStructure}
                        onChangePermissions={onChangeReceiver}
                    />
                    <Stack width="100%">
                        <Typography variant="h6" mb={1}>{t(tokens.documents.assignToDepartment)}</Typography>
                        <CheckboxDepartmentAutocompleteMultiple
                            title={t(tokens.documents.assignToDepartment)}
                            value={assignDepartment}
                            optionList={orgStructure}
                            onChange={onChangeAssignDepartment}
                            isDisabled={false}
                        />
                    </Stack>
                </Stack>
                <Stack direction="row" justifyContent="space-between" spacing={2} mb={1}>
                    <Stack width="49.2%" flexShrink={0}>
                        <Typography variant="h6" mb={1}>{t(tokens.documents.assignToPosition)}</Typography>
                        <AutocompleteCheckBox positionsList={positionsList} value={assignPositions} handleChange={handleChangeAssignPosition} placeholder="Доступ за посадаю"/>
                    </Stack>
                    <Stack width="100%" />
                </Stack>
            </Card>
            <Stack mt={3}>
                <Typography variant="h6">{t(tokens.course.nextDayAvailable)}</Typography>
                <Stack direction="row" alignItems="center" spacing={.5}>
                    <Typography variant="body2">{t(tokens.course.afterEndPrevious)}</Typography>
                    <Switch checked={isAvalableNextDay} size="medium" onChange={handleChangeNextDay} />
                    <Typography variant="body2">{t(tokens.course.andStartNextDay)}</Typography>
                </Stack>
            </Stack>
            <Stack pt={2} alignItems="flex-start">
                <Button
                    startIcon={<AddIcon />}
                    sx={styles.addQuestionButton}
                    onClick={() => { }}
                    disabled={true}
                >
                    {t(tokens.course.addTask)}
                </Button>
                <Stack position="relative" left="25px">
                    <Typography variant="caption" color="#F1594E">
                        {t(tokens.course.addTaskHelper)}
                    </Typography>
                </Stack>
            </Stack>
            <Stack direction='row' mt={3} spacing={3}>
                <CheckBox
                    isPublish={false}
                    testId={true}
                    title={t(tokens.documents.publish)}
                    value={publishChecked}
                    onChangeValue={handlePublishChange}
                />
                <CheckBox
                    isPublish={false}
                    testId={true}
                    title={t(tokens.documents.inform)}
                    value={notifyChecked}
                    onChangeValue={handleNotifyChange}
                />
            </Stack>
            <Stack direction='row' mt={3} spacing={3}>
                <Button
                    variant="contained"
                    sx={styles.createButton}
                    onClick={handleCourse}
                    disabled={createClick}
                >
                    {t(tokens.common.create)}
                </Button>
                <Button
                    variant="outlined"
                    sx={styles.cancelButton}
                    onClick={cancelCreate}
                    disabled={createClick}
                >
                    {t(tokens.common.cancel)}
                </Button>
            </Stack>
            <Backdrop sx={styles.backDrop} open={createClick}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <CircularProgress color="primary" />
                    <Typography color="primary" variant="h6">
                        {t(tokens.documents.await)}
                    </Typography>
                </Stack>
            </Backdrop>
        </Stack>
    )
};