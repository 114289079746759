import { FC } from "react";
import { FileImageBack } from "../fileImageBack";
import { FileDocumentBack } from "../fileDocumentBack";

interface IProps {
    isDisabled?: boolean;
    type: string;
    file: any;
    handleToggleImageFile: () => void;
    handleToggleVideoFile: () => void;
    handleDeleteImage: (name: string) => void;
};

export const FilesFromBack: FC<IProps> = ({ type, file, handleDeleteImage, handleToggleImageFile, handleToggleVideoFile, isDisabled }) => {

    return (
        <>
            {file.mimetype.startsWith('image/') ?
                <FileImageBack
                    isDisabled={isDisabled}
                    file={file}
                    handleDeleteImage={handleDeleteImage}
                    handleToggleImageFile={handleToggleImageFile}
                />
                :
                <FileDocumentBack
                    isDisabled={isDisabled}
                    type={type}
                    file={file}
                    handleDeleteImage={handleDeleteImage}
                    handleToggleVideoFile={handleToggleVideoFile}
                />
            }
        </>
    )
};