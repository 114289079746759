export const getStyles = () => {
    const styles = {
        image: { 
            marginLeft: 1, 
            borderRadius: '15px', 
            objectFit: 'cover',
            cursor: 'pointer' 
        },
        toolTip: { 
            position: 'absolute', 
            right: '2px', 
            top: '2px', 
            backgroundColor: 'lightgray', 
            ':hover': { 
                backgroundColor: 'darkgray' 
            } 
        },
    };
    return styles;
};