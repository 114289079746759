import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setAssignList, setLoading, setGetAssignListError } from "../../repository/slice";

function* getAssignListBadgeWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<{ count: number, rows: any[] }> = yield call(requester.get, `/api/user-badge/${payload.id}`, payload.body);
        if (isError) {
            yield put(setGetAssignListError(message));
        } else {
            yield put(setGetAssignListError(null));
            yield put(setAssignList({rows: data.rows, count: data.count}));
        }
    } catch (error) {
        console.error("getAssignListBadgeWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* getAssignListBadgeWatcher() {
    yield takeLatest("@saga/GET_ASSIGN_LIST_BADGE", getAssignListBadgeWorker);
};