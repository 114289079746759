import Router from './navigation/router';
import AppProvider from './providers/appProvider';

function App() {
  
  return (
    <AppProvider>
      <Router />
    </AppProvider>
  )
};

export default App;