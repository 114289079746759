import { combineReducers } from "@reduxjs/toolkit";
import { AuthReducer } from "./auth/repository/slice";
import { DocumentReducer } from "./document/repository/slice";
import { CategoryReducer } from "./category/repository/slice";
import { AdvertReducer } from "./advert/repository/slice";
import { OrgStructureReducer } from "./orgStructure/repository/slice";
import { FileReducer } from "./file/repository/slice";
import { RoleReducer } from "./role/repository/slice";
import { BackupReducer } from "./backup/repository/slice";
import { TestReducer } from "./test/repository/slice";
import { BadgeReducer } from "./badge/repository/slice";
import { pizzaWayAPI } from './pizzaWayAPI';
import { PermissionReducer } from "./permission/repository/slice";
import { AppPageReducer } from "./appPage/repository/slice";
import { pushNotificationReducer } from "./pushNotification/repository/reducer";
import { ProductReducer } from "./product/repository/slice";
import { QuizReducer } from "./quiz/repository/slice";
import { OrderReducer } from "./order/repository/slice";
import { CourseReducer } from "./course/repository/slice";
import { LearningHistoryReducer } from "./learningHistory/repository/slice";
import { UserReducer } from "./user/repository/slice";
import { GeneralQuestionReducer } from "./generalQuestion/repository/slice";
import { IsIdeaReducer } from "./isIdea/repository/slice";
import { AnonymousComplaintReducer } from "./anonymousComplaint/repository/slice";
import { AnalyticsReducer } from "./analytics/repository/slice";
import { RedirectReducer } from "./redirect/repository/slice";
import { TraineeReducer } from "./trainee/repository/slice";
import { AccessReducer } from "./access/repository/slice";
import { AssignReducer } from "./assign/repository/slice";

export const rootReducer = combineReducers({
    [pizzaWayAPI.reducerPath]: pizzaWayAPI.reducer,
    auth: AuthReducer,
    document: DocumentReducer,
    category: CategoryReducer,
    user: UserReducer,
    advert: AdvertReducer,
    orgStructure: OrgStructureReducer,
    file: FileReducer,
    role: RoleReducer,
    permission: PermissionReducer,
    backup: BackupReducer,
    test: TestReducer,
    badge: BadgeReducer,
    appPage: AppPageReducer,
    pushNotification: pushNotificationReducer,
    product: ProductReducer,
    quiz: QuizReducer,
    order: OrderReducer,
    course: CourseReducer,
    learningHistory: LearningHistoryReducer,
    generalQuestion: GeneralQuestionReducer,
    isIdea: IsIdeaReducer,
    anonymousComplaint: AnonymousComplaintReducer,
    analytics: AnalyticsReducer,
    redirect: RedirectReducer,
    trainee: TraineeReducer,
    access: AccessReducer,
    assign: AssignReducer
});