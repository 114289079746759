export const getStyles = () => {
    const styles = {
        name: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            lineHeight: 1,
            pl: 2
        },
        cell: {
            py: 1
        }
    };
    return styles;
};