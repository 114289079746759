import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';

interface IState {
    isLoading: boolean;
    quizList: any[];
    singleQuiz: any;
    quizId: number;
    quizCount: number;
    deleteQuizError: IError;
    updateQuizError: IError;
    uploadReportError: IError;
    createQuizError: IError;
    getQuizListError: IError;
    questionId: number;
    questionList: any[];
    createQuestionError: IError;
    updateQuestionError: IError;
    deleteQuestionError: IError;
    deleteFileFromQuestionError: IError;
};

const initialState: IState = {
    isLoading: false,
    quizList: [],
    singleQuiz: null,
    quizId: 0,
    quizCount: 0,
    deleteQuizError: null,
    updateQuizError: null,
    uploadReportError: null,
    createQuizError: null,
    getQuizListError: null,
    questionId: 0,
    questionList: [],
    createQuestionError: null,
    updateQuestionError: null,
    deleteQuestionError: null,
    deleteFileFromQuestionError: null,
};

const QuizSlice = createSlice({
    name: 'quiz',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setQuizList: (state, action: PayloadAction<any>) => {
            state.quizList = action.payload.list;
            state.quizCount = action.payload.count;
        },
        setQuizListError: (state, action: PayloadAction<IError>) => {
            state.getQuizListError = action.payload;
        },
        setSingleQuiz: (state, action: PayloadAction<any>) => {
            state.singleQuiz = action.payload;
        },
        setQuizId: (state, action: PayloadAction<any>) => {
            state.quizId = action.payload;
        },
        setDeleteQuizError: (state, action: PayloadAction<IError>) => {
            state.deleteQuizError = action.payload;
        },
        setUploadReportError: (state, action: PayloadAction<IError>) => {
            state.uploadReportError = action.payload;
        },
        setUpdateQuizError: (state, action: PayloadAction<IError>) => {
            state.updateQuizError = action.payload;
        },
        setCreateQuizError: (state, action: PayloadAction<IError>) => {
            state.createQuizError = action.payload;
        },
        setQuestionId: (state, action: PayloadAction<number>) => {
            state.questionId = action.payload;
        },
        setDeleteFileFromQuestionError: (state, action: PayloadAction<IError>) => {
            state.deleteFileFromQuestionError = action.payload;
        },
        createQuestionError: (state, action: PayloadAction<IError>) => {
            state.createQuestionError = action.payload;
        },
        updateQuestionError: (state, action: PayloadAction<IError>) => {
            state.updateQuestionError = action.payload;
        },
        setDeleteQuestionError: (state, action: PayloadAction<IError>) => {
            state.deleteQuestionError = action.payload;
        },
        setQuestionList: (state, action: PayloadAction<any[]>) => {
            state.questionList = action.payload;
        },
    },
});

export const { setLoading, setCreateQuizError, setDeleteQuizError, setQuizList, setQuizListError, setSingleQuiz, setUpdateQuizError, setUploadReportError, setQuizId,
    setQuestionId, setDeleteFileFromQuestionError, createQuestionError, setDeleteQuestionError, updateQuestionError, setQuestionList
 } = QuizSlice.actions;
export const QuizReducer = QuizSlice.reducer;