import type { FC } from 'react';
import { useCallback, useRef, useState } from 'react';
import { Avatar, Box, ButtonBase } from '@mui/material';
import { AccountPopover } from './account-popover';
import { getStyles } from './styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAuth } from '../../hooks/useAuth';
import { setLogout } from '../../store/auth/repository/slice';

interface IProps {
  handleField: () => void;
};

export const AccountButton: FC<IProps> = ({ handleField }) => {
  const styles = getStyles();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const { setLoggedIn } = useAuth();
  const userData = localStorage.getItem('user');
  const currentUser = userData ? JSON.parse(userData) : null;

const handleLogout = () => {
    dispatch(setLogout());
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
    setLoggedIn(false);
    handleField();
    navigate('/')
  };

  const handlePopover = useCallback((): void => {
    setOpenPopover(openPopover => !openPopover);
  }, []);

  return (
    <>
      <Box component={ButtonBase} onClick={handlePopover} ref={anchorRef} sx={styles.account}>
        <Avatar sx={styles.avatar}>
          <img src={currentUser?.photo || '/images/user/user.png'} alt='account' width={40} height={40} style={{objectFit: 'cover'}}/>
        </Avatar>
      </Box>
      <AccountPopover
        currentUser={currentUser}
        anchorEl={anchorRef.current}
        onClose={handlePopover}
        open={openPopover}
        handleLogout={handleLogout}
      />
    </>
  );
};
