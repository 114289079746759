import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setOrgStructure, setLoadingStructure, setGetOrgStructureError } from "../../repository/slice";

function* getOrgStructureWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoadingStructure(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.get, "/api/organization-structure/get", payload);
        if (isError) {
            yield put(setGetOrgStructureError(message));
        } else {
            yield put(setGetOrgStructureError(null));
            yield put(setOrgStructure(data));
        }
    } catch (error) {
        console.error("getOrgStructureWorker: ", error);
    } finally {
        yield put(setLoadingStructure(false));
    };
};

export function* getOrgStructureWatcher() {
    yield takeLatest("@saga/GET_ORGSTRUCTURE", getOrgStructureWorker);
};
