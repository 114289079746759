import { ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectBadge } from "../../../../store/badge/repository/selector";
import { useDebounce } from "../../../../hooks/useDebounce";
import { getAssignListBadge } from "../../../../store/badge/useCases/getAssignList/action";
import toast from "react-hot-toast";
import { tokens } from "../../../../locales/tokens";
import { deleteAssignBadge } from "../../../../store/badge/useCases/deleteAssign/action";

export const useEditAssignBadge = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const searchTextRef = useRef<HTMLInputElement | null>(null);
    const [pageAmount, setPageAmount] = useState(0);
    const [filters, setFilters] = useState({ page: 1, perPage: 30, searchText: '' });
    const scrollRef = useRef<HTMLDivElement>(null);
    const { assignList, assignCount, isLoading, assignDeleteError, getAssignListError } = useSelector(selectBadge);
    const [deleteClick, setDeleteClick] = useState(false);
    const [isProccess, setProcess] = useState(false);

    useEffect(() => {
        if (id) {
            dispatch(getAssignListBadge({
                id, body: {
                    search: filters.searchText,  
                    page: filters.page,
                    perPage: filters.perPage
                }
            }));
            setProcess(true);
        }
    }, [filters]);

    useEffect(() => {
        if (assignCount) {
            setPageAmount(Math.ceil(assignCount / filters.perPage));
        }
    }, [assignCount]);

    useEffect(() => {
        if (!isLoading && isProccess) {
            if (getAssignListError) {
                toast.error(getAssignListError)
            };
            setTimeout(() => setProcess(false), 400);
        }
    }, [getAssignListError, isLoading]);

    useEffect(() => {
        if (!isLoading && deleteClick) {
            if (assignDeleteError) {
                toast.error(assignDeleteError)
            } else {
                toast.success(t(tokens.assign.assignRemoved));
                dispatch(getAssignListBadge({
                    id, body: {
                        search: filters.searchText,  
                        page: filters.page,
                        perPage: filters.perPage
                    }
                }));
            }
            setDeleteClick(false);
        }
    }, [assignDeleteError, isLoading]);

    const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setSearch(e.target.value);
        debouncedHandleUserSeacrh(e.target.value);
    }, []);

    const onSearch = (value: any) => {
        const query = value ? value : '';
        setFilters((prevState) => ({ ...prevState, searchText: query, page: 1 }));
        scrollTopList();
        setProcess(true);
    };

    const { debouncedWrapper: debouncedHandleUserSeacrh } = useDebounce(onSearch, 800);

    const handleDelete = (assignId: number) => {
        const body: any = { 
            badgeId: Number(id),
            userIds: [assignId]
        };
        dispatch(deleteAssignBadge(body));
        setDeleteClick(true);
    };

    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        setFilters((prevState) => ({ ...prevState, page }));
        scrollTopList();
    }, [setFilters]);

    const scrollTopList = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        };
    };

    return {
        filters, searchTextRef, handleChangeSearch, handleDelete, pageAmount, handlePageChange,
        scrollRef, isProccess, assignList
    };
};