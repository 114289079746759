import { SagaIterator } from "redux-saga";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setCreateQuizError, setQuizId } from "../../repository/slice";

function* createQuizWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.post, "/api/admin/questionnaires", payload.body);
        if (isError) {
            yield put(setCreateQuizError(message));
        } else {
            yield put(setCreateQuizError(null));
            yield put(setQuizId(data.id));
        }
    } catch (error) {
        console.error("createQuizWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* createQuizWatcher() {
    yield takeLatest("@saga/CREATE_QUIZ", createQuizWorker);
};
