import { Button, CircularProgress, Stack, SvgIcon, Typography } from "@mui/material"
import { FC, useMemo } from "react"
import { useCourseList } from "../presenter/useCourseList";
import AddIcon from '@mui/icons-material/Add';
import { Link } from "react-router-dom";
import { paths } from "../../../../../paths";
import { useTranslation } from "react-i18next";
import { getStyles } from "./styles";
import { tokens } from "../../../../../locales/tokens";
import { SearchInput } from "../../../../../ui-kit/searchInput/searchInput";
import { Filters } from "../../../../../ui-kit/listFilters";
import { TabsChanger } from "../../../../../ui-kit/tabsChanger";
import { CourseTable } from "./components/courseTable";
import { EmptyPage } from "../../../category/categoryEmpty/ui";

export const Courses: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { searchText, handleSearchCourseText, pageAmount, resetFilters, handlePageChange, filters, orgStructure, changeStatus, handleDateChange, scrollRef,
        onSelectInitiator, onChangeInitiator, handleTabsChange, handleStatus, handleDelete, handleUploadReport, courseList, isProccessCourse } = useCourseList();

    return (
        <Stack m={2}>
            <Stack>
                <Stack direction='row' justifyContent='space-between'>
                    <Typography variant="h5" fontWeight={600}>
                        {t(tokens.course.courseList)}
                    </Typography>
                    <Button
                        startIcon={(<SvgIcon><AddIcon /></SvgIcon>)}
                        variant="contained"
                        sx={styles.createBtn}
                        component={Link}
                        to={paths.dashboard.content.course.create}
                    >
                        {t(tokens.common.create)}
                    </Button>
                </Stack>
                <Stack mt={3}>
                    <SearchInput
                        searchText={searchText}
                        label={t(tokens.course.searchByName)}
                        handleSearch={handleSearchCourseText}
                        size="small"
                    />
                </Stack>
                <Filters
                    filters={filters}
                    orgStructure={orgStructure}
                    changeStatus={changeStatus}
                    resetFilters={resetFilters}
                    handleDateChange={handleDateChange}
                    onSelectInitiator={onSelectInitiator}
                    onChangeInitiator={onChangeInitiator}
                />
                <TabsChanger
                    tab={filters.tab}
                    handleTabsChange={handleTabsChange}
                />
            </Stack>
            {courseList?.length > 0 &&
                <CourseTable
                    scrollRef={scrollRef}
                    page={filters.page}
                    courseList={courseList}
                    handlePageChange={handlePageChange}
                    pageAmount={pageAmount}
                    handleStatus={handleStatus}
                    handleDelete={handleDelete}
                    handleUpload={handleUploadReport}
                />
            }
            {(!courseList?.length && isProccessCourse) &&
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={styles.progress}
                >
                    <CircularProgress color="primary" />
                </Stack>
            }
            {(!courseList?.length && !isProccessCourse) &&
                <Stack>
                    <EmptyPage
                        title={t(tokens.course.emptyListTitle)}
                        description={t(tokens.course.emptyListDesc)}
                    />
                </Stack>
            }
        </Stack>
    )
};