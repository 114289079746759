export const getStyles = () => {
    const styles = {
        answer: {
            backgroundColor: 'white', 
            mt: 2,
            width: '100%',
            pr: 2,
            borderRadius: '15px'
        },
        textField: {
            wordBreak: 'break-all'
        },
        name: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            lineHeight: 1.2,
        },
    };
    return styles;
};