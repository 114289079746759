import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setAccessList, setLoading, setGetAccessListError } from "../../repository/slice";

function* getAccessListWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<{ count: number, rows: any[] }> = yield call(requester.get, '/api/access', payload);
        if (isError) {
            yield put(setGetAccessListError(message));
        } else {
            yield put(setGetAccessListError(null));
            yield put(setAccessList({rows: data.rows, count: data.count}));
        }
    } catch (error) {
        console.error("getAccessListWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* getAccessListWatcher() {
    yield takeLatest("@saga/GET_ACCESS_LIST", getAccessListWorker);
};