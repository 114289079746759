export const paths = {
    index: "/",
    dashboard: {
        auth: {
            login: "/login",
            forgotPassword: '/forgot-password',
            userForm: '/user-form'
        },
        users: {
            index: "/user",
            edit: "/user-edit/:id",
            studyHistory: "/user-studyHistory/:id",
            orderHistory: "/user-orderHistory/:id",
            roles: "/user-roles",
            rolesCreate: "/user-roles-create",
            rolesEdit: "/user-roles-edit/:id"
        },
        content: {
            index: "/content",
            category: "/content-category",
            document: {
                list: "/content-document",
                create: "/content-document-create",
                edit: "/content-document-edit/:id",
                editAccess: "/content-document-edit-access/:id",
                editAssign: "/content-document-edit-assign/:id",
            },
            test: {
                list: "/content-test",
                create: "/content-test-create",
                edit: "/content-test-edit/:id",
                editAccess: "/content-test-edit-access/:id",
                editAssign: "/content-test-edit-assign/:id",
            },
            quiz: {
                list: "/content-quiz",
                create: "/content-quiz-create",
                edit: "/content-quiz-edit/:id",
                editAccess: "/content-quiz-edit-access/:id",
                editAssign: "/content-quiz-edit-assign/:id",
            },
            course: {
                list: "/content-course",
                create: "/content-course-create",
                edit: "/content-course-edit/:id",
                editAccess: "/content-course-edit-access/:id",
                editAssign: "/content-course-edit-assign/:id",
            },
            trainee: {
                index: "/content-trainee",
                create: "/content-traineetask-create",
                edit: "/content-traineetask-edit/:id",
            },
        },
        feedback: {
            generalQuestion: "/feedback",
            isIdea: "/feedback-is-idea",
            anonymousComplaint: "/feedback-anonymous-complaint",
        },
        advert: {
            index: "/advert",
            create: "/advert-create",
            edit: "/advert-edit/:id",
            editAccess: "/advert-edit-access/:id",
            editAssign: "/advert-edit-assign/:id",
        },
        backup: {
            index: "/backup",
            create: "/backup-create"
        },
        badge: {
            index: "/badges",
            create: "/badges/create",
            edit: "/badges/:id/edit",
            view: "/badges/:id",
            editAssign: "/badges/:id/edit-assigns"
        },
        appPage: {
            index: "/app-page",
            create: "/app-page/create",
            edit: "/app-page/:id/edit",
        },
        notification: {
            index: "/notification",
            create: "/notification-create",
            edit: "/notification-edit/:id",
            detail: "/notification-detail/:id"
        },
        analytics: {
            general: "/analytics",
            user: "/analytics-user",
            course: "/analytics-course",
            content: "/analytics-content",
            advert: "/analytics-advert",
            product: "/analytics-product",
            knowledge: "/analytics-knowledge",
            process: "/analytics-process",
            leaderboard: "/analytics-leaderboard",
            singleTest: "/analytics-single-test",
            singleCourse: "/analytics-single-course",
            singleDocument: "/analytics-single-document",
            singleAdvert: "/analytics-single-advert",
            documents: "/analytics-documents",
            adverts: "/analytics-adverts",
        },
        shop: {
            index: "/shop",
            create: "/shop/product/create",
            edit: "/shop/product/:id/edit",
            orders: {
                list: "/shop-orders"
            }
        },
        access: {
            index: "/no-access"
        }
    },
    401: "/401",
    404: "/404",
    500: "/500",
};
