import { FC, RefObject, forwardRef, useMemo } from "react";
import { TreeSelect } from 'antd';
import SearchIcon from '@mui/icons-material/Search';
import './styles.css';
import { Box, useTheme } from "@mui/material";
import { getStyles } from "./styles";

interface ITreeItem {
    label: string;
    value: string;
    parent: string;
    children?: ITreeItem[];
}

interface IProps {
    title: string;
    value?: any | null;
    optionList: any[];
    isDisabled?: boolean;
    onChange?: (newValue: string[]) => void;
}; 

export const CheckboxDepartmentAutocompleteMultiple: FC<IProps> = forwardRef<RefObject<any>, IProps>(({ title, value, onChange, optionList, isDisabled }, ref: any) => {
    const theme = useTheme();
    const isDark = theme.palette.mode === 'dark';
    const styles = useMemo(() => getStyles(isDark), [isDark]);

    const transformData = (data: any[], parentKey: string) => {
        const filteredData = data.filter(item => 'departmentGuid' in item);

        return filteredData.map((item, index) => {
            const key = parentKey ? `${parentKey}-${index}` : `${index}`;
            const { name, children, departmentGuid, id } = item;

            const treeNode: any = {
                label: name,
                value: departmentGuid + '%' + id,
                // data: departmentGuid
            };

            if ('children' in item) {
                treeNode.children = transformData(children, key);
            };

            return treeNode;
        }) as ITreeItem[];
    };

    const transformedData = transformData(optionList, '');

    const onChangePermission = (newValue: string[], _info: any, data: any) => {
        let result: string[] = value || [];
        let selectedId = data.triggerValue;

        const selectAllChildren = (tree: ITreeItem[], value: string): string[] => {
            let result: string[] = [];
            tree.forEach((item) => {
                if (item.value === value) {
                    result.push(item.value);
                    if (item.children) {
                        const getAllDescendants = (children: ITreeItem[]): string[] => {
                            let descendants: string[] = [];
                            children.forEach((child) => {
                                descendants.push(child.value);
                                if (child.children) {
                                    descendants.push(...getAllDescendants(child.children));
                                }
                            });
                            return descendants;
                        };
                        result.push(...getAllDescendants(item.children));
                    }
                } else {
                    if (item.children) {
                        result.push(...selectAllChildren(item.children, value));
                    }
                }
            });
            return result;
        };

        const childrenArray = selectAllChildren(transformedData, selectedId);
        if (value.includes(selectedId)) {
            result = result.filter((id) => !childrenArray.includes(id));
        } else {
            result = Array.from(new Set([...result, ...childrenArray, selectedId]));
        }
        onChange && onChange(result);
    };

    const tProps = {
        treeData: transformedData,
        value,
        disabled: isDisabled,
        onChange: onChangePermission,
        showSearch: true,
        treeIcon: false,
        treeCheckable: true,
        placeholder: title,
        suffixIcon: <SearchIcon sx={styles.suffixIcon} />,
        showCheckedStrategy: TreeSelect.SHOW_ALL,
        style: { width: '100%' },
        filterTreeNode: (inputValue: string, treeNode: any) =>
            treeNode.label?.toLowerCase().includes(inputValue.toLowerCase())
    };

    return (
        <Box sx={styles.wrapper}>
            <TreeSelect ref={ref} {...tProps} treeCheckStrictly />
        </Box>
    );
});