import type { ChangeEvent, FC } from 'react';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import { ChartSeries } from '../../../presenter/useUserChart';
import InfoIcon from '@mui/icons-material/Info';
import { ChartBody } from '../chartBody';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../../locales/tokens';
import { ChartHeader } from '../../../../general/ui/components/chartHeader';
import { Dayjs } from 'dayjs';
import { MuiDateRange } from '../../../../general/ui/components/muiDateRange';
import { TestAutocomplete } from '../testAutocomplete';

interface IProps {
  chartSeries: ChartSeries;
  questionList: string[];
  dates: { startDate: Dayjs | null; endDate: Dayjs | null; };
  handleRangeChange: (selectedDates: any, dateStrings: [string, string]) => void;
  disabledDate: (current: Dayjs) => boolean;
  test: any;
  optionList: any[];
  handleChangeTest: (task: any) => void;
  changeTestText: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const UserChart: FC<IProps> = ({ chartSeries, questionList, dates, disabledDate, handleRangeChange, changeTestText, handleChangeTest, optionList, test }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="flex-end" spacing={1}>
        <TestAutocomplete
          test={test}
          handleChangeTest={handleChangeTest}
          changeTestText={changeTestText}
          optionList={optionList}
        />
        <MuiDateRange
          dates={dates}
          handleRangeChange={handleRangeChange}
          disabledDate={disabledDate}
        />
      </Stack>
      <Stack direction="row" alignItems="center" spacing={3}>
        <Stack spacing={1} pt={5}>
          {questionList.map((question: string, index: number) =>
            <Typography key={index} variant='body2' py="9px">{question}</Typography>
          )}
        </Stack>
        <Card elevation={2}>
          <ChartHeader title={t(tokens.analytics.analyticsResult)} subTitle={""} icon={<InfoIcon />} />
          <CardContent>
            <ChartBody chartSeries={chartSeries} questionList={questionList} />
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
};