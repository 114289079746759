import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setUpdateQuestionError } from "../../repository/slice";

function* updateGeneralQuestionWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.put, "/api/admin/general-question", payload);
        if (isError) {
            yield put(setUpdateQuestionError(message));
        } else {
            yield put(setUpdateQuestionError(null));
        }
    } catch (error) {
        console.error("updateGeneralQuestionWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* updateGeneralQuestionWatcher() {
    yield takeLatest("@saga/UPDATE_GENERAL_QUESTION", updateGeneralQuestionWorker);
};
