export const getStyles = () => ({
    clearImage: {
        position: 'absolute',
        top: '1px',
        left: '70px',
        zIndex: 1,
        backgroundColor: '#d3d3d3',
        borderRadius: '50%',
        '&:hover': {
            backgroundColor: '#bfbfbf'
        }
    },
    buttonSubmit: {
        width: "fit-content",
        borderRadius: '10px',
        color: '#FFFFFF',
        fontWeight: 600,
        backgroundColor: '#F1594E',
        textTransform: 'none',
        '&:hover': {
            backgroundColor: '#d85046'
        }
    },
    buttonCancel: {
        borderRadius: '10px',
        color: '#F1594E',
        fontWeight: 600,
        borderColor: '#F1594E',
        textTransform: 'none',
        '&:hover': {
            borderColor: '#d85046'
        }
    },
    spacesFields: {
        mb: 1.25
    },
    wrapperButtons: {
        display: "flex",
        flexDirection: "row",
        columnGap: 3,
        mt: 3
    },
    editBtn: { 
        borderRadius: '10px', 
        color: '#FFFFFF',
        fontWeight: 600,
        backgroundColor: '#F1594E',
        textTransform: 'none',
        height: "55px",
        mb: .2,
        '&:hover':{
            backgroundColor: '#d85046'
        } 
    },
});