import { ChangeEvent, FC, MutableRefObject, useMemo } from "react";
import { Box, Pagination, Stack, Table, TableBody, Theme, useMediaQuery } from "@mui/material";
import { EmptyPage } from "../../../../../content/category/categoryEmpty/ui";
import { tokens } from "../../../../../../locales/tokens";
import { useTranslation } from "react-i18next";
import { ProductsTableItem } from "../productsTableItem";
import { IProduct } from "../../../../../../store/product/types/IProduct";
import { getStyles } from "./styles";

interface IProps {
    scrollRef: MutableRefObject<HTMLDivElement | null>;
    products: IProduct[];
    page: number;
    pageAmount: number;
    handlePageChange: (event: ChangeEvent<unknown>, page: number) => void;
    onChangeVisibility: (id: number, isHidden: boolean) => void;
    onDelete: (id: number) => void;
};

export const ProductsTable: FC<IProps> = ({ products, page, pageAmount, handlePageChange, onChangeVisibility, onDelete, scrollRef }) => {
    const { t } = useTranslation();
    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const styles = useMemo(() => getStyles(lgDown), [lgDown]);

    if (products.length) {
        return (
            <>
                <Box>
                    <Box sx={styles.tableWrapper} ref={scrollRef}>
                        <Table>
                            <TableBody>
                                {products.map(product => (
                                    <ProductsTableItem
                                        key={product.id}
                                        product={product}
                                        onChangeVisibility={onChangeVisibility}
                                        onDelete={onDelete}
                                    />
                                ))
                                }
                            </TableBody>
                        </Table>
                    </Box>
                    <Stack direction='row' justifyContent='center' sx={styles.paginationWrapper}>
                        <Pagination
                            page={page}
                            count={pageAmount || 1}
                            color="primary"
                            onChange={handlePageChange}
                        />
                    </Stack>
                </Box>
            </>
        )
    } else {
        return (
            <Stack my={3}>
                <EmptyPage
                    title={t(tokens.shop.emptyListTitle)}
                    description={t(tokens.shop.emptyListDescription)}
                />
            </Stack>
        )
    }
};