import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBackupList } from "../../../store/backup/useCases/getBackupList/action";
import { selectBackup } from "../../../store/backup/repository/selector";
import { restoreData } from "../../../store/backup/useCases/restoreData/action";
import { createBackup } from "../../../store/backup/useCases/createBackup/action";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";

export const useBackupCreate = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [backupId, setBackupId] = useState('');
    const [createClick, setCreateClick] = useState(false);
    const [restoreClick, setRestoreClick] = useState(false);
    const [pagination, setPagination] = useState<any>({ page: 1, rowsPerPage: 30 });
    const [pageAmount, setPageAmount] = useState(0);

    const { backupList, backupCount, isLoading, restoreBackupError, createBackupError } = useSelector(selectBackup);

    useEffect(() => {
        dispatch(getBackupList({ page: pagination.page, perPage: pagination.rowsPerPage }));
    }, [pagination]);

    useEffect(() => {
        if (backupCount) {
            setPageAmount(Math.ceil(backupCount / pagination.rowsPerPage));
        }
    }, [backupCount]);

    useEffect(() => {
        if (!isLoading && createClick) {
            if (createBackupError) {
                toast.error(createBackupError);
            } else {
                toast.success(t(tokens.backup.messages.backupCreated));
                dispatch(getBackupList({ page: pagination.page, perPage: pagination.rowsPerPage }));
            }
            setCreateClick(false);
        }
    }, [createBackupError, isLoading]);

    useEffect(() => {
        if (!isLoading && restoreClick) {
            if (restoreBackupError) {
                toast.error(restoreBackupError);
            } else {
                toast.success(t(tokens.backup.messages.backupRestored));
                dispatch(getBackupList({ page: pagination.page, perPage: pagination.rowsPerPage }));
            }
            setRestoreClick(false);
        }
    }, [restoreBackupError, isLoading]);

    const onChangeBackupId = (event: ChangeEvent<HTMLInputElement>) => {
        setBackupId(event.target.value);
    };

    const handleRestore = () => {
        dispatch(restoreData({ id: backupId }));
        setRestoreClick(true);
    };

    const handleCreateBackup = () => {
        dispatch(createBackup());
        setCreateClick(true);
    };

    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        setPagination((prevState: any) => ({ ...prevState, page }));
    }, [setPagination]);

    return { handleCreateBackup, handleRestore, backupId, onChangeBackupId, backupList, pagination, pageAmount, handlePageChange };
};