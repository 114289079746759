import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useDebounce } from "../../../../hooks/useDebounce";
import toast from "react-hot-toast";
import { IFilters } from "./types";
import { selectLearningHistory } from "../../../../store/learningHistory/repository/selector";
import { getLearningHistory } from "../../../../store/learningHistory/useCases/getLearningHistory/action";
import { selectUser } from "../../../../store/user/repository/selector";

export const useUserStudyHistory = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [imageSrc, setImageSrc] = useState('/images/user/user.png');
    const [filters, updateFilters] = useState<IFilters>({ offset: 0, limit: 30, search: '' });
    const [pageAmount, setPageAmount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [isProccessStudy, setProcessStudy] = useState(false);
    const { learningHistory, learningHistoryCount, getLearningHistoryError, isLoading } = useSelector(selectLearningHistory);
    const { singleUser } = useSelector(selectUser);
    const [isBadgeModalOpen, setBadgeModalOpen] = useState(false);
    const [currentBadge, setCurrentBadge] = useState<{ id: number, image: any, name: string, points: number } | null>(null);

    useEffect(() => {
        dispatch(getLearningHistory({ id: Number(id), offset: filters.offset, limit: filters.limit, search: filters.search }));
        setProcessStudy(true);
    }, [filters]);

    useEffect(() => {
        singleUser && setImageSrc(singleUser.photo);
    }, [singleUser]);

    useEffect(() => {
        setPageAmount(Math.ceil(learningHistoryCount / filters.limit));
    }, [learningHistoryCount]);

    useEffect(() => {
        if (!isLoading && isProccessStudy) {
            if (getLearningHistoryError) {
                toast.error(getLearningHistoryError)
            };
            setTimeout(() => setProcessStudy(false), 400);
        }
    }, [getLearningHistoryError, isLoading]);

    const handleSearchTaskText = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        debouncedHandleTaskSeacrh(e.target.value);
    };

    const onSearchTask = (value: string) => {
        const query = value ? value : '';
        updateFilters((prevState) => ({ ...prevState, offset: 0, search: query }));
        setCurrentPage(1);
    };

    const { debouncedWrapper: debouncedHandleTaskSeacrh } = useDebounce(onSearchTask, 1000);

    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        updateFilters((prevState) => ({ ...prevState, offset: (page - 1) * filters.limit }));
        setCurrentPage(page);
        const element: HTMLElement | null = document.getElementById('search-task');
        element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, [updateFilters]);

    const handleOpenBadgeModal = () => {
        setBadgeModalOpen(true);
    };

    const handleCloseQuestionModal = () => {
        setBadgeModalOpen(false);
        setCurrentBadge(null);
    };

    const defineCurrentBadge = (badgeId: number) => {
        const badges = [...singleUser.badges];
        const badge = badges.find((badge: any) => badge.id === badgeId);
        setCurrentBadge(badge);
        setBadgeModalOpen(true);
    };

    return {
        imageSrc, singleUser, learningHistory, handlePageChange, pageAmount, currentPage, handleSearchTaskText, searchText, isProccessStudy,
        currentBadge, handleOpenBadgeModal, handleCloseQuestionModal, defineCurrentBadge, isBadgeModalOpen
    }
};