import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setOrderError, setOrderList, setOrderLoading } from "../../repository/slice";
import { IOrder } from "../../types/IOrder";
import { setSingleUser } from "../../../user/repository/slice";

function* getUserOrderWorker({ payload }: any): SagaIterator {
    try {
        yield put(setOrderLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.get, `/api/shop/order/user/${payload.id}`, payload);
        if (isError) {
            yield put(setOrderError(message));
        } else {
            yield put(setOrderList(data.orders));
            yield put(setSingleUser(data.user));
        }
    } catch (error) {
        console.error("getUserOrderWorker: ", error);
    } finally {
        yield put(setOrderLoading(false));
    };
};

export function* getUserOrderWatcher() {
    yield takeLatest("@saga/GET_USER_ORDERS", getUserOrderWorker);
};