import { Avatar, IconButton, Stack, SvgIcon, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import { getStyles } from "./styles";
import SchoolIcon from '@mui/icons-material/School';
import { tokens } from "../../../../../../locales/tokens";
import { useTranslation } from "react-i18next";
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';

interface CustomTreeItemLabelProps {
    user: any;
    handleStatus: (id: number, isBanned: boolean) => void;
};

export const UserTableItem: FC<CustomTreeItemLabelProps> = ({ user, handleStatus }) => {
    const { id, first_name, last_name, photo, isBanned, role, patronymic } = user;
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const handleUserStatus = useCallback(() => handleStatus(id, isBanned), [id, isBanned]);
    const rolesString = user.roles?.map((role: { id: number; name: string }) => role.name).join(', ');

    return (
        <TableRow>
            <TableCell align="left" width='5%' padding="none">
                <Avatar alt="avatar" src={photo} sx={styles.avatar} />
            </TableCell>
            <TableCell align="left" width='35%' padding="none">{last_name + ' ' + first_name + ' ' + patronymic}</TableCell>
            <TableCell padding="none" width='25%'>
                <Stack direction="row">
                    <Typography variant="body2" sx={styles.role}>{rolesString || t(tokens.common.noData)}</Typography>
                </Stack>
            </TableCell>
            <TableCell padding="none" width='25%' align="center">{user.status || t(tokens.common.noData)}</TableCell>
            <TableCell padding="none" width='10%'>
                <Stack direction='row' alignItems='center' spacing={1}>
                    <IconButton size="small" component={Link} to={`/user-orderHistory/${id}`}>
                        <Tooltip title={t(tokens.users.ordersHistory.ordersHistory)}>
                            <SvgIcon><WorkOutlineIcon /></SvgIcon>
                        </Tooltip>
                    </IconButton>
                    <IconButton size="small" component={Link} to={`/user-studyHistory/${id}`}>
                        <Tooltip title={t(tokens.users.studyHistory.studyHistory)}>
                            <SvgIcon><SchoolIcon /></SvgIcon>
                        </Tooltip>
                    </IconButton>
                    <IconButton size="small" component={Link} to={`/user-edit/${id}`}>
                        <Tooltip title={t(tokens.users.editUser)}>
                            <SvgIcon><EditIcon /></SvgIcon>
                        </Tooltip>
                    </IconButton>
                    <IconButton size="small" onClick={handleUserStatus}>
                        {!isBanned ?
                            <Tooltip title={t(tokens.common.active)} >
                                <img src="/images/user/open.svg" alt="open" />
                            </Tooltip>
                            :
                            <Tooltip title={t(tokens.common.blocked)} >
                                <img src="/images/user/lock.svg" alt="lock" />
                            </Tooltip>
                        }
                    </IconButton>
                </Stack>
            </TableCell>
        </TableRow>
    )
};