export const getStyles = () => {
    const styles = {
        allProducts: { 
            textTransform: 'none', 
            mt: 2, 
            borderRadius: '10px', 
            px: 2 
        },
        container: { 
            width: '50%', 
            m: 3 
        }
    };
    return styles;
};