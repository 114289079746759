import { Stack, SvgIcon, Tooltip, Typography } from "@mui/material";
import { FC, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";

interface IProps {
    title: string;
    subTitle: string;
    icon?: ReactElement;
};

export const ChartHeader: FC<IProps> = ({ title, subTitle, icon }) => {
    const { t } = useTranslation();

    return (
        <Stack direction="row" justifyContent="space-between" p={2}>
            <Stack>
                <Typography variant="h6" fontWeight={600}>{title}</Typography>
                <Typography variant="body1" color="GrayText">{subTitle}</Typography>
            </Stack>
            {icon &&
                <Tooltip title={t(tokens.analytics.updateInfo)}>
                    <SvgIcon color="action" fontSize='large'>
                        {icon}
                    </SvgIcon>
                </Tooltip>}
        </Stack>
    )
};