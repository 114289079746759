import { ChangeEvent } from "react";
import { useDispatch } from "react-redux";
import { useDebounce } from "../../../hooks/useDebounce";
import { getParentCategories } from "../../../store/category/useCases/getParentCategories/action";

export const useModalAutocomplete = () => {
    const dispatch = useDispatch();

    const changeCategoryText = (event: ChangeEvent<HTMLInputElement>) => {
        debouncedHandleCategorySeacrh(event.target.value);
    };

    const onSearchCategory = (value: string) => {
        if (value.length) {
            dispatch(getParentCategories({ name: value, isHidden: false }));
        } else {
            dispatch(getParentCategories({ isHidden: false }));
        }
    };

    const { debouncedWrapper: debouncedHandleCategorySeacrh } = useDebounce(onSearchCategory, 1000);

    return { changeCategoryText }
};