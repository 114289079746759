export const getStyles = () => {
    const styles = {
        message: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 3,
            lineHeight: 1.2,
        },
        svgIcon: {
            fontSize: 26
        },
        cell: {
            py: 1
        }
    };
    return styles;
};