import toast from 'react-hot-toast';
import { requester } from '../../../libs/requester/requester-axios';
import { pizzaWayAPI } from '../../pizzaWayAPI';

const badgeAPI = pizzaWayAPI.injectEndpoints({
    endpoints: (builder) => ({
        deleteBadge: builder.mutation<{ success: boolean; }, number>({
            async queryFn(id, { dispatch }) {
                try {
                    const { data, isError, message }: any = await requester.delete('/api/badge/admin/delete/' + id);
                    if (isError) {
                        toast.error(message);
                        throw new Error(message);
                    };
                    return { data };
                } catch (e) {
                    throw e;
                }
            },
            invalidatesTags: ['Badge']
        }),
    }),
    overrideExisting: false,
});

export const { useDeleteBadgeMutation } = badgeAPI;