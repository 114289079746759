export const TIMESTAMP_DAY = 24 * 60 * 60 * 1000;
export const FILE_SIZE = {
    '500KB': 500 * 1024,
    '1MB': 1024 * 1024,
    '20MB': 20 * 1024 * 1024
};

export const TEST_TYPES = {
    SINGLE: "Single",
    MULTIPLE: "Multiple",
    ORDER: "Order",
    FREE: "Free",
    SCALE: "Scale"
};

export const config = {
    badge: {
        perPage: 30
    }
};