import { Avatar, Stack } from "@mui/material"
import { ChangeEvent, FC, useMemo } from "react"
import { getStyles } from "./styles";

interface IProps {
    imageSrc: string;
    handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const UserAvatar: FC<IProps> = ({ imageSrc, handleFileChange }) => {
    const styles = useMemo(() => getStyles(), []);

    return (
        <Stack >
            <label htmlFor="fileInput">
                <Avatar
                    alt="User Avatar"
                    src={imageSrc}
                    sx={styles.avatar}
                />
            </label>
            {/* <input
                type="file"
                id="fileInput"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleFileChange}
            /> */}
        </Stack>
    )
};