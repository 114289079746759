import { TableBody } from "@mui/material";
import { FC } from "react";
import { BackupTableItem } from "../backupTableItem";

interface IProps {
    backupList: any[];
};

export const BackupTableBody: FC<IProps> = ({ backupList }) => {

    return (
        <TableBody>
            {backupList.map(backup =>
                <BackupTableItem
                    key={backup.id}
                    backup={backup}
                />
            )}
        </TableBody>
    )
};