import { Button, Stack, TableCell, TableRow, Typography } from "@mui/material";
import { CSSProperties, FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getStyles } from "./styles";
import { tokens } from "../../../../../locales/tokens";
import moment from "moment";

interface IProps {
    assign: any;
    sortedBy: string;
    handleDelete: (id: number) => void;
};

export const AssignTableItem: FC<IProps> = ({ assign, handleDelete, sortedBy }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const date = assign?.created_at || assign.createdAt 
    const formattedDate = moment(date).local().format('DD.MM.YYYY HH:mm');
    
    const deleteAssign = useCallback(() => handleDelete(assign?.departmentGuid || assign?.id || assign.user.id), [assign]);
    const title: any = {
        User: assign.user?.last_name + " " + assign.user?.first_name + " " + assign.user?.patronymic,
        Department: assign.department,
        Position: assign.name
    };

    return (
        <TableRow hover key={assign.id}>
            <TableCell align="left" width='55%' sx={styles.cell}>
                <Stack direction="row" spacing={1} alignItems="center">
                {sortedBy === "User" &&
                    <img
                        src={assign.photo ? assign.photo : "/images/user/user.png"}
                        alt=""
                        width={40}
                        height={40}
                        style={styles.avatar as CSSProperties}
                    />}
                    <Typography variant="body2" whiteSpace="nowrap">{title[sortedBy]}</Typography>
                    {sortedBy === "User" && <Typography variant="subtitle2" color="#579CFF">({assign.user?.position})</Typography>}
                </Stack>
            </TableCell>
            <TableCell align="center" width='15%' sx={styles.cell}>
                <Typography variant="body2">
                    {formattedDate}
                </Typography>
            </TableCell>
            <TableCell align="right" width='30%' sx={styles.cell}>
                <Stack direction='row' alignItems='center' spacing={1} justifyContent='flex-end'>
                    <Button variant="contained" sx={styles.button} onClick={deleteAssign}>
                        {t(tokens.common.delete)}
                    </Button>
                    {/* <Button variant="contained" sx={styles.button}>
                        {t(tokens.common.reAssign)}
                    </Button> */}
                </Stack>
            </TableCell>
        </TableRow>
    )
};