import type { ChangeEvent, FC } from 'react';
import { Card, CardContent, Stack } from '@mui/material';
import { ChartSeries } from '../../../presenter/useSingleTestChart';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { ChartHeader } from '../../../../general/ui/components/chartHeader';
import { ChartBody } from '../../../../general/ui/components/chartBody';
import { TextAutocomplete } from '../../../../../../ui-kit/textAutocomplete';

interface IProps {
  chartSeries: ChartSeries;
  labels: string[];
  currentTest: any;
  testList: any[];
  changeTest: (value: any) => void;
  changeTestText: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const SingleTestChart: FC<IProps> = ({ chartSeries, labels, testList, currentTest, changeTest, changeTestText }) => {
  const { t } = useTranslation();

  return (
    <Stack width="70%" m={3} spacing={1}>
      <Stack direction="row" justifyContent="flex-end">
        <TextAutocomplete
          optionList={testList}
          value={currentTest}
          handleChangeValue={changeTest}
          title='Тест'
          isMultiline={false}
          size='small'
          changeText={changeTestText}
        />
      </Stack>
      <Card elevation={2}>
        <ChartHeader title="Результат тесту" subTitle={""} icon={<InfoIcon />} />
        <CardContent>
          <ChartBody chartSeries={chartSeries} labels={labels} />
        </CardContent>
      </Card>
    </Stack>

  );
};