import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';

interface IState {
    isLoading: boolean;
    documentsList: any[];
    singleDocument: any;
    documentsCount: number;
    deleteDocumentError: IError;
    updateDocumentError: IError;
    uploadReportError: IError;
    createDocumentError: IError;
    addFileError: IError;
    deleteFileError: IError;
    getDocumentError: IError;
    galleryFiles: any[];
};

const initialState: IState = {
    isLoading: false,
    documentsList: [],
    singleDocument: null,
    documentsCount: 0,
    deleteDocumentError: null,
    updateDocumentError: null,
    uploadReportError: null,
    createDocumentError: null,
    addFileError: null,
    deleteFileError: null,
    getDocumentError: null,
    galleryFiles: []
};

const DocumentSlice = createSlice({
    name: 'document',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setDocuments: (state, action: PayloadAction<any>) => {
            state.documentsList = action.payload.documents;
            state.documentsCount = action.payload.count;
        },
        setSingleDocument: (state, action: PayloadAction<any>) => {
            state.singleDocument = action.payload;
        },
        setDeleteDocumentError: (state, action: PayloadAction<IError>) => {
            state.deleteDocumentError = action.payload;
        },
        setUploadReportError: (state, action: PayloadAction<IError>) => {
            state.uploadReportError = action.payload;
        },
        setUpdateDocumentError: (state, action: PayloadAction<IError>) => {
            state.updateDocumentError = action.payload;
        },
        setCreateDocumentError: (state, action: PayloadAction<IError>) => {
            state.createDocumentError = action.payload;
        },
        setGetDocumentError: (state, action: PayloadAction<IError>) => {
            state.getDocumentError = action.payload;
        },
        setAddFileError: (state, action: PayloadAction<IError>) => {
            state.addFileError = action.payload;
        },
        setDeleteFileError: (state, action: PayloadAction<IError>) => {
            state.deleteFileError = action.payload;
        },
        setGalleryFiles: (state, action: PayloadAction<any[]>) => {
            state.galleryFiles = action.payload;
        },
    },
});

export const { setLoading, setDocuments, setDeleteDocumentError, setUpdateDocumentError, setUploadReportError, setCreateDocumentError,
    setSingleDocument, setGetDocumentError, setGalleryFiles, setAddFileError, setDeleteFileError } = DocumentSlice.actions;
export const DocumentReducer = DocumentSlice.reducer;