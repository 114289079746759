import { SagaIterator } from "redux-saga";
import { call, delay, put, takeEvery } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { createQuestionError, setLoading, setQuestionId } from "../../repository/slice";
import { addQuestionImage } from "../questionAddImage/action";
import { getSingleTest } from "../getSingleTest/action";
import { createAnswer } from "../createAnswer/action";

function* createQuestionWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.post, "/api/admin/question/create", payload.body);
        if (isError) {
            yield put(createQuestionError(message));
        } else {
            yield put(createQuestionError(null));
            yield put(setQuestionId(data.id));

            if (payload.image) {
                yield put(addQuestionImage({ body: { id: data.id, image: payload.image } }))
            };

            if (payload.hiddenReply) {
                yield put(createAnswer({ reply: { ...payload.hiddenReply, questionId: data.id } }))
            };

            yield delay(600);
            yield put(getSingleTest({ id: Number(payload.testId) }));
        }
    } catch (error) {
        console.error("createQuestionWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* createQuestionWatcher() {
    yield takeEvery("@saga/CREATE_QUESTION", createQuestionWorker);
};
