import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setQuizList, setQuizListError } from "../../repository/slice";

function* getQuizListWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.get, "/api/admin/questionnaires", payload);
        if (isError) {
            yield put(setQuizListError(message));
        } else {
            yield put(setQuizListError(null));
            yield put(setQuizList({list: data.rows, count: data.count}));
        }
    } catch (error) {
        console.error("getQuizListWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* getQuizListWatcher() {
    yield takeLatest("@saga/GET_QUIZ_LIST", getQuizListWorker);
};
