import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setLeaderBoardData } from "../../repository/slice";

function* getLeaderBoardDataWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.get, "/api/statistic/leaderboard/" + payload.id);
        if (isError) {

        } else {
            yield put(setLeaderBoardData(data));
        }
    } catch (error) {
        console.error("getLeaderBoardDataWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* getLeaderBoardDataWatcher() {
    yield takeLatest("@saga/GET_LEADER_BOARD_DATA", getLeaderBoardDataWorker);
};