import { FC } from "react";
import { useGeneralAnalytics } from "../presenter/useGeneralAnalytics";
import { GeneralChart } from "./components/generalChart";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";

export const GeneralAnalytics: FC = () => {
    const { t } = useTranslation();
    const { dates, handleRangeChange, disabledDate } = useGeneralAnalytics();

    return (
        <Stack m={2}>
            <Typography variant="h4" fontWeight={600}>
                {t(tokens.analytics.generalAnalytics)}
            </Typography>
            <Stack justifyContent="center" alignItems="center">
                <GeneralChart
                    chartSeries={[4, 1, 1]}
                    labels={[t(tokens.analytics.all), t(tokens.analytics.active), t(tokens.analytics.working)]}
                    dates={dates}
                    handleRangeChange={handleRangeChange}
                    disabledDate={disabledDate}
                />
            </Stack>
        </Stack>
    )
};