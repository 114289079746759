import { Button, Stack, SvgIcon, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../locales/tokens";
import AddIcon from '@mui/icons-material/Add';
import { getStyles } from "./styles";
import { CategoryFilters } from "./components/categoryFilters";
import { useDocumentCategoroy } from "../presenters/useDocumentCategory";
import { CategoryTable } from "./components/categoryTable";
import { CategoryCreate } from "../../categoryCreate/ui";
import { EmptyPage } from "../../categoryEmpty/ui";

export const DocumentCategory: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { searchText, handleSearchCategoryText, handleTabsChange, filters, categoryList, handleStatus, setUpdateClick,
        capturedCategory, setCapturedCategory, dragDropHandler, allowDrop, swapSections, isOpen, handleToggle, defineCategoryId,
        categoryId, updateFilters } = useDocumentCategoroy();

    return (
        <Stack m={2} onDragOver={allowDrop} onDrop={dragDropHandler} pb={10}>
            <Stack direction='row' justifyContent='space-between'>
                <Typography variant="h5" fontWeight={600}>
                    {t(tokens.category.documentCategory)}
                </Typography>
                <Button
                    startIcon={(<SvgIcon><AddIcon /></SvgIcon>)}
                    variant="contained"
                    sx={styles.createBtn}
                    onClick={handleToggle}
                >
                    {t(tokens.common.create)}
                </Button>
            </Stack>
            <CategoryFilters
                searchText={searchText}
                tab={filters.tab}
                handleSearchCategoryText={handleSearchCategoryText}
                handleTabsChange={handleTabsChange}
            />
            {categoryList.length ?
                <CategoryTable
                    categories={categoryList}
                    setUpdateClick={setUpdateClick}
                    handleStatus={handleStatus}
                    capturedCategory={capturedCategory}
                    setCapturedCategory={setCapturedCategory}
                    swapSections={swapSections}
                    defineCategoryId={defineCategoryId}
                />
                :
                <Stack>
                    <EmptyPage
                        title={t(tokens.category.emptyListTitle)}
                        description={t(tokens.category.emptyListDesc)}
                    />
                </Stack>
            }
            <CategoryCreate
                categoryId={categoryId}
                isOpen={isOpen}
                handleToggle={handleToggle}
                filters={filters}
                updateFilters={updateFilters}
            />
        </Stack>
    )
};