import { IconButton, Stack, SvgIcon, Tooltip, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { getStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { TASK_TYPES } from "../../../../traineePositionList/presenter/types";
import { tokens } from "../../../../../../locales/tokens";

interface IProps {
    taskList: any[];
    defineCurrentTask: (index: number, questionId: number) => void;
    removeTask: (id: number, type: string) => void;
};

export const TraineeTaskList: FC<IProps> = ({ defineCurrentTask, removeTask, taskList }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    const type: any = {
        [TASK_TYPES.DOCUMENT]: t(tokens.course.document),
        [TASK_TYPES.TEST]: t(tokens.course.test),
        [TASK_TYPES.QUESTIONNAIRE]: t(tokens.course.quiz),
        [TASK_TYPES.COURSE]: t(tokens.course.course)
    };

    return (
        <>
            {taskList?.map((task: any, index: number) => {

                return (
                    <Stack key={task.id} direction="row" alignItems="center" justifyContent="space-between" spacing={3} sx={styles.answer}>
                        <Typography variant="h6" fontWeight={600} width="5%">{index + 1}</Typography>
                        <Typography variant="body1" width="70%" sx={styles.name}>{task.name}</Typography>
                        <Typography variant="body1" width="20%" sx={styles.textField}>{type[task.type]}</Typography>
                        <Stack direction="row" width="5%">
                            {/* <IconButton onClick={() => defineCurrentTask(index, task.id)}>
                                        <SvgIcon><EditIcon /></SvgIcon>
                                    </IconButton> */}
                            <Tooltip title={t(tokens.common.delete)}>
                                <IconButton onClick={() => removeTask(task.id, task.type)}>
                                    <SvgIcon><DeleteIcon /></SvgIcon>
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </Stack>
                )
            })}
        </>
    )
};