export interface IOption {
    label: string;
    name: string;
    id: number;
};

export const collectUserOptionList: Function = (options: any[]) => {
    const result = options?.reduce((result: any, option: any) => {
        return [
            ...result,
            {
                label: option?.first_name + " " + option?.last_name,
                name: option?.first_name + " " + option?.last_name,
                id: option?.id,
            },
        ];
    }, [] as IOption[]) ?? [];
    return result;
};