import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { paths } from "../../../../paths";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { selectProduct } from "../../../../store/product/repository/selector";
import { setErrorGalleryFiles, setProductError, setSelectedProduct } from "../../../../store/product/repository/slice";
import { getProductById } from "../../../../store/product/useCases/getProductById/action";
import { updateProduct } from "../../../../store/product/useCases/updateProduct/action";
import { deleteProductImage } from "../../../../store/product/useCases/deleteProductImage/action";
import { addFilesToProduct } from "../../../../store/product/useCases/addFiles/action";

const initialForm = {
    name: '',
    files: [] as any[],
    is_hidden: true,
    price: '',
    quantity: '',
    description: '',
};

const initialFormErrors = {
    nameError: false,
    filesError: false,
    descriptionError: false,
    priceError: false,
    quantityError: false
};

export const useProductEdit = () => {
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const { t } = useTranslation();
    const [form, setForm] = useState(initialForm);
    const [formErrors, setFormErrors] = useState(initialFormErrors);
    const [isClick, setIsClick] = useState(false);
    const [imagesIdsToDelete, setImagesIdsToDelete] = useState<string[]>([]);
    const { isProductLoading, selectedProduct, productError, galleryFiles, errorGalleryFiles } = useSelector(selectProduct);
    const isDisabled = !!selectedProduct?.deletedAt;
    const { id: productId } = useParams();
    const [isAddFileClick, setAddFileClick] = useState(false);

    useEffect(() => {
        if (productId) {
            dispatch(getProductById({ id: Number(productId) }));
        } else {
            toast.error('Something went wrong');
        };

        return () => { dispatch(setSelectedProduct(null)) };
    }, []);

    useEffect(() => {
        if (selectedProduct) {
            const initForm = { ...form };
            selectedProduct.name && (initForm.name = selectedProduct.name);
            selectedProduct.files?.length && (initForm.files = selectedProduct.files);
            initForm.price = String(selectedProduct.price || 0);
            initForm.quantity = String(selectedProduct.quantity || 0);
            initForm.description = (selectedProduct.description || '');
            initForm.is_hidden = selectedProduct?.is_hidden || false;
            setForm(initForm);
        };
    }, [selectedProduct]);

    useEffect(() => {
        if (!isProductLoading && isClick) {
            if (productError) {
                toast.error(productError)
                dispatch(setProductError(null));
            } else {
                toast.success(t(tokens.shop.messages.updated));
                navigation(paths.dashboard.shop.index)
            }
            setIsClick(false);
        };
    }, [isProductLoading]);

    useEffect(() => {
        if (galleryFiles?.length) {
            setForm(prev => ({ ...prev, files: galleryFiles }));
        }
    }, [galleryFiles]);

    useEffect(() => {
        if (!isProductLoading && isAddFileClick) {
            if (errorGalleryFiles) {
                toast.error(errorGalleryFiles);
            } else {
                toast.success('Файл додано');
            }
            setAddFileClick(false);
        }
    }, [errorGalleryFiles, isProductLoading]);

    const getHtmlText = (description: string) => {
        var div = document.createElement("div");
        div.innerHTML = description;
        var text = div.textContent || div.innerText || "";
        return text;
    };

    const isValidForm = useCallback(() => {
        let valid = true;
        let errors = formErrors;
        const { name, files, price, quantity, description } = form;
        const descriptionText = getHtmlText(description);

        if (name.length) {
            errors = { ...errors, nameError: false };
        } else {
            valid = false;
            errors = { ...errors, nameError: true };
        };
        if (files.length) {
            errors = { ...errors, filesError: false };
        } else {
            valid = false;
            errors = { ...errors, filesError: true };
        };
        if (!isNaN(Number(price)) && Number(price) > 0) {
            errors = { ...errors, priceError: false };
        } else {
            valid = false;
            errors = { ...errors, priceError: true };
        };
        if (!isNaN(Number(quantity)) && Number(quantity) > 0) {
            errors = { ...errors, quantityError: false };
        } else {
            valid = false;
            errors = { ...errors, quantityError: true };
        };
        // if (descriptionText.length > 500) {
        //     valid = false;
        //     errors = { ...errors, descriptionError: true };
        //     toast.error(t(tokens.shop.messages.maxDescriptionLength));
        // } else {
        //     errors = { ...errors, descriptionError: false };
        // };
        setFormErrors(errors);
        return valid;
    }, [form]);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target?.files?.[0];
        if (file) {
            setForm(prev => ({ ...prev, files: [file] }));
            formErrors.filesError && setFormErrors({ ...formErrors, filesError: false });
        }
    };

    const handleSetFiles = (items: any[]) => {
        setForm(prev => ({ ...prev, files: [...items] }));
    };

    const handleFilesDrop = useCallback((newFiles: File[]): void => {
        dispatch(addFilesToProduct({ id: Number(productId), files: newFiles }));
        formErrors.filesError && setFormErrors({ ...formErrors, filesError: false });
        setAddFileClick(true);
    }, []);

    const handleDeleteImage = (name: string) => {
        dispatch(deleteProductImage({ filename: name }));
        if (form.files.length > 1) {
            setForm(prev => ({ ...prev, files: prev.files.filter(file => (file.name || file.filename) !== name) }));
            toast.success("Файл видалено");
        } else {
            toast.error('Неможливо видалити файл');
        }
    };

    const handleFilesRemoveAll = useCallback((): void => {
        const filteredImages = form.files.filter(file => file.id);
        const imagesIds = filteredImages.map(file => file.filename);
        // setImagesIdsToDelete(imagesIds);
        if (imagesIds.length) {
            imagesIds.forEach(filename => {
                dispatch(deleteProductImage({ filename }));
            });
        };
        setForm(prev => ({ ...prev, files: [] }));
    }, [form.files]);

    const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, name: e.target.value });
        formErrors.nameError && setFormErrors({ ...formErrors, nameError: false });
    };

    const onChangePrice = (e: ChangeEvent<HTMLInputElement>) => {
        const regex = /^(\d*)$/;

        if (regex.test(e.target.value)) {
            setForm({ ...form, price: e.target.value });
            formErrors.priceError && setFormErrors({ ...formErrors, priceError: false });
        }
    };

    const onChangeAmount = (e: ChangeEvent<HTMLInputElement>) => {
        const regex = /^(\d*)$/;

        if (regex.test(e.target.value)) {
            setForm({ ...form, quantity: e.target.value });
            formErrors.quantityError && setFormErrors({ ...formErrors, quantityError: false });
        }
    };

    const onChangeDescription = (e: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, description: e.target.value });
        formErrors.descriptionError && setFormErrors({ ...formErrors, descriptionError: false });
    };

    const onTogglePublish = () => setForm(prev => ({ ...prev, is_hidden: !prev.is_hidden }));

    const onCreate = () => {
        const isValid = isValidForm();
        const data: any = {
            name: form.name,
            // files: form.files,
            is_hidden: form.is_hidden,
            product_id: selectedProduct?.id || 0,
            price: Number(form.price),
            quantity: Number(form.quantity)
        };
        if (form.description.length) data.description = form.description;
        if (isValid) {
            if (checkDescValid()) {
                setIsClick(true);
                dispatch(updateProduct(data));
                // if (imagesIdsToDelete.length) {
                //     imagesIdsToDelete.forEach(filename => {
                //         dispatch(deleteProductImage({ filename }));
                //     });
                // };
            }
        } else {
            toast.error(t(tokens.common.fillAllFields));
        };
    };

    const checkDescValid = () => {
        let valid = true;
        let errors = formErrors;
        const { name, description } = form;
        const descriptionText = getHtmlText(description);

        if (name.length > 255) {
            valid = false;
            errors = { ...errors, nameError: true };
            toast.error(t(tokens.shop.messages.maxNameLength));
        } else {
            errors = { ...errors, nameError: false };
        };
        if (descriptionText.length > 500) {
            valid = false;
            errors = { ...errors, descriptionError: true };
            toast.error(t(tokens.shop.messages.maxDescriptionLength));
        } else {
            errors = { ...errors, descriptionError: false };
        };
        setFormErrors(errors);
        return valid;
    };

    const onCancel = () => navigation(paths.dashboard.shop.index);

    return {
        form, formErrors, isClick, isDisabled, onChangeName, handleFileChange, handleFilesRemoveAll, handleDeleteImage, handleFilesDrop, onChangePrice,
        onChangeAmount, onChangeDescription, onTogglePublish, onCreate, onCancel, handleSetFiles
    };
};