import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getTraineePositions } from "../../../../store/trainee/useCases/getTraineePositions/action";
import { selectTrainee } from "../../../../store/trainee/repository/selector";
import toast from "react-hot-toast";
import { getPositions } from "../../../../store/user/useCases/getPositions/action";
import { selectUser } from "../../../../store/user/repository/selector";
import { createTraineePosition } from "../../../../store/trainee/useCases/createTraineePosition/action";
import { deleteTraineePosition } from "../../../../store/trainee/useCases/deleteTraineePosition/action";

export const useTraineePositionList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [deleteClick, setDeleteClick] = useState(false);
    const [pageAmount, setPageAmount] = useState(0);
    const [filters, updateFilters] = useState({ page: 1, rowsPerPage: 30, query: '', tab: 'Active' });
    const scrollRef = useRef<HTMLDivElement>(null);
    const { traineePositionsCount, traineePositions, isLoading, traineeTasksError } = useSelector(selectTrainee);
    const [isProccess, setProcess] = useState(false);
    const [positions, setPositions] = useState<any[]>([]);
    const { positionsList } = useSelector(selectUser);

    useEffect(() => {
        setProcess(true);
        dispatch(getTraineePositions());
        dispatch(getPositions());
    }, [filters]);

    useEffect(() => {
        traineePositions.length &&
            setPositions(traineePositions.map(pos => ({ id: pos.id, name: pos.name })));
    }, [traineePositions]);

    useEffect(() => {
        if (traineePositionsCount) {
            setPageAmount(Math.ceil(traineePositionsCount / filters.rowsPerPage));
        }
    }, [traineePositionsCount]);

    useEffect(() => {
        if (!isLoading && isProccess) {
            if (traineeTasksError) {
                toast.error(traineeTasksError)
            };
            setTimeout(() => setProcess(false), 400);
        }
    }, [traineeTasksError, isLoading]);

    const handleTabsChange = (event: ChangeEvent<{}>, tab: string): void => {
        updateFilters((prevState) => ({ ...prevState, tab, page: 1 }));
        scrollTopList();
    };

    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        updateFilters((prevState) => ({ ...prevState, page }));
        scrollTopList();
    }, [updateFilters]);

    const handleDelete = (id: number) => {
        // dispatch(deleteTask({ id }));
        // setDeleteClick(true);
    };

    const scrollTopList = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        };
    };

    const handleChangePosition = (event: any, newValue: readonly any[]) => {
        const addedItems = newValue.filter(item => !positions.some(pos => pos.id === item.id));
        const removedItems = positions.filter(item => !newValue.some(pos => pos.id === item.id));

        if (addedItems.length > 0) {
            dispatch(createTraineePosition({ position_id: addedItems[0].id }))
        };
        if (removedItems.length > 0) {
            dispatch(deleteTraineePosition({ id: removedItems[0].id }))
        };

        setPositions([...newValue]);
    };

    return { handleTabsChange, filters, handlePageChange, handleDelete, pageAmount, scrollRef, isProccess, traineePositions, positions, handleChangePosition, positionsList }
};