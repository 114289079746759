import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setGalleryFiles } from "../../repository/slice";

function* updateDocumentFilesWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.put, '/api/admin/document/update-file', payload);
        if (isError) {
            
        } else {
           
        }
    } catch (error) {
        console.error("updateDocumentFilesWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* updateDocumentFilesWatcher() {
    yield takeLatest("@saga/UPDATE_DOCUMENT_FILES", updateDocumentFilesWorker);
};
