import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setProductLoading, setGalleryFiles } from "../../repository/slice";

function* updateFilesInProductWorker({ payload }: any): SagaIterator {
    try {
        yield put(setProductLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.put, '/api/shop/product/admin/update-image', payload);
        if (isError) {
             
        } else {
            yield put(setGalleryFiles(data));
        }
    } catch (error) {
        console.error("updateFilesInProductWorker: ", error);
    } finally {
        yield put(setProductLoading(false));
    };
};

export function* updateFilesInProductWatcher() {
    yield takeLatest("@saga/UPDATE_FILES_IN_PRODUCT", updateFilesInProductWorker);
};
