import { SagaIterator } from "redux-saga";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setCreateTestError, setTestId } from "../../repository/slice";
import { createQuestion } from "../createQuestion/action";

function* createTestWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.post, "/api/admin/quiz/create", payload.body);
        if (isError) {
            yield put(setCreateTestError(message));
        } else {
            yield put(setCreateTestError(null));
            yield put(setTestId(data.id));
        }
    } catch (error) {
        console.error("createTestWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* createTestWatcher() {
    yield takeLatest("@saga/CREATE_TEST", createTestWorker);
};
