import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';

interface IState {
    isLoading: boolean;
    traineePositions: any[];
    traineeTaskList: any[];
    traineePositionById: any;
    traineePositionsCount: number;
    traineeTasksError: IError;
    createTraineeTasksError: IError;
    updateTraineeTasksError: IError;
    deleteTraineeTasksError: IError;
};

const initialState: IState = {
    isLoading: false,
    traineePositions: [],
    traineeTaskList: [
        // {
        //     "id": 103,
        //     "sortNumber": 1,
        //     "day": 1,
        //     "document": null,
        //     "quiz": {
        //         "id": 277,
        //         "name": "24"
        //     },
        //     "questionnaire": null
        // },
        // {
        //     "id": 105,
        //     "sortNumber": 1,
        //     "day": 2,
        //     "document": null,
        //     "quiz": null,
        //     "questionnaire": {
        //         "id": 33,
        //         "name": "Без асайна"
        //     }
        // },
        // {
        //     "id": 106,
        //     "sortNumber": 1,
        //     "day": 3,
        //     "document": null,
        //     "quiz": {
        //         "id": 280,
        //         "name": "Как приготвоить пиццу?"
        //     },
        //     "questionnaire": null
        // }
    ],
    traineePositionById: null,
    traineePositionsCount: 10,
    traineeTasksError: null,
    createTraineeTasksError: null,
    updateTraineeTasksError: null,
    deleteTraineeTasksError: null,
};

const TraineeSlice = createSlice({
    name: 'trainee',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setTraineePositions: (state, action: PayloadAction<{ rows: any[], count: number }>) => {
            state.traineePositions = action.payload.rows;
            state.traineePositionsCount = action.payload.count;
        },
        setTraineePositionById: (state, action: PayloadAction<any>) => {
            state.traineePositionById = action.payload;
        },
        setTraineeTasksError: (state, action: PayloadAction<IError>) => {
            state.traineeTasksError = action.payload;
        },
        setCreateTraineeTaskError: (state, action: PayloadAction<IError>) => {
            state.createTraineeTasksError = action.payload;
        },
        setUpdateTraineeTaskError: (state, action: PayloadAction<IError>) => {
            state.updateTraineeTasksError = action.payload;
        },
        setDeleteTraineeTaskError: (state, action: PayloadAction<IError>) => {
            state.deleteTraineeTasksError = action.payload;
        },
        setTraineeTaskList: (state, action: PayloadAction<any[]>) => {
            state.traineeTaskList = action.payload;
        },
    },
});

export const { setLoading, setTraineePositions, setTraineeTasksError, setCreateTraineeTaskError, setUpdateTraineeTaskError, setTraineePositionById, setDeleteTraineeTaskError, setTraineeTaskList } = TraineeSlice.actions;
export const TraineeReducer = TraineeSlice.reducer;