import { IconButton, SvgIcon, Tooltip } from "@mui/material";
import { FC, useMemo } from "react";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { getStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";

interface VisibleButtonProps {
    isPassShown: boolean;
    onShowPassword: () => void;
};

export const VisibleButton: FC<VisibleButtonProps> = ({ isPassShown, onShowPassword }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    return (
        <Tooltip title={isPassShown ? t(tokens.auth.hide) : t(tokens.auth.show)}>
            <IconButton sx={styles.visibleIcon} onClick={onShowPassword}>
                <SvgIcon>
                    {isPassShown ?
                        <RemoveRedEyeIcon />
                        :
                        <VisibilityOffIcon />
                    }
                </SvgIcon>
            </IconButton>
        </Tooltip>
    )
};