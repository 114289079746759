import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { selectAnalytics } from "../../../../store/analytics/repository/selector";
import { useDebounce } from "../../../../hooks/useDebounce";
import { getDocuments } from "../../../../store/document/useCases/getDocuments/action";
import { selectDocument } from "../../../../store/document/repository/selector";
import { getSingleDocumentData } from "../../../../store/analytics/useCases/getSingleDocumentData/action";

export const useSingleDocumentAnalytics = () => {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [currentDocument, setCurrentDocument] = useState<any>(null);
    const { singleDocumentData } = useSelector(selectAnalytics);
    const { documentsList } = useSelector(selectDocument);

    useEffect(()=>{
        dispatch(getDocuments(combineRequestBody(searchText)));
    }, []);

    useEffect(()=>{
        currentDocument && dispatch(getSingleDocumentData({id: currentDocument.id}));
    }, [currentDocument]);
    
    const handleChangeDocument = useCallback((document: any) => {
        setCurrentDocument(document);
    }, [setCurrentDocument]);

    const changeDocumentText = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
        debouncedHandleDocumentSeacrh(event.target.value);
    };

    const onSearchDocument = (value: string) => {
        if (value.length) {
            dispatch(getDocuments(combineRequestBody(value)));
        } else {
            dispatch(getDocuments(combineRequestBody('')));
        }
    };

    const { debouncedWrapper: debouncedHandleDocumentSeacrh } = useDebounce(onSearchDocument, 1000);
    
    const combineRequestBody = (value: string) => {
        return { page: 1, perPage: 15, name: value }
    };

    const data = [singleDocumentData?.likesAmount || null, singleDocumentData?.dislikesAmount || null, singleDocumentData?.commentsAmount || null];

    return { documentsList, changeDocumentText, handleChangeDocument, currentDocument, data }
};