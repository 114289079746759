import { Stack, Typography } from "@mui/material";
import { ChangeEvent, FC, MutableRefObject, useMemo } from "react";
import InputMask, { ReactInputMask } from "react-input-mask";
import './styles.css';
import { getStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";

interface PhoneInputProps {
    phone: string;
    phoneValid: boolean;
    isHideError: boolean;
    phoneInputRef: MutableRefObject<ReactInputMask | null>;
    handleEnterPhone: (e: React.KeyboardEvent) => void;
    onChangePhone: (event: ChangeEvent<HTMLInputElement>) => void;
}; 

export const PhoneField: FC<PhoneInputProps> = ({ phone, onChangePhone, phoneValid, handleEnterPhone, phoneInputRef, isHideError }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    return (
        <Stack sx={styles.phone} >
            <InputMask
                id="phoneInput"
                mask="+380 (99)-999-99-99"
                value={phone}
                type="text"
                disabled={false}
                maskChar="X"
                onKeyDown={handleEnterPhone}
                onChange={onChangePhone}
                placeholder={t(tokens.auth.phoneNumber)}
                className="custom-input-mask"
                ref={phoneInputRef}
                style={!phoneValid ? styles.borderError : styles.borderMask}
            />
            {(!phoneValid && !isHideError) ?
                <Typography
                    color="error"
                    variant="caption"
                    sx={styles.error}
                >
                    {t(tokens.auth.messages.phoneIsRequired)}
                </Typography>
                :
                null
            }
        </Stack>
    )
};