import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setUploadAdvertReportError, setLoading } from "../../repository/slice";

function* uploadAdvertReportWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.get, "/api/excel/announcement-report/" + payload.id);
        if (isError) {
            yield put(setUploadAdvertReportError(message));
        } else {
            yield put(setUploadAdvertReportError(null));
            const downloadLink = document.createElement("a");
            downloadLink.href = data.url;
            downloadLink.click();
        }
    } catch (error) {
        console.error("uploadAdvertReportWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* uploadAdvertReportWatcher() {
    yield takeLatest("@saga/UPLOAD_ADVERT_REPORT", uploadAdvertReportWorker);
};
