import { IconButton, Stack, SvgIcon, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import { getStyles } from "./styles";
import { useNavigate } from "react-router-dom";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useTranslation } from "react-i18next";
import { IRole } from "../../../../../../store/role/types/IRole";
import { tokens } from "../../../../../../locales/tokens";
import { paths } from "../../../../../../paths";
import { useDispatch } from "react-redux";
import { setSelectedRole } from "../../../../../../store/role/repository/slice";

interface IProps {
    role: IRole;
    onDelete: (id: number) => void;
};

export const RoleTableItem: FC<IProps> = ({ role, onDelete }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    const permissionsString = useMemo(() => {
        const permissionNames = role.permissions.map(permission => permission.name);
        return permissionNames.join(', ')
    }, [role.permissions]);

    const handleEdit = useCallback(() => {
        dispatch(setSelectedRole(role));
        navigate(paths.dashboard.users.rolesEdit.replace(':id', String(role.id)));
    }, []);

    const handleDelete = useCallback(() => onDelete(role.id), []);

    return (
        <TableRow hover key={role.id}>
            <TableCell align="left" width="20%" sx={styles.cell}>
                <Typography sx={styles.permissionsText} variant="body2">{role.name}</Typography>
            </TableCell>
            <TableCell align="left" width="60%" sx={styles.cell}>
                <Typography sx={styles.permissionsText} variant="body2">{permissionsString}</Typography>
            </TableCell>
            <TableCell align="right" width="20%" sx={styles.cell}>
                <Stack direction='row' alignItems='center' spacing={1} justifyContent='flex-end'>
                    <IconButton size="small" onClick={handleEdit}>
                        <Tooltip title={role.deleted_at ? t(tokens.common.watch) : t(tokens.common.edit)}>
                            <SvgIcon style={styles.svgIcon}><EditOutlinedIcon /></SvgIcon>
                        </Tooltip>
                    </IconButton>
                    {!role.deleted_at &&
                        <IconButton size="small" onClick={handleDelete}>
                            <Tooltip title={t(tokens.common.delete)}>
                                <SvgIcon style={styles.svgIcon}><DeleteForeverIcon /></SvgIcon>
                            </Tooltip>
                        </IconButton>
                    }
                </Stack>
            </TableCell>
        </TableRow>
    )
};