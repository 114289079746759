import { Stack, Typography } from "@mui/material"
import { CheckboxAutocompleteSingle } from "../../../../../../../ui-kit/checkboxAutocompleteSingle"
import { FC } from "react";

interface IProps {
    testId?: any;
    isValid: boolean;
    title: string;
    label: string;
    value: any;
    orgStructure: any[];
    onChangeInitiator: () => void;
    onSelectInitiator: (selectedKeysValue: string, node: any) => void;
};

export const AutocompleteSingle: FC<IProps> = ({ isValid, label, onChangeInitiator, onSelectInitiator, orgStructure, title, value, testId }) => {

    return (
        <Stack width="100%">
            <Typography variant="h6" mb={1} color={isValid ? 'inherit' : 'error'}>
                {title}
            </Typography>
            <CheckboxAutocompleteSingle
                title={label}
                value={value}
                optionList={orgStructure}
                onSelect={onSelectInitiator}
                onChange={onChangeInitiator}
                isValid={true}
                isDisabled={!!testId}
            />
        </Stack>
    )
};