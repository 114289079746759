import { FC } from "react";
import { TableBody } from "@mui/material";
import { PositionTableItem } from "../positionTableItem";

interface IProps {
    positionList: any[];
    handleDelete: (id: number) => void;
};

export const PositionTableBody: FC<IProps> = ({ handleDelete, positionList }) => {

    return (
        <TableBody>
            {positionList.map(position => (
                <PositionTableItem
                    key={position.id}
                    position={position}
                    handleDelete={handleDelete}
                />
            ))}
        </TableBody>
    )
};