import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setRoleLoading, setRoleError, setRoles } from "../../repository/slice";
import { IRole } from "../../types/IRole";
import { getRoles } from "../getRoles/action";
import { selectRole } from "../../repository/selector";

function* deleteRoleWorker({ payload }: any): SagaIterator {
    try {
        yield put(setRoleLoading(true));
        const { roleList, rolesCount } = yield select(selectRole);
        const deletedRoles = roleList.map((role: IRole) => role.id === payload.id ? { ...role, deleted_at: String(new Date().getTime()) } : role)
        yield put(setRoles({ count: rolesCount, rows: deletedRoles }));
        const { data, message, isError }: IResponse<{ count: number, rows: IRole[] }> = yield call(requester.delete, "/api/role/delete/" + payload.id);
        if (isError) {
            yield put(setRoleError(message));
        }
    } catch (error) {
        console.error("creteRoleWorker: ", error);
    } finally {
        yield put(getRoles());
        yield put(setRoleLoading(false));
    };
};

export function* deleteRoleWatcher() {
    yield takeLatest("@saga/DELETE_ROLE", deleteRoleWorker);
};