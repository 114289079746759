import toast from 'react-hot-toast';
import { requester } from '../../../libs/requester/requester-axios';
import { pizzaWayAPI } from '../../pizzaWayAPI';
import { IBadge } from '../repository/IBadge';

const badgeAPI = pizzaWayAPI.injectEndpoints({
    endpoints: (builder) => ({
        getBadge: builder.query<IBadge, number>({
            async queryFn(id, { dispatch }) {
                try {
                    const { data, isError, message }: any = await requester.get('/api/badge/admin/one/' + id);
                    if (isError) {
                        toast.error(message);
                        throw new Error(message);
                    };
                    return { data };
                } catch (e) {
                    throw e;
                }
            },
        }),
    }),
    overrideExisting: false,
});

export const { useGetBadgeQuery } = badgeAPI;