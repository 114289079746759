import { Login } from "../modules/auth/login/ui";
import { FC } from "react"
import { Navigate, Route, Routes } from "react-router-dom";
import { ForgotPassword } from "../modules/auth/forgotPassword/ui";
import { paths } from "../paths";
import { UserForm } from "../modules/auth/userForm/ui";

export const PublicRoutes: FC = () => {

    return (
        <Routes>
            <Route path='/' element={<Navigate to={paths.dashboard.auth.login} />} />
            <Route path={paths.dashboard.auth.login} element={<Login />} />
            <Route path={paths.dashboard.auth.forgotPassword} element={<ForgotPassword />} />
            <Route path={paths.dashboard.auth.userForm} element={<UserForm />} />
        </Routes>
    )
};