import { Button, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { getStyles } from "./styles";
import { useRoleEdit } from "../presenters/useRoleEdit";
import { IPermission } from "../../../../store/permission/types/IPermission";

export const RoleEdit: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { form, formErrors, isClick, selectedRole, permissionList, isRoleDeleted, onChangeRoleName, onSelectPermission, onEditRole, onCancel } = useRoleEdit();
    const { name } = form;
    const { roleNameError } = formErrors;

    return (
        <Stack m={2}>
            <Stack marginBottom={2} direction='row' justifyContent='space-between'>
                <Typography variant="h4" fontWeight={600}>{t(tokens.roles.title.editRole)}</Typography>
            </Stack>
            <Stack width={'50%'} >
                <Stack mt={4}>
                    <Typography variant="h6" mb={1} color={roleNameError ? 'error' : 'inherit'} >{t(tokens.roles.name)}*</Typography>
                    <TextField
                        type="text"
                        label={t(tokens.roles.name) + '*'}
                        value={name}
                        onChange={onChangeRoleName}
                        autoComplete="off"
                        disabled={isRoleDeleted}
                    />
                </Stack>
                <Stack mt={4}>
                    <Typography variant="h6" mb={1}  >{t(tokens.users.permissions)}</Typography>
                    <FormControl>
                        <InputLabel id="demo-simple-select-permission">{t(tokens.users.permissions)}</InputLabel>
                        <Select
                            fullWidth
                            onChange={onSelectPermission}
                            value={selectedRole?.permissions || []}
                            multiple
                            renderValue={(selected) => selected.map(value => value.name).join(', ')}
                            id="demo-simple-select-permission"
                            label={t(tokens.users.permissions)}
                            disabled={isRoleDeleted}
                        >
                            {permissionList?.map((permission: IPermission, index) => {
                                const isSelected = selectedRole?.permissions?.find(rolePermission => rolePermission.id === permission.id) ? true : false;
                                return (
                                    <MenuItem value={permission.id} key={permission.id}>
                                        <Checkbox checked={isSelected} />
                                        <ListItemText primary={permission.name} />
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Stack>
            </Stack>
            {!isRoleDeleted &&
                <Stack direction='row' mt={3} spacing={3}>
                    <Button
                        variant="contained"
                        sx={styles.createButton}
                        onClick={onEditRole}
                        disabled={isClick}
                    >
                        {t(tokens.common.save)}
                    </Button>
                    <Button
                        variant="outlined"
                        sx={styles.cancelButton}
                        onClick={onCancel}
                    >
                        {t(tokens.common.cancel)}
                    </Button>
                </Stack>
            }
        </Stack >
    )
};