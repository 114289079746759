import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';
import { IAppPage } from '../types/IAppPage';

interface IState {
    isAppPageLoading: boolean;
    appPageList: IAppPage[];
    selectedAppPage: IAppPage | null;
    appPageCount: number;
    appPageError: IError;
};

const initialState: IState = {
    isAppPageLoading: false,
    appPageList: [],
    selectedAppPage: null,
    appPageCount: 0,
    appPageError: null,
};

const AppPageSlice = createSlice({
    name: 'appPage',
    initialState,
    reducers: {
        setAppPageLoading: (state, action: PayloadAction<boolean>) => {
            state.isAppPageLoading = action.payload;
        },
        setAppPage: (state, action: PayloadAction<{ rows: IAppPage[], count: number }>) => {
            state.appPageList = action.payload.rows;
            state.appPageCount = action.payload.count;
        },
        setSelectedAppPage: (state, action: PayloadAction<IAppPage | null>) => {
            state.selectedAppPage = action.payload;
        },
        setAppPageError: (state, action: PayloadAction<IError>) => {
            state.appPageError = action.payload;
        },
    },
});

export const { setAppPageLoading, setAppPage, setSelectedAppPage, setAppPageError } = AppPageSlice.actions;
export const AppPageReducer = AppPageSlice.reducer;