import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';

interface IState {
    isLoading: boolean;
    file: any;
    getFileError: IError;
    deleteFileError: IError;
};

const initialState: IState = {
    isLoading: false,
    file: null,
    getFileError: null,
    deleteFileError: null,
};

const FileSlice = createSlice({
    name: 'file',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setFile: (state, action: PayloadAction<any>) => {
            state.file = action.payload;
        },
        setGetFileError: (state, action: PayloadAction<IError>) => {
            state.getFileError = action.payload;
        },
        setDeleteFileError: (state, action: PayloadAction<IError>) => {
            state.deleteFileError = action.payload;
        }
    },
});

export const { setLoading, setFile, setGetFileError, setDeleteFileError } = FileSlice.actions;
export const FileReducer = FileSlice.reducer;