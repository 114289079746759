import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setUsers, setLoading, setCreateRoleError } from "../../repository/slice";

function* setUsersRoleWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.post, "/api/user-role/create", payload);
        if (isError) {
            yield put(setCreateRoleError(message));
        } else {
            yield put(setCreateRoleError(null));
        }
    } catch (error) {
        console.error("setUsersRoleWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* setUsersRoleWatcher() {
    yield takeLatest("@saga/SET_USERS_ROLE", setUsersRoleWorker);
};
