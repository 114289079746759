import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setDeleteCourseError, setLoading } from "../../repository/slice";

function* deleteCourseWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.delete, "/api/admin/courses/" + payload.id);
        if (isError) {
            yield put(setDeleteCourseError(message));
        } else {
            yield put(setDeleteCourseError(null));
        }
    } catch (error) {
        console.error("deleteCourseWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* deleteCourseWatcher() {
    yield takeEvery("@saga/DELETE_COURSE", deleteCourseWorker);
};
