export const getStyles = (lgDown: boolean) => {
    const styles = {
        buttonCreate: {
            borderRadius: '10px',
            color: '#FFFFFF',
            fontWeight: 600,
            backgroundColor: '#F1594E',
            textTransform: 'none',
            '&:hover': {
                backgroundColor: '#d85046'
            }
        },
        tableContainer: {
            height: "calc(100dvh - 75px - 16px - 42px - 80px - 32px - 16px)"
        },
        emptyStateWrapper: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }, 
        cell: {
            py: 1
        },
        paginationWrapper: {
            position: 'fixed',
            bottom: 25,
            left: lgDown? 0 : '150px',
            right: 0,
            width: '100%',
            zIndex: 1000,
            height: '50px'
        }, 
        tableWrapper: {
            height: "calc(100vh - 200px - 40px)",
            position: 'relative',
            overflowY: "scroll",
            width: "100%"
        },
    };
    return styles;
};