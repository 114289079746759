import { FC } from "react";
import { useSingleTestAnalytics } from "../presenter/useSingleTestAnalytics";
import { SingleTestChart } from "./components/singleTestChart";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";

export const SingleTestAnalytics: FC = () => {
    const { t } = useTranslation();
    const { currentTest, changeTest, changeTestText, testList, labels, chartSeries } = useSingleTestAnalytics();

    return (
        <Stack m={2}>
            <Typography variant="h4" fontWeight={600}>
                Результат тесту
            </Typography>
            <Stack justifyContent="center" alignItems="center">
                <SingleTestChart
                    chartSeries={chartSeries}
                    labels={labels}
                    currentTest={currentTest}
                    testList={testList}
                    changeTest={changeTest}
                    changeTestText={changeTestText}
                />
            </Stack>
        </Stack>
    )
};