import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading } from "../../repository/slice";
import toast from "react-hot-toast";
import { getTraineePositions } from "../getTraineePositions/action";

function* deleteTraineePositionWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { isError }: IResponse<any> = yield call(requester.delete, '/api/intern/' + payload.id);
        if (isError) {
        } else {
            toast.success("Посада видалена");
            yield put(getTraineePositions());
        }
    } catch (error) {
        console.error("deleteTraineePositionWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* deleteTraineePositionWatcher() {
    yield takeEvery("@saga/DELETE_TRAINEE_POSITION", deleteTraineePositionWorker);
};
