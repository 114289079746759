import { CircularProgress, Stack, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UserSearch } from "./components/userSearch";
import { useUserList } from "../presenters/useUserList";
import { UserTree } from "./components/userTree";
import { tokens } from "../../../../locales/tokens";
import { UserTable } from "./components/userTable";
import { EmptyPage } from "../../../content/category/categoryEmpty/ui";
import { getStyles } from "./styles";

export const UserList: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { searchTextRef, handleChangeSearch, handleSearchTextChange, orgStructure, handleStatus, handleSortBy, filters, userList, isProccess } = useUserList();

    return (
        <Stack m={2}>
            <Typography variant="h5" fontWeight={600}>{t(tokens.users.handleUsers)}</Typography>
            <UserSearch
                sortBy={filters.sortBy}
                searchTextRef={searchTextRef}
                handleSortBy={handleSortBy}
                handleChangeSearch={handleChangeSearch}
                handleSearchTextChange={handleSearchTextChange}
            />
            {isProccess ? (
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={styles.progress}
                >
                    <CircularProgress color="primary" />
                </Stack>
            ) : (
                <>
                    {!!orgStructure?.length &&
                        <UserTree
                            orgStructure={orgStructure}
                            handleStatus={handleStatus}
                        />
                    }
                    {!!userList?.length &&
                        <UserTable
                            userList={userList}
                            handleStatus={handleStatus}
                        />
                    }
                    {(!userList?.length && !orgStructure?.length) &&
                        <Stack mt={5}>
                            <EmptyPage
                                title={t(tokens.users.emptyList)}
                                description={t(tokens.users.emptyListDesc)}
                            />
                        </Stack>
                    }
                </>
            )}
        </Stack>
    )
};