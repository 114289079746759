import { Stack, TextField, Typography } from "@mui/material";
import { ChangeEvent, FC } from "react";

interface IProps {
    isDisabled?: boolean;
    title: string;
    label: string;
    isValid: boolean;
    value: string;
    isMultiline: boolean;
    onChangeValue: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const TextInput: FC<IProps> = ({ isValid, value, onChangeValue, label, title, isMultiline, isDisabled }) => {

    return (
        <Stack width="100%">
            <Typography variant="h6" mb={1} color={isValid ? 'inherit' : 'error'}>
                {title}
            </Typography>
            <TextField
                fullWidth
                value={value}
                onChange={onChangeValue}
                label={label}
                autoComplete="off"
                multiline={isMultiline}
                rows={isMultiline ? 6 : 1}
                disabled={isDisabled}
            />
        </Stack>
    )
};