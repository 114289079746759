import { Button, Checkbox, FormControlLabel, Stack, TextField, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { getStyles } from "./styles";
import { useAppPageCreate } from "../presenters/useAppPageCreate";
import { ImagePicker } from "../../../../ui-kit/imagePicker";
import TextEditor from "../../../../ui-kit/textEditor/textEditor";

export const AppPageCreate: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { form, formErrors, isClick, editorState, iconSrc, onChangeName, onUploadImage, onDeleteImage, onChangeDescription, onTogglePublish, onCreate, onCancel } = useAppPageCreate();
    const { name, isHidden } = form;
    const { nameError, iconError, descriptionError } = formErrors;

    return (
        <Stack m={2}>
            <Stack marginBottom={2} direction='row' justifyContent='space-between'>
                <Typography variant="h4" fontWeight={600}>{t(tokens.appPage.title.create)}</Typography>
            </Stack>
            <Stack width={'50%'} >
                <ImagePicker
                    title={t(tokens.appPage.form.images)}
                    imageSrc={iconSrc}
                    isError={iconError}
                    accept={'.svg'}
                    onUploadImage={onUploadImage}
                    onDeleteImage={onDeleteImage}
                />
                <Stack mt={4}>
                    <Typography variant="h6" mb={1} color={nameError ? 'error' : 'inherit'} >{t(tokens.appPage.form.name)}*</Typography>
                    <TextField
                        type="text"
                        label={t(tokens.appPage.form.name) + '*'}
                        value={name}
                        onChange={onChangeName}
                        autoComplete="off"
                    />
                </Stack>
            </Stack>
            <Stack mt={2}>
                <Typography variant="h6" mb={1} color={descriptionError ? 'error' : 'inherit'}>
                    {t(tokens.appPage.form.description)}*
                </Typography>
                <TextEditor
                    editorState={editorState}
                    onEditorStateChange={onChangeDescription}
                    isToggleTable={true}
                />
            </Stack>
            <Stack mt={2}>
                <div>
                    <FormControlLabel control={<Checkbox checked={!isHidden} onChange={onTogglePublish} />} label={t(tokens.documents.publish)} />
                </div>
            </Stack>
            <Stack direction='row' mt={3} spacing={3}>
                <Button
                    variant="contained"
                    sx={styles.createButton}
                    onClick={onCreate}
                    disabled={isClick}
                >
                    {t(tokens.common.create)}
                </Button>
                <Button
                    variant="outlined"
                    sx={styles.cancelButton}
                    onClick={onCancel}
                >
                    {t(tokens.common.cancel)}
                </Button>
            </Stack>
        </Stack >
    )
};