export const styles = {
    mainBox: {
        flexGrow: 1, 
        pb: 4,
        pt: 2
    },
    data: { 
        display: 'flex', 
        alignItems: 'stretch' 
    },
    createButton: { 
        borderRadius: '10px', 
        color: '#FFFFFF',
        fontWeight: 600,
        backgroundColor: '#F1594E',
        textTransform: 'none',
        '&:hover':{
            backgroundColor: '#d85046'
        } 
    },
    cancelButton: {
        borderRadius: '10px', 
        color: '#F1594E',
        fontWeight: 600,
        borderColor: '#F1594E',
        textTransform: 'none',
        '&:hover':{
            borderColor: '#d85046'
        } 
    },
};