import { ChangeEvent, FC, useMemo } from 'react';
import { Box, Card, Stack, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../../locales/tokens';
import { AudienceSelector } from '../audienceSelector';
import { AudienceValueInput } from '../audienceValueInput';
import { SearchUserList } from '../searchUserList';
import { IRole } from '../../../../../../store/role/types/IRole';
import { styles } from './styles';
import { getCountries, getStates } from 'country-state-picker';
import { TextAutocomplete } from '../../../../../../ui-kit/textAutocomplete';
import { UserAutocomplete } from '../../../../../../ui-kit/userAutocomplete';

interface IProps {
    isDisabled?: boolean;
    audience: string;
    onChangAudience: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    country: string;
    onChangeCountry: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    region: string;
    onChangeRegion: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    userStatus: string;
    onChangeUserStatus: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    users: any[] | null;
    roles: IRole[] | null;
    onSearch: (value: any) => void;
    handleUserChange: (value: any) => void;
    onSearchUsers: (event: ChangeEvent<HTMLInputElement>) => void;
    groupUsers?: any[];
    onUserChange: (value: any) => void;
    user: any;
}

export const Audience: FC<IProps> = ({ audience, onChangAudience, country, onChangeCountry, isDisabled, onUserChange, user,
    userStatus, onChangeUserStatus, users, onSearch, onSearchUsers, groupUsers, region, onChangeRegion, roles, handleUserChange }) => {
    const { t } = useTranslation();

    const countryOptions = useMemo(() => {
        return getCountries().map((option: any) => ({
            label: option['name'],
            value: option['name'],
        }));
    }, []);

    const regionOptions = useMemo(() => {
        if (country !== '') {
            const currentCountry: any = getCountries().find((option: any) => option['name'] === country);
            const regions = getStates(currentCountry['code']).map((region: any) => {
                const value = region;
                const label = region;
                return { value, label };
            });
            return regions;
        }
        return [];
    }, [country]);

    const roleOptions = useMemo(() => {
        if (roles) {
            return roles?.map((role) => ({ label: role.name, value: role.name }));
        }
        return [];
    }, [roles]);

    const audienceItems: { label: string, value: string }[] = [
        { label: t(tokens.notification.all), value: 'All' },
        { label: t(tokens.notification.user), value: 'UserID' },
        // { label: t(tokens.notification.region), value: 'City' },
        { label: t(tokens.notification.role), value: 'Role' },
        { label: t(tokens.notification.group), value: 'Group' },
    ];

    return (
        <Stack sx={styles.container} >
            <Typography variant='h5' fontWeight={600} mb={4} >{t(tokens.notification.audience)}</Typography>
            <Box sx={styles.box} >
                <Stack width="200px">
                    <AudienceSelector
                        label={t(tokens.notification.audience)}
                        id='Audience'
                        value={audience}
                        onChange={onChangAudience}
                        values={audienceItems}
                        isDisabled={isDisabled}
                    />
                </Stack>
                {audience === 'UserID'
                    &&
                    <Stack width="200px">
                        <UserAutocomplete
                            title={t(tokens.notification.user)}
                            value={user}
                            handleChangeValue={onUserChange}
                            optionList={users as any[]}
                            isMultiline={false}
                            changeText={onSearchUsers}
                            isDisabled={isDisabled}
                        />
                    </Stack>
                }
                {audience === 'City'
                    && <TextField onChange={onChangeRegion} value={region} sx={{ width: 300 }} label={t(tokens.notification.region)} disabled={isDisabled} />
                }
                {
                    audience === 'Role'
                    &&
                    <Stack width="200px">
                        <AudienceSelector
                            label={t(tokens.notification.role)}
                            id='AudienceStatus'
                            value={userStatus}
                            onChange={onChangeUserStatus}
                            values={roleOptions}
                            isDisabled={isDisabled}
                        />
                    </Stack>
                }
                {
                    audience === 'Group'
                    && <SearchUserList
                        onSearch={onSearch}
                        users={users}
                        onSearchUsers={onSearchUsers}
                        groupUsers={groupUsers}
                        handleUserChange={handleUserChange}
                        isDisabled={isDisabled}
                    />
                }
            </Box >
        </Stack >
    );
};