import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';

interface IState {
    isLoading: boolean;
    assignList: any[];
    assignCount: number;
    assignDeleteError: IError;
    getAssignListError: IError;
};

const initialState: IState = {
    isLoading: false,
    assignList: [],
    assignCount: 0,
    assignDeleteError: null,
    getAssignListError: null,
};

const AssignSlice = createSlice({
    name: 'assign',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setAssignList: (state, action: PayloadAction<{rows: any[], count: number}>) => {
            state.assignList = action.payload.rows;
            state.assignCount = action.payload.count;
        },
        setAssignDeleteError: (state, action: PayloadAction<IError>) => {
            state.assignDeleteError = action.payload;
        },
        setGetAssignListError: (state, action: PayloadAction<IError>) => {
            state.getAssignListError = action.payload;
        },
    },
});

export const { setLoading, setAssignList, setAssignDeleteError, setGetAssignListError } = AssignSlice.actions;
export const AssignReducer = AssignSlice.reducer;