import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';
import { IPosition } from './IPosition';

interface IState {
    isLoading: boolean;
    userList: any[];
    singleUser: any;
    usersCount: number;
    createRoleError: IError;
    deleteRoleError: IError;
    updateUserError: IError;
    getUsersError: IError;
    positionsList: IPosition[];
};

const initialState: IState = {
    isLoading: false,
    userList: [],
    singleUser: null,
    usersCount: 0,
    createRoleError: null,
    deleteRoleError: null,
    updateUserError: null,
    getUsersError: null,
    positionsList: []
};

const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setUsers: (state, action: PayloadAction<any>) => {
            state.userList = action.payload.users;
            state.usersCount = action.payload.count;
        },
        setSingleUser: (state, action: PayloadAction<any>) => {
            state.singleUser = action.payload;
        },
        setCreateRoleError: (state, action: PayloadAction<IError>) => {
            state.createRoleError = action.payload;
        },
        setUpdateUserError: (state, action: PayloadAction<IError>) => {
            state.updateUserError = action.payload;
        },
        setGetUsersError: (state, action: PayloadAction<IError>) => {
            state.getUsersError = action.payload;
        },
        setDeleteRoleError: (state, action: PayloadAction<IError>) => {
            state.deleteRoleError = action.payload;
        },
        setPositions: (state, action: PayloadAction<IPosition[]>) => {
            state.positionsList = action.payload;
        },
    },
});

export const { setLoading, setUsers, setSingleUser, setCreateRoleError, setDeleteRoleError, setUpdateUserError, setGetUsersError, setPositions } = UserSlice.actions;
export const UserReducer = UserSlice.reducer;