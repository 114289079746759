interface IPayload {
    name: string;
    description: string;
    icon: File;
    isHidden?: boolean;
}

export const createAppPage = (payload: IPayload) => ({
    type: "@saga/CREATE_APP_PAGE",
    payload
});