export const getStyles = () => {
    const styles = {
        answer: {
            backgroundColor: 'white', 
            mt: 2,
            width: '100%',
            px: 2,
            borderRadius: '15px'
        },
        textField: {
            wordBreak: 'break-all'
        }
    };
    return styles;
};