import { CircularProgress, Stack, Typography } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";
import { FileInput } from "../../../../documentsCreate/ui/components/fileInput";
import { AddedFiles } from "../addedFiles";
import { FilesFromBack } from "../filesFromBack";
import { tokens } from "../../../../../../../locales/tokens";

interface IProps {
    isDisabled?: boolean;
    fileLoading?: boolean;
    files: File[];
    fileValid: boolean;
    type: string;
    handleToggleImageFile: () => void;
    handleToggleVideoFile: () => void;
    handleDeleteImage: (name: string) => void;
    handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const FileSelector: FC<IProps> = ({ fileValid, files, handleDeleteImage, handleFileChange, type, handleToggleImageFile, handleToggleVideoFile, isDisabled, fileLoading }) => {
    const { t } = useTranslation();

    return (
        <Stack>
            <Typography variant="h6" color={fileValid ? 'inherit' : 'error'}>
                {type === 'File' ? t(tokens.documents.image) : t(tokens.documents.video)}*
            </Typography>
            <Typography variant="caption" color='gray'>
                Файл можна оновити, видалення неможливе, оскільки файл є обов’язковим
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
                <FileInput
                    isDisabled={isDisabled || fileLoading}
                    type={type}
                    handleFileChange={handleFileChange}
                />
                {!fileLoading && files?.length > 0 &&
                    <Stack direction="row">
                        {files.map((file: any, index: number) =>
                            "type" in file ?
                                <AddedFiles
                                    type={type}
                                    file={file}
                                    key={index}
                                    handleDeleteImage={handleDeleteImage}
                                    handleToggleImageFile={handleToggleImageFile}
                                    handleToggleVideoFile={handleToggleVideoFile}
                                />
                                :
                                <FilesFromBack
                                    isDisabled={isDisabled || fileLoading}
                                    type={type}
                                    file={file}
                                    key={index}
                                    handleDeleteImage={handleDeleteImage}
                                    handleToggleImageFile={handleToggleImageFile}
                                    handleToggleVideoFile={handleToggleVideoFile}
                                />
                        )}
                    </Stack>
                }
                {fileLoading &&
                    <Stack direction="row" spacing={2} alignItems="center" height={112} pl={2}>
                        <CircularProgress color="primary" />
                        <Typography color="primary" variant="body1">
                            {t(tokens.documents.await)}
                        </Typography>
                    </Stack>}
            </Stack>
        </Stack>
    )
};