import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";

interface IProps {
    title: string;
    value: any;
};

export const TextData: FC<IProps> = ({ title, value }) => {
    const { t } = useTranslation();

    return (
        <Stack direction="row" spacing={1}>
            <Typography variant="body1" fontWeight={600} whiteSpace="nowrap">{title}:</Typography>
            <Typography variant="body1" whiteSpace="nowrap">{value ? value : t(tokens.common.noData)}</Typography>
        </Stack>
    )
};