import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';
import { IPermission } from '../types/IPermission';

interface IState {
    isPermissionLoading: boolean;
    permissionList: IPermission[];
    permissionCount: number;
    permissionError: IError;
};

const initialState: IState = {
    isPermissionLoading: false,
    permissionList: [],
    permissionCount: 0,
    permissionError: null,
};

const PermissionSlice = createSlice({
    name: 'permission',
    initialState,
    reducers: {
        setPermissionLoading: (state, action: PayloadAction<boolean>) => {
            state.isPermissionLoading = action.payload;
        },
        setPermissions: (state, action: PayloadAction<{ rows: IPermission[], count: number }>) => {
            state.permissionList = action.payload.rows;
            state.permissionCount = action.payload.count;
        },
        setPermissionError: (state, action: PayloadAction<IError>) => {
            state.permissionError = action.payload;
        },
    },
});

export const { setPermissionLoading, setPermissions, setPermissionError } = PermissionSlice.actions;
export const PermissionReducer = PermissionSlice.reducer;