export const getStyles = () => {
    const styles = {
        option: {
            wordBreak: 'break-all',
            maxWidth: '200px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'initial',
        }
    };
    return styles;
};