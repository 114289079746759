import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setProductLoading, setProductList, setProductError } from "../../repository/slice";
import { IProduct } from "../../types/IProduct";

export const PRODUCTS_PER_PAGE = 30;

function* getProductsWorker({ payload }: any): SagaIterator {
    try {
        yield put(setProductLoading(true));
        const body = { ...payload, perPage: PRODUCTS_PER_PAGE };
        const { data, message, isError }: IResponse<{ count: number, rows: IProduct[] }> = yield call(requester.get, "/api/shop/product/admin/list", body);
        if (isError) {
            yield put(setProductError(message));
        } else {
            yield put(setProductList(data));
        }
    } catch (error) {
        console.error("getProductsWorker: ", error);
    } finally {
        yield put(setProductLoading(false));
    };
};

export function* getProductsWatcher() {
    yield takeLatest("@saga/GET_PRODUCTS", getProductsWorker);
};