import { TableBody } from "@mui/material";
import { FC } from "react";
import { CourseTableItem } from "../courseTableItem";

interface IProps {
    courseList: any[];
    handleStatus: (id: number, status: string) => void;
    handleDelete: (id: number) => void;
    handleUpload: (id: number, name: string) => void;
};

export const CourseTableBody: FC<IProps> = ({ courseList, handleDelete, handleStatus, handleUpload }) => {

    return (
        <TableBody>
            {courseList.map(course => (
                <CourseTableItem
                    course={course}
                    key={course.id}
                    handleStatus={handleStatus}
                    handleDelete={handleDelete}
                    handleUpload={handleUpload}
                />
            ))}
        </TableBody>
    )
};