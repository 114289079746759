import { Box, IconButton, SvgIcon, Tooltip } from "@mui/material"
import { CSSProperties, FC, useMemo } from "react"
import { getStyles } from "./styles";
import ClearIcon from '@mui/icons-material/Clear';

interface IProps {
    isDisabled?: boolean;
    file: any;
    handleToggleImageFile: () => void;
    handleDeleteImage: (name: string) => void;
};

export const FileImageBack: FC<IProps> = ({ file, handleDeleteImage, handleToggleImageFile, isDisabled }) => {
    const styles = useMemo(() => getStyles(), []);

    return (
        <Box position="relative" key={file.size}>
            <Tooltip title="Показати зображення" arrow followCursor>
                <img
                    src={file.url}
                    onClick={handleToggleImageFile}
                    alt="image"
                    width={83}
                    height={83}
                    style={styles.image as CSSProperties}
                />
            </Tooltip>
            {!isDisabled &&
            <IconButton size="small" onClick={() => handleDeleteImage(file.filename)} sx={styles.toolTip}>
                <SvgIcon fontSize="small" sx={{ fontSize: '14px' }}>
                    <ClearIcon />
                </SvgIcon>
            </IconButton>} 
        </Box>
    )
};