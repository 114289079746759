import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGeneralQuestionList } from "../presenter/useGeneralQuestionList";
import { CircularProgress, Pagination, Stack, Typography } from "@mui/material";
import { EmptyPage } from "../../../content/category/categoryEmpty/ui";
import { tokens } from "../../../../locales/tokens";
import { SearchInput } from "../../../../ui-kit/searchInput/searchInput";
import { FeedbackFilters } from "./components/feedbackFilters";
import { QuestionList } from "./components/questionList";
import { getStyles } from "./styles";

export const GeneralQuestionList: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { searchText, handleSearchUserText, filters, resetFilters, changeStatus, handleDateChange, generalQuestionList, changeUserText, onUserChange, userList,
        changeQuestionStatus, statusOptions, pageAmount, handlePageChange, isProccessQuestion, scrollRef
    } = useGeneralQuestionList();

    return (
        <Stack m={2}>
            <Typography variant="h5" fontWeight={600}>
                {t(tokens.feedback.generalQuestionList)}
            </Typography>
            <Stack mt={3}>
                <SearchInput
                    searchText={searchText}
                    label={t(tokens.feedback.searchByName)}
                    handleSearch={handleSearchUserText}
                    size="small"
                />
            </Stack>
            <FeedbackFilters
                filters={filters}
                statusOptions={statusOptions}
                resetFilters={resetFilters}
                changeStatus={changeStatus}
                handleDateChange={handleDateChange}
            />
            {generalQuestionList?.length > 0 &&
                <Stack mt={1} ref={scrollRef}>
                    <QuestionList
                        questionList={generalQuestionList}
                        users={userList}
                        statusOptions={statusOptions}
                        onUserChange={onUserChange}
                        changeUserText={changeUserText}
                        changeQuestionStatus={changeQuestionStatus}
                    />
                    <Stack direction='row' justifyContent='center' my={4}>
                        <Pagination
                            page={filters.page}
                            count={pageAmount || 1}
                            color="primary"
                            onChange={handlePageChange}
                        />
                    </Stack>
                </Stack>}
            {(!generalQuestionList?.length && isProccessQuestion) &&
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={styles.progress}
                >
                    <CircularProgress color="primary" />
                </Stack>
            }
            {(!generalQuestionList?.length && !isProccessQuestion) &&
                <Stack pt={2}>
                    <EmptyPage
                        title={t(tokens.feedback.emptyList)}
                        description={t(tokens.feedback.generalQuestion.emptyListDesc)}
                    />
                </Stack>
            }
        </Stack>
    )
};