import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setProductLoading, setProductList, setProductError } from "../../repository/slice";
import { selectProduct } from "../../repository/selector";
import { IProduct } from "../../types/IProduct";

function* deleteProductWorker({ payload }: any): SagaIterator {
    try {
        yield put(setProductLoading(true));
        const { productList, productCount } = yield select(selectProduct);
        const deletedAppPages = productList.filter((product: IProduct) => product.id !== payload.id)
        const count = productCount ? productCount - 1 : 0;
        yield put(setProductList({ count, rows: deletedAppPages }));
        const { data, message, isError }: IResponse<{ count: number, rows: IProduct[] }> = yield call(requester.delete, "/api/shop/product/admin/delete/" + payload.id);
        if (isError) {
            yield put(setProductError(message));
        }
    } catch (error) {
        console.error("deleteProductWorker: ", error);
    } finally {
        yield put(setProductLoading(false));
    };
};

export function* deleteProductWatcher() {
    yield takeLatest("@saga/DELETE_PRODUCT", deleteProductWorker);
};