import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setSignInError } from "../../repository/slice";

function* SignInWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.post, "/api/auth/admin/login", payload);
        if (isError) {
            yield put(setSignInError(message));
        } else {
            yield put(setSignInError(null));
            const user = JSON.stringify(data.user);
            yield call([localStorage, localStorage.setItem], "user", user);
            yield call([localStorage, localStorage.setItem], "accessToken", data.accessToken);
            yield call([localStorage, localStorage.setItem], "refreshToken", data.refreshToken);
        }
    } catch (error) {
        console.error("SignInWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* SignInWatcher() {
    yield takeLatest("@saga/SIGN_IN", SignInWorker);
};
