import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setCreateCategoryError, setLoading } from "../../repository/slice";
import { addFileToCategory } from "../addFileToCategory/action";
import i18n from "../../../../locales/i18n";
import { tokens } from "../../../../locales/tokens";
import toast from "react-hot-toast";

function* createCategoryWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.post, "/api/admin/category/create", payload.body);
        if (isError) {
            yield put(setCreateCategoryError(message));
        } else {
            yield put(setCreateCategoryError(null));
            if (payload.image) {
                yield put(addFileToCategory({ id: data.id, image: payload.image }));
            }
        }
    } catch (error) {
        console.error("createCategoryWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* createCategoryWatcher() {
    yield takeLatest("@saga/CREATE_CATEGORY", createCategoryWorker);
};
