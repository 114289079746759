import { FC } from 'react';
import { Box, Button, ButtonGroup } from '@mui/material';
import { paths } from '../../../../../../paths';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../../locales/tokens';
import { styles } from './styles';
import { Link } from 'react-router-dom';

interface IProps {
    id: number;
    isDisabled: boolean;
    onSend: (id: number) => void;
    onDelete: (id: number) => void;
}

export const NotificationButtons: FC<IProps> = ({ id, onSend, onDelete, isDisabled }) => {
    const { t } = useTranslation();

    return (
        <Box sx={styles.box}>
            <ButtonGroup variant="contained" size='large' sx={{borderRadius: '10px', color: '#ffffff'}} disabled={isDisabled}>
                <Button onClick={()=>onSend(id)} sx={styles.btnLeft}>{t(tokens.notification.send)}</Button>
                <Button component={Link} to={paths.dashboard.notification.edit.replace(':id', String(id))} sx={styles.btnEdit}>{t(tokens.common.edit)}</Button>
                <Button onClick={()=>onDelete(id)} sx={styles.btnRight}>{t(tokens.common.delete)}</Button>
            </ButtonGroup>
        </Box>
    );
};
