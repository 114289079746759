import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';
import { IQuestion } from './IQuestion';

interface IState {
    isLoading: boolean;
    generalQuestionList: IQuestion[];
    generalQuestionCount: number;
    updateQuestionError: IError;
    createQuestionError: IError;
    getQuestionsError: IError;
};

const initialState: IState = {
    isLoading: false,
    generalQuestionList: [],
    generalQuestionCount: 0,
    updateQuestionError: null,
    createQuestionError: null,
    getQuestionsError: null,
};

const AnonymousComplaintSlice = createSlice({
    name: 'anonymousComplaint',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setGeneralQuestionList: (state, action: PayloadAction<{list: IQuestion[], count: number}>) => {
            state.generalQuestionList = action.payload.list;
            state.generalQuestionCount = action.payload.count;
        },
        setUpdateQuestionError: (state, action: PayloadAction<IError>) => {
            state.updateQuestionError = action.payload;
        },
        setCreateQuestionError: (state, action: PayloadAction<IError>) => {
            state.createQuestionError = action.payload;
        },
        setgetQuestionsError: (state, action: PayloadAction<IError>) => {
            state.getQuestionsError = action.payload;
        }
    },
});

export const { setLoading, setCreateQuestionError, setGeneralQuestionList, setUpdateQuestionError, setgetQuestionsError } = AnonymousComplaintSlice.actions;
export const AnonymousComplaintReducer = AnonymousComplaintSlice.reducer;