interface IPayload {
    name: string;
    price: number;
    quantity: number;
    is_hidden: boolean;
    files: File[];
    description?: string;
}

export const createProduct = (payload: IPayload) => ({
    type: "@saga/CREATE_PRODUCT",
    payload
});