import { Dispatch } from "react";
import { useDispatch } from "react-redux";
import { updateCategory } from "../../../../../store/category/useCases/updateCategory/action";

export const useDragDrop = (node: any, capturedCategory: any, setCapturedCategory: Dispatch<any>, setUpdateClick: Dispatch<boolean> ) => {
    const dispatch = useDispatch();

    const dragStartHandler = (event: React.DragEvent<HTMLDivElement>) => {
        event.stopPropagation();
        if (!capturedCategory) {
            setCapturedCategory(node);
        }
    };

    const dragLeaveHandler = (_e: React.DragEvent<HTMLDivElement>) => {
        
    };

    const dragEndHandler = (_e: React.DragEvent<HTMLDivElement>) => {

    };

    const dragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const dragDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        
        if (capturedCategory && node && node.id !== capturedCategory.id) {
            dispatch(updateCategory({ id: capturedCategory.id, parentId: node.id }))
            setUpdateClick(true);
        }
        setCapturedCategory(null);
    };

    return { capturedCategory, setCapturedCategory, dragStartHandler, dragLeaveHandler, dragOverHandler, dragEndHandler, dragDropHandler }
};