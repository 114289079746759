export const getStyles = () => {
    const styles = {
        svgIcon: {
            fontSize: 26
        },
        cell: {
            py: 1
        },
        button: { 
            borderRadius: '10px', 
            color: '#FFFFFF',
            fontWeight: 600,
            backgroundColor: '#F1594E',
            textTransform: 'none',
            '&:hover':{
                backgroundColor: '#d85046'
            } 
        },
        avatar: { 
            objectFit: 'cover', 
            borderRadius: '50%' 
        }
    };
    return styles;
};