import { Dispatch, FC, SetStateAction, useMemo } from "react";
import { useCategoryCreate } from "../presenters/useCategoryCreate";
import { Button, Checkbox, Dialog, FormControlLabel, IconButton, Stack, SvgIcon, TextField, Typography } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from "react-i18next";
import { getStyles } from "./styles";
import { tokens } from "../../../../../locales/tokens";
import { ModalAutocomplete } from "../../../../../ui-kit/modalAutocomplete/ui";
import { ImagePicker } from "./components/imagePicker";

interface IProps {
    categoryId: number;
    isOpen: boolean;
    filters: any;
    handleToggle: () => void;
    updateFilters: Dispatch<SetStateAction<any>>;
};

export const CategoryCreate: FC<IProps> = ({ isOpen, handleToggle, categoryId, filters, updateFilters }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { title, onChangeTitle, handleChangeCategory, category, handleHiddenChange, closeAndClear, createCategoryError,
        handleShowChange, hiddenChecked, showChecked, imageSrc, handleFileChange, createNewCategory, titleValid, editCategory,
        handleClearImageClick, imageValid, parentCategoriesList, fileInputRef } = useCategoryCreate(handleToggle, categoryId, filters, updateFilters);

    return (
        <Dialog open={isOpen} onClose={closeAndClear} maxWidth='md' fullWidth sx={{ borderRadius: '20px' }}>
            <Stack m={3}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" fontWeight={600}>
                        {categoryId ? t(tokens.category.editCategory) : t(tokens.category.createCategory)}
                    </Typography>
                    <IconButton onClick={closeAndClear}>
                        <SvgIcon><ClearIcon /></SvgIcon>
                    </IconButton>
                </Stack>
                <Stack direction="row" spacing={2} justifyContent="space-between">
                    <Stack >
                        <Stack mt={4}>
                            <Typography color={titleValid ? 'inherit' : 'error'}>
                                {t(tokens.category.categoryTitle)}*
                            </Typography>
                            <TextField
                                type="text"
                                label={t(tokens.category.categoryTitle) + '*'}
                                value={title}
                                onChange={onChangeTitle}
                                sx={{ mt: 1 }}
                                autoComplete="off"
                                error={!titleValid}
                            />
                        </Stack>
                        <Stack mt={2}>
                            <Typography>{t(tokens.category.parentCategory)}</Typography>
                            <Stack mt={1}>
                                <ModalAutocomplete
                                    categoryId={categoryId}
                                    title={t(tokens.category.parentCategory)}
                                    value={category}
                                    handleChangeValue={handleChangeCategory}
                                    optionList={parentCategoriesList}
                                    isMultiline={false}
                                />
                            </Stack>
                        </Stack>
                        <Stack mt={2} spacing={1}>
                            <FormControlLabel
                                control={<Checkbox checked={hiddenChecked} onChange={handleHiddenChange} />}
                                label={t(tokens.category.makeCategoryHidden)}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={showChecked} onChange={handleShowChange} disabled={!!category || !!categoryId} />}
                                label={t(tokens.category.showCategory)}
                            />
                        </Stack>
                        <Stack direction='row' mt={3} spacing={3}>
                            <Button
                                variant="contained"
                                sx={styles.createButton}
                                onClick={categoryId ?
                                    editCategory
                                    :
                                    createNewCategory
                                }
                            >
                                {categoryId ?
                                    t(tokens.common.save)
                                    :
                                    t(tokens.common.create)
                                }
                            </Button>
                            <Button
                                variant="outlined"
                                sx={styles.cancelButton}
                                onClick={closeAndClear}
                            >
                                {t(tokens.common.cancel)}
                            </Button>
                        </Stack>
                    </Stack>
                    <Stack pt={4} pr={15}>
                        {(showChecked && !(category?.isKnowledgeCategory)) ?
                            <ImagePicker
                                fileInputRef={fileInputRef}
                                imageSrc={imageSrc}
                                isValid={imageValid}
                                handleFileChange={handleFileChange}
                                handleClearImageClick={handleClearImageClick}
                            />
                            :
                            null}
                    </Stack>
                </Stack>
            </Stack>
        </Dialog>
    )
};