import { Checkbox, FormControlLabel } from "@mui/material";
import { ChangeEvent, FC } from "react";

interface IProps{
    isDisabled?: boolean;
    testId?: any;
    isPublish?: boolean;
    title: string;
    value: boolean;
    onChangeValue: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const CheckBox: FC<IProps> = ({title, value, onChangeValue, testId, isPublish, isDisabled}) => {

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={value}
                    onChange={onChangeValue}
                    disabled={(!!testId && !isPublish) || isDisabled}
                />
            }
            label={title}
        />
    )
};