import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setSingleDocumentData } from "../../repository/slice";

function* getSingleDocumentDataWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.get, "/api/statistic/document/" + payload.id);
        if (isError) {

        } else {
            yield put(setSingleDocumentData(data));
        }
    } catch (error) {
        console.error("getSingleDocumentDataWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* getSingleDocumentDataWatcher() {
    yield takeLatest("@saga/GET_SINGLE_DOCUMENT_DATA", getSingleDocumentDataWorker);
};