import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setDeleteFileFromQuestionError, setLoading } from "../../repository/slice";
import { getSingleQuiz } from "../getSingleQuiz/action";

function* deleteFileFromQuizQuestionWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call( requester.delete, `/api/questionnaire-questions/${payload.questionId}/remove-image/` );
        if (isError) {
            yield put(setDeleteFileFromQuestionError(message));
        } else {
            yield put(setDeleteFileFromQuestionError(null));
            yield put(getSingleQuiz({id: Number(payload.quizId)}));
        }
    } catch (error) {
        console.error("deleteFileFromQuizQuestionWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* deleteFileFromQuizQuestionWatcher() {
    yield takeEvery("@saga/DELETE_FILE_FROM_QUIZ_QUESTION", deleteFileFromQuizQuestionWorker);
};