import { IconButton, List, Stack, SvgIcon, TextField, Typography } from "@mui/material";
import { ChangeEvent, Dispatch, FC, SetStateAction, useMemo } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import { getStyles } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { updateAnswer } from "../../../../../../../store/test/useCases/updateAnswer/action";
import { selectTest } from "../../../../../../../store/test/repository/selector";

interface IProps {
    testId: any;
    replies: any[];
    repliesValid: any[];
    setReplies: Dispatch<SetStateAction<any[]>>;
    setRepliesValid: Dispatch<SetStateAction<any[]>>;
    removeReply: (sortNumber: number) => void;
    onChangeReply: (event: ChangeEvent<HTMLInputElement>, index: number) => void;
};

export const RepliesDraggableList: FC<IProps> = ({ replies, repliesValid, onChangeReply, removeReply, setReplies, setRepliesValid, testId }) => {
    const dispatch = useDispatch();
    const styles = useMemo(() => getStyles(), []);
    const { questionId } = useSelector(selectTest);

    const reorder = (replies: any[], startIndex: number, endIndex: number) => {
        let result = Array.from(replies);
        // const currentReply = result[startIndex];
        // const destinationReply = result[endIndex];
        // dispatch(updateAnswer({ body: { id: currentReply.id, sortNumber: destinationReply.sortNumber }, testId: testId })); 
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        result = result.map((reply: any, index: number) => ({
            ...reply,
            sortNumber: index + 1
        }))
        return result;
    };

    const reorderValid = (repliesValid: any[], startIndex: number, endIndex: number) => {
        let result = Array.from(repliesValid); 
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        result = result.map((reply: any, index: number) => ({
            ...reply,
            sortNumber: index + 1
        }))
        return result;
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        };
        const items = reorder(replies, result.source.index, result.destination.index);
        setReplies(items);
        const itemsValid = reorderValid(repliesValid, result.source.index, result.destination.index);
        setRepliesValid(itemsValid);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided: any, snapshot: any) => (
                    <List ref={provided.innerRef} {...provided.droppableProps}>
                        {replies.map((reply: any, index: number) =>
                            <Draggable key={index} draggableId={`${reply.sortNumber}-id`} index={index}>
                                {(provided: any, snapshot: any) => (
                                    <Stack key={index} direction="row" alignItems="center" spacing={3} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} sx={styles.answer}>
                                        <Typography variant="h4" fontWeight={600}>{index + 1}</Typography>
                                        <TextField
                                            value={reply.title}
                                            fullWidth
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeReply(e, index)}
                                            error={!repliesValid[index].isValid}
                                        />
                                        <IconButton onClick={() => removeReply(reply.sortNumber)}>
                                            <SvgIcon><DeleteIcon /></SvgIcon>
                                        </IconButton>
                                    </Stack>
                                )}
                            </Draggable>
                        )}
                        {provided.placeholder}
                    </List>
                )}
            </Droppable>
        </DragDropContext>
    )
};