import type { FC } from 'react';
import { Box, Button, Divider, Popover, Stack, Typography } from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import { getStyles } from './styles';
import { MenuItem } from './menu-item';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../locales/tokens';
import { paths } from '../../paths';
import { useNavigate } from 'react-router-dom';

interface IProps {
  open?: boolean;
  currentUser: any;
  anchorEl: null | Element;
  onClose?: () => void;
  handleLogout: () => void;
};

export const AccountPopover: FC<IProps> = ({ anchorEl, onClose, open, handleLogout, currentUser, ...other }) => {
  const { t } = useTranslation();
  const styles = getStyles();
  const navigate = useNavigate();

  const handleProfile = () => {
    navigate(paths.dashboard.users.edit.replace(':id', currentUser?.id));
    onClose?.();
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
    >
      <Box p={2} sx={{}}>
        <Typography variant="body1" fontWeight={600}>
          {currentUser ? currentUser.first_name + " " + currentUser.last_name : t(tokens.users.name) + " " + t(tokens.users.secondName)}
        </Typography>
        <Typography color="text.secondary" variant="body2" >
          {currentUser ? "+" + currentUser.login : t(tokens.users.phone)}
        </Typography>
      </Box>
      <Divider />
      <Stack p={1} direction="row" justifyContent="flex-start">
        <Button startIcon={<PersonOutlineIcon/>} onClick={handleProfile} sx={{textTransform: 'none', color: 'inherit', width: '100%', justifyContent: 'flex-start'}}>Профіль</Button>
      </Stack>
      <Divider />
      <Button color="inherit" onClick={handleLogout} size="small" sx={styles.logout}>
        Вийти
      </Button>
    </Popover>
  );
};