import { Stack } from "@mui/material";
import { ChangeEvent, FC, RefObject } from "react";
import { QuestionCard } from "../questionCard";
import { IQuestion } from "../../../../../../store/generalQuestion/repository/IQuestion";
import { IAutocompleteItem } from "../../../presenter/types";
import { IUser } from "../../../../../../store/user/repository/IUser";

interface IProps {
    questionList: IQuestion[];
    users: IUser[];
    statusOptions: IAutocompleteItem[];
    changeQuestionStatus: (status: IAutocompleteItem, questionId: number) => void
    onUserChange: (value: any, questionId: number) => void;
    changeUserText: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const QuestionList: FC<IProps> = ({ questionList, onUserChange, users, changeUserText, changeQuestionStatus, statusOptions }) => {

    return (
        <Stack>
            {questionList.map(question => (
                <QuestionCard
                    question={question}
                    key={question.id}
                    users={users}
                    statusOptions={statusOptions}
                    changeQuestionStatus={changeQuestionStatus}
                    onUserChange={onUserChange}
                    changeUserText={changeUserText}
                />
            ))}
        </Stack>
    )
};