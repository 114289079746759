import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { selectAnalytics } from "../../../../store/analytics/repository/selector";
import { getSingleTestData } from "../../../../store/analytics/useCases/getSingleTestData/action";
import { getTestList } from "../../../../store/test/useCases/getTestList/action";
import { selectTest } from "../../../../store/test/repository/selector";
import { useDebounce } from "../../../../hooks/useDebounce";

export const useSingleTestAnalytics = () => {
    const dispatch = useDispatch();
    const { singleTestData } = useSelector(selectAnalytics);
    const [searchText, setSearchText] = useState('');
    const [currentTest, setCurrentTest] = useState<any>(null);
    const { testList } = useSelector(selectTest);

    useEffect(() => {
        dispatch(getTestList(getRequestBody(searchText)));
    }, []);

    useEffect(() => {
        currentTest && dispatch(getSingleTestData({id: currentTest.id}));
    }, [currentTest])

    const changeTestText = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        debouncedHandleTestSeacrh(e.target.value);
    };

    const onSearchTest = (value: string) => {
        const search = value ? value : '';
        if (search.length !== 1) {
            dispatch(getTestList(getRequestBody(value)));
        }
    };

    const { debouncedWrapper: debouncedHandleTestSeacrh } = useDebounce(onSearchTest, 1000);

    const changeTest = useCallback((value: any) => {
        setCurrentTest(value);
    }, [setCurrentTest]);

    const getRequestBody = (value: string) => {
        const body: any = { page: 1, perPage: 15 };
        if (value) body.search = value;
        return body;
    };

    const labels = singleTestData?.statusStatistic?.map((item: {amount: number, status: string}) => item.status) || [];
    const chartSeries = singleTestData?.statusStatistic?.map((item: {amount: number, status: string}) => item.amount) || [];

    return { testList, currentTest, changeTest, changeTestText, labels, chartSeries }
};