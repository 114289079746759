import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, updateQuestionError } from "../../repository/slice";
import { getSingleQuiz } from "../getSingleQuiz/action";

function* updateQuizQuestionWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.put, `/api/questionnaire-questions/${payload.body.id}`, payload.body);
        if (isError) {
            yield put(updateQuestionError(message));
        } else {
            yield put(updateQuestionError(null));
            if(payload?.quizId){
                yield put(getSingleQuiz({ id: Number(payload.quizId) }));
            };
        }
    } catch (error) {
        console.error("updateQuizQuestionWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* updateQuizQuestionWatcher() {
    yield takeEvery("@saga/UPDATE_QUIZ_QUESTION", updateQuizQuestionWorker);
};
