import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';
import { IOrder } from '../types/IOrder';

interface IState {
    isOrderLoading: boolean;
    orderList: IOrder[];
    orderCount: number;
    orderError: IError;
};

const initialState: IState = {
    isOrderLoading: false,
    orderList: [],
    orderCount: 0,
    orderError: null,
};

const OrderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        setOrderLoading: (state, action: PayloadAction<boolean>) => {
            state.isOrderLoading = action.payload;
        },
        setOrderList: (state, action: PayloadAction<{ rows: IOrder[], count: number }>) => {
            state.orderList = action.payload.rows;
            state.orderCount = action.payload.count;
        },
        setOrderError: (state, action: PayloadAction<IError>) => {
            state.orderError = action.payload;
        },
    },
});

export const { setOrderLoading, setOrderList, setOrderError } = OrderSlice.actions;
export const OrderReducer = OrderSlice.reducer;