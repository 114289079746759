export const getStyles = () => {
    const styles = {
        title: { textAlign: 'center', fontWeight: 600, py: 0, pt: 1 },
        button: { textTransform: 'none', height: '56px', backgroundColor: '#E3241A', color: '#fff', '&:hover': {backgroundColor: '#971811' } },
        okButton: { textTransform: 'none', backgroundColor: '#E3241A', color: '#fff', '&:hover': {backgroundColor: '#971811' } },
        buttonContainer: {mt: 3, width: '100%', px: 3},
        background: {
            width: '100%',
            height: '100%',
            position: 'fixed',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundImage: "url('/images/logo/background.png')"
        },
    };
    return styles;
};