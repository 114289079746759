import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { Button, Stack, SvgIcon, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { getStyles } from "./styles";
import { Link } from "react-router-dom";
import { paths } from "../../../../paths";
import { SearchInput } from "../../../../ui-kit/searchInput/searchInput";
import { TabsChanger } from "../../../../ui-kit/tabsChanger";
import { useAdvertList } from "../presenters/useAdvertList";
import { AdvertFilters } from "./advertFilters";
import { AdvertTable } from "./advertTable";
import { EmptyPage } from "../../../content/category/categoryEmpty/ui";

export const AdvertList: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { filters, searchText, handleTabsChange, handleSearchAdvertText, changeType, changeStatus, handlePageChange,
        advertList, orgStructure, handleDateChange, pageAmount, onSelectInitiator, onChangeInitiator, scrollRef,
        handleStatus, resetFilters, handleDelete, handleUploadReport } = useAdvertList();

    return (
        <Stack m={2}>
            <Stack>
                <Stack direction='row' justifyContent='space-between'>
                    <Typography variant="h5" fontWeight={600}>
                        {t(tokens.adverts.advertList)}
                    </Typography>
                    <Button
                        startIcon={(<SvgIcon><AddIcon /></SvgIcon>)}
                        variant="contained"
                        sx={styles.createBtn}
                        component={Link}
                        to={paths.dashboard.advert.create}
                    >
                        {t(tokens.common.create)}
                    </Button>
                </Stack>
                <Stack mt={3}>
                    <SearchInput
                        searchText={searchText}
                        label={t(tokens.adverts.messages.searchByAdvertName)}
                        handleSearch={handleSearchAdvertText}
                        size="small"
                    />
                </Stack>
                <AdvertFilters
                    filters={filters}
                    orgStructure={orgStructure}
                    changeType={changeType}
                    changeStatus={changeStatus}
                    handleDateChange={handleDateChange}
                    resetFilters={resetFilters}
                    onSelectInitiator={onSelectInitiator}
                    onChangeInitiator={onChangeInitiator}
                />
                <TabsChanger
                    tab={filters.tab}
                    handleTabsChange={handleTabsChange}
                />
            </Stack>
            {advertList.length ?
                <AdvertTable
                    scrollRef={scrollRef}
                    page={filters.page}
                    advertList={advertList}
                    handlePageChange={handlePageChange}
                    pageAmount={pageAmount}
                    handleStatus={handleStatus}
                    handleDelete={handleDelete}
                    handleUploadReport={handleUploadReport}
                />
                :
                <Stack>
                    <EmptyPage
                        title={t(tokens.adverts.emptyListTitle)}
                        description={t(tokens.adverts.emptyListDesc)}
                    />
                </Stack>
            }
        </Stack>
    )
};