
export const getStyles = () => {
    const styles = {
        drawer: {
            width: '350px',
            // position: 'relative',
            backgroundColor: 'primary.main',
            color: '#FFFFFF',
            overflow: 'auto',
            scrollbarWidth: "none",
        },
    }
    return styles;
}