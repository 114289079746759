import { Accordion, AccordionDetails, AccordionSummary, Card, Checkbox, Divider, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { CSSProperties, ChangeEvent, FC, SyntheticEvent, useCallback, useMemo } from "react";
import { TextAutocomplete } from "../../../../../../ui-kit/textAutocomplete";
import { tokens } from "../../../../../../locales/tokens";
import { IOption, collectUserOptionList } from "../../../../../../ui-kit/userAutocomplete/collectOptionList";
import { IQuestion } from "../../../../../../store/generalQuestion/repository/IQuestion";
import { IAutocompleteItem } from "../../../presenter/types";
import { IUser } from "../../../../../../store/user/repository/IUser";
import { getStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { ExpandableText } from "../../../../generalQuestionList/ui/components/expandableText";
import { UserAutocomplete } from "../userAutocomplete";

interface IProps {
    question: IQuestion;
    users: IUser[];
    statusOptions: IAutocompleteItem[];
    changeQuestionStatus: (status: IAutocompleteItem, questionId: number) => void;
    onUserChange: (value: any, questionId: number) => void;
    changeUserText: (event: ChangeEvent<HTMLInputElement>) => void;
    handleToggleImageFile: (image: any) => void;
};

export const QuestionCard: FC<IProps> = ({ onUserChange, users, changeUserText, question, changeQuestionStatus, statusOptions, handleToggleImageFile }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const formattedDate = dayjs(question.updatedAt).format('DD.MM.YYYY');
    const changeUser = useCallback((e: SyntheticEvent<Element, Event>, value: IOption) => onUserChange(value, question.id), [question.id]);
    const changeStatus = useCallback((value: any) => changeQuestionStatus(value, question.id), [question.id]);
    const user = question.responsible ? collectUserOptionList([question.responsible])[0] : null;
    const currentStatus = statusOptions.find((status: any) => status.value === question?.status);
    const status = currentStatus ? { ...currentStatus, label: currentStatus.name } : null;

    return (
        <Card sx={styles.card} elevation={3}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" p={2}>
                <Stack>
                    <Typography fontSize="15px" noWrap>
                        {question?.user?.last_name + " " + question?.user?.first_name + " " + question?.user?.patronymic}
                    </Typography>
                    <Typography fontSize="15px" color="GrayText">{formattedDate}</Typography>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center" width="50%">
                    <Stack flex={1}>
                        <TextAutocomplete
                            title={t(tokens.feedback.chooseDecision)}
                            value={status}
                            handleChangeValue={changeStatus}
                            optionList={statusOptions}
                            isMultiline={false}
                            isDisabled={false}
                        />
                    </Stack>
                    <Stack flex={1}>
                        <UserAutocomplete
                            title={t(tokens.feedback.assignResponsible)}
                            value={user}
                            handleChangeValue={changeUser}
                            optionList={users as IUser[]}
                            isMultiline={false}
                            changeText={changeUserText}
                            isDisabled={false}
                        />
                    </Stack>
                </Stack>
            </Stack>
            <Divider variant="fullWidth" sx={{ borderBottomWidth: '3px', mb: 1 }} />
            <Stack >
                {question?.message &&
                    <Stack>
                        <Typography fontSize="14px" fontWeight={600} pl={2}>{t(tokens.feedback.ideaDesc)}</Typography>
                        <Stack mb={1}>
                            <ExpandableText text={question.message} />
                        </Stack>
                    </Stack>}
                {question?.ideaContribute &&
                    <Stack>
                        <Typography fontSize="14px" fontWeight={600} pl={2}>{t(tokens.feedback.ideaImpact)}</Typography>
                        <Stack mb={1}>
                            <ExpandableText text={question.ideaContribute} />
                        </Stack>
                    </Stack>}
                {question?.example &&
                    <Stack>
                        <Typography fontSize="14px" fontWeight={600} pl={2}>{t(tokens.feedback.examples)}</Typography>
                        <Stack mb={1}>
                            <ExpandableText text={question.example} />
                        </Stack>
                    </Stack>}
            </Stack>
            <Stack direction="row" justifyContent="flex-start" alignItems="center" ml={.5} mt={.5}>
                <Checkbox checked={question.isTakePart} size="small" disabled />
                <Typography fontSize="15px">{t(tokens.feedback.takePart)}</Typography>
            </Stack>
            <Stack direction="row" flexWrap="wrap" sx={styles.container}>
                {question?.photos?.map((photo: any) =>
                    <div style={styles.wrapper} key={photo.id}>
                        <img
                            src={photo.url}
                            alt="logo"
                            width={45}
                            height={45}
                            style={styles.image as CSSProperties}
                            onClick={() => handleToggleImageFile(photo)}
                        />
                    </div>
                )}
            </Stack>
        </Card>
    )
};