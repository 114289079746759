import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setDeleteTestError, setLoading } from "../../repository/slice";

function* deleteTestWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.delete, "/api/admin/quiz/delete/" + payload.id);
        if (isError) {
            yield put(setDeleteTestError(message));
        } else {
            yield put(setDeleteTestError(null));
        }
    } catch (error) {
        console.error("deleteTestWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* deleteTestWatcher() {
    yield takeEvery("@saga/DELETE_TEST", deleteTestWorker);
};
