import { SagaIterator } from "redux-saga";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setAppPageLoading, setAppPageError, setAppPage } from "../../repository/slice";
import { IAppPage } from "../../types/IAppPage";
import { selectAppPage } from "../../repository/selector";
import { getAppPages } from "../getAppPages/action";

function* deleteAppPageWorker({ payload }: any): SagaIterator {
    try {
        yield put(setAppPageLoading(true));
        const { appPageList, appPageCount } = yield select(selectAppPage);
        const deletedAppPages = appPageList.filter((page: IAppPage) => page.id !== payload.id)
        const pageCount = appPageCount ? appPageCount - 1 : 0;
        yield put(setAppPage({ count: pageCount, rows: deletedAppPages }));
        const { data, message, isError }: IResponse<{ count: number, rows: IAppPage[] }> = yield call(requester.delete, "/api/admin/dynamic-pages/delete/" + payload.id);
        if (isError) {
            yield put(setAppPageError(message));
        }
    } catch (error) {
        console.error("deleteAppPageWorker: ", error);
    } finally {
        yield put(getAppPages({ type: 'ACTIVE' }));
        yield put(setAppPageLoading(false));
    };
};

export function* deleteAppPageWatcher() {
    yield takeLatest("@saga/DELETE_APP_PAGE", deleteAppPageWorker);
};