import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setCreateAdvertError, setLoading, setSingleAdvert } from "../../repository/slice";

function* createAdvertWorker({ payload }: any): SagaIterator {
    try {
        const formData = new FormData();
        for (const [key, value] of Object.entries(payload as Object)) {
            const isValue = value !== null && value !== undefined && value !== "";
            if (isValue) {
                if (Array.isArray(value)) {
                    if (typeof value[0] === 'number') {
                        formData.append(key, JSON.stringify(value))
                    } else if (typeof value[0] === 'string') {
                        formData.append(key, value.join(", "));
                    } else {
                        value.forEach(item => {
                            formData.append(key, item);
                        });
                    }
                } else {
                    formData.append(key, value);
                }
            }
        };
        
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.post, "/api/admin/announcement/create", formData);
        if (isError) {
            yield put(setCreateAdvertError(message));
        } else {
            yield put(setCreateAdvertError(null));
        }
    } catch (error) {
        console.error("createAdvertWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* createAdvertWatcher() {
    yield takeLatest("@saga/CREATE_ADVERT", createAdvertWorker);
};
