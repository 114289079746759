import { FC } from "react";
import { TableBody } from "@mui/material";
import { DocumentTableItem } from "../documentTableItem";

interface IProps {
    documents: any[];
    isProcess: boolean;
    documentId: number;
    handleStatus: (id: number, status: string) => void;
    handleDelete: (id: number) => void;
    handleUpload: (id: number, name: string) => void;
};

export const DocumentTableBody: FC<IProps> = ({ documents, handleStatus, handleDelete, handleUpload, isProcess, documentId }) => {

    return (
        <TableBody>
            {documents.map(document => (
                <DocumentTableItem
                    key={document.id}
                    document={document}
                    isProcess={isProcess}
                    documentId={documentId}
                    handleStatus={handleStatus}
                    handleDelete={handleDelete}
                    handleUpload={handleUpload}
                />
            ))}
        </TableBody>
    )
};