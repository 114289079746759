import { mt } from "date-fns/locale";

export const getStyles = () => {
    const styles = {
        avatar: { 
            width: '160px', 
            height: '160px', 
        }, 
        progress: {
            position: 'relative', 
            height: '300px',
            minHeight: '365px'
        }
    };
    return styles;
};