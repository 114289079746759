import { FC, useMemo } from "react";
import { Modal } from "../../../../../../ui-kit/modal/modal";
import { Button, DialogContent, DialogTitle, Stack } from "@mui/material";
import { ProgressTimer } from "../../../../../../ui-kit/progress-timer/progress-timer";
import { getStyles } from "./styles";
import { tokens } from "../../../../../../locales/tokens";
import { useTranslation } from "react-i18next";

interface IProps {
    isOpen: boolean;
    timerInSec: number;
    handleClose: () => void;
    handleRecover: () => void;
    handleOk: () => void;
};

export const InfoModal: FC<IProps> = ({ isOpen, handleClose, timerInSec, handleRecover, handleOk }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    return (
        <Modal isOpen={isOpen} handleClose={handleClose} size='md'>
            <DialogTitle sx={styles.title}>{t(tokens.auth.messages.smsSent)}</DialogTitle>
            <DialogContent >
                    {timerInSec > 0 ? <ProgressTimer timerInSec={timerInSec} /> : null}
                    <Stack direction='row' alignItems='center' spacing={2} justifyContent='center' >
                        <Button
                            color="primary"
                            sx={styles.button}
                            variant="contained"
                            disabled={timerInSec > 0}
                            onClick={handleRecover}
                        >
                            {t(tokens.auth.messages.sendAgain)}
                        </Button>
                        <Button
                            color="primary"
                            sx={styles.button}
                            variant="contained"
                            onClick={handleOk}
                        >
                            {t(tokens.auth.ok)}
                        </Button>
                    </Stack>
            </DialogContent>
        </Modal>
    )
};