import { CircularProgress, Stack } from "@mui/material";
import { FC, useState } from "react";

interface IProps {
    src: string;
    alt: string;
    width: number;
    height: number;
    style: any;
    chooseBadge: () => void;
};

export const ImageWithPlaceholder: FC<IProps> = ({ src, alt, width, height, style, chooseBadge }) => {
    const [loaded, setLoaded] = useState(false);

    return (
        <>
            {!loaded &&
                <Stack justifyContent="center" alignItems="center" position="absolute" width={50} height={50}>
                    <CircularProgress color="primary" size={20} />
                </Stack>}
            <img
                src={src}
                alt={alt}
                width={width}
                height={height}
                style={{
                    ...style,
                    opacity: loaded ? 1 : 0,
                    transition: 'opacity 0.5s ease-in-out',
                    cursor: 'pointer'
                }}
                onLoad={() => setLoaded(true)}
                onClick={chooseBadge}
            />
        </>
    );
};
