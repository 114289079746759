import { ChangeEvent, useEffect, useRef, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../../../../store/auth/useCases/signIn/action";
import { selectAuth } from "../../../../store/auth/repository/selector";
import { useAuth } from "../../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

export const useAuthorization = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setLoggedIn } = useAuth();
    const { isLoading, signInError } = useSelector(selectAuth);
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [phoneValid, setPhoneValid] = useState(true);
    const [passwordValid, setPasswordValid] = useState(true);
    const [isPassShown, setPassShown] = useState(false);
    const phoneInputRef = useRef<ReactInputMask | null>(null);
    const passwordInputRef = useRef<HTMLInputElement | null>(null);
    const [loginClick, setLoginClick] = useState(false);
    const [isShowLoader, setShowLoader] = useState(false);
    const [isHideError, setHideError] = useState(false);

    useEffect(() => {
        if (!isLoading && loginClick) {
            if (signInError) {
                setPasswordValid(false);
                setPhoneValid(false);
                window.document.getElementById('phoneInput')?.blur();
            } else {
                setShowLoader(true);
                setTimeout(() => {
                    setLoggedIn(true);
                    navigate('/');
                    setShowLoader(false);
                }, 3600);
            }
            setLoginClick(false);
        }
    }, [signInError, isLoading]);

    const onChangePhone = (event: ChangeEvent<HTMLInputElement>) => {
        setPhoneValid(true);
        setPhone(event.target.value);
    };

    const validatePhone = (phoneNumber: string) => {
        const phonePattern = /^\+380 \(\d{2}\)-\d{3}-\d{2}-\d{2}$/;
        return phonePattern.test(phoneNumber);
    };

    const handleEnter = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
        setPasswordValid(true);
        setPassword(event.target.value);
    };

    const validatePassword = (password: string) => {
        // const passwordPattern = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z]).{8,}$/;
        // return passwordPattern.test(password);
        return password.length > 0;
    };

    const onShowPassword = () => {
        setPassShown(!isPassShown)
    };

    const handleLogin = () => {
        setHideError(false);

        const isPhoneValid = validatePhone(phone);
        const isPasswordValid = validatePassword(password);
        window.document.getElementById('phoneInput')?.blur();
        setPhoneValid(isPhoneValid);
        setPasswordValid(isPasswordValid);

        if (isPhoneValid && isPasswordValid) {
            dispatch(signIn({ login: formatPhone(phone), password }));
            setLoginClick(true);
            setHideError(true);
        }
    };

    const formatPhone = (phone: string) => {
        return phone.replace(/\D/g, '');
    };

    return {
        phone, onChangePhone, password, onChangePassword, handleLogin, isPassShown, onShowPassword, signInError,
        phoneValid, passwordValid, passwordInputRef, phoneInputRef, handleEnter, isShowLoader, isHideError, isLoading
    };
};