import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { paths } from "../../../../paths";
import { useTranslation } from "react-i18next";
import { getTraineePositionById } from "../../../../store/trainee/useCases/getTraineePositionById/action";
import { useSelector } from "react-redux";
import { selectTrainee } from "../../../../store/trainee/repository/selector";
import { deleteTraineeTask } from "../../../../store/trainee/useCases/deleteTraineeTask/action";
import { setTraineePositionById, setTraineeTaskList } from "../../../../store/trainee/repository/slice";

export const useTraineePositionEdit = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const [isTaskModalOpen, setTaskModalOpen] = useState(false);
    const [currentTask, setCurrentTask] = useState<any>(null);
    const { traineePositionById, traineeTaskList } = useSelector(selectTrainee);

    useEffect(() => {
        id && dispatch(getTraineePositionById({ id }))
        return () => {
            dispatch(setTraineePositionById(null));
            dispatch(setTraineeTaskList([]));
        }
    }, [id]);

    useEffect(() => {
        if (traineePositionById) {
            dispatch(setTraineeTaskList(traineePositionById.content))
        }
    }, [traineePositionById]);

    const handleOpenTaskModal = () => {
        setTaskModalOpen(true);
    };

    const handleCloseTaskModal = () => {
        setTaskModalOpen(false);
        setCurrentTask(null);
    };

    const removeTask = (idTask: number, type: string) => {
        dispatch(deleteTraineeTask({ body: { content_id: idTask, content_type: type, id: id } }));
    };

    const defineCurrentTask = (index: number, taskId: number) => {
        setCurrentTask(traineeTaskList[index]);
        setTaskModalOpen(true);
        // dispatch(setTaskId(taskId));
    };

    return { handleOpenTaskModal, isTaskModalOpen, currentTask, handleCloseTaskModal, id, traineeTaskList, defineCurrentTask, removeTask, traineePositionById }
};