import { FC, useEffect, useMemo } from 'react';
import { Backdrop, Box, CircularProgress, Container, Divider, Stack, Theme, Typography, useMediaQuery } from '@mui/material';
import { useNotificationDetail } from '../presenters/useNotificationDetail';
import { NotificationAudienceInfo } from './components/notificationAudienceInfo';
import { NotificationButtons } from './components/notificationButtons';
import { NotificationPreview } from './components/notificationPreview';
import toast from 'react-hot-toast';
import { tokens } from '../../../../locales/tokens';
import { useTranslation } from 'react-i18next';
import { paths } from '../../../../paths';
import { useNavigate } from 'react-router-dom';
import { getStyles } from './styles';

export const NotificationDetail: FC = () => {
    const { t } = useTranslation();
    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const styles = useMemo(() => getStyles(lgDown), [lgDown]);
    const navigate = useNavigate();
    const { notificationById, onSend, onDelete, errorsSendNotification, errorsDeletePushNotifications, isLoading, deleteClick,
        setDeleteClick, sendClick, setSendClick } = useNotificationDetail();

    useEffect(() => {
        if (!isLoading && deleteClick) {
            if (errorsDeletePushNotifications) {
                toast.error(errorsDeletePushNotifications);
            } else {
                toast.success(t(tokens.notification.pushDeleted));
                navigate(paths.dashboard.notification.index);
            }
            setDeleteClick(false);
        }
    }, [errorsDeletePushNotifications, isLoading]);

    useEffect(() => {
        if (!isLoading && sendClick) {
            if (errorsSendNotification) {
                toast.error(errorsSendNotification);
            } else {
                toast.success(t(tokens.notification.pushIsSend));
                navigate(paths.dashboard.notification.index);
            }
            setSendClick(false);
        }
    }, [errorsSendNotification, isLoading]);

    return (
        <>
            <Divider />
            <Box component="main" sx={styles.mainBox}>
                {notificationById &&
                    <>
                        <Typography variant="h4" fontWeight={600} mb={4}>{t(tokens.notification.pushDetails)}</Typography>
                        <NotificationAudienceInfo item={notificationById} />
                        <NotificationButtons id={notificationById.id} onSend={onSend} onDelete={onDelete} isDisabled={sendClick || deleteClick}/>
                        <NotificationPreview item={notificationById} />
                    </>
                }
            </Box>
            <Backdrop sx={styles.backDrop} open={sendClick || deleteClick}>
                <Stack direction="row" spacing={2} alignItems="center">
                    <CircularProgress color="primary" />
                    <Typography color="primary" variant="h6">
                        {t(tokens.documents.await)}
                    </Typography>
                </Stack>
            </Backdrop>
        </>
    );
};