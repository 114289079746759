import { FC } from "react";
import { TableBody } from "@mui/material";
import { AdvertTableItem } from "../advertTableItem";

interface IProps {
    adverts: any[];
    handleStatus: (id: number, status: string) => void;
    handleDelete: (id: number) => void;
    handleUploadReport: (id: number, name: string) => void;
};

export const AdvertTableBody: FC<IProps> = ({ adverts, handleStatus, handleDelete, handleUploadReport }) => {

    return (
        <TableBody>
            {adverts.map(advert => (
                <AdvertTableItem
                    advert={advert}
                    key={advert.id}
                    handleStatus={handleStatus}
                    handleDelete={handleDelete}
                    handleUploadReport={handleUploadReport}
                />
            ))}
        </TableBody>
    )
};