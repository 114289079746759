export const getStyles = () => {
    const styles = {
        clearImage: { 
            position: 'absolute', 
            top: '50px', 
            left: '68px', 
            backgroundColor: '#d3d3d3', 
            borderRadius: '50%', 
            '&:hover': { 
                backgroundColor: '#bfbfbf' 
            } 
        }, 
        cover: { 
            cursor: 'pointer', 
            borderRadius: '15px', 
            objectFit: 'cover',
        }
    };
    return styles;
};