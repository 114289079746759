export const getStyles = () => {
    const styles = {
        createButton: {
            borderRadius: '10px',
            width: '100px',
            color: '#FFFFFF',
            fontWeight: 600,
            backgroundColor: '#F1594E',
            textTransform: 'none',
            '&:hover': {
                backgroundColor: '#d85046'
            }
        },
        cancelButton: {
            borderRadius: '10px',
            color: '#F1594E',
            fontWeight: 600,
            borderColor: '#F1594E',
            textTransform: 'none',
            '&:hover': {
                borderColor: '#d85046'
            }
        },
        addQuestionButton: {
            color: '#F1594E',
            textTransform: 'none',
            fontSize: '16px'
        },
        answer: {
            backgroundColor: 'white',
            mt: 1
        },
        wrapper: {
            // height: '520px', overflow: 'scroll',
            // scrollbarWidth: 'none',
            // '.dialog-content::-webkit-scrollbar': {
            //     display: 'none'
            // }
        }
    };
    return styles;
};