import { SagaIterator } from "redux-saga";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setUpdateTestError } from "../../repository/slice";
import { createQuestion } from "../createQuestion/action";
import { updateQuestion } from "../updateQuestion/action";
import { deleteQuestion } from "../deleteQuestion/action";

function* updateTestWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.put, "/api/admin/quiz/update", payload.body);
        if (isError) {
            yield put(setUpdateTestError(message));
        } else {
            yield put(setUpdateTestError(null));
        }
    } catch (error) {
        console.error("updateTestWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* updateTestWatcher() {
    yield takeLatest("@saga/UPDATE_TEST", updateTestWorker);
};
