import { SagaIterator } from "redux-saga";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading } from "../../repository/slice";

function* createAnswerWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.post, "/api/admin/answer/create", payload.reply);
    } catch (error) {
        console.error("createAnswerWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* createAnswerWatcher() {
    yield takeEvery("@saga/CREATE_ANSWER", createAnswerWorker);
};
