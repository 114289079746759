export const getStyles = () => {
    const styles = {
        answer: {
            backgroundColor: 'white', 
            mt: 1,
            px: 2,
            borderRadius: '15px'
        }
    };
    return styles;
};