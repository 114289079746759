import { ChangeEvent, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { config } from '../../../../config';
import { tokens } from '../../../../locales/tokens';
import { useDeleteBadgeMutation, useGetBadgesQuery } from '../../../../store/badge/useCases';

export const useBadges = () => {
    const { t } = useTranslation();
    const scrollRef = useRef<HTMLDivElement>(null);

    const tabOptions = [
        { label: t(tokens.badge.tabs.active), value: "ACTIVE" },
        { label: t(tokens.badge.tabs.archive), value: "ARCHIVE" },
    ];

    const [tab, setTab] = useState(tabOptions[0].value);

    const [filterBadges, setFilterBadges] = useState({ perPage: config.badge.perPage, page: 1, type: tab });

    const handleTabsChange = (event: ChangeEvent<{}>, tab: string): void => {
        setTab(tab);
        setFilterBadges((prev) => ({ ...prev, type: tab, page: 1 }));
        scrollTopList();
    };
    const handleChangePageBadge = (event: ChangeEvent<unknown>, page: number): void => {
        setFilterBadges((prev) => ({ ...prev, page }));
        scrollTopList();
    };

    const { data: { rows: rowsBadges = [], count: countBadges = 0 } = {}, isLoading: isLoadingGetBadges } = useGetBadgesQuery(filterBadges);

    const [deleteBadge, { isLoading: isLoadingDeleteBadges, isSuccess: isSuccessDeleteBadges }] = useDeleteBadgeMutation();

    useEffect(() => {
        if (isSuccessDeleteBadges) toast.success(t(tokens.badge.messages.delete));
    }, [isSuccessDeleteBadges]);

    const isArchive = tab === 'ARCHIVE';

    const scrollTopList = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        };
    };

    return { t, tab, tabOptions, handleTabsChange, isLoadingGetBadges, rowsBadges, isArchive, isLoadingDeleteBadges, deleteBadge, countBadges, filterBadges, handleChangePageBadge, scrollRef };
};
