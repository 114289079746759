import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { QuizFilters } from "./types";
import { updateOrgStructure } from "../../../../../store/orgStructure/useCases/updateOrgStructure/action";
import { selectOrgStructure } from "../../../../../store/orgStructure/repository/selector";
import { getQuizList } from "../../../../../store/quiz/useCases/getQuizList/action";
import { selectQuiz } from "../../../../../store/quiz/repository/selector";
import { deleteQuiz } from "../../../../../store/quiz/useCases/deleteQuiz/action";
import { updateQuiz } from "../../../../../store/quiz/useCases/updateQuiz/action";
import toast from "react-hot-toast";
import { tokens } from "../../../../../locales/tokens";
import { uploadQuizReport } from "../../../../../store/quiz/useCases/uploadQuizReport/action";
import { TIMESTAMP_DAY } from "../../../../../config";
import { setQuizList } from "../../../../../store/quiz/repository/slice";

export const useQuizList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [filters, updateFilters] = useState<QuizFilters>({ page: 1, rowsPerPage: 30, search: '', tab: 'Active', status: null, initiator: null, date: null });
    const { orgStructure } = useSelector(selectOrgStructure);
    const { quizList, quizCount, isLoading, updateQuizError, deleteQuizError, getQuizListError } = useSelector(selectQuiz);
    const [pageAmount, setPageAmount] = useState(0);
    const [updateClick, setUpdateClick] = useState(false);
    const [deleteClick, setDeleteClick] = useState(false);
    const [isProccessQuiz, setProcessQuiz] = useState(false);
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        dispatch(getQuizList(combineRequestBody()));
        setProcessQuiz(true);
        return () => {
            dispatch(setQuizList([]));
        }
    }, [filters]);

    useEffect(() => {
        if (quizCount) {
            setPageAmount(Math.ceil(quizCount / filters.rowsPerPage));
        }
    }, [quizCount]);

    useEffect(() => {
        dispatch(updateOrgStructure());
    }, []);
    
    useEffect(() => {
        if (!isLoading && updateClick) {
            if (updateQuizError) {
                toast.error(updateQuizError)
            } else {
                toast.success(t(tokens.quiz.message.quizUpdated));
                dispatch(getQuizList(combineRequestBody()));
            }
            setUpdateClick(false);
        }
    }, [updateQuizError, isLoading]);

    useEffect(() => {
        if (!isLoading && deleteClick) {
            if (deleteQuizError) {
                toast.error(deleteQuizError)
            } else {
                toast.success(t(tokens.quiz.message.quizRemoved));
                dispatch(getQuizList(combineRequestBody()));
            }
            setDeleteClick(false);
        }
    }, [deleteQuizError, isLoading]);

    useEffect(() => {
        if (!isLoading && isProccessQuiz) {
            if (getQuizListError) {
                toast.error(getQuizListError)
            };
            setTimeout(() => setProcessQuiz(false), 400);
        }
    }, [getQuizListError, isLoading]);

    const handleSearchQuizText = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        debouncedHandleQuizSeacrh(e.target.value);
    };

    const onSearchQuiz = (value: string) => {
        const query = value ? value : '';
        if (query.length !== 1) {
            updateFilters((prevState) => ({ ...prevState, search: query, page: 1 }));
            scrollTopList();
        }
    };

    const { debouncedWrapper: debouncedHandleQuizSeacrh } = useDebounce(onSearchQuiz, 1000);
    
    const handleTabsChange = (event: ChangeEvent<{}>, tab: string): void => {
        updateFilters((prevState: any) => ({ ...prevState, tab, page: 1 }));
        scrollTopList();
    };

    const onSelectInitiator = (selectedKeysValue: string, node: any) => {
        if ('children' in node) {
            return;
        }
        updateFilters((prevState) => ({ ...prevState, page: 1, initiator: node }));
        scrollTopList();
    };

    const onChangeInitiator = () => {
        if (filters.initiator) {
            updateFilters((prevState) => ({ ...prevState, page: 1, initiator: null }));
        }
    };

    const handleDateChange = useCallback((date: Date | null): void => {
        const newDate = date?.valueOf();
        const currentDate = newDate || null;
        updateFilters((prevState) => ({ ...prevState, page: 1, date: currentDate }));
        scrollTopList();
    }, [updateFilters]);

    const changeStatus = useCallback((status: any) => {
        updateFilters((prevState) => ({ ...prevState, page: 1, status }));
        scrollTopList();
    }, [updateFilters]);
    
    const resetFilters = useCallback((): void => {
        updateFilters((prevState) => ({ ...prevState, page: 1, rowsPerPage: 30, status: null, initiator: null, date: null }));
        scrollTopList();
    }, [updateFilters]);

    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        updateFilters((prevState) => ({ ...prevState, page }));
        scrollTopList();
    }, [updateFilters]);

    const handleStatus = (id: number, status: string) => {
        const testStatus = status === 'Hidden' ? 'Available' : 'Hidden';
        dispatch(updateQuiz({ body: { id, status: testStatus } }));
        setUpdateClick(true);
    };

    const handleDelete = (id: number) => {
        dispatch(deleteQuiz({ id }));
        setDeleteClick(true);
    };

    const handleUploadReport = (id: number, name: string) => {
        dispatch(uploadQuizReport({ id, name }));
    };

    const combineRequestBody = () => {
        const body: any = { page: filters.page, size: filters.rowsPerPage };
        if (filters.search) body.search = filters.search;
        if (filters.initiator) body.initiatorId = filters.initiator.value;
        if (filters.status) body.status = filters.status.value;
        if (filters.date) {
            const startDateString = new Date(filters.date).toISOString();
            body.startDate = startDateString;
            const endDateString = new Date((filters.date) + TIMESTAMP_DAY).toISOString();
            body.endDate = endDateString;
        };
        if(filters.tab !== 'Active') body.deleted = 'true';
        return body;
    };

    const scrollTopList = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        };
    };

    return { searchText, handleSearchQuizText, filters, handleTabsChange, orgStructure, changeStatus, resetFilters, handleDateChange, onSelectInitiator, 
        onChangeInitiator, handlePageChange, handleStatus, handleDelete, handleUploadReport, pageAmount, quizList, isProccessQuiz, scrollRef }
};