import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { AdvertChart } from "./components/advertChart";
import { useAdvertAnalytics } from "../presenter/useAdvertAnalytics";

export const AdvertAnalytics: FC = () => {
  const { t } = useTranslation();
  const { dates, handleRangeChange, disabledDate } = useAdvertAnalytics();

  return (
    <Stack m={2}>
      <Typography variant="h4" fontWeight={600}>
        {t(tokens.analytics.analyticsAdvert)}
      </Typography>
      <Stack justifyContent="center" alignItems="center">
        <AdvertChart
          chartSeries={[
            {
              name: t(tokens.analytics.news),
              data: [25, 72, 47, 78, 141, 115, 92, 105, 115, 131, 141, 171]
            },
            {
              name: t(tokens.analytics.promotion),
              data: [31, 72, 55, 73, 112, 80, 67, 80, 65, 80, 87, 125]
            },
            {
              name: t(tokens.analytics.events),
              data: [50, 72, 51, 60, 86, 54, 112, 94, 74, 105, 122, 101]
            },
          ]}
          dates={dates}
          handleRangeChange={handleRangeChange}
          disabledDate={disabledDate}
        />
      </Stack>
    </Stack>
  )
};