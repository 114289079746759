import { Button, IconButton, Stack, SvgIcon, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { FC, MouseEventHandler, SyntheticEvent, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { tokens } from "../../../../../../../locales/tokens";
import { useTranslation } from "react-i18next";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { getStyles } from "./styles";
import { useTheme } from "@emotion/react";
import AppsIcon from '@mui/icons-material/Apps';
import { SwapButtons } from "../swapButtons";

interface CustomTreeItemLabelProps {
    category: any;
    isPoster: boolean;
    index: number;
    sectionIndex: number | undefined;
    defineCategoryId: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => void;
    handleStatus: (id: number, isHidden: boolean) => void;
    swapSections: (direction: "UP" | "DOWN", index: number, firstSection: any) => void;
};

export const TreeCategoryItemLabel: FC<CustomTreeItemLabelProps> = ({ category, handleStatus, isPoster, swapSections, index, sectionIndex, defineCategoryId }) => {
    const { id, name, isHidden, image } = category;
    const { t } = useTranslation();
    const theme = useTheme();
    const styles = useMemo(() => getStyles(theme), [theme]);

    const handleCategoryStatus = useCallback((event: SyntheticEvent) => {
        event.stopPropagation();
        handleStatus(id, isHidden);
    }, [id, isHidden]);

    const editCategory = useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => defineCategoryId(e, category.id), [category.id]);

    return (
        <Table id={'contentSection' + category.sortNumber}>
            <TableBody >
                <TableRow sx={{ height: '50px' }} >
                    <TableCell align="left" padding="none">
                        <Stack direction='row' alignItems="center" spacing={1}>
                            {/* <IconButton>
                                <SvgIcon><AppsIcon color="primary" /></SvgIcon>
                            </IconButton> */}
                            {isPoster && image ?
                                <img src={image.url} alt="cover" width={45} height={45} style={{ borderRadius: '15px' }} /> : null}
                            <Typography variant="body2">{name}</Typography>
                        </Stack>
                    </TableCell>
                    <TableCell padding="none" width='10%'>
                        <Stack direction='row' alignItems='center' spacing={1}>
                            <Stack direction='row' alignItems='center'>
                                <SwapButtons swapSections={swapSections} category={category} index={index} sectionIndex={sectionIndex} />
                            </Stack>
                            <IconButton size="small" onClick={editCategory} sx={{ zIndex: 100 }}>
                                <Tooltip title={t(tokens.common.edit)}>
                                    <SvgIcon style={styles.svgIcon}><EditOutlinedIcon /></SvgIcon>
                                </Tooltip>
                            </IconButton>
                            <Button
                                startIcon={isHidden ?
                                    <SvgIcon><RemoveRedEyeIcon color="action" /></SvgIcon>
                                    :
                                    <SvgIcon><VisibilityOffIcon color="action" /></SvgIcon>
                                }
                                variant="outlined"
                                size="small"
                                sx={styles.statusButton}
                                onClick={handleCategoryStatus}
                            >
                                {isHidden ?
                                    t(tokens.common.publish)
                                    :
                                    t(tokens.common.hide)
                                }
                            </Button>
                        </Stack>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table >
    )
};