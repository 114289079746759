import { FC, ReactNode } from "react";
import { NavColor } from "../../../types/settings";
import { MenuSection } from "../../../types/settings";

interface HorizontalLayoutProps {
    children?: ReactNode;
    navColor?: NavColor;
    sections?: MenuSection[];
}

export const HorizontalLayout: FC<HorizontalLayoutProps> = ({children, sections, navColor}) => {
    
    return(
        <></>
    )
}