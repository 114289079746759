import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setGalleryFiles, setDeleteFileError } from "../../repository/slice";

function* deleteDocumentFilesWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true)); 
        const { data, message, isError }: IResponse<any> = yield call(requester.delete, '/api/admin/document/delete-file/' + payload.fileName);
        if (isError) {
            yield put(setDeleteFileError(message));
        } else {
            yield put(setDeleteFileError(null));
           yield put(setGalleryFiles(data))
        }
    } catch (error) {
        console.error("deleteDocumentFilesWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* deleteDocumentFilesWatcher() {
    yield takeLatest("@saga/DELETE_DOCUMENT_FILES", deleteDocumentFilesWorker);
};
