import { Stack } from "@mui/material"
import { FC } from "react"
import Chart from 'react-apexcharts'
import { ChartSeries, useAdvertChart } from "../../../presenter/useAdvertChart";

interface IProps {
    chartSeries: ChartSeries;
};

export const ChartBody: FC<IProps> = ({ chartSeries }) => {
    const chartOptions = useAdvertChart();

    return (
        <Chart
            height={320}
            width="100%"
            options={chartOptions}
            series={chartSeries}
            type="line"
        />
    )
}