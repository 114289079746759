import { ChangeEvent, useEffect, useState } from "react";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { getCategories } from "../../../../../store/category/useCases/getCategories/action";
import { useDispatch, useSelector } from "react-redux";
import { selectCategory } from "../../../../../store/category/repository/selector";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../locales/tokens";
import { ICategory } from "../../../../../types/category";
import { swapRows } from "../../../../../utils/swapHTMLElements";
import { setCategories, setSingleCategory } from "../../../../../store/category/repository/slice";
import { updateCategory } from "../../../../../store/category/useCases/updateCategory/action";

export const useDocumentCategoroy = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [filters, updateFilters] = useState({ page: 0, rowsPerPage: 10, query: '', tab: 'Documents' });
    const { categoryList, isLoading, updateCategoryError } = useSelector(selectCategory);
    const [updateClick, setUpdateClick] = useState(false);
    const [capturedCategory, setCapturedCategory] = useState<ICategory | null>(null);
    const [isOpen, toggleOpen] = useState(false);
    const [categoryId, setCategoryId] = useState(0);

    useEffect(() => {
        dispatch(getCategories(combineRequestBody()));
    }, [filters]);

    const combineRequestBody = () => {
        let body: any = {};
        if (filters.query) body.name = filters.query;
        body = defineType(body);
        return body;
    };

    const defineType = (body: any) => {
        switch (filters.tab) {
            case 'Documents':
                body.isKnowledgeCategory = false;
                body.isHidden = false;
                break;
            case 'Knowledge':
                body.isKnowledgeCategory = true;
                body.isHidden = false;
                break;
            case 'Hidden':
                body.isHidden = true;
                break;
        }
        return body;
    };

    useEffect(() => {
        if (!isLoading && updateClick) {
            if (updateCategoryError) {
                toast.error(updateCategoryError)
            } else {
                toast.success(t(tokens.category.messages.categoryUpdated));
                dispatch(getCategories(combineRequestBody()));
            }
            setUpdateClick(false);
        }
    }, [updateCategoryError, isLoading]);

    const handleSearchCategoryText = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        debouncedHandleCategorySeacrh(e.target.value);
    };

    const onSearchCategory = (value: string) => {
        const query = value ? value : '';
        if (query.length !== 1) {
            updateFilters((prevState) => ({ ...prevState, query }));
        }
    };

    const { debouncedWrapper: debouncedHandleCategorySeacrh } = useDebounce(onSearchCategory, 400);

    const handleTabsChange = (event: ChangeEvent<{}>, tab: string): void => {
        updateFilters((prevState) => ({ ...prevState, tab }));
    };

    const handleStatus = (id: number, isHidden: boolean) => {
        const status = isHidden ? false : true;
        dispatch(updateCategory({ body: { id, isHidden: status } }));
        setUpdateClick(true);
    };

    const allowDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const dragDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        if (capturedCategory) {
            dispatch(updateCategory({ body: { id: capturedCategory.id, parentId: null } }));
            setUpdateClick(true);
            setCapturedCategory(null);
        }
    };

    const swapSections = (direction: "UP" | "DOWN", index: number, firstSection: any, sectionIndex?: number | undefined) => {
        const isTopElement = index === 0;
        const isBottomElement =
            sectionIndex !== undefined
                ? index === categoryList[sectionIndex].children.length - 1
                : index === categoryList.length - 1;

        if ((direction === "UP" && isTopElement) || (direction === "DOWN" && isBottomElement)) {
            return;
        };

        const swapIndex = direction === "UP" ? index - 1 : index + 1;

        const secondSection: any =
            sectionIndex !== undefined
                ? categoryList[sectionIndex].children[swapIndex]
                : categoryList[swapIndex];

        swapRows(firstSection.sortNumber, secondSection.sortNumber, 'contentSection', () => {
            const newContent = swapItems(firstSection, secondSection);
            dispatch(setCategories({ categories: newContent }));
            dispatch(
                updateCategory({
                    body: {
                        id: firstSection.id,
                        sortNumber: secondSection.sortNumber,
                    }
                })
            );
            // dispatch(
            //     updateCategory({
            //         body: {
            //             id: secondSection.id,
            //             sortNumber: firstSection.sortNumber,
            //         }
            //     })
            // );
        }
        );
        setUpdateClick(true)
    };

    const swapItems = (firstSection: any, secondSection: any) => {
        const result = categoryList.map((contentSection) => {
            if (contentSection.id === firstSection.id) {
                return {
                    ...secondSection,
                    sortNumber: firstSection.sortNumber,
                };
            }
            if (contentSection.id === secondSection.id) {
                return {
                    ...firstSection,
                    sortNumber: secondSection.sortNumber,
                };
            }
            return contentSection;
        });
        return result;
    };

    const handleToggle = () => {
        toggleOpen(!isOpen);
        if (isOpen) {
            setCategoryId(0);
        }
    };

    const defineCategoryId = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => {
        e.stopPropagation();
        setCategoryId(id);
        toggleOpen(!isOpen);
    };

    return {
        categoryList, handleSearchCategoryText, filters, searchText, handleTabsChange, handleStatus, setUpdateClick,
        capturedCategory, setCapturedCategory, dragDropHandler, allowDrop, swapSections, isOpen, handleToggle, defineCategoryId,
        categoryId, updateFilters
    };
};