import { TreeItem } from "@mui/x-tree-view";
import { FC } from "react";
import { TreeItemUserLabel } from "../treeItemUserLabel";

interface IProps {
    node: any;
    handleStatus: (id: number, isBanned: boolean) => void;
};

export const TreeItemUser: FC<IProps> = ({ node, handleStatus }) => {

    return (
        <TreeItem
            key={node.name}
            nodeId={node.name}
            label={<TreeItemUserLabel user={node} handleStatus={handleStatus} />}
        />
    )
};