import { Button, Checkbox, FormControlLabel, Stack, TextField, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { getStyles } from "./styles";
import { useProductEdit } from "../presenters/useProductEdit";
import { FileDropzone } from "../../../../ui-kit/fileDropZone";
import { updateFilesInProduct } from "../../../../store/product/useCases/updateFiles/action";

export const ProductEdit: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { form, formErrors, isClick, isDisabled, onChangeName, handleFileChange, handleFilesRemoveAll, handleFilesDrop, handleDeleteImage, onChangePrice, 
        onChangeAmount, onChangeDescription, onTogglePublish, onCreate, onCancel, handleSetFiles } = useProductEdit();
    const { name, files, price, quantity, description, is_hidden } = form;
    const { nameError, filesError, priceError, quantityError, descriptionError } = formErrors;

    return (
        <Stack m={2}>
            <Stack marginBottom={2} direction='row' justifyContent='space-between'>
                <Typography variant="h4" fontWeight={600}>{t(tokens.shop.title.edit)}</Typography>
            </Stack>
            <Stack>
                <Stack direction='row' spacing={2}>
                    <Stack width="100%">
                        <Typography variant="h6" mb={1} color={filesError ? 'error' : 'inherit'}>
                            {t(tokens.shop.form.images)}*
                        </Typography>
                        <FileDropzone
                            accept={{ "image/*": [] }}
                            caption={t(tokens.shop.form.imagesDNDSubtitle)}
                            files={files}
                            setFiles={handleSetFiles}
                            onDrop={handleFilesDrop}
                            onRemove={handleDeleteImage}
                            // onRemoveAll={handleFilesRemoveAll}
                            disabled={isDisabled}
                            isEdit={true}
                            updateFiles={updateFilesInProduct}
                        />
                    </Stack>
                    <Stack mt={2} width="100%">
                        <Typography variant="h6" mb={1} color={descriptionError ? 'error' : 'inherit'}>
                            {t(tokens.shop.form.description)}
                        </Typography>
                        <TextField
                            multiline
                            disabled={isDisabled}
                            value={description}
                            onChange={onChangeDescription}
                            label={t(tokens.shop.form.description)}
                            minRows={6}
                            maxRows={20} />
                    </Stack>
                </Stack>
                <Stack direction='row' spacing={2}>
                    <Stack width="100%">
                        <Stack mt={4}>
                            <Typography variant="h6" mb={1} color={nameError ? 'error' : 'inherit'} >{t(tokens.shop.form.name)}*</Typography>
                            <TextField
                                type="text"
                                label={t(tokens.shop.form.name) + '*'}
                                value={name}
                                onChange={onChangeName}
                                autoComplete="off"
                                disabled={isDisabled}
                            />
                        </Stack>
                        <Stack mt={4}>
                            <Typography variant="h6" mb={1} color={priceError ? 'error' : 'inherit'} >{t(tokens.shop.form.price)}*</Typography>
                            <TextField
                                label={t(tokens.shop.form.price) + '*'}
                                value={price}
                                onChange={onChangePrice}
                                autoComplete="off"
                                disabled={isDisabled}
                            />
                        </Stack>
                        <Stack mt={4}>
                            <Typography variant="h6" mb={1} color={quantityError ? 'error' : 'inherit'} >{t(tokens.shop.form.amount)}*</Typography>
                            <TextField
                                label={t(tokens.shop.form.amount) + '*'}
                                value={quantity}
                                onChange={onChangeAmount}
                                autoComplete="off"
                                disabled={isDisabled}
                            />
                        </Stack>
                    </Stack>
                    <Stack width="100%" />
                </Stack>
            </Stack>
            <Stack mt={2}>
                <div>
                    <FormControlLabel control={<Checkbox disabled={isDisabled} checked={!is_hidden} onChange={onTogglePublish} />} label={t(tokens.documents.publish)} />
                </div>
            </Stack>
            {!isDisabled &&
                <Stack direction='row' mt={3} spacing={3}>
                    <Button
                        variant="contained"
                        sx={styles.createButton}
                        onClick={onCreate}
                        disabled={isClick}
                    >
                        {t(tokens.common.save)}
                    </Button>
                    <Button
                        variant="outlined"
                        sx={styles.cancelButton}
                        onClick={onCancel}
                    >
                        {t(tokens.common.cancel)}
                    </Button>
                </Stack>}
        </Stack >
    )
};