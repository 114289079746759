import { FC } from "react";
import { TableBody } from "@mui/material";
import { ProductTableItem } from "../productTableItem";

interface IProps {
    products: any[];
};

export const ProductTableBody: FC<IProps> = ({ products }) => {

    return (
        <TableBody>
            {products.map((product: any) => {
                return (
                    <ProductTableItem
                        key={product.id}
                        product={product}
                    />
                );
            })}
        </TableBody>
    )
};