import { SagaIterator } from "redux-saga";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setCreateCourseError, setCourseId } from "../../repository/slice";

function* createCourseWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.post, "/api/admin/courses", payload.body);
        if (isError) {
            yield put(setCreateCourseError(message));
        } else {
            yield put(setCreateCourseError(null));
            yield put(setCourseId(data.id));
        }
    } catch (error) {
        console.error("createCourseWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* createCourseWatcher() {
    yield takeLatest("@saga/CREATE_COURSE", createCourseWorker);
};
