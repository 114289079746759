import { FC } from "react";
import { useCourseAnalytics } from "../presenter/useCourseAnalytics";
import { CourseChart } from "./components/courseChart";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";

export const CourseAnalytics: FC = () => {
    const { t } = useTranslation();
    const { dates, handleRangeChange, disabledDate } = useCourseAnalytics();

    return (
        <Stack m={2}>
            <Typography variant="h4" fontWeight={600}>
                {t(tokens.analytics.analyticsCourse)}
            </Typography>
            <Stack justifyContent="center" alignItems="center">
                <CourseChart
                    chartSeries={[4, 1]}
                    labels={[t(tokens.analytics.complete), t(tokens.analytics.failed)]}
                    dates={dates}
                    handleRangeChange={handleRangeChange}
                    disabledDate={disabledDate}
                />
            </Stack>
        </Stack>
    )
};