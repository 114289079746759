import { Button, Stack, TableCell, TableRow, Tooltip, Typography, useTheme } from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import { getStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { IOrder } from "../../../../../../store/order/types/IOrder";
import moment from "moment";

interface IProps {
    order: IOrder;
    onProcessedOrder: (id: number) => void;
};
export const OrdersTableItem: FC<IProps> = ({ order, onProcessedOrder }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isDark = theme.palette.mode === 'dark';
    const isActive = order.status !== "ACCEPT";
    const styles = useMemo(() => getStyles(isActive, isDark), [isActive, isDark]);

    const handleProcessed = useCallback(() => onProcessedOrder(order.id), [order]);

    const date = useMemo(() => {
        return moment(order.createdAt).format('DD.MM.YYYY')
    }, [])

    return (
        <TableRow hover key={order.id}>
            <TableCell align="left" sx={styles.cell}>
                <Typography mr={2} sx={styles.date} variant="body2">{date}</Typography>
            </TableCell>
            <TableCell width="5%" sx={styles.cell}>
                <img
                    src={order.product?.files?.[0]?.url || "/images/user/uploadImage.png"}
                    alt="Product Image"
                    width={45}
                    height={45}
                    className="image"
                />
            </TableCell>
            <TableCell sx={styles.cell}>
                <Stack ml={2} direction={'column'}>
                    <Typography variant="body2">№{order.id}</Typography>
                    <Typography color={'#A5A8AD'} noWrap variant="body2">{order.totalPrice} балів</Typography>
                </Stack>
            </TableCell>
            <TableCell align="center" sx={styles.cell}>
                <Tooltip title={order.product?.name} arrow>
                    <Typography ml={2} sx={styles.name} variant="body2">{order.product?.name}</Typography>
                </Tooltip>
            </TableCell>
            <TableCell sx={styles.cell}>
                <Typography ml={2} noWrap variant="body2">{order.quantity} шт</Typography>
            </TableCell>
            <TableCell width="20%" sx={styles.cell}>
                <Stack direction="row" alignItems="center">
                    <img
                        src={order.user?.photo || "/images/user/uploadImage.png"}
                        alt="User Avatar"
                        width={45}
                        height={45}
                        className="image"
                    />
                    <Typography ml={2} variant="body2">{order.user?.first_name} {order.user?.last_name}</Typography>
                </Stack>
            </TableCell>
            <TableCell align="right" sx={styles.cell}>
                <Stack direction='row' alignItems='center' spacing={1} justifyContent='flex-end'>
                    <Button
                        variant="contained"
                        sx={styles.processedBtn}
                        onClick={handleProcessed}
                    >
                        {isActive ?
                            t(tokens.order.title.closeOrder)
                            : t(tokens.order.title.orderClosed)
                        }
                    </Button>
                </Stack>
            </TableCell>
        </TableRow>
    )
};