import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../locales/tokens";
import { useDispatch, useSelector } from "react-redux";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { selectCourse } from "../../../../../store/course/repository/selector";
import { getDocuments } from "../../../../../store/document/useCases/getDocuments/action";
import { getTestList } from "../../../../../store/test/useCases/getTestList/action";
import { getQuizList } from "../../../../../store/quiz/useCases/getQuizList/action";
import { selectDocument } from "../../../../../store/document/repository/selector";
import { selectTest } from "../../../../../store/test/repository/selector";
import { selectQuiz } from "../../../../../store/quiz/repository/selector";
import { setDocuments } from "../../../../../store/document/repository/slice";
import { setTestList } from "../../../../../store/test/repository/slice";
import { setQuizList } from "../../../../../store/quiz/repository/slice";
import { ITask, TASK_TYPES } from "./types";
import { createTask } from "../../../../../store/course/useCases/createTask/action";
import { updateTask } from "../../../../../store/course/useCases/updateTask/action";

export const useDayCreate = (onClose: () => void, currentTask: ITask | null, courseId: string | undefined) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [day, setDay] = useState("");
    const [dayValid, setDayValid] = useState(true);
    const [type, setType] = useState(TASK_TYPES.DOCUMENT);
    const typeOptions = [
        { label: t(tokens.course.document), value: TASK_TYPES.DOCUMENT },
        { label: t(tokens.course.test), value: TASK_TYPES.TEST },
        { label: t(tokens.course.quiz), value: TASK_TYPES.QUESTIONNAIRE },
    ];
    const [task, setTask] = useState<any>(null);
    const [taskValid, setTaskValid] = useState(true);
    const [taskDropdownList, setTaskDropdownList] = useState<any[]>([]);
    const { documentsList } = useSelector(selectDocument);
    const { testList } = useSelector(selectTest);
    const { quizList } = useSelector(selectQuiz);
    const { taskId, taskList, createTaskError, isLoading, updateTaskError } = useSelector(selectCourse);
    const [createClick, setCreateClick] = useState(false);
    const [editClick, setEditClick] = useState(false);

    useEffect(() => {
        getTaskDropdownList();
    }, [type]);

    useEffect(() => {
        documentsList?.length && setTaskDropdownList(documentsList);
        testList?.length && setTaskDropdownList(testList);
        quizList?.length && setTaskDropdownList(quizList);
    }, [documentsList, testList, quizList]);

    useEffect(() => {
        if(currentTask){
            const task = defineTask(currentTask);
            setDay(String(currentTask.day));
            setType(task.type);
            setTask({ id: task.id, name: task.name, label: task.name });
        }
    }, [currentTask]);

    useEffect(() => {
        if (!isLoading && createClick) {
            if (createTaskError) {
                toast.error(createTaskError);
            } else {
                toast.success(t(tokens.course.message.taskCreated));
                closeAndClearData();
            }
            setCreateClick(false);
        }
    }, [createTaskError, isLoading]);

    useEffect(() => {
        if (!isLoading && editClick) {
            if (updateTaskError) {
                toast.error(updateTaskError);
            } else {
                toast.success(t(tokens.course.message.taskUpdated));
                closeAndClearData();
            }
            setEditClick(false);
        }
    }, [updateTaskError, isLoading]);

    const defineTask = (task: any) => {
        let type;
        if (task.document) {
            type = { id: task.document.id, name: task.document.name, type: TASK_TYPES.DOCUMENT }
        } else if (task.quiz) {
            type = { id: task.quiz.id, name: task.quiz.name, type: TASK_TYPES.TEST }
        } else {
            type = { id: task.questionnaire.id, name: task.questionnaire.name, type: TASK_TYPES.QUESTIONNAIRE }
        };
        return type;
    };

    const getTaskDropdownList = (search?: string) => {
        switch (type) {
            case 'Document':
                dispatch(getDocuments(combineRequestBody(search)));
                break;
            case 'Test':
                dispatch(getTestList(combineRequestBody(search)));
                break;
            case 'Questionnaire':
                dispatch(getQuizList(combineRequestBody(search)));
                break;
        }
    };

    const combineRequestBody = (search?: string) => {
        const body: any = { page: 1 };
        if (type === TASK_TYPES.QUESTIONNAIRE) body.size = 15;
        if (type !== TASK_TYPES.QUESTIONNAIRE) body.perPage = 15;
        if (search && type === TASK_TYPES.DOCUMENT) body.name = search;
        if (search && type !== TASK_TYPES.DOCUMENT) body.search = search;
        return body;
    };

    const changeType = useCallback((event: SelectChangeEvent<any>) => {
        dispatch(setDocuments([]));
        dispatch(setTestList([]));
        dispatch(setQuizList([]));

        setType(event.target.value);
        setTask(null);
        setTaskValid(true);
    }, [setType]);

    const handleChangeTask = useCallback((task: any) => {
        setTask(task);
        setTaskValid(true);
    }, [setTask]);

    const changeTaskText = (event: ChangeEvent<HTMLInputElement>) => {
        debouncedHandleTaskSeacrh(event.target.value);
    };

    const onSearchTask = (value: string) => {
        if (value.length) {
            getTaskDropdownList(value)
        } else {
            getTaskDropdownList();
        }
    };

    const { debouncedWrapper: debouncedHandleTaskSeacrh } = useDebounce(onSearchTask, 1000);

    const onChangeDay = (event: ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        const regex = /^(100|[1-9]?\d|)$/;

        if (regex.test(input)) {
            setDay(input);
            setDayValid(true);
        }
    };

    function findMaxSortNumber(taskList: any[]) {
        if (taskList.length === 0) {
            return 1;
        } else {
            let max = taskList[0].sortNumber;
            for (let i = 1; i < taskList.length; i++) {
                if (taskList[i].sortNumber > max) {
                    max = taskList[i].sortNumber;
                }
            }
            return max + 1;
        }
    };

    const closeAndClearData = () => {
        onClose();
        setDay("");
        setTask(null);
        setDayValid(true);
        setTaskValid(true);

        dispatch(setTestList([]));
        dispatch(setQuizList([]));
    };

    const handleTask = () => {
        const isPassedValidation = validateData();
        if (isPassedValidation) {
            createOrEditTask();
        } else {
            toast.error(t(tokens.adverts.fillAllFields))
        };
    };

    const validateData = () => {
        const data = [
            { field: day, setField: setDayValid },
            { field: task, setField: setTaskValid },

        ];
        let allFieldsValid = true;
        for (const { field, setField } of data) {
            if (!field) {
                setField(false);
                allFieldsValid = false;
            }
        };

        return allFieldsValid;
    };

    const createOrEditTask = () => {
        if (taskId) {
            editTask();
        } else {
            dispatch(createTask({ body: getRequestBody(), courseId }));
            setCreateClick(true);
        }
    };

    const getRequestBody = () => {
        const body: any = { sortNumber: findMaxSortNumber(taskList), day: Number(day) };
        if (type === TASK_TYPES.DOCUMENT) body.documentId = task.id;
        if (type === TASK_TYPES.TEST) body.quizId = task.id;
        if (type === TASK_TYPES.QUESTIONNAIRE) body.questionnaireId = task.id;
        return body;
    };
    
    const editTask = () => {
        dispatch(updateTask({ body: {id: taskId, day: Number(day), sortNumber: currentTask?.sortNumber}, courseId }));
        setEditClick(true);
    };

    return {
        closeAndClearData, type, changeType, typeOptions, task, handleChangeTask, taskDropdownList, changeTaskText, day, onChangeDay, dayValid, taskValid,
        handleTask, taskId, createClick, editClick
    };
};