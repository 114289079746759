export const getStyles = () => {
    const styles = {
        backgraund: {
            backgroundColor: '#F8B043',
            position: 'fixed',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
        },
        loader: { 
            width: '30%',  
        }
    };
    return styles;
}