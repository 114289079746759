export const getStyles = () => {
    const styles = {
        title: { 
            mb: 1 
        },
        details: {  
            mb: '5px'
        },
        textValue: { 
            width: '100%' 
        }
    };
    return styles;
};