import { SagaIterator } from "redux-saga";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setUsersActivity } from "../../repository/slice";

function* getUsersActivityWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.get, "/api/badge/admin/list", payload);
        if (isError) {

        } else {
            yield put(setUsersActivity(data));
        }
    } catch (error) {
        console.error("getUsersActivityWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* getUsersActivityWatcher() {
    yield takeLatest("@saga/GET_USERS_ACTIVITY", getUsersActivityWorker);
};