import type { ChangeEvent, FC } from 'react';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import { ChartSeries } from '../../../presenter/useUserChart';
import InfoIcon from '@mui/icons-material/Info';
import { ChartBody } from '../chartBody';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../../locales/tokens';
import { ChartHeader } from '../../../../general/ui/components/chartHeader';
import { TextAutocomplete } from '../../../../../../ui-kit/textAutocomplete';

interface IProps {
  currentDocument: any;
  handleChangeDocument: (document: any) => void;
  changeDocumentText: (event: ChangeEvent<HTMLInputElement>) => void;
  optionList: any[];
  chartSeries: ChartSeries;
  questionList: string[];
};

export const SingleDocumentChart: FC<IProps> = ({ chartSeries, questionList, changeDocumentText, currentDocument, handleChangeDocument, optionList }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="flex-end" spacing={1}>
        <Stack width="400px">
          <TextAutocomplete
            optionList={optionList}
            value={currentDocument}
            handleChangeValue={handleChangeDocument}
            title='Документ'
            isMultiline={false}
            size='small'
            changeText={changeDocumentText}
          />
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={3}>
        <Stack spacing={1} pt={5}>
          {questionList.map((question: string, index: number) =>
            <Typography key={index} variant='body2' py="20px">{question}</Typography>
          )}
        </Stack>
        <Card elevation={2}>
          <ChartHeader title={t(tokens.analytics.analyticsResult)} subTitle={""} icon={<InfoIcon />} />
          <CardContent>
            <ChartBody chartSeries={chartSeries} questionList={questionList} />
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
};