import { ChangeEvent, FC, MutableRefObject, useMemo } from "react";
import { Box, Divider, Pagination, Stack, Table, Theme, useMediaQuery } from "@mui/material";
import { AccessTableBody } from "../accessTableBody";
import { getStyles } from "./styles";
import { AccessTableHead } from "../accessTableHead";

interface IProps {
    scrollRef: MutableRefObject<HTMLDivElement | null>;
    accessList: any[];
    sortedBy: string;
    page: number;
    pageAmount: number;
    handleDelete: (id: number) => void;
    handlePageChange: (event: ChangeEvent<unknown>, page: number) => void;
};

export const AccessTable: FC<IProps> = ({ accessList, handlePageChange, pageAmount, page, scrollRef, handleDelete, sortedBy }) => {
    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const styles = useMemo(() => getStyles(lgDown), [lgDown]);

    return (
        <>
            <Box mt={2}>
                <AccessTableHead />
                <Divider />
                <Box sx={styles.tableWrapper} ref={scrollRef}>
                    <Table>
                        <AccessTableBody
                            sortedBy={sortedBy}
                            accessList={accessList}
                            handleDelete={handleDelete}
                        />
                    </Table>
                </Box>
                <Stack direction='row' justifyContent='center' sx={styles.paginationWrapper}>
                    <Pagination
                        page={page}
                        count={pageAmount || 1}
                        color="primary"
                        onChange={handlePageChange}
                    />
                </Stack>
            </Box>
        </>
    )
};