import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { useDispatch, useSelector } from "react-redux";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { useDebounce } from "../../../../hooks/useDebounce";
import { getDocuments } from "../../../../store/document/useCases/getDocuments/action";
import { getTestList } from "../../../../store/test/useCases/getTestList/action";
import { getQuizList } from "../../../../store/quiz/useCases/getQuizList/action";
import { selectDocument } from "../../../../store/document/repository/selector";
import { selectTest } from "../../../../store/test/repository/selector";
import { selectQuiz } from "../../../../store/quiz/repository/selector";
import { setDocuments } from "../../../../store/document/repository/slice";
import { setTestList } from "../../../../store/test/repository/slice";
import { setQuizList } from "../../../../store/quiz/repository/slice";
import { createTraineeTask } from "../../../../store/trainee/useCases/createTraineeTask/action";
import { updateTraineeTask } from "../../../../store/trainee/useCases/updateTraineeTask/action";
import { selectTrainee } from "../../../../store/trainee/repository/selector";
import { selectCourse } from "../../../../store/course/repository/selector";
import { getCourseList } from "../../../../store/course/useCases/getCourseList/action";
import { setCourseList } from "../../../../store/course/repository/slice";
import { TASK_TYPES } from "../../traineePositionList/presenter/types";

export const useTaskCreate = (onClose: () => void, currentTask: any, positionId: string | undefined) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [type, setType] = useState(TASK_TYPES.DOCUMENT);
    const typeOptions = [
        { label: t(tokens.course.document), value: TASK_TYPES.DOCUMENT },
        { label: t(tokens.course.test), value: TASK_TYPES.TEST },
        { label: t(tokens.course.quiz), value: TASK_TYPES.QUESTIONNAIRE },
        { label: t(tokens.course.course), value: TASK_TYPES.COURSE },
    ];
    const [task, setTask] = useState<{id: number, name: string, label: string} | null>(null);
    const [taskValid, setTaskValid] = useState(true);
    const [taskDropdownList, setTaskDropdownList] = useState<any[]>([]);
    const { documentsList } = useSelector(selectDocument);
    const { testList } = useSelector(selectTest);
    const { quizList } = useSelector(selectQuiz);
    const { courseList } = useSelector(selectCourse);
    const { createTraineeTasksError, updateTraineeTasksError, isLoading, traineeTaskList } = useSelector(selectTrainee);
    const [createClick, setCreateClick] = useState(false);
    const [editClick, setEditClick] = useState(false);

    useEffect(() => {
        getTaskDropdownList();
    }, [type]);

    useEffect(() => {
        documentsList?.length && setTaskDropdownList(documentsList);
        testList?.length && setTaskDropdownList(testList);
        quizList?.length && setTaskDropdownList(quizList);
        courseList?.length && setTaskDropdownList(courseList);
    }, [documentsList, testList, quizList, courseList]);

    useEffect(() => {
        if (currentTask) {
            const task = defineTask(currentTask);
            setType(task.type);
            setTask({ id: task.id, name: task.name, label: task.name });
        }
    }, [currentTask]);

    useEffect(() => {
        if (!isLoading && createClick) {
            if (createTraineeTasksError) {
                toast.error(createTraineeTasksError);
            } else {
                toast.success(t(tokens.trainee.taskAdded));
                closeAndClearData();
            }
            setCreateClick(false);
        }
    }, [createTraineeTasksError, isLoading]);

    useEffect(() => {
        if (!isLoading && editClick) {
            if (updateTraineeTasksError) {
                toast.error(updateTraineeTasksError);
            } else {
                toast.success(t(tokens.course.message.taskUpdated));
                closeAndClearData();
            }
            setEditClick(false);
        }
    }, [updateTraineeTasksError, isLoading]);

    const defineTask = (task: any) => {
        let type;
        if (task.document) {
            type = { id: task.document.id, name: task.document.name, type: TASK_TYPES.DOCUMENT }
        } else if (task.quiz) {
            type = { id: task.quiz.id, name: task.quiz.name, type: TASK_TYPES.TEST }
        } else if (task.questionnaire) {
            type = { id: task.questionnaire.id, name: task.questionnaire.name, type: TASK_TYPES.QUESTIONNAIRE }
        } else {
            type = { id: task.course.id, name: task.course.name, type: TASK_TYPES.COURSE }
        };
        return type;
    };

    const getTaskDropdownList = (search?: string) => {
        switch (type) {
            case 'document':
                dispatch(getDocuments(combineRequestBody(search)));
                break;
            case 'quiz':
                dispatch(getTestList(combineRequestBody(search)));
                break;
            case 'questionnaire':
                dispatch(getQuizList(combineRequestBody(search)));
                break;
            case 'course':
                dispatch(getCourseList(combineRequestBody(search)));
                break;
        }
    };

    const combineRequestBody = (search?: string) => {
        const body: any = { page: 1 };
        if (type === TASK_TYPES.QUESTIONNAIRE) body.size = 15;
        if (type === TASK_TYPES.COURSE) body.size = 15;
        if (type !== TASK_TYPES.QUESTIONNAIRE) body.perPage = 15;
        if (search && type === TASK_TYPES.DOCUMENT) body.name = search;
        if (search && type !== TASK_TYPES.DOCUMENT) body.search = search;
        return body;
    };

    const changeType = useCallback((event: SelectChangeEvent<any>) => {
        dispatch(setDocuments([]));
        dispatch(setTestList([]));
        dispatch(setQuizList([]));
        dispatch(setCourseList([]));
        setType(event.target.value);
        setTask(null);
        setTaskValid(true);
    }, [setType]);

    const handleChangeTask = useCallback((task: any) => {
        setTask(task);
        setTaskValid(true);
    }, [setTask]);

    const changeTaskText = (event: ChangeEvent<HTMLInputElement>) => {
        debouncedHandleTaskSeacrh(event.target.value);
    };

    const onSearchTask = (value: string) => {
        if (value.length) {
            getTaskDropdownList(value)
        } else {
            getTaskDropdownList();
        }
    };

    const { debouncedWrapper: debouncedHandleTaskSeacrh } = useDebounce(onSearchTask, 1000);

    function findMaxSortNumber(taskList: any[]) {
        if (taskList.length === 0) {
            return 1;
        } else {
            let max = taskList[0].sortNumber;
            for (let i = 1; i < taskList.length; i++) {
                if (taskList[i].sortNumber > max) {
                    max = taskList[i].sortNumber;
                }
            }
            return max + 1;
        }
    };

    const closeAndClearData = () => {
        onClose();
        setTask(null);
        setTaskValid(true);

        dispatch(setTestList([]));
        dispatch(setQuizList([]));
        dispatch(setCourseList([]));
    };

    const handleTask = () => {
        const isPassedValidation = validateData();
        if (isPassedValidation) {
            createOrEditTask();
        } else {
            toast.error(t(tokens.adverts.fillAllFields))
        };
    };

    const validateData = () => {
        const data = [
            { field: task, setField: setTaskValid },

        ];
        let allFieldsValid = true;
        for (const { field, setField } of data) {
            if (!field) {
                setField(false);
                allFieldsValid = false;
            }
        };

        return allFieldsValid;
    };

    const createOrEditTask = () => {
        if (currentTask?.id) {
            // editTask();
        } else {
            dispatch(createTraineeTask({ body: getRequestBody(), positionId }));
            setCreateClick(true);
        }
    };

    const editTask = () => {
        dispatch(updateTraineeTask({ body: { id: currentTask.id, sortNumber: currentTask?.sortNumber }, positionId }));
        setEditClick(true);
    };

    const getRequestBody = () => {
        const body: any = { content_type: type, source_guid: positionId };
        if (type === TASK_TYPES.DOCUMENT) body.content_id = task?.id;
        if (type === TASK_TYPES.TEST) body.content_id = task?.id;
        if (type === TASK_TYPES.QUESTIONNAIRE) body.content_id = task?.id;
        if (type === TASK_TYPES.COURSE) body.content_id = task?.id;
        return body;
    };

    return {
        closeAndClearData, type, changeType, typeOptions, task, handleChangeTask, taskDropdownList, changeTaskText, taskValid,
        handleTask, createClick, editClick
    };
};