export const getStyles = () => {
    const styles = {
        avatar: {
            width: '30px',
            height: '30px',
            my: 1
        },
        role: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            lineHeight: 1.2,
        },
    };
    return styles;
};