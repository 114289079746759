import toast from 'react-hot-toast';
import { requester } from '../../../libs/requester/requester-axios';
import { pizzaWayAPI } from '../../pizzaWayAPI';
import { convertToFormData } from '../../../utils';
import { IUpsertBadgeParams } from '../repository/IBadge';



const badgeAPI = pizzaWayAPI.injectEndpoints({
    endpoints: (builder) => ({
        createBadge: builder.mutation<any, any>({
            async queryFn(body, { dispatch }) {
                try {
                    const { data, isError, message }: any = await requester.post('/api/badge/admin/create', convertToFormData(body));
                    if (isError) {
                        toast.error(message);
                        throw new Error(message);
                    };
                    return { data };
                } catch (e) {
                    throw e;
                }
            },
            invalidatesTags: ['Badge']
        }),
    }),
    overrideExisting: false,
});

export const { useCreateBadgeMutation } = badgeAPI;