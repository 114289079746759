import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setAssignDeleteError, setLoading } from "../../repository/slice";

function* deleteAssignWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.delete, "/api/assign/", payload);
        if (isError) {
            yield put(setAssignDeleteError(message));
        } else {
            yield put(setAssignDeleteError(null));
        }
    } catch (error) {
        console.error("deleteAssignWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* deleteAssignWatcher() {
    yield takeLatest("@saga/DELETE_ASSIGN", deleteAssignWorker);
};