import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { createQuestionError, setLoading, setQuestionId } from "../../repository/slice";
import { getSingleQuiz } from "../getSingleQuiz/action";

function* createQuizQuestionWorker({ payload }: any): SagaIterator {
    try {
        const formData = new FormData();
        for (const [key, value] of Object.entries(payload.body as Object)) {
            const isValue = value !== null && value !== undefined && value !== "";
            if (isValue) {
                if (key === 'answers'){
                    formData.append(key, JSON.stringify(value));
                } else {
                    formData.append(key, value);
                }
            }
        };
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.post, "/api/questionnaire-questions", formData);
        if (isError) {
            yield put(createQuestionError(message));
        } else {
            yield put(createQuestionError(null));
            yield put(setQuestionId(data.id));
            yield put(getSingleQuiz({ id: Number(payload.quizId) }));
        }
    } catch (error) {
        console.error("createQuizQuestionWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* createQuizQuestionWatcher() {
    yield takeEvery("@saga/CREATE_QUIZ_QUESTION", createQuizQuestionWorker);
};


/* 

if(value instanceof File){
                    formData.append(key, JSON.stringify(value));
                } else if (typeof value === 'object') {
                    formData.append(key, JSON.stringify(value));
                } else 

*/