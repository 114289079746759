import { SagaIterator } from "redux-saga";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setFile, setGetFileError, setLoading } from "../../repository/slice";

function* getFileWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(
            requester.get,
            "/api/file/get/" + payload.fileName
        );
        if (isError) {
            yield put(setGetFileError(message));
        } else {
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });            
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            const modifiedFileName = payload.documentName.replace(/\./g, "_");
            link.download = modifiedFileName;
            link.click();
        }
    } catch (error) {
        console.error("getFileWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* getFileWatcher() {
    yield takeLatest("@saga/GET_FILE", getFileWorker);
};