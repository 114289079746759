import { Box, Button, CircularProgress, FormGroup, Stack, TextField, Typography } from '@mui/material';
import { FC } from 'react';
import { FormControlLayout } from '../../../../ui-kit';
import { CheckboxAutocompleteMultiple } from '../../../../ui-kit/checkboxAutocompleteMultiple';
import { CheckboxAutocompleteSingle } from '../../../../ui-kit/checkboxAutocompleteSingle';
import { useUpsertBadge } from '../presenters/useUpsertBadge';
import { ImageUpload } from './ImageUpload';
import { getStyles } from './styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { paths } from '../../../../paths';
import { tokens } from '../../../../locales/tokens';

const UpsertBadge: FC = () => {
    const { t } = useTranslation();
    const { buttonSubmit, buttonCancel, spacesFields, wrapperButtons, editBtn } = getStyles();

    const { idBadge, isDisabledField, isLoadingGetBadge, handleSubmit, handleUpsertBadge, control, errors, setValue, clearErrors, watch, orgStructure,
        isLoadingCreateBadge, isLoadingUpdateBadge, handleBadgeNavigation } = useUpsertBadge();

    return (
        <Stack p={2} pb={4} rowGap={3}>
            <Stack direction='row' justifyContent='space-between'>
                <Typography variant="h4" fontWeight={600}>
                    {idBadge
                        ? "Редагування"
                        : "Cтворення"} бейджу
                </Typography>
            </Stack>
            {isLoadingGetBadge
                ? <Box height={"calc(100vh - 42px - 48px - 75px - 2rem)"} position={"relative"}>
                    <CircularProgress color="primary" sx={{ position: "absolute", top: "50%", left: "50%" }} />
                </Box>
                : <Box component={'form'} onSubmit={handleSubmit(handleUpsertBadge)} sx={{ display: "flex", flexDirection: "column" }}>
                    <Stack width="100%" spacing={3}>
                        <Stack direction="row" spacing={2}>
                            <Stack flex={1} spacing={3}>
                                <FormGroup>
                                    <FormControlLayout control={control} name='name'>
                                        <Typography variant="h6" sx={spacesFields} color={!errors['name'] ? 'inherit' : 'error'}>Назва*</Typography>
                                        <TextField disabled={isDisabledField} sx={{ width: "100%" }} label="Назва*" />
                                    </FormControlLayout>
                                </FormGroup>
                                <FormGroup>
                                    <FormControlLayout control={control} name='file'>
                                        <Typography variant="h6"  color={!errors['file'] ? 'inherit' : 'error'}>Зображення*</Typography>
                                        {/* {(!!idBadge && !isDisabledField) && <Typography variant="caption" color='gray' sx={spacesFields}>Файл можна оновити, видалення неможливе, оскільки файл є обов’язковим</Typography>} */}
                                        <ImageUpload isDisabled={isDisabledField} setValue={setValue} clearErrors={clearErrors} image={watch('file')} /* isEdit={!!idBadge} */ />
                                    </FormControlLayout>
                                </FormGroup>
                            </Stack>
                            <FormGroup sx={{ flex: 1 }}>
                                <FormControlLayout control={control} name='description'>
                                    <Typography variant="h6" sx={spacesFields} color={!errors['description'] ? 'inherit' : 'error'}>Опис</Typography>
                                    <TextField multiline disabled={isDisabledField} label="Опис" minRows={5} maxRows={10} />
                                </FormControlLayout>
                            </FormGroup>
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <FormGroup sx={{ flex: 1 }}>
                                <FormControlLayout control={control} name='points'>
                                    <Typography variant="h6" sx={spacesFields} color={!errors['points'] ? 'inherit' : 'error'}>Кількість балів</Typography>
                                    <TextField disabled={isDisabledField} type='number' label="Кількість балів" sx={{
                                        width: "100%",
                                        "input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button": {
                                            WebkitAppearance: "none",
                                            margin: 0
                                        }
                                    }} />
                                </FormControlLayout>
                            </FormGroup>
                            <Stack flex={1} />
                        </Stack>
                        <Stack direction="row" spacing={2}>
                            <FormGroup sx={{ minWidth: "49%" }}>
                                <Stack width="100%">
                                    <Typography variant="h6" sx={spacesFields} color={!errors['initiatorId'] ? 'inherit' : 'error'}>Ініціатор бейджу*</Typography>
                                    <FormControlLayout control={control} name='initiatorId'>
                                        <CheckboxAutocompleteSingle isDisabled={isDisabledField} title="Ініціатор бейджу*" optionList={orgStructure} />
                                    </FormControlLayout>
                                </Stack>
                            </FormGroup>
                            <Stack width="100%" />
                        </Stack>
                        <Stack direction="row" spacing={2} alignItems="flex-end">
                            <FormGroup sx={{ width: '49%' }}>
                                <Stack width="100%">
                                    <Typography variant="h6" sx={spacesFields} color={true ? 'inherit' : 'error'}>Призначити</Typography>
                                    <FormControlLayout control={control} name='assigneeIds'>
                                        <CheckboxAutocompleteMultiple title="Призначити" optionList={orgStructure} />
                                    </FormControlLayout>
                                </Stack>
                            </FormGroup>
                            <Stack flex={1}>
                                {!!idBadge &&
                                    <Button
                                        variant="contained"
                                        sx={editBtn}
                                        disabled={isDisabledField}
                                        startIcon={<EditOutlinedIcon />}
                                        component={Link}
                                        to={paths.dashboard.badge.editAssign.replace(":id", String(idBadge))}
                                    >
                                        {t(tokens.common.editAssign)}
                                    </Button>}
                            </Stack>
                        </Stack>
                    </Stack>
                    {!isDisabledField
                        && <Stack sx={wrapperButtons}>
                            <Button type="submit" variant="contained" disabled={isLoadingCreateBadge || isLoadingUpdateBadge} sx={buttonSubmit} >
                                {idBadge ? "Зберегти" : "Створити"}
                            </Button>
                            <Button onClick={handleBadgeNavigation} variant="outlined" sx={buttonCancel} >
                                Скасувати
                            </Button>
                        </Stack>}
                </Box>}
        </Stack >
    );
};

export default UpsertBadge;