import { IconButton, Stack, SvgIcon, Tooltip, Typography } from "@mui/material";
import { ChangeEvent, FC, useMemo } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import { getStyles } from "./styles";
import { useTranslation } from "react-i18next";
import "./styles.css";
import { tokens } from "../../locales/tokens";

interface IProps {
    isClear?: boolean;
    imageSrc: string;
    isError: boolean;
    title?: string;
    subtitle?: string;
    accept?: string;
    disabled?: boolean;
    onUploadImage: (event: ChangeEvent<HTMLInputElement>) => void;
    onDeleteImage: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export const ImagePicker: FC<IProps> = ({ imageSrc, isError, title, subtitle, accept, disabled, onUploadImage, onDeleteImage, isClear }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    return (
        <Stack>
            {!!title && <Typography variant="h6" color={isError ? 'error' : 'inherit'}>{title}*</Typography>}
            {!!subtitle && <Typography variant="caption" color={isError ? 'error' : 'GrayText'}>{subtitle}</Typography>}
            <Stack mt={1} position="relative" >
                <label htmlFor="fileInput" className="image">
                    <img
                        src={imageSrc || "/images/user/uploadImage.png"}
                        alt="uploadImage"
                        width={112}
                        height={112}
                        className="image"
                    />
                    {imageSrc && !disabled && !isClear ?
                        <IconButton sx={styles.clearImage} onClick={onDeleteImage}>
                            <Tooltip title={t(tokens.common.delete)} placement="right">
                                <SvgIcon><ClearIcon /></SvgIcon>
                            </Tooltip>
                        </IconButton>
                        : null
                    }
                </label>
                <input
                    disabled={disabled}
                    type="file"
                    id="fileInput"
                    accept={accept || "image/*"}
                    style={{ display: 'none' }}
                    onChange={onUploadImage}
                />
            </Stack>
        </Stack>
    )
};