import { FC } from "react";
import { SingleDocumentChart } from "./components/singleDocumentChart";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { useSingleDocumentAnalytics } from "../presenter/useSingleDocumentAnalytics";

export const SingleDocumentAnalytics: FC = () => {
    const { t } = useTranslation();
    const {  documentsList, changeDocumentText, handleChangeDocument, currentDocument, data } = useSingleDocumentAnalytics();

    return (
        <Stack m={2}>
            <Typography variant="h4" fontWeight={600}>
                Cтатистика документа
            </Typography>
            <Stack justifyContent="center" alignItems="center" mt={3}>
                <SingleDocumentChart
                    currentDocument={currentDocument}
                    handleChangeDocument={handleChangeDocument}
                    changeDocumentText={changeDocumentText}
                    optionList={documentsList}
                    chartSeries={[
                        { name: "Аналітика документа", data: data },
                    ]}
                    questionList={[
                        "Кількість лайків",
                        "Кількість дислайків",
                        "Кількість коментарів",
                    ]}
                />
            </Stack>
        </Stack>
    )
};