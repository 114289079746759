import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectOrgStructure } from "../../../../../store/orgStructure/repository/selector";
import { CourseFilters } from "./types";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { selectCourse } from "../../../../../store/course/repository/selector";
import { getCourseList } from "../../../../../store/course/useCases/getCourseList/action";
import { updateOrgStructure } from "../../../../../store/orgStructure/useCases/updateOrgStructure/action";
import toast from "react-hot-toast";
import { tokens } from "../../../../../locales/tokens";
import { updateCourse } from "../../../../../store/course/useCases/updateCourse/action";
import { deleteCourse } from "../../../../../store/course/useCases/deleteCourse/action";
import { uploadCourseReport } from "../../../../../store/course/useCases/uploadCourseReport/action";
import { TIMESTAMP_DAY } from "../../../../../config";

export const useCourseList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [filters, updateFilters] = useState<CourseFilters>({ page: 1, rowsPerPage: 30, search: '', tab: 'Active', status: null, initiator: null, date: null });
    const { orgStructure } = useSelector(selectOrgStructure);
    const { courseList, courseCount, isLoading, updateCourseError, deleteCourseError, getCourseListError } = useSelector(selectCourse);
    const [pageAmount, setPageAmount] = useState(0);
    const [updateClick, setUpdateClick] = useState(false);
    const [deleteClick, setDeleteClick] = useState(false);
    const [isProccessCourse, setProcessCourse] = useState(false);
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        dispatch(getCourseList(combineRequestBody()));
        setProcessCourse(true);
    }, [filters]);

    useEffect(() => {
        if (courseCount) {
            setPageAmount(Math.ceil(courseCount / filters.rowsPerPage));
        }
    }, [courseCount]);

    useEffect(() => {
        dispatch(updateOrgStructure());
    }, []);
    
    useEffect(() => {
        if (!isLoading && updateClick) {
            if (updateCourseError) {
                toast.error(updateCourseError)
            } else {
                toast.success(t(tokens.course.message.courseUpdated));
                dispatch(getCourseList(combineRequestBody()));
            }
            setUpdateClick(false);
        }
    }, [updateCourseError, isLoading]);

    useEffect(() => {
        if (!isLoading && deleteClick) {
            if (deleteCourseError) {
                toast.error(deleteCourseError)
            } else {
                toast.success(t(tokens.course.message.courseRemoved));
                dispatch(getCourseList(combineRequestBody()));
            }
            setDeleteClick(false);
        }
    }, [deleteCourseError, isLoading]);

    useEffect(() => {
        if (!isLoading && isProccessCourse) {
            if (getCourseListError) {
                toast.error(getCourseListError)
            };
            setTimeout(() => setProcessCourse(false), 400);
        }
    }, [getCourseListError, isLoading]);

    const handleSearchCourseText = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        debouncedHandleCourseSeacrh(e.target.value);
    };

    const onSearchCourse = (value: string) => {
        const query = value ? value : '';
        if (query.length !== 1) {
            updateFilters((prevState) => ({ ...prevState, search: query, page: 1 }));
            scrollTopList();
        }
    };

    const { debouncedWrapper: debouncedHandleCourseSeacrh } = useDebounce(onSearchCourse, 1000);
    
    const onSelectInitiator = (selectedKeysValue: string, node: any) => {
        if ('children' in node) {
            return;
        }
        updateFilters((prevState) => ({ ...prevState, page: 1, initiator: node }));
        scrollTopList();
    };

    const onChangeInitiator = () => {
        if (filters.initiator) {
            updateFilters((prevState) => ({ ...prevState, page: 1, initiator: null }));
        }
    };

    const resetFilters = useCallback((): void => {
        updateFilters((prevState) => ({ ...prevState, page: 1, rowsPerPage: 30, status: null, initiator: null, date: null }));
        scrollTopList();
    }, [updateFilters]);

    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        updateFilters((prevState) => ({ ...prevState, page }));
        scrollTopList();
    }, [updateFilters]);
    
    const handleDateChange = useCallback((date: Date | null): void => {
        const newDate = date?.valueOf();
        const currentDate = newDate || null;
        updateFilters((prevState) => ({ ...prevState, page: 1, date: currentDate }));
        scrollTopList();
    }, [updateFilters]);

    const changeStatus = useCallback((status: any) => {
        updateFilters((prevState) => ({ ...prevState, page: 1, status }));
        scrollTopList();
    }, [updateFilters]);

    const handleTabsChange = (event: ChangeEvent<{}>, tab: string): void => {
        updateFilters((prevState: any) => ({ ...prevState, tab, page: 1 }));
        scrollTopList();
    };

    const handleStatus = (id: number, status: string) => {
        const testStatus = status === 'Hidden' ? 'Available' : 'Hidden';
        dispatch(updateCourse({ body: { status: testStatus }, id }));
        setUpdateClick(true);
    };

    const handleDelete = (id: number) => {
        dispatch(deleteCourse({ id }));
        setDeleteClick(true);
    };

    const handleUploadReport = (id: number, name: string) => {
        dispatch(uploadCourseReport({ id, name }));
    };

    const combineRequestBody = () => {
        const body: any = { page: filters.page, size: filters.rowsPerPage };
        if (filters.search) body.search = filters.search;
        if (filters.initiator) body.initiatorId = filters.initiator.value;
        if (filters.status) body.status = filters.status.value;
        if (filters.date) {
            body.date = new Date(filters.date + TIMESTAMP_DAY).toISOString();
        };
        if(filters.tab !== 'Active') body.deleted = 'true';
        return body;
    };

    const scrollTopList = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        };
    };

    return { searchText, handleSearchCourseText, pageAmount, resetFilters, handlePageChange, filters, orgStructure, changeStatus, handleDateChange,
        onSelectInitiator, onChangeInitiator, handleTabsChange, handleStatus, handleDelete, handleUploadReport, courseList, isProccessCourse, scrollRef }
};