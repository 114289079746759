import { IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { getStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../../locales/tokens";

interface IProps {
    isDisabled?: boolean;
    taskList: any[];
    defineCurrentTask: (index: number, questionId: number) => void;
    removeTask: (id: number) => void;
};

export const TaskList: FC<IProps> = ({ defineCurrentTask, removeTask, taskList, isDisabled }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    const defineTask = (task: any) => {
        let type;
        if (task?.document) {
            type = { name: task.document.name, type: t(tokens.course.document) }
        } else if (task?.quiz) {
            type = { name: task.quiz.name, type: t(tokens.course.test) }
        } else if (task?.questionnaire) {
            type = { name: task.questionnaire.name, type: t(tokens.course.quiz) }
        };
        return type;
    };

    return (
        <>
            {taskList?.map((task: any, index: number) => {
                const currentTask = defineTask(task);

                return (
                    <>
                        {!!currentTask ?
                            <Stack key={index} direction="row" alignItems="center" justifyContent="space-between" spacing={3} sx={styles.answer}>
                                <Typography variant="h6" fontWeight={600} width="10%">{task.day + t(tokens.course.numDay)}</Typography>
                                <Typography variant="body1" width="65%" sx={styles.name}>{!!currentTask && currentTask.name}</Typography>
                                <Typography variant="body1" width="25%" sx={styles.textField}>{!!currentTask && currentTask.type}</Typography>
                                <Stack direction="row" width="5%">
                                    <IconButton onClick={() => defineCurrentTask(index, task.id)} disabled={isDisabled}>
                                        <SvgIcon><EditIcon /></SvgIcon>
                                    </IconButton>
                                    <IconButton onClick={() => removeTask(task.id)} disabled={isDisabled}>
                                        <SvgIcon><DeleteIcon /></SvgIcon>
                                    </IconButton>
                                </Stack>
                            </Stack>
                            : <Stack key={index}/>
                        }
                    </>
                )
            })}
        </>
    )
};