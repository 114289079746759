import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLearningHisory, setLoading, setGetLearningnHistoryError } from "../../repository/slice";
import { setSingleUser } from "../../../user/repository/slice";

function* getLearningHistoryWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.get, `/api/admin/user/${payload.id}/learning-history`, payload);
        if (isError) {
            setGetLearningnHistoryError(message);
        } else {
            setGetLearningnHistoryError(null);
            yield put(setLearningHisory({ list: data.data.rows, count: data.data.count }));
            yield put(setSingleUser(data.user));
        }
    } catch (error) {
        console.error("getLearningHistoryWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* getLearningHistoryWatcher() {
    yield takeLatest("@saga/GET_LEARNING_HISTORY", getLearningHistoryWorker);
};
