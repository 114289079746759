import { ChangeEvent, FC, MutableRefObject, useMemo } from "react";
import { Box, Pagination, Stack, Table, TableBody, Theme, useMediaQuery } from "@mui/material";
import { EmptyPage } from "../../../../../content/category/categoryEmpty/ui";
import { tokens } from "../../../../../../locales/tokens";
import { useTranslation } from "react-i18next";
import { OrdersTableItem } from "../ordersTableItem";
import { IProduct } from "../../../../../../store/product/types/IProduct";
import { IOrder } from "../../../../../../store/order/types/IOrder";
import { getStyles } from "./styles";

interface IProps {
    scrollRef: MutableRefObject<HTMLDivElement | null>;
    orders: IOrder[];
    page: number;
    pageAmount: number;
    handlePageChange: (event: ChangeEvent<unknown>, page: number) => void;
    onProcessedOrder: (id: number) => void;
};

export const OrdersTable: FC<IProps> = ({ orders, page, pageAmount, handlePageChange, onProcessedOrder, scrollRef }) => {
    const { t } = useTranslation();
    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const styles = useMemo(() => getStyles(lgDown), [lgDown]);
    
    if (orders.length) {
        return (
            <>
                <Box>
                    <Box sx={styles.tableWrapper} ref={scrollRef}>
                        <Table>
                            <TableBody>
                                {orders.map(order => (
                                    <OrdersTableItem
                                        key={order.id}
                                        order={order}
                                        onProcessedOrder={onProcessedOrder}
                                    />
                                ))
                                }
                            </TableBody>
                        </Table>
                    </Box>
                    <Stack direction='row' justifyContent='center' sx={styles.paginationWrapper}>
                        <Pagination
                            page={page}
                            count={pageAmount || 1}
                            color="primary"
                            onChange={handlePageChange}
                        />
                    </Stack>
                </Box>
            </>
        )
    } else {
        return (
            <Stack my={3}>
                <EmptyPage
                    title={t(tokens.order.emptyListTitle)}
                    description={t(tokens.order.emptyListDescription)}
                />
            </Stack>
        )
    }
};