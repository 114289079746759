import { useCallback, useEffect, useState } from "react";
import { selectAnalytics } from "../../../../store/analytics/repository/selector";
import { useDispatch, useSelector } from "react-redux";
import { getLeaderBoardData } from "../../../../store/analytics/useCases/getLeaderBoardData/action";
import { getOrgStructure } from "../../../../store/orgStructure/useCases/getOrgStructure/action";
import { selectOrgStructure } from "../../../../store/orgStructure/repository/selector";

export const useLeaderBoardAnalytics = () => {
    const dispatch = useDispatch();
    const { leaderBoardData } = useSelector(selectAnalytics);
    const { orgStructure } = useSelector(selectOrgStructure);
    const [departmentList, setDepartmentList] = useState<any[]>([]);
    const [currentDepartment, setCurrentDepartment] = useState<{ id: string, label: string, name: string, value?: string}>({ id: "2a95006d-a322-11ee-91d7-00155d45d204", label: "Pizza Day", name: "Pizza Day", value: "Pizza Day" });

    useEffect(() => {
        dispatch(getOrgStructure());
    }, []);

    useEffect(() => {
        if (!!orgStructure.length) {
            setDepartmentList(extractDepartments(orgStructure))
        }
    }, [orgStructure]);

    useEffect(() => {
        currentDepartment && dispatch(getLeaderBoardData({ id: currentDepartment.id }));
    }, [currentDepartment]);

    function extractDepartments(data: any[]) {
        const result: any[] = [];
        const traverse = (nodes: any[]) => {
            nodes.forEach(node => {
                if (node.departmentGuid) {
                    result.push({
                        id: node.departmentGuid,
                        name: node.name,
                        label: node.name
                    });
                }
                if (node.children) {
                    traverse(node.children);
                }
            });
        };
        traverse(data);
        return result;
    };

    const changeDepartment = useCallback((department: any) => {
        setCurrentDepartment(department);
    }, [setCurrentDepartment]);

    return { departmentList, changeDepartment, currentDepartment, leaderBoardData }
};