import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setDeleteQuestionError } from "../../repository/slice";
import i18next from "i18next";
import toast from "react-hot-toast";
import { tokens } from "../../../../locales/tokens";
import { getSingleQuiz } from "../getSingleQuiz/action";

function* deleteQuizQuestionWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.delete, "/api/questionnaire-questions/" + payload.questionId);
        if (isError) {
            yield put(setDeleteQuestionError(message));
        } else {
            yield put(setDeleteQuestionError(null));
            yield put(getSingleQuiz({ id: payload.quizId }))
            toast.success(i18next.t(tokens.test.message.questionRemoved));
        }
    } catch (error) {
        console.error("deleteQuizQuestionWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* deleteQuizQuestionWatcher() {
    yield takeEvery("@saga/DELETE_QUIZ_QUESTION", deleteQuizQuestionWorker);
};
