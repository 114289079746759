export const getStyles = (isDragActive: boolean) => {
    const styles = {
        container: {
            alignItems: "center",
            border: 1,
            borderRadius: 1,
            borderStyle: "dashed",
            borderColor: "gray",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            outline: "none",
            p: 6,
            pb: 7,
            ...(isDragActive && {
                backgroundColor: "action.active",
                opacity: 0.5,
            }),
            "&:hover": {
                backgroundColor: "#f0f0f0",
                cursor: "pointer",
            },
        },
        description: {
            "& span": {
                textDecoration: "underline",
            },
        },
        item: {
            border: 1,
            minHeight: 70,
            borderColor: "divider",
            borderRadius: 1,
            "& + &": {
                mt: 1,
            },
        },
        itemBox: {
            width: 50,
            height: "auto",
            maxHeight: 50,
            objectFit: "cover",
            objectPosition: "center center",
            borderRadius: 0.5,
        },
        avatar: {
            height: 64,
            width: 64
        }
    }

    return styles;
};