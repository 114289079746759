import { FC } from "react";
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { IconButton, SvgIcon } from "@mui/material";

interface IProps {
    category: any;
    swapSections: (direction: "UP" | "DOWN", index: number, firstSection: any, sectionIndex?: number | undefined) => void;
    index: number;
    sectionIndex: number | undefined;
}

export const SwapButtons: FC<IProps> = ({ swapSections, category, index, sectionIndex }) => {
    const onSwapUp = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        swapSections('UP', index, category, sectionIndex);
    };

    const onSwapDown = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.stopPropagation();
        swapSections('DOWN', index, category, sectionIndex);
    };

    return (
        <>
            <IconButton onClick={onSwapUp}>
                <SvgIcon >
                    <KeyboardDoubleArrowUpIcon />
                </SvgIcon>
            </IconButton>
            <IconButton onClick={onSwapDown}>
                <SvgIcon>
                    <KeyboardDoubleArrowDownIcon />
                </SvgIcon>
            </IconButton>
        </>
    )
};