import { Fragment } from "react";
import { TreeItemSection } from "../modules/user/userList/ui/components/treeItemSection";
import { TreeItemUser } from "../modules/user/userList/ui/components/treeItemUser";

export function renderUserTree(nodes: any[], key: string, handleStatus: (id: number, isBanned: boolean) => void) {
    return (
        <>
            {nodes.map((node) => {
                return (
                    <Fragment key={node.name + key}>
                        {'children' in node ?
                            <TreeItemSection key={node.name} node={node} renderUserTree={renderUserTree} handleStatus={handleStatus} />
                            :
                            <TreeItemUser key={node.name} node={node} handleStatus={handleStatus} />
                        }
                    </Fragment>
                )
            })}
        </>
    );
};