import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading } from "../../repository/slice";
import { getTraineePositions } from "../getTraineePositions/action";
import toast from "react-hot-toast";

function* createTraineePositionWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { isError }: IResponse<any> = yield call(requester.post, '/api/intern', payload);
        if (isError) {
        } else {
            toast.success("Посада додана");
            yield put(getTraineePositions());
        }
    } catch (error) {
        console.error("createTraineePositionWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* createTraineePositionWatcher() {
    yield takeEvery("@saga/CREATE_TRAINEE_POSITION", createTraineePositionWorker);
};
