import { Autocomplete, TextField } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { collectOptionList } from "../../utils/collectOptionList";
import { useCategoryAutocomplete } from "../../modules/content/documents/documentsCreate/presenters/useCategoryAutocomplete";
import { CategoryModal } from "../../modules/content/documents/documentsCreate/ui/components/categoryModal";
import { collectOptionChildrenList } from "../../utils/collectOptionChildrenList";

interface IProps {
    isDisabled?: boolean;
    label: string;
    value: any | null;
    onCategoryChange: (value: any) => void;
};

export const CategoryAutocomplete: FC<IProps> = ({ label, value, onCategoryChange, isDisabled }) => {
    const { t } = useTranslation();
    const { dialogValue, handleClose, handleOnChange, filterBankCardOptions, isOpen, categoryList, setDialogValue, maxSortNumber } = useCategoryAutocomplete(onCategoryChange);
 
    return (
        <>
            <Autocomplete
                disablePortal
                onChange={(e, value) => handleOnChange(e, value)}
                value={value}
                options={collectOptionChildrenList(categoryList) as any[]}
                filterOptions={filterBankCardOptions}
                getOptionLabel={(option) => {
                    if (typeof option === 'string'){
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.name;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option) =>
                    <li {...props} key={option.name + option.id}>{option.name}</li>
                }
                freeSolo
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label={label}
                    />
                }
                clearIcon={false}
                sx={{ width: '100%' }}
                disabled={isDisabled}
            /> 
            <CategoryModal
                categoryName={dialogValue.name}
                isOpen={isOpen}
                maxSortNumber={maxSortNumber}
                handleToggle={handleClose}
                setDialogValue={setDialogValue}
            />
        </>
    )
};