import { useTheme } from "@mui/material/styles";

export type ChartSeries = {
    name: string;
    data: number[];
}[];

export const useUserChart = (questionList: string[]) => {
  const theme = useTheme();

  return {
    chart: {
      background: 'transparent',
      stacked: true,
      toolbar: {
        show: false
      }
    },
    colors: [
        "#16B364",
    ],
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    grid: {
      borderColor: theme.palette.divider,
      padding: {
        bottom: 0,
        left: 0,
        right: 0,
        top: 0
      },
      strokeDashArray: 2,
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        columnWidth: '32px',
        horizontal: true
      }
    },
    theme: {
      mode: theme.palette.mode
    },
    xaxis: {
      max: 100,
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      lines: {
        show: true
      },
      categories: questionList,
      labels: {
        style: {
          colors: theme.palette.text.secondary
        }
      }
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false
      },
      lines: {
        show: true
      },
    },
  };
};