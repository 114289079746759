import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setOrderError, setOrderList, setOrderLoading } from "../../repository/slice";
import { IOrder } from "../../types/IOrder";

export const ORDERS_PER_PAGE = 30;

function* getOrderWorker({ payload }: any): SagaIterator {
    try {
        yield put(setOrderLoading(true));
        const body = { ...payload, perPage: ORDERS_PER_PAGE };
        const { data, message, isError }: IResponse<{ count: number, rows: IOrder[] }> = yield call(requester.get, "/api/shop/order", body);
        if (isError) {
            yield put(setOrderError(message));
        } else {
            yield put(setOrderList(data));
        }
    } catch (error) {
        console.error("getOrdersWorker: ", error);
    } finally {
        yield put(setOrderLoading(false));
    };
};

export function* getOrderWatcher() {
    yield takeLatest("@saga/GET_ORDERS", getOrderWorker);
};