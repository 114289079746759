export const getStyles = (isDark: boolean, size?: string) => {
    const styles = {
        clearIcon: {
            color: isDark ? "#ffffff" : "#757575",
            backgroundColor: isDark ? "#363535" : "#ffffff",
            position: 'relative',
            top: '-3px',
            right: '7px',
            mt: size ? 0 : 1
        },
        suffixIcon: {
            color: isDark ? "#ffffff" : "#757575",
            mt: size ? 0 : 2
        },
        wrapper: {
            ".ant-select-selector": {
                borderColor: isDark ? "#666565 !important" : "#C7C7C7 !important",
                backgroundColor: isDark ? "#363535 !important" : "#ffffff",
                color: isDark ? "#ffffff" : "#000000",
                fontSize: size ? "14px" : "16px",
                minHeight: size ? "40px" : "56px",
                ":hover": {
                    borderColor: isDark ? "#ffffff !important" : "#E5E5E5"
                },
                ":focus-within": {
                    border: "2px solid #F9B754 !important"
                }
            },
            ".ant-select-selection-placeholder": {
                color: "#8D8C8C !important"
            },
        }
    };
    return styles;
};