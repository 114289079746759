import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "../../../../hooks/useDebounce";
import { getUsers } from "../../../../store/user/useCases/getUsers/action";
import { selectUser } from "../../../../store/user/repository/selector";
import { tokens } from "../../../../locales/tokens";
import { getGeneralQuestionList } from "../../../../store/generalQuestion/useCases/getGeneralQuestionList/action";
import { TIMESTAMP_DAY } from "../../../../config";
import { selectGeneralQuestion } from "../../../../store/generalQuestion/repository/selector";
import { updateGeneralQuestion } from "../../../../store/generalQuestion/useCases/updateGeneralQuestion/action";
import toast from "react-hot-toast";
import { setUpdateQuestionError } from "../../../../store/generalQuestion/repository/slice";
import { IAutocompleteItem, IFilters } from "./types";

export const useGeneralQuestionList = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [filters, updateFilters] = useState<IFilters>({ page: 1, rowsPerPage: 30, search: '', status: null, date: null });
    const { userList } = useSelector(selectUser);
    const [pageAmount, setPageAmount] = useState(0);
    const { generalQuestionList, generalQuestionCount, isLoading, getQuestionsError, updateQuestionError } = useSelector(selectGeneralQuestion);
    const [updateClick, setUpdateClick] = useState(false);
    const statusOptions: IAutocompleteItem[] = [
        { id: 1, name: t(tokens.feedback.inWork), value: "inWork" },
        { id: 2, name: t(tokens.feedback.takeToDevelop), value: "takeToDevelop" },
        { id: 3, name: t(tokens.feedback.refusal), value: "refusal" },
        { id: 4, name: t(tokens.feedback.duplicate), value: "duplicate" },
        { id: 5, name: t(tokens.feedback.pause), value: "pause" },
        { id: 6, name: t(tokens.feedback.notEnoughInformation), value: "notEnoughInformation" },
        { id: 7, name: t(tokens.feedback.embodied), value: "embodied" },
    ];
    const [isProccessQuestion, setProcessQuestion] = useState(false);
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        dispatch(getGeneralQuestionList(getRequestBody()));
    }, [filters]);

    useEffect(() => {
        dispatch(getUsers({ page: 1, perPage: 30 }));
    }, []);

    useEffect(() => {
        if (generalQuestionCount) {
            setPageAmount(Math.ceil(generalQuestionCount / filters.rowsPerPage));
        }
    }, [generalQuestionCount]);

    useEffect(() => {
        if (!isLoading && isProccessQuestion) {
            if (getQuestionsError) {
                toast.error(getQuestionsError)
            };
            setTimeout(() => setProcessQuestion(false), 400);
        }
    }, [getQuestionsError, isLoading]);

    useEffect(() => {
        if (!isLoading && updateClick) {
            if (updateQuestionError) {
                toast.error(updateQuestionError)
                dispatch(setUpdateQuestionError(null));
            } else {
                toast.success(t(tokens.feedback.message.questionUpdated));
                dispatch(getGeneralQuestionList(getRequestBody()));
                dispatch(setUpdateQuestionError(null));
            }
            setUpdateClick(false);
        }
    }, [updateQuestionError, isLoading]);

    const getRequestBody = () => {
        const body: any = { page: filters.page, perPage: filters.rowsPerPage };
        if (filters.search.length) body.search = filters.search;
        if (filters.status) body.status = filters.status.value;
        if (filters.date) body.date = new Date(filters.date + TIMESTAMP_DAY).toISOString();
        return body;
    };

    const scrollTopList = useCallback(() => {
        if (scrollRef.current) {
            // scrollRef.current.scrollTop = 0;
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [scrollRef]);

    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        updateFilters((prevState) => ({ ...prevState, page }));
        scrollTopList();
    }, [updateFilters]);

    const handleSearchUserText = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        debouncedHandleUserSeacrh(e.target.value);
    };

    const onSearchUser = (value: string) => {
        const query = value ? value : '';
        if (query.length !== 1) {
            updateFilters((prevState) => ({ ...prevState, search: query, page: 1 }));
        };
    };

    const { debouncedWrapper: debouncedHandleUserSeacrh } = useDebounce(onSearchUser, 1000);

    const resetFilters = useCallback((): void => {
        updateFilters((prevState) => ({ ...prevState, page: 1, status: null, date: null }));
    }, [updateFilters]);

    const handleDateChange = useCallback((date: Date | null): void => {
        const newDate = date?.valueOf();
        const currentDate = newDate || null;
        updateFilters((prevState) => ({ ...prevState, page: 1, date: currentDate }));
    }, [updateFilters]);

    const changeStatus = useCallback((status: IAutocompleteItem) => {
        updateFilters((prevState) => ({ ...prevState, page: 1, status }));
    }, [updateFilters]);

    const onUserChange = (value: IAutocompleteItem, questionId: number) => {
        const id = value ? value.id : null;
        dispatch(updateGeneralQuestion({ id: questionId, responsibleId: id }));
        setUpdateClick(true);
    };

    const changeUserText = (event: ChangeEvent<HTMLInputElement>) => {
        debouncedHandleUsersSelect(event.target.value);
    };

    const onSearch = (value: string) => {
        if (value === '') {
            dispatch(getUsers({ page: 1, perPage: 30 }));
            return;
        } else {
            dispatch(getUsers({ page: 1, perPage: 30, name: value }));
        };
    };

    const { debouncedWrapper: debouncedHandleUsersSelect } = useDebounce(onSearch, 1000);

    const changeQuestionStatus = (status: IAutocompleteItem, questionId: number) => {
        const statusValue = status ? status.value : null;
        dispatch(updateGeneralQuestion({ id: questionId, status: statusValue }));
        setUpdateClick(true);
    };

    return {
        searchText, handleSearchUserText, filters, resetFilters, changeStatus, handleDateChange, generalQuestionList, changeUserText,
        onUserChange, userList, changeQuestionStatus, statusOptions, pageAmount, handlePageChange, isProccessQuestion, scrollRef
    }
};