export const getStyles = () => {
    const styles = {
        logo: {
            width: '50%',
        },
        logoContainer: {
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: 3,
            animation: 'zoomInAndOut 1s infinite',
            mb: 2
        },
        button: {
            color: '#ffffff',
            height: '45px',
            mt: 1
        }
    };
    return styles;
};