import { IconButton, Typography } from '@mui/material';
import React, { useState, useRef, useEffect, useMemo, CSSProperties, FC } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface IProps {
    text: string;
};

export const ExpandableText: FC<IProps> = ({ text }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const [isClickable, setIsClickable] = useState(false);

    const styles = useMemo(() => getStyles(isExpanded, contentRef.current?.scrollHeight, isClickable), [isExpanded, contentRef.current?.scrollHeight, isClickable]);

    const toggleExpansion = () => {
        if (!contentRef.current) return;

        setIsExpanded(!isExpanded);
    };

    useEffect(() => {
        if (contentRef.current) {
            const thresholdHeight = parseFloat(window.getComputedStyle(contentRef.current).fontSize) * 4.5;
            setIsClickable(contentRef.current.scrollHeight > thresholdHeight);
        }
    }, [text]);

    return (
        <div ref={contentRef} style={styles.textContainer as CSSProperties} onClick={toggleExpansion}>
            <div>
                <Typography fontSize="14px" px={2} pt={1}>
                    {text}
                </Typography>
            </div>
            {isClickable && (
                <IconButton onClick={toggleExpansion} sx={styles.arrow}>
                    <ExpandMoreIcon fontSize='small'/>
                </IconButton>
            )}
        </div>
    );
};

export const getStyles = (isExpanded: boolean, scrollHeight: number | undefined, isClickable: boolean) => {
    let maxHeight = 'none';
    if (!isExpanded && scrollHeight) {
        maxHeight = '4.5em';
    } else if (scrollHeight) {
        maxHeight = `${scrollHeight}px`;
    }

    const styles: any = {
        textContainer: {
            // display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: isExpanded ? 1000 : 3,
            display: 'flex', 
            alignItems: 'flex-start',
            maxHeight: maxHeight,
            overflow: 'hidden',
            transition: 'max-height 0.3s ease-in-out',
            cursor: isClickable ? 'pointer' : 'default',
        },
        arrow: {
            mt: 2, 
            mr: 1,  
            transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)', 
            transition: 'transform 0.3s ease-in-out'
        }
    };
    return styles;
};