import { Button, Stack, SvgIcon, Tooltip } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../../locales/tokens";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { TextAutocomplete } from "../../../../../../../ui-kit/textAutocomplete";
import { TimePicker } from "../../../../../../../ui-kit/timePicker";
import { CheckboxAutocompleteSingle } from "../../../../../../../ui-kit/checkboxAutocompleteSingle";

interface IProps {
    filters: any;
    categoryList: any[];
    orgStructure: any[];
    handleDateChange: (date: Date | null) => void;
    handleChangeCategory: (value: any) => void;
    changeCategoryText: (event: ChangeEvent<HTMLInputElement>) => void;
    changeType: (type: any) => void;
    resetFilters: () => void;
    changeStatus: (status: any) => void;
    onChangeInitiator: () => void;
    onSelectInitiator: (selectedKeysValue: string, node: any) => void;
};

export const Filters: FC<IProps> = ({ filters, handleChangeCategory, changeType, changeStatus, categoryList, orgStructure,
    changeCategoryText, handleDateChange, resetFilters, onSelectInitiator, onChangeInitiator }) => {
    const { t } = useTranslation();

    const typeOptions = [
        { id: 1, name: t(tokens.documents.page), value: "Page" },
        { id: 3, name: t(tokens.documents.file), value: "File" },
        { id: 4, name: t(tokens.documents.video), value: "Video" },
        { id: 5, name: t(tokens.documents.link), value: "Link" },
        { id: 7, name: t(tokens.documents.gallery), value: "Gallery" },
    ];

    const statusOptions = [
        { id: 1, name: t(tokens.documents.publish), value: "Available" },
        { id: 2, name: t(tokens.documents.notPublish), value: "Hidden" }
    ]; 
    
    return (
        <Stack direction='row' alignItems='center' spacing={1} mt={1}>
            <Stack width="20%">
                <TextAutocomplete
                    title={t(tokens.documents.category)}
                    value={filters.category}
                    handleChangeValue={handleChangeCategory}
                    optionList={categoryList}
                    isMultiline={false}
                    changeText={changeCategoryText}
                    size="small"
                />
            </Stack>
            <Stack width="15%">
                <TextAutocomplete
                    title={t(tokens.documents.type)}
                    value={filters.type}
                    handleChangeValue={changeType}
                    optionList={typeOptions}
                    isMultiline={false}
                    size="small"
                />
            </Stack>
            <Stack width="15%">
                <TimePicker
                    value={filters.date}
                    handleDate={handleDateChange}
                    size="small"
                />
            </Stack>
            <Stack width="15%">
                <TextAutocomplete
                    title={t(tokens.documents.status)}
                    value={filters.status}
                    handleChangeValue={changeStatus}
                    optionList={statusOptions}
                    isMultiline={false}
                    size="small"
                />
            </Stack>
            <Stack width="30%" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <CheckboxAutocompleteSingle
                    size="small"
                    title={t(tokens.documents.initiator)}
                    value={filters.initiator}
                    optionList={orgStructure}
                    onSelect={onSelectInitiator}
                    onChange={onChangeInitiator}
                    isValid={true}
                    isDisabled={false}
                />
            </Stack>
            <Button
                size="small"
                onClick={resetFilters}
                variant="outlined"
                style={{ width: '5%' }}
            >
                <Tooltip title={t(tokens.documents.cancelFilters)}>
                    <SvgIcon style={{ fontSize: 32 }}>
                        <FilterAltOffIcon fontSize="small" />
                    </SvgIcon>
                </Tooltip>
            </Button>
        </Stack>
    )
};