import { FC } from "react";
import { SingleAdvertChart } from "./components/singleAdvertChart";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { useSingleAdvertAnalytics } from "../presenter/useSingleAdvertAnalytics";

export const SingleAdvertAnalytics: FC = () => {
    const { t } = useTranslation();
    const { advertList, changeAdvertText, handleChangeAdvert, currentAdvert, data } = useSingleAdvertAnalytics();

    return (
        <Stack m={2}>
            <Typography variant="h4" fontWeight={600}>
                Cтатистика оголошення
            </Typography>
            <Stack justifyContent="center" alignItems="center" mt={3}>
                <SingleAdvertChart
                    currentAdvert={currentAdvert}
                    handleChangeAdvert={handleChangeAdvert}
                    changeAdvertText={changeAdvertText}
                    optionList={advertList}
                    chartSeries={[
                        { name: "Аналітика оголошення", data: data },
                    ]}
                    questionList={[
                        "Кількість лайків",
                        "Кількість дислайків",
                        "Кількість коментарів",
                    ]}
                />
            </Stack>
        </Stack>
    )
};