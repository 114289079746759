import { TreeItem } from "@mui/x-tree-view";
import { Dispatch, FC } from "react";
import { TreeCategoryItemLabel } from "../treeCategoryItemLabel";
import { useDragDrop } from "../../../presenters/useDragDrop";
import { ICategory } from "../../../../../../../types/category";

interface IProps {
    node: ICategory;
    isPoster: boolean;
    index: number;
    sectionIndex: number | undefined;
    handleStatus: (id: number, isHidden: boolean) => void;
    setUpdateClick: Dispatch<boolean>;
    capturedCategory: ICategory | null;
    setCapturedCategory: Dispatch<any>;
    defineCategoryId: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => void; 
    swapSections: (direction: "UP" | "DOWN", index: number, firstSection: any) => void;
}

export const TreeCategoryItem: FC<IProps> = ({ node, handleStatus, isPoster, capturedCategory, setCapturedCategory, setUpdateClick, swapSections, index, sectionIndex, defineCategoryId }) => {
    const { dragStartHandler, dragOverHandler, dragDropHandler, dragLeaveHandler, dragEndHandler } = useDragDrop(node, capturedCategory, setCapturedCategory, setUpdateClick);

    return (
        <div
            style={{ cursor: 'grab' }}
        // onDragStart={dragStartHandler}
        // onDragLeave={dragLeaveHandler}
        // onDragEnd={dragEndHandler}
        // onDragOver={dragOverHandler}
        // onDrop={dragDropHandler}
        >
            <TreeItem
                key={node.name + node.sortNumber}
                nodeId={node.name + node.id}
                // draggable
                label={
                    <TreeCategoryItemLabel
                        category={node}
                        isPoster={isPoster}
                        handleStatus={handleStatus}
                        swapSections={swapSections}
                        index={index}
                        sectionIndex={sectionIndex}
                        defineCategoryId={defineCategoryId}
                    />
                }
            />
        </div >
    )
};