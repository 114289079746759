import { FC, useMemo } from "react";
import { Box, Stack, Table, TableBody, Theme, useMediaQuery } from "@mui/material";
import { IRole } from "../../../../../../store/role/types/IRole";
import { EmptyPage } from "../../../../../content/category/categoryEmpty/ui";
import { tokens } from "../../../../../../locales/tokens";
import { useTranslation } from "react-i18next";
import { AppPageTableItem } from "../appPageTableItem";
import { IAppPage } from "../../../../../../store/appPage/types/IAppPage";
import { getStyles } from "./styles";

interface IProps {
    appPageList: IAppPage[];
    onChangeVisibility: (id: number, isHidden: boolean) => void;
    onDelete: (id: number) => void;
};

export const AppPageTable: FC<IProps> = ({ appPageList, onChangeVisibility, onDelete }) => {
    const { t } = useTranslation();
    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const styles = useMemo(() => getStyles(lgDown), [lgDown]);
    
    if (appPageList.length) {
        return (
            <Box sx={styles.tableWrapper} /* ref={scrollRef} */>
                <Table>
                    <TableBody>
                        {appPageList.map(page => (
                            <AppPageTableItem
                                key={page.id}
                                page={page}
                                onChangeVisibility={onChangeVisibility}
                                onDelete={onDelete}
                            />
                        ))
                        }
                    </TableBody>
                </Table>
            </Box>
        )
    } else {
        return (
            <Stack my={3}>
                <EmptyPage
                    title={t(tokens.appPage.emptyListTitle)}
                    description={t(tokens.appPage.emptyListDescription)}
                />
            </Stack>
        )
    }
};