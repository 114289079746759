import { FC } from "react";
import { TableBody } from "@mui/material";
import { AccessTableItem } from "../accessTableItem";

interface IProps {
    sortedBy: string;
    accessList: any[];
    handleDelete: (id: number) => void;
};

export const AccessTableBody: FC<IProps> = ({ accessList, handleDelete, sortedBy }) => {

    return (
        <TableBody>
            {accessList.map(access => (
                <AccessTableItem
                    key={access.id}
                    sortedBy={sortedBy}
                    access={access}
                    handleDelete={handleDelete}
                />
            ))}
        </TableBody>
    )
};