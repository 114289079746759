export interface IOption {
    label: string;
    name: string;
    id: number;
    isKnowledgeCategory: boolean;
};

export const collectOptionChildrenList: Function = (options: any[]) => {
    const transformItem = (item: any): IOption => {
        return {
            label: item.name,
            name: item.name,
            id: item.id,
            isKnowledgeCategory: item.isKnowledgeCategory
        };
    };

    const result: IOption[] = [];

    const collectOptions = (items: any[]) => {
        items.forEach((item) => {
            result.push(transformItem(item));
            if (item.children && item.children.length > 0) {
                collectOptions(item.children);
            }
        });
    };

    collectOptions(options);

    return result;
};