import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { getCategories } from "../../../../../store/category/useCases/getCategories/action";
import { selectCategory } from "../../../../../store/category/repository/selector";
import toast from "react-hot-toast";
import { createCategory } from "../../../../../store/category/useCases/createCategory/action";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../locales/tokens";
import { deleteFile } from "../../../../../store/file/useCases/deleteFile/action";

export const useCategoryModal = (categoryName: string, handleToggle: () => void, setDialogValue: Dispatch<SetStateAction<any>>, maxSortNumber: number) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [title, setTitle] = useState('');
    const [titleValid, setTitleValid] = useState(true);
    const [imageValid, setImageValid] = useState(true);
    const [category, setCategory] = useState<any>(null);
    const { categoryList } = useSelector(selectCategory);
    const [hiddenChecked, setHiddenChecked] = useState(false);
    const [showChecked, setShowChecked] = useState(false);
    const [imageSrc, setImageSrc] = useState('');
    const [image, setImage] = useState<any>(null);
    const [createClick, setCreateClick] = useState(false);
    const { createCategoryError, isLoading } = useSelector(selectCategory);

    useEffect(() => {
        dispatch(getCategories({ isHidden: false }));
    }, []);

    useEffect(() => {
        if (categoryName) {
            setTitle(categoryName);
        }
    }, [categoryName]);

    useEffect(() => {
        if (!isLoading && createClick) {
            if (createCategoryError) {
                toast.error(createCategoryError)
            } else {
                toast.success(t(tokens.category.messages.categoryCreated));
                setTimeout(() => {
                    setDialogValue((prevState: any) => ({ ...prevState, type: "New" }));
                    handleToggle();
                    clearFields();
                    dispatch(getCategories({ isHidden: false }));
                }, 1000);
            }
            setCreateClick(false);
        }
    }, [createCategoryError, isLoading]);

    const clearFields = () => {
        setTitle('');
        setCategory(null);
        setHiddenChecked(false);
        setShowChecked(false);
        setImage(null);
        setImageSrc('');
        setTitleValid(true);
        setImageValid(true);
    };

    const onChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
        setTitleValid(true);
    };

    const handleChangeCategory = useCallback((category: any) => {
        setCategory(category);
    }, [setCategory]);

    const changeCategoryText = (event: ChangeEvent<HTMLInputElement>) => {
        debouncedHandleCategorySeacrh(event.target.value);
    };

    const onSearchCategory = (value: string) => {
        if (value.length) {
            dispatch(getCategories({ name: value, isHidden: false }));
        } else {
            dispatch(getCategories({ isHidden: false }));
        }
    };

    const { debouncedWrapper: debouncedHandleCategorySeacrh } = useDebounce(onSearchCategory, 400);

    const handleHiddenChange = (event: ChangeEvent<HTMLInputElement>) => {
        setHiddenChecked(event.target.checked);
    };

    const handleShowChange = (event: ChangeEvent<HTMLInputElement>) => {
        setShowChecked(event.target.checked);
        if (!event.target.checked) {
            setImageSrc('');
            setImage(null);
            setImageValid(true);
            if (image?.categoryImageId) {
                dispatch(deleteFile({ fileName: image.filename }));
            }
        }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            // const maxSizeBytes = 20 * 1024 * 1024;
            // if (file.size > maxSizeBytes) {
            //     toast.error("Файл слишком большой. Максимальный размер: 20 MB.");
            //     return;
            // };

            const reader = new FileReader();
            reader.onload = (e) => {
                const newImageSrc = e.target?.result as string;
                setImageSrc(newImageSrc);
            };
            reader.readAsDataURL(file);
        }
        setImage(file);
        setImageValid(true);
    };

    const handleClearImageClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setImageSrc('');
        setImage(null);
    };

    const createNewCategory = () => {
        const isPassedValidation = validateData();
        if (isPassedValidation) {
            dispatch(createCategory({ body: getRequestBody(), image }));
            setCreateClick(true);
        } else {
            toast.error(t(tokens.adverts.fillAllFields))
        }
    };

    const validateData = () => {
        const data: any = [
            { field: title, setField: setTitleValid },
        ];
        if (showChecked) {
            data.push({ field: imageSrc, setField: setImageValid })
        };
        let allFieldsValid = true;
        for (const { field, setField } of data) {
            if (!field) {
                setField(false);
                allFieldsValid = false;
            }
        };

        return allFieldsValid;
    };

    const getRequestBody = () => {
        const body: any = { name: title };
        body.sortNumber = maxSortNumber + 1;
        if (category) body.parentId = category.id;
        body.isHidden = hiddenChecked;
        body.isKnowledgeCategory = showChecked;
        return body;
    };

    const closeAndClear = () => {
        handleToggle();
        clearFields();
    };

    return {
        title, onChangeTitle, handleChangeCategory, category, changeCategoryText, categoryList, handleHiddenChange, closeAndClear,
        handleShowChange, hiddenChecked, showChecked, imageSrc, handleFileChange, createNewCategory, titleValid,
        handleClearImageClick, imageValid
    }
};