import { IconButton, List, Stack, SvgIcon, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { getStyles } from "./styles";

interface IProps {
    isDisabled?: boolean;
    questions: any[];
    onDragEnd: (result: DropResult) => void;
    defineCurrentQuestion: (index: number, questionId: number) => void;
    removeQuestion: (sortNumber: number) => void;
};

export const QuestionList: FC<IProps> = ({ questions, removeQuestion, onDragEnd, defineCurrentQuestion, isDisabled }) => {
    const styles = useMemo(() => getStyles(), []);
    
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided: any, snapshot: any) => (
                    <List ref={provided.innerRef} {...provided.droppableProps}>
                        {questions.map((question: any, index: number) =>
                            <Draggable key={index} draggableId={`${question.sortNumber}-id`} index={index} isDragDisabled={isDisabled}>
                                {(provided: any, snapshot: any) => (
                                    <Stack key={index} direction="row" alignItems="center" justifyContent="space-between" spacing={3} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} sx={styles.answer}>
                                        <Typography variant="h4" fontWeight={600} width="10%">{index + 1}</Typography>
                                        <Typography variant="body1" width="65%">{question.title}</Typography>
                                        <Typography variant="body1" width="25%" sx={styles.textField}>{question.type.name}</Typography>
                                        {/* <Typography variant="body1" width="10%" sx={styles.textField} textAlign="center">{question.questionPoints}</Typography> */}
                                        <Stack direction="row" width="5%">
                                            <IconButton onClick={() => defineCurrentQuestion(index, question.id)} disabled={isDisabled}>
                                                <SvgIcon><EditIcon /></SvgIcon>
                                            </IconButton>
                                            <IconButton onClick={() => removeQuestion(question.sortNumber)} disabled={isDisabled}>
                                                <SvgIcon><DeleteIcon /></SvgIcon>
                                            </IconButton>
                                        </Stack>
                                    </Stack>
                                )}
                            </Draggable>
                        )}
                        {provided.placeholder}
                    </List>
                )}
            </Droppable>
        </DragDropContext>
    )
};