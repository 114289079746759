import { Box, Pagination, Stack, Table, Theme, useMediaQuery } from "@mui/material";
import { ChangeEvent, FC, MutableRefObject, useMemo } from "react";
import { NotificationTableBody } from "../notificationTableBody";
import { getStyles } from "./styles";

interface IProps {
    scrollRef: MutableRefObject<HTMLDivElement | null>;
    page: number;
    notifications: any[];
    handlePageChange: (event: ChangeEvent<unknown>, page: number) => void;
    pageAmount: number;
    handleDelete: (e: React.MouseEvent<HTMLButtonElement>, id: number) => void;
};

export const NotificationTable: FC<IProps> = ({ handleDelete, handlePageChange, notifications, page, pageAmount, scrollRef }) => {
    const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));
    const styles = useMemo(() => getStyles(lgDown), [lgDown]);
    
    return (
        <>
            <Box>
                <Box sx={styles.tableWrapper} ref={scrollRef}>
                    <Table>
                        <NotificationTableBody
                            notifications={notifications}
                            handleDelete={handleDelete}
                        />
                    </Table>
                </Box>
                <Stack direction='row' justifyContent='center' sx={styles.paginationWrapper}>
                    <Pagination
                        page={page}
                        count={pageAmount || 1}
                        color="primary"
                        onChange={handlePageChange}
                    />
                </Stack>
            </Box>
        </>
    )
};