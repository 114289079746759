import { SagaIterator } from "redux-saga";
import { all, call, delay, put, takeEvery } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, updateQuestionError } from "../../repository/slice";
import { createAnswer } from "../createAnswer/action";
import { updateAnswer } from "../updateAnswer/action";
import { deleteAnswer } from "../deleteAnswer/action";
import { getSingleTest } from "../getSingleTest/action";

function* updateQuestionWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.put, "/api/admin/question/update", payload.body);
        if (isError) {
            yield put(updateQuestionError(message));
        } else {
            yield put(updateQuestionError(null));

            const removedAnswers: any[] = [];
            if (payload?.removedRepliesIds?.length > 0) {
                payload.removedRepliesIds.map((id: number) =>
                    removedAnswers.push(put(deleteAnswer({ id })))
                );
                yield all(removedAnswers);
            };

            const addedRepliesList: any[] = [];
            if (payload?.newReplies?.length > 0) {
                payload.newReplies.map((reply: any) =>
                    addedRepliesList.push(put(createAnswer({ reply, questionId: payload.body.id })))
                );
                yield all(addedRepliesList);
            };

            const editedRepliesList: any[] = [];
            if (payload?.editedReplies?.length > 0) {
                const answers = payload.editedReplies.map((answer: any) => ({
                    title: answer.title,
                    isRight: answer.isRight,
                    sortNumber: answer.sortNumber,
                    id: answer.id
                }));
                answers.map((answer: any) =>
                    editedRepliesList.push(put(updateAnswer({ body: answer })))
                );
                yield all(editedRepliesList);
            };

            yield delay(600);
            if(payload?.testId){
                yield put(getSingleTest({ id: Number(payload.testId) }));
            };
        }
    } catch (error) {
        console.error("updateQuestionWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* updateQuestionWatcher() {
    yield takeEvery("@saga/UPDATE_QUESTION", updateQuestionWorker);
};
