import { Avatar, Dialog, IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from "react-i18next";
import { getStyles } from "./styles";
import { tokens } from "../../../../../../locales/tokens";

interface IProps {
    isOpen: boolean;
    onClose: () => void;
    currentBadge: any;
};

export const BadgeModal: FC<IProps> = ({ isOpen, onClose, currentBadge }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth='sm' fullWidth sx={{ borderRadius: '20px' }}>
            <Stack m={3}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" fontWeight={600}>
                        {t(tokens.users.studyHistory.aboutBadge)}
                    </Typography>
                    <IconButton onClick={onClose}>
                        <SvgIcon><ClearIcon /></SvgIcon>
                    </IconButton>
                </Stack>
                <Stack justifyContent="center" alignItems="center">
                    <Avatar
                        alt="User Avatar"
                        src={currentBadge?.image?.url}
                        sx={styles.avatar}
                    />
                </Stack>
                <Stack justifyContent="center" alignItems="center" mt={2}>
                    <Typography variant="h5" fontWeight={600} textAlign="center">{currentBadge?.name}</Typography>
                    <Typography textAlign="center">{currentBadge?.description}</Typography>
                </Stack>
                {!!currentBadge?.points &&
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="center" mt={2}>
                    <img src="/images/user/coins.svg" alt="coins" />
                    <Typography variant="h4" fontWeight={600}>{currentBadge?.points || 0}</Typography>
                    <Typography fontWeight={600}>{t(tokens.users.studyHistory.points)}</Typography>
                </Stack>}
            </Stack>
        </Dialog>
    )
};