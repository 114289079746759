import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setRestoreBackupError, setLoading } from "../../repository/slice";

function* restoreDataWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.get, "/api/backup/restore/" + payload.id);
        if (isError) {
            yield put(setRestoreBackupError(message));
        } else {
            yield put(setRestoreBackupError(null));
        }
    } catch (error) {
        console.error("restoreDataWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* restoreDataWatcher() {
    yield takeLatest("@saga/RESTORE_DATA", restoreDataWorker);
};
