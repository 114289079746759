export const getStyles = () => {
    const styles = {
        option: { 
            '& > img': { 
                mr: 2, flexShrink: 0 
            }, 
            display: 'flex', 
            flexDirection: 'row', 
            justifyContent: 'space-between' 
        }
    };
    return styles;
};