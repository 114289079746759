import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../../paths";
import { useSelector } from "react-redux";
import { selectRole } from "../../../../store/role/repository/selector";
import { createRole } from "../../../../store/role/useCases/createRole/action";
import toast from "react-hot-toast";
import { setRoleError } from "../../../../store/role/repository/slice";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";

const initialForm = {
    name: ''
};

const initialFormErrors = {
    roleNameError: false
};

export const useRoleCreate = () => {
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const { t } = useTranslation();
    const [form, setForm] = useState(initialForm);
    const [formErrors, setFormErrors] = useState(initialFormErrors);
    const [isClick, setIsClick] = useState(false);
    const { isRoleLoading, selectedRole, roleError } = useSelector(selectRole);

    useEffect(() => {
        if (!isRoleLoading && selectedRole) {
            navigation(paths.dashboard.users.rolesEdit.replace(':id', String(selectedRole.id)), { replace: true })
        };
    }, [isRoleLoading, selectedRole]);

    useEffect(() => {
        if (!isRoleLoading && isClick) {
            if (roleError) {
                toast.error(roleError)
                dispatch(setRoleError(null));
            } else {
                toast.success(t(tokens.roles.messages.roleCreated));
            }
            setIsClick(false);
        };
    }, [isRoleLoading]);

    const isValidForm = useCallback(() => {
        let valid = true;
        let errors = formErrors;
        const { name } = form;
        if (name.length) {
            errors = { ...formErrors, roleNameError: false };
        } else {
            valid = false;
            errors = { ...formErrors, roleNameError: true };
        };
        setFormErrors(errors);
        return valid;
    }, [form]);

    const onChangeRoleName = (e: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, name: e.target.value });
        if (formErrors.roleNameError) {
            setFormErrors({ ...formErrors, roleNameError: false });
        };
    };

    const onCreateRole = () => {
        const isValid = isValidForm();
        if (isValid) {
            setIsClick(true);
            dispatch(createRole(form));
        } else {
            toast.error(t(tokens.common.fillAllFields));
        };
    };

    const onCancel = () => navigation(paths.dashboard.users.roles);

    return { form, formErrors, isClick, onChangeRoleName, onCreateRole, onCancel };
};