import { IconButton, Stack, SvgIcon, Tooltip, Typography, CircularProgress } from "@mui/material";
import { ChangeEvent, FC, RefObject, useMemo, useState } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../../locales/tokens";
import { getStyles } from "./styles";

interface IProps { 
    fileInputRef?: RefObject<HTMLInputElement>;
    imageSrc: string;
    handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleClearImageClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export const ImagePicker: FC<IProps> = ({ imageSrc, handleFileChange, handleClearImageClick, fileInputRef }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const [loading, setLoading] = useState(false);

    const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setLoading(true);

        if (event.target.files && event.target.files[0]) {
            handleFileChange(event);
        } else {
            setLoading(false);
        }
    };

    const handleImageLoad = () => {
        setLoading(false); 
    };

    return (
        <Stack>
            <Typography>{t(tokens.test.uploadFile)}</Typography>
            <Stack position="relative" mt={1}>
                <label htmlFor="fileInput" className="image">
                    <Tooltip title={imageSrc ? t(tokens.common.edit) : ''} followCursor>
                        <div style={{ position: 'relative', width: 100, height: 100 }}>
                            {loading && (
                                <CircularProgress 
                                    size={24}
                                    style={{ 
                                        position: 'absolute', 
                                        top: '50%', 
                                        left: '50%', 
                                        marginTop: -12, 
                                        marginLeft: -12,
                                        zIndex: 1
                                    }}
                                />
                            )}
                            <img
                                src={imageSrc || "/images/user/uploadImage.png"}
                                alt="uploadImage"
                                width={100}
                                height={100}
                                className="image"
                                onLoad={handleImageLoad}
                                style={{ display: loading ? 'none' : 'block' }}
                            />
                        </div>
                    </Tooltip>
                    {(imageSrc && !loading) &&
                        <IconButton onClick={handleClearImageClick} sx={styles.clearImage}>
                            <SvgIcon sx={{ fontSize: '14px' }}><ClearIcon /></SvgIcon>
                        </IconButton>
                    }
                </label>
                <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleFileInputChange}
                    ref={fileInputRef}
                />
            </Stack>
        </Stack>
    );
};





// import { IconButton, Stack, SvgIcon, Tooltip, Typography } from "@mui/material";
// import { ChangeEvent, FC, RefObject, useCallback, useMemo } from "react";
// import ClearIcon from '@mui/icons-material/Clear';
// import { useTranslation } from "react-i18next";
// import { tokens } from "../../../../../../../locales/tokens";
// import { getStyles } from "./styles";

// interface IProps { 
//     fileInputRef?: RefObject<HTMLInputElement>;
//     imageSrc: string;
//     handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
//     handleClearImageClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
// };

// export const ImagePicker: FC<IProps> = ({ imageSrc, handleFileChange, handleClearImageClick, fileInputRef }) => {
//     const { t } = useTranslation();
//     const styles = useMemo(() => getStyles(), []);

//     return (
//         <Stack>
//             <Typography>{t(tokens.test.uploadFile)}</Typography>
//             <Stack position="relative" mt={1}>
//                 <label htmlFor="fileInput" className="image">
//                     <Tooltip title={imageSrc ? t(tokens.common.edit) : ''} followCursor>
//                         <img
//                             src={imageSrc || "/images/user/uploadImage.png"}
//                             alt="uploadImage"
//                             width={100}
//                             height={100}
//                             className="image"
//                         />
//                     </Tooltip>
//                     {imageSrc ?
//                         <IconButton onClick={handleClearImageClick} sx={styles.clearImage}>
//                             <SvgIcon sx={{ fontSize: '14px' }}><ClearIcon /></SvgIcon>
//                         </IconButton>
//                         : null
//                     }
//                 </label>
//                 <input
//                     type="file"
//                     id="fileInput"
//                     accept="image/*"
//                     style={{ display: 'none' }}
//                     onChange={handleFileChange}
//                     ref={fileInputRef}
//                 />
//             </Stack>
//         </Stack>
//     )
// };