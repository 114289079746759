import { createFilterOptions } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../../../../store/category/useCases/getCategories/action";
import { selectCategory } from "../../../../../store/category/repository/selector";

export const useCategoryAutocomplete = (onCategoryChange: (value: any) => void) => {
    const dispatch = useDispatch();
    const [isOpen, setOpen] = useState(false);
    const [dialogValue, setDialogValue] = useState({ name: '', type: '' });
    const { categoryList } = useSelector(selectCategory);
    const [maxSortNumber, setMaxSortNumber] = useState(0);
    
    useEffect(() => {
        dispatch(getCategories(combineRequestBody()));
    }, [dialogValue.type]);

    useEffect(() => {
        if (categoryList) {
            setMaxSortNumber(findMaxSortNumber(categoryList));
        }
    }, [categoryList]);

    const findMaxSortNumber = (tree: any[]): number => {
        const traverse = (node: any): number => {
            let maxSortNumber = node.sortNumber;

            if (node.children && node.children.length > 0) {
                const childrenMaxSort = Math.max(...node.children.map(traverse));
                maxSortNumber = Math.max(maxSortNumber, childrenMaxSort);
            }

            return maxSortNumber;
        };

        const maxSortNumber = Math.max(...tree.map(traverse), -Infinity);
        return maxSortNumber;
    };

    const combineRequestBody = () => {
        let body: any = { isHidden: false };
        if (dialogValue.name) body.name = dialogValue.name;
        return body;
    };

    const handleClose = () => {
        setDialogValue({ name: '', type: '' });
        setOpen(false);
    }; 

    const handleOnChange = (event: any, newValue: any) => {
        if (typeof newValue === 'string') {
            setTimeout(() => {
                setOpen(true);
                setDialogValue({ name: newValue, type: '' });
            });
        } else if (newValue && newValue.inputValue) {
            setOpen(true);
            setDialogValue({ name: newValue.inputValue, type: '' });
        } else {
            onCategoryChange(newValue);
        };
    };

    const filter = createFilterOptions<any>();

    const filterBankCardOptions = (options: any[], params: any) => {
        const filtered = filter(options, params);
        if (params.inputValue !== '') {
            filtered.push({
                inputValue: params.inputValue,
                name: `Додати: "${params.inputValue}"`,
                type: ''
            });
        };
        return filtered;
    };

    return { dialogValue, handleClose, handleOnChange, filterBankCardOptions, isOpen, setDialogValue, categoryList, maxSortNumber }
};