import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { tokens } from "../../../../locales/tokens";
import { ChangeEvent, ReactNode, useCallback, useEffect, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { updateOrgStructure } from "../../../../store/orgStructure/useCases/updateOrgStructure/action";
import { selectOrgStructure } from "../../../../store/orgStructure/repository/selector";
import { useNavigate, useParams } from "react-router-dom";
import { paths } from "../../../../paths";
import toast from "react-hot-toast";
import { selectAdvert } from "../../../../store/advert/repository/selector";
import { getSingleAdvert } from "../../../../store/advert/useCases/getSingleAdvert/action";
import { updateAdvert } from "../../../../store/advert/useCases/updateAdvert/action";
import htmlToDraft from "html-to-draftjs";
import { ContentState, EditorState, convertFromRaw, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { setSingleAdvert } from "../../../../store/advert/repository/slice";
import { IError } from "../../../../types/error";
import { selectUser } from "../../../../store/user/repository/selector";
import { getPositions } from "../../../../store/user/useCases/getPositions/action";
import { ensureParagraphTag, extractTablesFromHtml, transformHtml, transformTable } from "../../../../utils/transformToggleTable";

export const useAdvertEdit = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [initialized, setInitialized] = useState(false);
    const [type, setType] = useState('');
    const [theme, setTheme] = useState('');
    const [initiator, setInitiator] = useState<any>(null);
    const [groupPermissions, setGroupPermissions] = useState<any[]>([]);
    const [receiver, setReceiver] = useState<any[]>([]);
    const [publishChecked, setPublishChecked] = useState(true);
    const [notifyChecked, setNotifyChecked] = useState(false);
    const [imageSrc, setImageSrc] = useState('');
    const [image, setImage] = useState<any>(null);
    const [link, setLink] = useState('');
    const [startDate, setStartDate] = useState<number | null>(null);
    const [upToDate, setUpToDate] = useState<number | null>(null);
    const [typeValid, setTypeValid] = useState(true);
    const [themeValid, setThemeValid] = useState(true);
    const [imageValid, setImageValid] = useState(true);
    const [initiatorValid, setInitiatorValid] = useState(true);
    const [receiverValid, setReceiverValid] = useState(true);
    const [startDateValid, setStartDateValid] = useState(true);
    const [upToDateValid, setUpToDateValid] = useState(true);
    const [editClick, setEditClick] = useState(false);
    const { orgStructure } = useSelector(selectOrgStructure);
    const { isLoading, updateAdvertError, singleAdvert } = useSelector(selectAdvert);
    const { positionsList } = useSelector(selectUser);
    const [contentValid, setContentValid] = useState(true);
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [initialContent, setInitialContent] = useState('{"blocks":[],"entityMap":{}}');
    const [editorState, setEditorState] = useState(() => {
        if (initialContent) {
            try {
                const parsedContent = JSON.parse(initialContent);
                const contentState = convertFromRaw(parsedContent);
                return EditorState.createWithContent(contentState);
            } catch (error) {
                console.error('Error parsing initial content:', error);
            }
        }
        return EditorState.createEmpty();
    });
    const [deletedFileName, setDeletedFileName] = useState<string>('');
    const [departmentPermissions, setDepartmentPermissions] = useState<any[]>([]);
    const [positions, setPositions] = useState<any[]>([]);
    const [assignDepartment, setAssignDepartment] = useState<any[]>([]);
    const [assignPositions, setAssignPositions] = useState<any[]>([]);

    const advertTypeOptions = [
        { label: t(tokens.adverts.news), value: "News" },
        { label: t(tokens.adverts.event), value: "Event" },
        { label: t(tokens.adverts.promo), value: "Promo" },
    ];

    useEffect(() => {
        dispatch(updateOrgStructure());
        dispatch(getPositions());
        dispatch(getSingleAdvert({ id: Number(id) }));
        return () => {
            dispatch(setSingleAdvert(null));
        }
    }, []);

    useEffect(() => {
        if (type && !initialized) {
            setStartDate(null);
            setUpToDate(null);
            setLink('');
        }
        setInitialized(false);
    }, [type]);

    useEffect(() => {
        if (singleAdvert) {
            setType(singleAdvert.type);
            setTheme(singleAdvert.theme);
            setImage(singleAdvert.image);
            setImageSrc(singleAdvert.image?.url);
            setInitiator(singleAdvert.initiator.id);
            setNotifyChecked(singleAdvert.isInformed ? true : false);
            // setReceiver(singleAdvert.assigns.map((assign: any) => assign.id));
            // setGroupPermissions(singleAdvert.familiarizes.map((permission: any) => permission.id));
            setPublishChecked(singleAdvert.status === 'Available' ? true : false);
            if (singleAdvert.eventStartDate) {
                setStartDate(new Date(singleAdvert.eventStartDate).getTime());
            };
            if (singleAdvert.eventEndDate) {
                setUpToDate(new Date(singleAdvert.eventEndDate).getTime());
            };
            if (singleAdvert.content) {
                const updatedHtml = extractTablesFromHtml(singleAdvert.content);
                let result = transformHtml(updatedHtml);
                result = ensureParagraphTag(result);

                // console.log("result --->", result);
                // console.log("singleDocument.content --->", singleAdvert.content);

                const contentBlocks = htmlToDraft(result || '');
                const contentState = ContentState.createFromBlockArray(contentBlocks.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);

                setEditorState(editorState);
            };
            if (singleAdvert.deleted_at) {
                setIsDeleted(true);
            };
            setInitialized(true);
            setLink(singleAdvert.url ? singleAdvert.url : '');
        }
    }, [singleAdvert]);

    useEffect(() => {
        if (!isLoading && editClick) {
            if (updateAdvertError) {
                handleServerError(updateAdvertError);
            } else {
                toast.success(t(tokens.adverts.messages.advertUpdated));
                setTimeout(() => navigate(paths.dashboard.advert.index), 2000);
            }
            setEditClick(false);
        }
    }, [updateAdvertError, isLoading]);

    const handleServerError = (error: IError) => {
        if (error?.startsWith("value too long for type character varying(255)")) {
            toast.error(t(tokens.common.tooMuchSymbols));
            setThemeValid(false);
        } else if (error?.startsWith("value too long for type character varying(20000)")) {
            toast.error(t(tokens.common.tooMuchSymbols));
            setContentValid(false);
        } else {
            toast.error(error);
        }
    };

    const changeType = useCallback((event: SelectChangeEvent<any>) => {
        setType(event.target.value);
        setTypeValid(true);
    }, [setType]);

    const onChangeTheme = (event: ChangeEvent<HTMLInputElement>) => {
        setTheme(event.target.value);
        setThemeValid(true);
    };

    const onSelectInitiator = (selectedKeysValue: string, node: any) => {
        if ('children' in node) {
            return;
        }
        setInitiator(node);
        setInitiatorValid(true);
    };

    const onChangeInitiator = () => {
        if (initiator) {
            setInitiator(null);
        }
    };

    const onChangePermissions = (newValue: string[]) => {
        setGroupPermissions(newValue);
    };

    const onChangeDepartmentPermissions = (newValue: string[]) => {
        setDepartmentPermissions(newValue);
    };

    const onChangeAssignDepartment = (newValue: string[]) => {
        setAssignDepartment(newValue);
    };

    const onChangeReceiver = (newValue: string[]) => {
        setReceiver(newValue);
        setReceiverValid(true);
    };

    const handlePublishChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPublishChecked(event.target.checked);
    };

    const handleNotifyChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNotifyChecked(event.target.checked);
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];

        if (file) {
            // const maxSizeBytes = FILE_SIZE["1MB"];
            // if (file.size > maxSizeBytes) {
            //     toast.error(t(tokens.adverts.fileTooBig));
            //     return;
            // };

            const reader = new FileReader();
            reader.onload = (e) => {
                const newImageSrc = e.target?.result as string;
                setImageSrc(newImageSrc);
            };
            reader.readAsDataURL(file);
        }
        setImage(file);
        setImageValid(true);
    };

    const handleClearImageClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (image?.announcementImageId) {
            setDeletedFileName(image.filename);
            setImageSrc('');
            setImage(null);
        } else {
            setImageSrc('');
            setImage(null);
        }
    };

    const onChangeLink = (event: ChangeEvent<HTMLInputElement>) => {
        setLink(event.target.value);
    };

    const editAdvert = () => {
        const isPassedValidation = validateData();
        if (isPassedValidation) {
            dispatch(updateAdvert({ body: getRequestBody(), image: image, deletedFileName }));
            setEditClick(true);
        } else {
            toast.error(t(tokens.adverts.fillAllFields))
        }
    };

    const getHtml = useCallback(() => {
        const contentState = editorState.getCurrentContent();
        const htmlContent = draftToHtml(convertToRaw(contentState));

        const updatedHtml = htmlContent.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;/g, '');
        const updatedContent = transformTable(updatedHtml);

        // console.log("updatedHtml----->", updatedHtml);
        // console.log("updatedContent----->", updatedContent);

        return updatedContent;
    }, [editorState]);

    const validateData = () => {
        const html = getHtml();
        const content = html.length !== 8;
        const data = [
            { field: type, setField: setTypeValid },
            { field: theme, setField: setThemeValid },
            { field: content, setField: setContentValid },
            { field: image, setField: setImageValid },
            { field: initiator, setField: setInitiatorValid },
            // { field: receiver.length, setField: setReceiverValid },
        ];

        if (type === 'Event') {
            data.push({ field: startDate, setField: setStartDateValid });
            data.push({ field: upToDate, setField: setUpToDateValid });
        };

        let allFieldsValid = true;
        for (const { field, setField } of data) {
            if (!field) {
                setField(false);
                allFieldsValid = false;
            }
        };

        return allFieldsValid;
    };

    const cleanedGuiIds = (guiIds: string[]): string[] => {
        return guiIds.map(permission => {
            const [cleaned] = permission.split('%');
            return cleaned;
        });
    };

    const getRequestBody = () => {
        const body: any = { id: Number(id), type, theme, initiatorId: initiator.value };
        body.status = publishChecked ? "Available" : "Hidden";
        body.isInformed = notifyChecked;
        if (type === 'Event') {
            body.eventStartDate = new Date(Number(startDate)).toISOString();
            body.eventEndDate = new Date(Number(upToDate)).toISOString();
        }
        if (type === 'News') {
            body.url = link ? link : null;
        }
        if (receiver.length) body.assigns = receiver;
        if (groupPermissions.length) body.access = groupPermissions;
        if (departmentPermissions.length) body.accessDepartmentIds = cleanedGuiIds(departmentPermissions);
        if (positions.length) body.accessPositionIds = positions.map(item => item.id);
        if (assignDepartment.length) body.assignDepartmentIds = cleanedGuiIds(assignDepartment);
        if (assignPositions.length) body.assignPositionIds = assignPositions.map(item => item.id);
        const html = getHtml();
        if (html.length > 8) body.content = html;
        return body;
    };

    const cancelEdit = () => {
        navigate(paths.dashboard.advert.index);
    };

    const handleStartDateChange = useCallback((date: Date | null): void => {
        const currentDate: any = date?.valueOf() || null;
        setStartDate(currentDate);
        setStartDateValid(true);
    }, [setStartDate]);

    const handleUpToDateChange = useCallback((date: Date | null): void => {
        const currentDate: any = date?.valueOf() || null;
        setUpToDate(currentDate);
        setUpToDateValid(true);
    }, [setUpToDate]);

    const onEditorStateChange = (newEditorState: EditorState) => {
        setEditorState(newEditorState);
        setContentValid(true);
    };

    const handleChangePosition = (event: any, newValue: readonly any[]) => {
        setPositions([...newValue]);
    };

    const handleChangeAssignPosition = (event: any, newValue: readonly any[]) => {
        setAssignPositions([...newValue]);
    };

    return {
        advertTypeOptions, type, changeType, theme, onChangeTheme, initiator, onChangeInitiator, onSelectInitiator, orgStructure,
        groupPermissions, onChangePermissions, onChangeReceiver, receiver, publishChecked, notifyChecked, handlePublishChange,
        handleNotifyChange, editAdvert, cancelEdit, handleFileChange, imageSrc, link, onChangeLink, startDate, editClick, isDeleted,
        upToDate, handleStartDateChange, handleUpToDateChange, typeValid, themeValid, imageValid, initiatorValid, receiverValid,
        startDateValid, upToDateValid, handleClearImageClick, editorState, onEditorStateChange, setEditorState, contentValid, singleAdvert,
        onChangeDepartmentPermissions, departmentPermissions, positionsList, positions, handleChangePosition, onChangeAssignDepartment, assignDepartment,
        handleChangeAssignPosition, assignPositions, id
    }
};