import { FC } from "react";
import { TableBody } from "@mui/material";
import { AssignTableItem } from "../assignTableItem";

interface IProps {
    sortedBy: string;
    assignList: any[];
    handleDelete: (id: number) => void;
};

export const AssignTableBody: FC<IProps> = ({ assignList, handleDelete, sortedBy }) => {

    return (
        <TableBody>
            {assignList.map(assign => (
                <AssignTableItem
                    key={assign.id}
                    sortedBy={sortedBy}
                    assign={assign}
                    handleDelete={handleDelete}
                />
            ))}
        </TableBody>
    )
};