import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setOrderError, setOrderLoading } from "../../repository/slice";

function* updateOrderStatusWorker({ payload }: any): SagaIterator {
    try {
        yield put(setOrderLoading(true));
        const { data, message, isError }: IResponse<number[]> = yield call(requester.put, "/api/shop/order/status", payload);
        if (isError) {
            yield put(setOrderError(message));
        }
    } catch (error) {
        console.error("updateOrderStatusWorker: ", error);
    } finally {
        yield put(setOrderLoading(false));
    };
};

export function* updateOrderStatusWatcher() {
    yield takeLatest("@saga/UPDATE_ORDER_STATUS", updateOrderStatusWorker);
};