
export const getStyles = () => {
    const styles = {
        userTable: {
            minHeight: 180, 
            flexGrow: 1, 
            mt: 1 
        }
    };
    return styles;
};