import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';

interface IState {
    isLoading: boolean;
    accessList: any[];
    accessCount: number;
    accessDeleteError: IError;
    getAccessListError: IError;
};

const initialState: IState = {
    isLoading: false,
    accessList: [],
    accessCount: 0,
    accessDeleteError: null,
    getAccessListError: null,
};

const AccessSlice = createSlice({
    name: 'access',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setAccessList: (state, action: PayloadAction<{ rows: any[], count: number }>) => {
            state.accessList = action.payload.rows;
            state.accessCount = action.payload.count;
        },
        setAccessDeleteError: (state, action: PayloadAction<IError>) => {
            state.accessDeleteError = action.payload;
        },
        setGetAccessListError: (state, action: PayloadAction<IError>) => {
            state.getAccessListError = action.payload;
        },
    },
});

export const { setLoading, setAccessList, setAccessDeleteError, setGetAccessListError } = AccessSlice.actions;
export const AccessReducer = AccessSlice.reducer;