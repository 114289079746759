import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { selectAnalytics } from "../../../../store/analytics/repository/selector";
import { getSearchRequests } from "../../../../store/analytics/useCases/getSearchRequests/action";

export const useKnowledgeAnalytics = () => {
    const dispatch = useDispatch();
    const { searchRequests } = useSelector(selectAnalytics);

    // useEffect(()=> {
    //     dispatch(getSearchRequests());
    // }, []);

    const [dates, setDates] = useState<{ startDate: Dayjs | null, endDate: Dayjs | null }>({
        startDate: dayjs().startOf('month'),
        endDate: dayjs().endOf('month')
    });

    const handleRangeChange = (selectedDates: [start: Dayjs | null, end: Dayjs | null], dateStrings: [string, string]) => {
        if (selectedDates) {
            const [start, end] = selectedDates;
            setDates({ startDate: start, endDate: end });
        }
    };

    const disabledDate = (current: Dayjs) => {
        if (!current) {
            return false;
        }
        const { startDate, endDate } = dates;
        return false;
    };
    
    return { dates, handleRangeChange, disabledDate }
};