export const getStyles = (theme: any) => {
    const styles = {
        statusButton: {
            width: '150px',
            borderRadius: '8px',
            fontSize: '12px',
            py: 1,
            color: theme.palette.mode === 'dark' ?
                "#ffffff"
                :
                "#757575",
            borderColor: "#757575"
        },
        svgIcon: {
            fontSize: 26
        },
        cell: {
            py: 1
        }
    };
    return styles;
};