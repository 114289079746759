import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const useMobileNav = () => {
    const pathname = useLocation().pathname;
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handlePathnameChange = useCallback((): void => {
        if (isOpen) setIsOpen(false);
    }, [isOpen]);

    useEffect(() => {
        handlePathnameChange();
    }, [pathname]);

    const handleOpen = useCallback((): void => {
        setIsOpen(prevState => !prevState);
    }, []);

    return { isOpen, handleOpen };
};