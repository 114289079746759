import { SagaIterator } from "redux-saga";
import { put, call, takeLatest } from "redux-saga/effects";
import { setCount, setErrorsGetPushNotifications, setPushNotificationLoading, setPushNotifications } from "../../repository/actions";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";

function* getPushNotificationsWorker({ payload }: any): SagaIterator {
    try {
        yield put(setPushNotificationLoading(true));
        const { data, isError, message }: IResponse<any> = yield call( requester.get, "/api/admin/notification", payload);
        if (isError) {
            yield put(setErrorsGetPushNotifications(message));
        } else {
            yield put(setErrorsGetPushNotifications(null));
            yield put(setPushNotifications(data.data));
            yield put(setCount(data.count));
        }
    } catch (error) {
        console.error("getPushNotificationsWorker: ", error);
    } finally {
        yield put(setPushNotificationLoading(false));
    }
};

export function* getPushNotificationsWatcher() {
    yield takeLatest("@saga/GET_PUSH_NOTIFICATIONS", getPushNotificationsWorker);
};
