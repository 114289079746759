import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IState {
    isLoading: boolean;
    usersActivity: any[];
    testResults: any[];
    courseResults: any[];
    sectionFrequency: any[];
    searchRequests: any[];
    timeContent: any[];
    timeAdvert: any[];
    productsRate: any[];

    leaderBoardData: any;
    singleTestData: any;
    singleCourseData: any;
    singleDocumentData: any;
    singleAdvertData: any;
};

const initialState: IState = {
    isLoading: false,
    usersActivity: [],
    testResults: [],
    courseResults: [],
    sectionFrequency: [],
    searchRequests: [],
    timeContent: [],
    timeAdvert: [],
    productsRate: [],

    leaderBoardData: null,
    singleTestData: null,
    singleCourseData: null,
    singleDocumentData: null,
    singleAdvertData: null,
};

const AnalyticsSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setUsersActivity: (state, action: PayloadAction<any>) => {
            state.usersActivity = action.payload;
        },
        setTestResults: (state, action: PayloadAction<any>) => {
            state.testResults = action.payload;
        },
        setCourseResults: (state, action: PayloadAction<any>) => {
            state.courseResults = action.payload;
        },
        setSectionFrequency: (state, action: PayloadAction<any>) => {
            state.sectionFrequency = action.payload;
        },
        setSearchRquests: (state, action: PayloadAction<any>) => {
            state.searchRequests = action.payload;
        },
        setTimeContent: (state, action: PayloadAction<any>) => {
            state.timeContent = action.payload;
        },
        setTimeAdvert: (state, action: PayloadAction<any>) => {
            state.timeAdvert = action.payload;
        },
        setProductsRate: (state, action: PayloadAction<any>) => {
            state.productsRate = action.payload;
        },
        setLeaderBoardData: (state, action: PayloadAction<any>) => {
            state.leaderBoardData = action.payload;
        },
        setSingleTestData: (state, action: PayloadAction<any>) => {
            state.singleTestData = action.payload;
        },
        setSingleCourseData: (state, action: PayloadAction<any>) => {
            state.singleCourseData = action.payload;
        },
        setSingleDocumentData: (state, action: PayloadAction<any>) => {
            state.singleDocumentData = action.payload;
        },
        setSingleAdvertData: (state, action: PayloadAction<any>) => {
            state.singleAdvertData = action.payload;
        },
    },
});

export const { setLoading, setUsersActivity, setTestResults, setCourseResults, setSectionFrequency, setSearchRquests, setTimeContent, setTimeAdvert, setProductsRate, 
    setLeaderBoardData, setSingleTestData, setSingleCourseData, setSingleDocumentData, setSingleAdvertData } = AnalyticsSlice.actions;
export const AnalyticsReducer = AnalyticsSlice.reducer;