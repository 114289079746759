import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setPermissionError, setPermissionLoading } from "../../repository/slice";
import { getRoleById } from "../../../role/useCases/getRoleById/action";

function* addRolePermissionWorker({ payload }: any): SagaIterator {
    try {
        yield put(setPermissionLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.post, "/api/role-permission/create", payload);
        if (isError) {
            yield put(setPermissionError(message));
            yield put(getRoleById({ id: payload?.role_id }));
        }
    } catch (error) {
        console.error("addRolePermissionWorker: ", error);
    } finally {
        yield put(setPermissionLoading(false));
    };
};

export function* addRolePermissionWatcher() {
    yield takeLatest("@saga/ADD_ROLE_PERMISSION", addRolePermissionWorker);
};