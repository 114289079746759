export const getStyles = () => {
    const styles = {
        container: { 
            margin: '-0.1rem' 
        },
        wrapper: { 
            padding: '0.1rem' 
        },
        image: { 
            objectFit: 'cover', 
            borderRadius: '50%' 
        }
    };
    return styles;
};