import { Button, Stack, SvgIcon, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getStyles } from "./styles";
import { tokens } from "../../../../locales/tokens";
import AddIcon from '@mui/icons-material/Add';
import { Link } from "react-router-dom";
import { paths } from "../../../../paths";
import { SearchInput } from "../../../../ui-kit/searchInput/searchInput";
import { useNotificationList } from "../presenter/useNotificationList";
import { TabSelector } from "../../../../ui-kit/tabSelector/tabSelector";
import { EmptyPage } from "../../../content/category/categoryEmpty/ui";
import { NotificationTable } from "./components/notificationTable";

export const NotificationList: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { searchText, handleSearchNotificationText, filters, tabOptions, handleTabsChange, handlePageChange, handleDelete, pageAmount, notifications,
        scrollRef
    } = useNotificationList();

    return (
        <Stack m={2}>
            <Stack>
                <Stack direction='row' justifyContent='space-between'>
                    <Typography variant="h5" fontWeight={600}>
                        {t(tokens.notification.notificationList)}
                    </Typography>
                    <Button
                        startIcon={(<SvgIcon><AddIcon /></SvgIcon>)}
                        variant="contained"
                        sx={styles.createBtn}
                        component={Link}
                        to={paths.dashboard.notification.create}
                    >
                        {t(tokens.common.create)}
                    </Button>
                </Stack>
                <Stack mt={3}>
                    <SearchInput
                        searchText={searchText}
                        label="Пошук за назвою сповіщення"
                        handleSearch={handleSearchNotificationText}
                        size="small"
                    />
                </Stack>
                <Stack>
                    <TabSelector
                        tab={filters.tab}
                        tabOptions={tabOptions}
                        handleTabsChange={handleTabsChange}
                    />
                </Stack>
            </Stack>
            {notifications?.length ?
                <NotificationTable
                    scrollRef={scrollRef}
                    page={filters.page}
                    notifications={notifications}
                    handlePageChange={handlePageChange}
                    pageAmount={pageAmount}
                    handleDelete={handleDelete}
                />
                :
                <Stack>
                    <EmptyPage
                        title={t(tokens.notification.emptyListTitle)}
                        description={t(tokens.notification.emptyListDesc)}
                    />
                </Stack>
            }
        </Stack>
    )
};