import { FC, useMemo } from 'react';
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { getStyles } from './styles';

interface IProps {
    isDisabled?: boolean;
    id: string;
    label: string;
    value: string;
    values: { label: string, value: string }[];
    onChange: (event: any) => void;
}

export const AudienceSelector: FC<IProps> = ({ id, label, value, values, onChange, isDisabled }) => {
    const styles = useMemo(() => getStyles(), []);

    return (
        <Grid item>
            <FormControl fullWidth>
                <InputLabel id={id} variant='outlined'>{label}</InputLabel>
                <Select
                    labelId={id}
                    id={id}
                    value={value}
                    label={label}
                    onChange={onChange}
                    variant='outlined'
                    MenuProps={{ disableScrollLock: true }}
                    disabled={isDisabled}
                >
                    {values.map((value, index) => (
                        <MenuItem key={index} value={value.value} sx={styles.option}>{value.label}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Grid>
    );
}
