import { Stack, Typography } from "@mui/material";
import { ChangeEvent, FC, RefObject } from "react";
import { FileInput } from "../fileInput";
import { FileImage } from "../fileImage";
import { FileDocument } from "../fileDocument";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../../locales/tokens";

interface IProps {
    fileInputRef: RefObject<HTMLInputElement>;
    files: File[];
    fileValid: boolean;
    type: string;
    handleToggleImageFile: () => void;
    handleToggleVideoFile: () => void;
    handleDeleteImage: (name: string) => void;
    handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const FileSelector: FC<IProps> = ({ handleFileChange, files, handleDeleteImage, fileValid, type, handleToggleImageFile, handleToggleVideoFile, fileInputRef }) => {
    const { t } = useTranslation();

    return (
        <Stack>
            <Typography variant="h6" color={fileValid ? 'inherit' : 'error'}>
                {type === 'File' ? t(tokens.documents.image) : t(tokens.documents.video)}*
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
                <FileInput
                    fileInputRef={fileInputRef}
                    type={type}
                    handleFileChange={handleFileChange}
                />
                {files.length ?
                    <Stack direction="row">
                        {files.map((file: any) =>
                            file.type.startsWith('image/') ?
                                <FileImage
                                    key={file.size}
                                    file={file}
                                    handleDeleteImage={handleDeleteImage}
                                    handleToggleImageFile={handleToggleImageFile}
                                />
                                :
                                <FileDocument
                                    key={file.size}
                                    file={file}
                                    type={type}
                                    handleDeleteImage={handleDeleteImage}
                                    handleToggleVideoFile={handleToggleVideoFile}
                                />
                        )}
                    </Stack>
                    : null
                }
            </Stack >
        </Stack>
    )
};