import { SagaIterator } from "redux-saga";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setSingleTest, setLoading } from "../../repository/slice";

function* getSingleTestWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.get, "/api/admin/quiz/get/" + payload.id);
        if (isError) {

        } else {
            yield put(setSingleTest(data));
        }
    } catch (error) {
        console.error("getSingleTestWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* getSingleTestWatcher() {
    yield takeEvery("@saga/GET_SINGLE_TEST", getSingleTestWorker);
};
