import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setPermissionLoading, setPermissionError, setPermissions } from "../../repository/slice";
import { IPermission } from "../../types/IPermission";

function* getPermissionsWorker({ payload }: any): SagaIterator {
    try {
        yield put(setPermissionLoading(true));
        const { data, message, isError }: IResponse<{ count: number, rows: IPermission[] }> = yield call(requester.get, "/api/permission/get-list", payload);
        if (isError) {
            yield put(setPermissionError(message));
        } else {
            yield put(setPermissions(data));
        }
    } catch (error) {
        console.error("getPermissionsWorker: ", error);
    } finally {
        yield put(setPermissionLoading(false));
    };
};

export function* getPermissionsWatcher() {
    yield takeLatest("@saga/PERMISSION_LIST", getPermissionsWorker);
};