import { Box, IconButton, SvgIcon, Tooltip } from "@mui/material";
import { CSSProperties, FC, useMemo } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from "react-i18next";
import { getStyles } from "./styles";

interface IProps {
    file: any;
    handleToggleImageFile: () => void;
    handleDeleteImage: (name: string) => void;
};

export const FileImage: FC<IProps> = ({ file, handleDeleteImage, handleToggleImageFile }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []); 

    return (
        <Box position="relative" key={file.size}>
            <Tooltip  title="Показати зображення" arrow followCursor>
                <img
                    src={URL.createObjectURL(file || null)} 
                    onClick={handleToggleImageFile}
                    alt="image"
                    width={83} 
                    height={83}
                    style={styles.image as CSSProperties}
                />
            </Tooltip>
            <IconButton size="small" onClick={() => handleDeleteImage(file.name)} sx={styles.toolTip}>
                <SvgIcon fontSize="small" sx={{ fontSize: '14px' }}>
                    <ClearIcon />
                </SvgIcon>
            </IconButton>
        </Box>
    )
};