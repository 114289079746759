export const getStyles = () => {
    const styles = {
        div: {
            '&:before': { 
                display: 'none' 
            }, 
            '&.Mui-expanded': { 
                margin: 0 
            }
        },
        summary: {
            fontSize: '14px',
            fontWeight: 600,
            '&.MuiAccordionSummary-content': { 
                margin: '0px'
            }
        },
        text: {
            fontSize: '14px'
        },
        card: {
            mt: 1,
            borderRadius: '20px',
            pb: 2
        },
        container: {
            margin: '-0.1rem',
            pl: 2
        },
        wrapper: {
            padding: '0.1rem'
        },
        image: {
            objectFit: 'cover',
            borderRadius: '15px',
            cursor: 'pointer'
        }
    };
    return styles;
};