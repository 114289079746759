import { SagaIterator } from "redux-saga";
import { put, call, takeEvery } from "redux-saga/effects";
import { setErrorsUpdateNotification, setPushNotificationLoading } from "../../repository/actions";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";

function* updateNotificationWorker({ payload }: any): SagaIterator {
  try {
    yield put(setPushNotificationLoading(true));
    const formData = new FormData();
    for (const [key, value] of Object.entries(payload as Object)) {
      const isValue = value !== null && value !== undefined && value !== "";
      if (isValue) {
        if (Array.isArray(value)) {
          formData.append(key, JSON.stringify(value))
        } else {
          formData.append(key, value);
        };
      }
    };
    const { data, isError, message }: IResponse<any> = yield call( requester.put, "/api/user-notification", formData, { "Content-Type": "multipart/form-data" });
    if (isError) {
      yield put(setErrorsUpdateNotification(message));
    } else {
      yield put(setErrorsUpdateNotification(null));
    }
  } catch (error) {
    console.error("updateNotificationWorker: ", error);
  } finally {
    yield put(setPushNotificationLoading(false));
  }
};

export function* updateNotificationWatcher() {
  yield takeEvery("@saga/UPDATE_NOTIFICATION", updateNotificationWorker);
};
