import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setAppPageLoading, setAppPageError } from "../../repository/slice";
import { convertToFormData } from "../../../../utils";
import { getAppPages } from "../getAppPages/action";

function* updateAppPageWorker({ payload }: any): SagaIterator {
    try {
        yield put(setAppPageLoading(true));
        const body = convertToFormData(payload)
        const { data, message, isError }: IResponse<number[]> = yield call(requester.put, "/api/admin/dynamic-pages/update", body);
        if (isError) {
            yield put(setAppPageError(message));
        }
    } catch (error) {
        console.error("updateAppPageWorker: ", error);
    } finally {
        yield put(setAppPageLoading(false));
    };
};

export function* updateAppPageWatcher() {
    yield takeLatest("@saga/UPDATE_APP_PAGE", updateAppPageWorker);
};