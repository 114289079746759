import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setSingleUser } from "../../repository/slice";

function* getSingleUserWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.get, "/api/admin/user/get/" + payload.id);
        if (isError) {

        } else {
            yield put(setSingleUser(data));
        }
    } catch (error) {
        console.error("getSingleUserWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* getSingleUserWatcher() {
    yield takeLatest("@saga/GET_SINGLE_USER", getSingleUserWorker);
};
