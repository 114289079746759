import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { selectAnalytics } from "../../../../store/analytics/repository/selector";
import { getProductsRate } from "../../../../store/analytics/useCases/getProductsRate/action";

export const useProductAnalytics = () => {
    const dispatch = useDispatch();
    const { productsRate } = useSelector(selectAnalytics);

    useEffect(() => {
        dispatch(getProductsRate());
    }, []);
    
    const products: any[] = [
        { id: 1, name: "Назва товару", price: 1200, amount: 400, image: { fileUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTo9ovnmIXP5GUsXoozGx4_mxXJz8Flo3YBHSVJ4Fc_Sw&s" } },
        { id: 4, name: "Назва товару", price: 1200, amount: 400, image: { fileUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTo9ovnmIXP5GUsXoozGx4_mxXJz8Flo3YBHSVJ4Fc_Sw&s" } },
        { id: 9, name: "Довга назва товару", price: 1200, amount: 400, image: { fileUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTo9ovnmIXP5GUsXoozGx4_mxXJz8Flo3YBHSVJ4Fc_Sw&s" } },
        { id: 10, name: "Дуже дуже дуже дуже дуже дуже дуже дуже дуже довга назва товару", price: 1200, amount: 400, image: { fileUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTo9ovnmIXP5GUsXoozGx4_mxXJz8Flo3YBHSVJ4Fc_Sw&s" } },
        { id: 11, name: "Це воооооооооооооооооооооооооообще", price: 1200, amount: 400, image: { fileUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTo9ovnmIXP5GUsXoozGx4_mxXJz8Flo3YBHSVJ4Fc_Sw&s" } },
    ];

    const [dates, setDates] = useState<{ startDate: Dayjs | null, endDate: Dayjs | null }>({
        startDate: dayjs().startOf('month'),
        endDate: dayjs().endOf('month')
    });

    const handleRangeChange = (selectedDates: [start: Dayjs | null, end: Dayjs | null], dateStrings: [string, string]) => {
        if (selectedDates) {
            const [start, end] = selectedDates;
            setDates({ startDate: start, endDate: end });
        }
    };

    const disabledDate = (current: Dayjs) => {
        if (!current) {
            return false;
        }
        const { startDate, endDate } = dates;
        return false;
    };

    return { products, dates, handleRangeChange, disabledDate }
};