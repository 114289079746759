import { TableBody } from "@mui/material";
import { FC } from "react";
import { NotificationTableItem } from "../notificationTableItem";

interface IProps {
    notifications: any[];
    handleDelete: (e: React.MouseEvent<HTMLButtonElement>, id: number) => void;
};

export const NotificationTableBody: FC<IProps> = ({ notifications, handleDelete }) => {

    return (
        <TableBody>
            {notifications.map(notification => (
                <NotificationTableItem
                    notification={notification}
                    key={notification.id}
                    handleDelete={handleDelete}
                />
            ))}
        </TableBody>
    )
};