import { Button, Stack, SvgIcon, Tooltip } from "@mui/material";
import { FC } from "react";
import { CheckboxAutocompleteSingle } from "../checkboxAutocompleteSingle";
import { useTranslation } from "react-i18next";
import { TestFilters } from "../../modules/content/tests/testList/presenters/types";
import { tokens } from "../../locales/tokens";
import { TextAutocomplete } from "../textAutocomplete";
import { TimePicker } from "../timePicker";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

interface IProps {
    filters: TestFilters;
    orgStructure: any[];
    onChangeInitiator: () => void;
    resetFilters: () => void;
    changeStatus: (status: any) => void;
    handleDateChange: (date: Date | null) => void;
    onSelectInitiator: (selectedKeysValue: string, node: any) => void;
};

export const Filters: FC<IProps> = ({ filters, orgStructure, onChangeInitiator, onSelectInitiator, handleDateChange, changeStatus, resetFilters }) => {
    const { t } = useTranslation();

    const statusOptions = [
        { id: 1, name: t(tokens.documents.publish), value: "Available" },
        { id: 2, name: t(tokens.documents.notPublish), value: "Hidden" }
    ];

    return (
        <Stack direction='row' alignItems='center' spacing={1} mt={1}>
            <Stack width="20%">
                <TimePicker
                    value={filters.date}
                    handleDate={handleDateChange}
                    size="small"
                />
            </Stack>
            <Stack width="25%">
                <TextAutocomplete
                    title={t(tokens.documents.status)}
                    value={filters.status}
                    handleChangeValue={changeStatus}
                    optionList={statusOptions}
                    isMultiline={false}
                    size="small"
                />
            </Stack>
            <Stack width="50%" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                <CheckboxAutocompleteSingle
                    title={t(tokens.test.initiator)}
                    value={filters.initiator}
                    optionList={orgStructure}
                    onSelect={onSelectInitiator}
                    onChange={onChangeInitiator}
                    isValid={true}
                    isDisabled={false}
                    size="small"
                />
            </Stack>
            <Button
                size="small"
                onClick={resetFilters}
                variant="outlined"
                style={{ width: '5%' }}
            >
                <Tooltip title={t(tokens.documents.cancelFilters)}>
                    <SvgIcon style={{ fontSize: 32 }}>
                        <FilterAltOffIcon fontSize="small" />
                    </SvgIcon>
                </Tooltip>
            </Button>
        </Stack>
    )
};