import { FC } from "react";
import { TableBody } from "@mui/material";
import { LeaderboardTableItem } from "../leaderboardTableItem";

interface IProps {
    usersData: any[];
};

export const LeaderboardTableBody: FC<IProps> = ({ usersData }) => {

    return (
        <TableBody>
            {usersData?.map((item: any) => {
                return (
                    <LeaderboardTableItem
                        key={item.user.id}
                        item={item}
                    />
                );
            })}
        </TableBody>
    )
};