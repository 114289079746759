import { FC } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { Button, Stack, SvgIcon, Tooltip } from "@mui/material";
import { TimePicker } from "../../../../../../ui-kit/timePicker";
import { TextAutocomplete } from "../../../../../../ui-kit/textAutocomplete";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { IAutocompleteItem, IFilters } from "../../../presenter/types";

interface IProps {
    filters: IFilters;
    statusOptions: IAutocompleteItem[];
    resetFilters: () => void;
    changeStatus: (status: IAutocompleteItem) => void;
    handleDateChange: (date: Date | null) => void;
};

export const FeedbackFilters: FC<IProps> = ({ changeStatus, filters, handleDateChange, resetFilters, statusOptions }) => {
    const { t } = useTranslation();

    return (
        <Stack direction='row' alignItems='center' spacing={1} mt={1}>
            <Stack flex={2}>
                <TimePicker
                    value={filters.date}
                    handleDate={handleDateChange}
                    size="small"
                />
            </Stack>
            <Stack flex={2}>
                <TextAutocomplete
                    title={t(tokens.documents.status)}
                    value={filters.status}
                    handleChangeValue={changeStatus}
                    optionList={statusOptions}
                    isMultiline={false}
                    size="small"
                />
            </Stack>
            <Button
                size="small"
                onClick={resetFilters}
                variant="outlined"
                style={{ flex: .2 }}
            >
                <Tooltip title={t(tokens.documents.cancelFilters)}>
                    <SvgIcon style={{ fontSize: 32 }}>
                        <FilterAltOffIcon fontSize="small" />
                    </SvgIcon>
                </Tooltip>
            </Button>
        </Stack>
    )
};