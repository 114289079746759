import { FC, useMemo } from "react";
import { useTraineePositionList } from "../presenter/useTraineePositionList";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getStyles } from "./styles";
import { tokens } from "../../../../locales/tokens";
import { PositionTable } from "./components/positionTable";
import { EmptyPage } from "../../../content/category/categoryEmpty/ui";
import { AutocompleteCheckBox } from "../../../../ui-kit/autocompleteCheckBox";

export const TraineePositionList: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { filters, handlePageChange, handleDelete, pageAmount, scrollRef, isProccess, traineePositions,
        positions, handleChangePosition, positionsList } = useTraineePositionList();

    return (
        <Stack m={2}>
            <Stack direction='row' justifyContent='space-between' alignItems="center">
                <Typography variant="h5" fontWeight={600}>
                    {t(tokens.trainee.traineesTasks)}
                </Typography>
            </Stack>
            <Stack width="100%" py={3}>
                <Typography variant="h6" mb={1}>Посади</Typography>
                <AutocompleteCheckBox positionsList={positionsList} value={positions} handleChange={handleChangePosition} placeholder="Додати посаду"/>
            </Stack>
            {!!traineePositions.length &&
                <PositionTable
                    scrollRef={scrollRef}
                    positionList={traineePositions}
                    page={filters.page}
                    pageAmount={pageAmount}
                    handleDelete={handleDelete}
                    handlePageChange={handlePageChange}
                />
            }
            {(!traineePositions.length && isProccess) &&
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={styles.progress}
                >
                    <CircularProgress color="primary" />
                </Stack>
            }
            {(!traineePositions.length && !isProccess) &&
                <Stack direction="row" alignItems="flex-start" justifyContent="center" mt={10}>
                    <EmptyPage
                        title={t(tokens.common.emptyState)}
                        description={t(tokens.trainee.emptyDesc)}
                    />
                </Stack>
            }
        </Stack>
    )
};