import { FC, useMemo } from "react";
import { useBackupCreate } from "../presenters/useBackupCreate";
import { Button, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";
import { getStyles } from "./styles";
import { BackupTable } from "./components/backupList";

export const Backup: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { handleCreateBackup, handleRestore, backupId, onChangeBackupId, backupList, pagination, pageAmount, handlePageChange } = useBackupCreate();

    return (
        <Stack m={2} mb={4}>
            <Typography variant="h4" fontWeight={600}>
                {t(tokens.backup.backup)}
            </Typography>
            <Stack width="400px" mt={3}>
                <Typography variant="h6">
                    {t(tokens.backup.createBackUp)}
                </Typography>
                <Button
                    variant="contained"
                    sx={styles.button}
                    onClick={handleCreateBackup}
                    size="medium"
                >
                    {t(tokens.common.create)}
                </Button>
            </Stack>
            <Stack width="400px" mt={2}>
                <Typography variant="h6">
                    {t(tokens.backup.restoreBackUp)}
                </Typography>
                <TextField
                    variant='outlined'
                    placeholder={t(tokens.backup.enterKey)}
                    value={backupId}
                    onChange={onChangeBackupId}
                    sx={styles.backupId}
                />
                <Button
                    variant="contained"
                    sx={styles.button}
                    onClick={handleRestore}
                    size="medium"
                >
                    {t(tokens.common.restore)}
                </Button>
            </Stack>
            <Tabs
                scrollButtons="auto"
                sx={styles.tabs}
                value={t(tokens.backup.backupList)}
                variant="fullWidth"
            >
                <Tab
                    label={t(tokens.backup.backupList)}
                    value={t(tokens.backup.backupList)}
                    sx={styles.tab}
                />
            </Tabs>
            <BackupTable
                page={pagination.page}
                pageAmount={pageAmount}
                backupList={backupList}
                handlePageChange={handlePageChange}
            />
        </Stack>
    )
};