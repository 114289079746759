import { FC } from "react";
import { LeaderboardTableHeader } from "../leaderboardTableHeader";
import { Table } from "@mui/material";
import { LeaderboardTableBody } from "../leaderboardTableBody";

interface IProps {
    usersData: any[];
};

export const LeaderboardTable: FC<IProps> = ({usersData}) => {

    return (
        <Table>
            <LeaderboardTableHeader />
            <LeaderboardTableBody
                usersData={usersData}
            />
        </Table>
    )
}