import { useMemo, type FC } from 'react';
import { Button, Card, CardContent, Stack, SvgIcon, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../../locales/tokens';
import { ChartHeader } from '../../../../general/ui/components/chartHeader';
import { ProductTable } from '../productTable';
import EastIcon from '@mui/icons-material/East';
import { useNavigate } from 'react-router-dom';
import { paths } from '../../../../../../paths';
import { getStyles } from './styles';
import { Dayjs } from 'dayjs';
import { MuiDateRange } from '../../../../general/ui/components/muiDateRange';

interface IProps {
  products: any[];
  dates: { startDate: Dayjs | null; endDate: Dayjs | null; };
  handleRangeChange: (selectedDates: any, dateStrings: [string, string]) => void;
  disabledDate: (current: Dayjs) => boolean;
}

export const ProductChart: FC<IProps> = ({ products, dates, disabledDate, handleRangeChange }) => {
  const { t } = useTranslation();
  const styles = useMemo(() => getStyles(), []);
  const navigate = useNavigate();

  return (
    <Stack sx={styles.container} spacing={1}>
      <Stack direction="row" justifyContent="flex-end">
        <MuiDateRange
          dates={dates}
          handleRangeChange={handleRangeChange}
          disabledDate={disabledDate}
        />
      </Stack>
      <Card elevation={2}>
        <ChartHeader title={t(tokens.analytics.productRating)} subTitle="" icon={<InfoIcon />} />
        <CardContent>
          <ProductTable
            products={products}
          />
          <Button
            endIcon={<SvgIcon color="inherit" ><EastIcon /></SvgIcon>}
            variant='text'
            color='inherit'
            sx={styles.allProducts}
            onClick={() => navigate(paths.dashboard.shop.index)}
          >
            <Typography variant='body2' fontWeight={600}>{t(tokens.analytics.allProducts)}</Typography>
          </Button>
        </CardContent>
      </Card>
    </Stack>
  )
};