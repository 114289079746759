import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setDeleteFileFromQuestionError, setLoading } from "../../repository/slice";
import { getSingleTest } from "../getSingleTest/action";

function* deleteFileFromQuestionWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call( requester.delete, "/api/file/delete/" + payload.fileName );
        if (isError) {
            yield put(setDeleteFileFromQuestionError(message));
        } else {
            yield put(setDeleteFileFromQuestionError(null));
            yield put(getSingleTest({id: Number(payload.testId)}));
        }
    } catch (error) {
        console.error("deleteFileFromQuestionWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* deleteFileFromQuestionWatcher() {
    yield takeEvery("@saga/DELETE_FILE_FROM_QUESTION", deleteFileFromQuestionWorker);
};