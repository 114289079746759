import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading } from "../../repository/slice";
import { getSingleTest } from "../getSingleTest/action";

function* questionAddImageWorker({ payload }: any): SagaIterator {
    try {
        const formData = new FormData();
        for (const [key, value] of Object.entries(payload.body as Object)) {
            const isValue = value !== null && value !== undefined && value !== "";
            if (isValue) {
                formData.append(key, value);
            }
        };
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.post, "/api/admin/question/add-image", formData);
        if (isError) {
            
        } else {
            if(payload?.testId){
                yield put(getSingleTest({id: Number(payload.testId)}));
            }
        }
    } catch (error) {
        console.error("questionAddImageWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* questionAddImageWatcher() {
    yield takeLatest("@saga/QUESTION_ADD_IMAGE", questionAddImageWorker);
};
