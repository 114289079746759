import toast from "react-hot-toast";
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../../../../store/category/useCases/getCategories/action";
import { selectCategory } from "../../../../../store/category/repository/selector";
import { createCategory } from "../../../../../store/category/useCases/createCategory/action";
import { getSingleCategory } from "../../../../../store/category/useCases/getSingleCategory/action";
import { updateCategory } from "../../../../../store/category/useCases/updateCategory/action";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../locales/tokens";
import { deleteFile } from "../../../../../store/file/useCases/deleteFile/action";
import { getParentCategories } from "../../../../../store/category/useCases/getParentCategories/action";
import { setSingleCategory } from "../../../../../store/category/repository/slice";
import { FILE_SIZE } from "../../../../../config";
import { IError } from "../../../../../types/error";

export const useCategoryCreate = (handleToggle: () => void, categoryId: number, filters: any, updateFilters: Dispatch<SetStateAction<any>>) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [title, setTitle] = useState('');
    const [titleValid, setTitleValid] = useState(true);
    const [imageValid, setImageValid] = useState(true);
    const [category, setCategory] = useState<any>(null);
    const { categoryList, parentCategoriesList } = useSelector(selectCategory);
    const [hiddenChecked, setHiddenChecked] = useState(false);
    const [showChecked, setShowChecked] = useState(false);
    const [imageSrc, setImageSrc] = useState('');
    const [image, setImage] = useState<any>(null);
    const [maxSortNumber, setMaxSortNumber] = useState(0);
    const [createClick, setCreateClick] = useState(false);
    const [editClick, setEditClick] = useState(false);
    const { createCategoryError, isLoading, singleCategory, updateCategoryError } = useSelector(selectCategory);
    const fileInputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        dispatch(getCategories());
        dispatch(getParentCategories({ isHidden: false }));
    }, []);

    useEffect(() => {
        if (categoryId) {
            dispatch(getSingleCategory({ id: categoryId }));
        }
    }, [categoryId]);

    useEffect(() => {
        if (singleCategory) {
            setTitle(singleCategory.name);
            setHiddenChecked(singleCategory.isHidden);
            setShowChecked(singleCategory.isKnowledgeCategory);
            setImageSrc(singleCategory?.image?.url || null);
            setImage(singleCategory?.image || null);
            setCategory(singleCategory.parent ? {
                id: singleCategory.parent.id,
                label: singleCategory.parent.name,
                name: singleCategory.parent.name,
                value: singleCategory.parent.name,
                isKnowledgeCategory: singleCategory.parent.isKnowledgeCategory
            }
                : null);
        }
    }, [singleCategory]);

    useEffect(() => {
        if (categoryList) {
            setMaxSortNumber(findMaxSortNumber(categoryList));
        }
    }, [categoryList]);

    useEffect(() => {
        if (!isLoading && createClick) {
            if (createCategoryError) {
                handleServerError(createCategoryError);
            } else {
                toast.success(t(tokens.category.messages.categoryCreated));
                setTimeout(() => {
                    const currentTab = defineCurrentTab();
                    updateFilters((prevState: any) => ({ ...prevState, tab: currentTab }));
                    handleToggle();
                    clearFields();
                    dispatch(getParentCategories({ isHidden: false }));
                }, 1000);
            }
            setCreateClick(false);
        }
    }, [createCategoryError, isLoading]);

    const handleServerError = (error: IError) => {
        if(error?.startsWith("value too long for type character varying(255)")){
            toast.error(t(tokens.common.tooMuchSymbols));
            setTitleValid(false);
        } else{
            toast.error(createCategoryError);
        }
    };

    useEffect(() => {
        if (!isLoading && editClick) {
            if (updateCategoryError) {
                toast.error(updateCategoryError)
            } else {
                toast.success(t(tokens.category.messages.categoryUpdated));
                setTimeout(() => {
                    dispatch(getCategories(combineRequestBody()));
                    handleToggle();
                    clearFields();
                }, 1000);
            }
            setEditClick(false);
        }
    }, [updateCategoryError, isLoading]);

    const defineCurrentTab = () => {
        if (hiddenChecked) return 'Hidden';
        if (showChecked) return 'Knowledge';
        return 'Documents';
    };

    const combineRequestBody = () => {
        let body: any = {};
        if (filters.query) body.name = filters.query;
        body = defineType(body);
        return body;
    };

    const defineType = (body: any) => {
        switch (filters.tab) {
            case 'Documents':
                body.isKnowledgeCategory = false;
                body.isHidden = false;
                break;
            case 'Knowledge':
                body.isKnowledgeCategory = true;
                body.isHidden = false;
                break;
            case 'Hidden':
                body.isHidden = true;
                break;
        }
        return body;
    };

    const clearFields = () => {
        setTitle('');
        setCategory(null);
        setHiddenChecked(false);
        setShowChecked(false);
        setImage(null);
        setImageSrc('');
        setTitleValid(true);
        setImageValid(true);
        dispatch(setSingleCategory(null));
    };

    const findMaxSortNumber = (tree: any[]): number => {
        const traverse = (node: any): number => {
            let maxSortNumber = node.sortNumber;

            if (node.children && node.children.length > 0) {
                const childrenMaxSort = Math.max(...node.children.map(traverse));
                maxSortNumber = Math.max(maxSortNumber, childrenMaxSort);
            }

            return maxSortNumber;
        };

        const maxSortNumber = Math.max(...tree.map(traverse), 1);
        return maxSortNumber;
    };

    const onChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
        setTitle(e.target.value);
        setTitleValid(true);
    };

    const handleChangeCategory = useCallback((category: any) => {
        setCategory(category);

        if (!!category && category.isKnowledgeCategory) {
            setShowChecked(true);
            deleteImage();
        } else if (!!category && !category.isKnowledgeCategory) {
            setShowChecked(false);
        };
    }, [setCategory]);

    const handleHiddenChange = (event: ChangeEvent<HTMLInputElement>) => {
        setHiddenChecked(event.target.checked);
    };

    const handleShowChange = (event: ChangeEvent<HTMLInputElement>) => {
        setShowChecked(event.target.checked);
        if (!event.target.checked) {
            setImageSrc('');
            setImage(null);
            setImageValid(true);
            if (image?.categoryImageId) {
                dispatch(deleteFile({ fileName: image.filename }));
            }
        }
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            // const maxSizeBytes = FILE_SIZE["500KB"];
            // if (file.size > maxSizeBytes) {
            //     toast.error(t(tokens.category.maxSize500));
            //     return;
            // };

            const reader = new FileReader();
            reader.onload = (e) => {
                const newImageSrc = e.target?.result as string;
                setImageSrc(newImageSrc);
            };
            reader.readAsDataURL(file);
        }
        setImage(file);
        setImageValid(true);

        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        };
    };

    const handleClearImageClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        deleteImage();
    };

    const deleteImage = () => {
        if (image?.categoryImageId) {
            dispatch(deleteFile({ fileName: image.filename }));
            setImageSrc('');
            setImage(null);
        } else {
            setImageSrc('');
            setImage(null);
        }
    };

    const createNewCategory = () => {
        const isPassedValidation = validateData();
        if (isPassedValidation) {
            dispatch(createCategory({ body: getRequestBody(), image }));
            setCreateClick(true);
        } else {
            toast.error(t(tokens.adverts.fillAllFields))
        }
    };

    const editCategory = () => {
        const isPassedValidation = validateData();
        if (isPassedValidation) {
            dispatch(updateCategory({ body: { ...getRequestBody(), id: categoryId }, image }));
            setEditClick(true);
        } else {
            toast.error(t(tokens.adverts.fillAllFields))
        }
    };

    const validateData = () => {
        const data: any = [
            { field: title, setField: setTitleValid },
        ];
        if (showChecked && !category?.isKnowledgeCategory || showChecked && !(!!category)) {
            data.push({ field: imageSrc, setField: setImageValid })
        };
        let allFieldsValid = true;
        for (const { field, setField } of data) {
            if (!field) {
                setField(false);
                allFieldsValid = false;
            }
        };
        return allFieldsValid;
    };
    
    const getRequestBody = () => {
        const body: any = { name: title };
        // if (!categoryId) body.sortNumber = maxSortNumber? maxSortNumber + 1 : 1;
        if (category) body.parentId = category.id;
        body.isHidden = hiddenChecked;
        body.isKnowledgeCategory = showChecked;
        return body;
    };

    const closeAndClear = () => {
        handleToggle();
        clearFields();
    };

    return {
        title, onChangeTitle, handleChangeCategory, category, categoryList, handleHiddenChange, closeAndClear,
        handleShowChange, hiddenChecked, showChecked, imageSrc, handleFileChange, createNewCategory, titleValid, editCategory,
        handleClearImageClick, imageValid, parentCategoriesList, createCategoryError, fileInputRef
    }
};