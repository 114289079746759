import { ChangeEvent, ReactNode, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateOrgStructure } from "../../../../../store/orgStructure/useCases/updateOrgStructure/action";
import { getBadges } from "../../../../../store/badge/useCases/getBadges/action";
import { selectOrgStructure } from "../../../../../store/orgStructure/repository/selector";
import { selectBadge } from "../../../../../store/badge/repository/selector";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { paths } from "../../../../../paths";
import toast from "react-hot-toast";
import { tokens } from "../../../../../locales/tokens";
import { createCourse } from "../../../../../store/course/useCases/createCourse/action";
import { selectCourse } from "../../../../../store/course/repository/selector";
import { IBadge, IInitiator, IRequestBody } from "./types";
import { TIMESTAMP_DAY } from "../../../../../config";
import { IError } from "../../../../../types/error";
import { SelectChangeEvent } from "@mui/material";
import { getPositions } from "../../../../../store/user/useCases/getPositions/action";
import { selectUser } from "../../../../../store/user/repository/selector";

export const useCourseCreate = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [theme, setTheme] = useState('');
    const [themeValid, setThemeValid] = useState(true);
    const { orgStructure } = useSelector(selectOrgStructure);
    const [badge, setBadge] = useState<IBadge | null>(null);
    const [badgeSeacrh, setBadgeSearch] = useState('');
    const { badgesList } = useSelector(selectBadge);
    const [content, setContent] = useState('');
    const [contentValid, setContentValid] = useState(true);
    const [initiator, setInitiator] = useState<IInitiator | null>(null);
    const [initiatorValid, setInitiatorValid] = useState(true);
    const [groupPermissions, setGroupPermissions] = useState<string[]>([]);
    const [groupPermissionsValid, setGroupPermissionsValid] = useState(true);
    const [receiver, setReceiver] = useState<string[]>([]);
    const [deadline, setDeadline] = useState<number | null>(null);
    const [duration, setDuration] = useState('');
    const [deadlineValid, setDeadlineValid] = useState<any>(true);
    const [publishChecked, setPublishChecked] = useState(false);
    const [notifyChecked, setNotifyChecked] = useState(false);
    const [createClick, setCreateClick] = useState(false);
    const [points, setPoints] = useState('');
    const [isAvalableNextDay, setAvailableNextDay] = useState<boolean>(false);
    const { createCourseError, isLoading, courseId } = useSelector(selectCourse);
    const { positionsList } = useSelector(selectUser);
    const [departmentPermissions, setDepartmentPermissions] = useState<any[]>([]);
    const [positions, setPositions] = useState<any[]>([]);
    const [assignDepartment, setAssignDepartment] = useState<any[]>([]);
    const [assignPositions, setAssignPositions] = useState<any[]>([]);

    useEffect(() => {
        dispatch(updateOrgStructure());
        dispatch(getBadges({ type: 'ACTIVE' }));
        dispatch(getPositions());
    }, []);

    useEffect(() => {
        if (!isLoading && createClick) {
            if (createCourseError) {
                handleServerError(createCourseError);
            } else {
                toast.success(t(tokens.course.message.courseCreated));
                navigate(paths.dashboard.content.course.edit.replace(":id", String(courseId)));
            }
            setCreateClick(false);
        }
    }, [createCourseError, isLoading]);


    const handleServerError = (error: IError) => {
        if (error?.startsWith("value too long for type character varying(255)")) {
            toast.error(t(tokens.common.tooMuchSymbols));
            setThemeValid(false);
        } else if (error?.startsWith("value too long for type character varying(500)")) {
            setContentValid(false);
            toast.error(t(tokens.common.tooMuchSymbols));
        } else {
            toast.error(error);
        }
    };

    const onChangeTheme = (event: ChangeEvent<HTMLInputElement>) => {
        setTheme(event.target.value);
        setThemeValid(true);
    };

    const handleChangeBadge = useCallback((badge: IBadge) => {
        setBadge(badge);
    }, [setBadge]);

    const changeBadgeText = (event: ChangeEvent<HTMLInputElement>) => {
        setBadgeSearch(event.target.value);
        debouncedHandleBadgeSeacrh(badgeSeacrh);
    };

    const onSearchBadge = (value: string) => {
        if (value.length) {
            dispatch(getBadges({ search: value, type: 'ACTIVE' }));
        } else {
            dispatch(getBadges({ type: 'ACTIVE' }));
        }
    };

    const { debouncedWrapper: debouncedHandleBadgeSeacrh } = useDebounce(onSearchBadge, 1000);

    const onChangeContent = (event: ChangeEvent<HTMLInputElement>) => {
        setContent(event.target.value);
        setContentValid(true);
    };

    const onSelectInitiator = (selectedKeysValue: string, node: any) => {
        if ('children' in node) {
            return;
        }
        setInitiator(node);
        setInitiatorValid(true);
    };

    const onChangeInitiator = () => {
        if (initiator) {
            setInitiator(null);
        }
    };

    const onChangePermissions = (newValue: string[]) => {
        setGroupPermissions(newValue);
        setGroupPermissionsValid(true);
    };

    const onChangeDepartmentPermissions = (newValue: string[]) => {
        setDepartmentPermissions(newValue);
    };

    const onChangeAssignDepartment = (newValue: string[]) => {
        setAssignDepartment(newValue);
    };

    const onChangeReceiver = (newValue: string[]) => {
        setReceiver(newValue);
    };

    const onChangeDuration = (event: ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        const regex = /^(100|[1-9]?[0-9])?$/;

        if (regex.test(input)) {
            setDuration(input);
        }
    };

    const handleChangeDeadline = useCallback((date: Date | null): void => {
        const currentDate = date?.valueOf() || null;
        setDeadline(currentDate);
        setDeadlineValid(true);
    }, [setDeadline]);

    const handlePublishChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPublishChecked(event.target.checked);
    };

    const handleNotifyChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNotifyChecked(event.target.checked);
    };

    const onChangePoints = (event: ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        const regex = /^(\d*)$/;

        if (regex.test(input)) {
            setPoints(input);
        }
    };

    const handleChangeNextDay = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAvailableNextDay(event.target.checked);
    };

    const cancelCreate = () => {
        navigate(paths.dashboard.content.course.list);
    };

    const handleCourse = () => {
        const isPassedValidation = validateData();
        if (isPassedValidation) {
            createNewCourse();
        } else {
            toast.error(t(tokens.adverts.fillAllFields))
        };
    };

    const validateData = () => {
        const data = [
            { field: theme, setField: setThemeValid },
            { field: initiator, setField: setInitiatorValid },
            // { field: groupPermissions.length, setField: setGroupPermissionsValid },
            // { field: deadline, setField: setDeadlineValid },
        ];
        let allFieldsValid = true;
        for (const { field, setField } of data) {
            if (!field) {
                setField(false);
                allFieldsValid = false;
            }
        };

        return allFieldsValid;
    };

    const createNewCourse = () => {
        dispatch(createCourse({ body: getRequestBody() }));
        setCreateClick(true);
    };

    const cleanedGuiIds = (guiIds: string[]): string[] => {
        return guiIds.map(permission => {
            const [cleaned] = permission.split('%');
            return cleaned;
        });
    };  
    
    const getRequestBody = () => {
        const body: IRequestBody = {
            name: theme,
            initiatorId: initiator?.value,
            openNextDayImmediately: !isAvalableNextDay,
        };
        if (deadline) body.deadline = new Date(deadline as number).toISOString();
        if(!!duration) body.days_to_deadline = Number(duration);
        if (content) body.description = content;
        if (points) body.points = Number(points);
        if (badge) body.badgeId = badge.id;
        if (receiver.length) body.assigns = receiver;
        if (groupPermissions.length) body.access = groupPermissions;
        if(departmentPermissions.length) body.accessDepartmentIds = cleanedGuiIds(departmentPermissions);
        if(positions.length) body.accessPositionIds = positions.map(item => item.id);
        if(assignDepartment.length) body.assignDepartmentIds = cleanedGuiIds(assignDepartment);
        if(assignPositions.length) body.assignPositionIds = assignPositions.map(item => item.id); 
        return body;
    };

    const handleChangePosition = (event: any, newValue: readonly any[]) => {
        setPositions([...newValue]);
    };

    const handleChangeAssignPosition = (event: any, newValue: readonly any[]) => {
        setAssignPositions([...newValue]);
    };

    return {
        theme, onChangeTheme, themeValid, badge, badgesList, handleChangeBadge, changeBadgeText, content, contentValid, onChangeContent, receiver,
        onChangeInitiator, onSelectInitiator, initiator, initiatorValid, orgStructure, groupPermissions, groupPermissionsValid, onChangePermissions,
        onChangeReceiver, handleChangeDeadline, deadline, deadlineValid, handlePublishChange, publishChecked, handleNotifyChange,
        notifyChecked, handleCourse, cancelCreate, createClick, onChangePoints, points, isAvalableNextDay, handleChangeNextDay, onChangeDepartmentPermissions,
        departmentPermissions, positionsList, positions, handleChangePosition, onChangeAssignDepartment, assignDepartment, handleChangeAssignPosition,
        assignPositions, duration, onChangeDuration
    }
};