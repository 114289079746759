export const getStyles = () => {
    const styles = {
        permissionsText: {
            maxWidth: 700,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        svgIcon: {
            fontSize: 26
        },
        cell: {
            py: 1
        }
    };
    return styles;
};