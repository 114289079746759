import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setUpdateUserError } from "../../repository/slice";

function* updateUserWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.put, "/api/admin/user/update", payload);
        if (isError) {
            yield put(setUpdateUserError(message));
        } else {
            yield put(setUpdateUserError(null));
        }
    } catch (error) {
        console.error("updateUserWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* updateUserWatcher() {
    yield takeLatest("@saga/UPDATE_USER", updateUserWorker);
};
