import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IState {
    redirectTo403: boolean;
    logoutTo403: boolean;
};

const initialState: IState = {
    redirectTo403: false,
    logoutTo403: false,
};

const RedirectSlice = createSlice({
    name: 'redirect',
    initialState,
    reducers: {
        setRedirectTo403(state) {
            state.redirectTo403 = true;
        },
        resetRedirect(state) {
            state.redirectTo403 = false;
        },
        setLogoutTo403(state) {
            state.logoutTo403 = true;
        },
        resetLogoutTo403(state) {
            state.logoutTo403 = false;
        },
    },
});

export const { resetRedirect, setRedirectTo403, setLogoutTo403, resetLogoutTo403 } = RedirectSlice.actions;
export const RedirectReducer = RedirectSlice.reducer;