import { SagaIterator } from "redux-saga";
import { call, put, takeEvery } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setUpdateTraineeTaskError } from "../../repository/slice";
import { getTraineePositionById } from "../getTraineePositionById/action";

function* updateTraineeTaskWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.put, `/api/admin/courses/${payload.courseId}/update-content`, payload.body);
        if (isError) {
            yield put(setUpdateTraineeTaskError(message));
        } else {
            yield put(setUpdateTraineeTaskError(null));
            yield put(getTraineePositionById({ id: Number(payload.positionId) }));
        }
    } catch (error) {
        console.error("updateTraineeTaskWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* updateTraineeTaskWatcher() {
    yield takeEvery("@saga/UPDATE_TRAINEE_TASK", updateTraineeTaskWorker);
};
