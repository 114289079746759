import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setTestList, setTestListError } from "../../repository/slice";

function* getTestListWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.get, "/api/admin/quiz/get-list", payload);
        if (isError) {
            yield put(setTestListError(message));
        } else {
            yield put(setTestListError(null));
            yield put(setTestList({testList: data.rows, count: data.count}));
        }
    } catch (error) {
        console.error("getTestListWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* getTestListWatcher() {
    yield takeLatest("@saga/GET_TEST_LIST", getTestListWorker);
};
