import React, { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../../locales/tokens';
import { Stack, Typography } from '@mui/material';
import { NotificationInput } from '../notificationInput';
import { styles } from './styles';
import { ImagePicker } from '../imagePicker';
import { AudienceSelector } from '../audienceSelector';
import { ImageTypeSelector } from '../imageTypeSelector';

interface IProps {
    isDisabled?: boolean;
    title: string;
    text: string;
    imageUrl: string;
    linkTo: string;
    imageType: string;
    imageTypeOptions: any[];
    imageSrc: string;
    titleValid: boolean;
    textValid: boolean;
    navLinkValid: boolean;
    imageUrlValid: boolean;
    imageSrcValid: boolean;
    handleClearImageClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
    handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
    onChangTitle: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    onChangText: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    onChangImageUrl: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    onChangLinkTo: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    onChangeImageType: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
};

export const Content: FC<IProps> = ({ title, onChangTitle, text, onChangText, imageUrl, onChangImageUrl, linkTo, onChangLinkTo, isDisabled, imageUrlValid,
    imageType, onChangeImageType, imageTypeOptions, handleFileChange, imageSrc, handleClearImageClick, titleValid, textValid, navLinkValid, imageSrcValid }) => {
    const { t } = useTranslation();

    return (
        <Stack sx={styles.card}>
            <Typography variant='h5' fontWeight={600} mb={3}>{t(tokens.notification.notification)}</Typography>
            <Stack spacing={2}>
                <NotificationInput
                    textArea={false}
                    title={t(tokens.notification.title) + '*'}
                    value={title}
                    onChange={onChangTitle}
                    error={!titleValid}
                    placeholder={t(tokens.notification.title) + '*'}
                    isDisabled={isDisabled}
                />
                <NotificationInput
                    textArea={true}
                    title={t(tokens.notification.text) + '*'}
                    value={text}
                    error={!textValid}
                    onChange={onChangText}
                    placeholder={t(tokens.notification.text) + '*'}
                    isDisabled={isDisabled}
                />
                <Stack>
                    <Typography variant='h6' color="primary" mb={1}>{t(tokens.notification.image)}</Typography>
                    <ImageTypeSelector
                        label={t(tokens.notification.image)}
                        id='ImageType'
                        value={imageType}
                        onChange={onChangeImageType}
                        values={imageTypeOptions}
                        isDisabled={isDisabled}
                    />
                </Stack>
                {imageType === "Link" ?
                    <Stack>
                        <NotificationInput
                            isDetail={true}
                            textArea={false}
                            title={t(tokens.notification.imageUrl)}
                            value={imageUrl}
                            onChange={onChangImageUrl}
                            placeholder={t(tokens.notification.imageUrl)}
                            isDisabled={isDisabled}
                            error={!imageUrlValid}
                        />
                    </Stack>
                    :
                    <ImagePicker
                        imageSrc={imageSrc}
                        isValid={imageSrcValid}
                        handleFileChange={handleFileChange}
                        handleClearImageClick={handleClearImageClick}
                        isDisabled={isDisabled}
                    />
                }
                <NotificationInput
                    textArea={false}
                    title={t(tokens.notification.linkTo)}
                    value={linkTo}
                    onChange={onChangLinkTo}
                    placeholder={t(tokens.notification.linkTo)}
                    isDisabled={isDisabled}
                    error={!navLinkValid}
                />
            </Stack>
        </Stack>
    );
};