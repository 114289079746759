interface IPayload {
    product_id: number;
    name?: string;
    price?: number;
    quantity?: number;
    is_hidden?: boolean;
    files?: File[];
    description?: string;
}

export const updateProduct = (payload: IPayload) => ({
    type: "@saga/UPDATE_PRODUCT",
    payload
});