import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoadingStructure } from "../../repository/slice";
import { getOrgStructure } from "../getOrgStructure/action";

function* updateOrgStructureWorker({ payload }: any): SagaIterator {
    try {
        // yield put(setLoadingStructure(true));
        /* const { data, message, isError }: IResponse<any> = yield call(requester.post, "/api/organization-structure/update");
        if (isError) {

        } else { */
            yield put(getOrgStructure());
        // }
    } catch (error) {
        console.error("updateOrgStructureWorker: ", error);
    } finally {
        // yield put(setLoadingStructure(false));
    };
};

export function* updateOrgStructureWatcher() {
    yield takeLatest("@saga/UPDATE_ORGSTRUCTURE", updateOrgStructureWorker);
};
