export const getStyles = (isDark: boolean) => {
    const styles = {
        clearIcon: {
            color: isDark ? "#ffffff" : "#757575",
            backgroundColor: isDark ? "#363535" : "#ffffff",
            position: 'relative',
            top: '-3px',
            right: '7px',
        },
        suffixIcon: {
            color: isDark ? "#ffffff" : "#757575",
        },
        wrapper: {
            ".ant-select-selector": {
                borderColor: isDark ? "#666565 !important" : "#C7C7C7 !important",
                backgroundColor: isDark ? "#363535 !important" : "#ffffff",
                color: isDark ? "#ffffff" : "#000000",
                fontSize: "16px",
                ":hover": {
                    borderColor: isDark ? "#ffffff !important" : "#E5E5E5"
                },
                ":focus-within": {
                    border: "2px solid #F9B754 !important"
                }
            },
            ".ant-select-selection-placeholder": {
                color: "#8D8C8C !important"
            },
            ".ant-select-selection-item-remove": {
                color: isDark ? '#ffffff' : '#000000',
                ':hover': {
                    color: isDark ? '#ffffff' : '#000000',
                }
            }
        }
    };
    return styles;
};



/* 

ant-select ant-tree-select treeSelect css-dev-only-do-not-override-2q8sxy ant-select-single ant-select-allow-clear ant-select-show-arrow ant-select-show-search

*/