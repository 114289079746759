import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setAppPageLoading, setAppPageError, setSelectedAppPage } from "../../repository/slice";
import { IAppPage } from "../../types/IAppPage";
import { convertToFormData } from "../../../../utils";

function* creteAppPageWorker({ payload }: any): SagaIterator {
    try {
        yield put(setAppPageLoading(true));
        const body = convertToFormData(payload)
        const { data, message, isError }: IResponse<IAppPage> = yield call(requester.post, "/api/admin/dynamic-pages/create", body);
        if (isError) {
            yield put(setAppPageError(message));
        } else {
            yield put(setSelectedAppPage(data));
        }
    } catch (error) {
        console.error("creteAppPageWorker: ", error);
    } finally {
        yield put(setAppPageLoading(false));
    };
};

export function* createAppPageWatcher() {
    yield takeLatest("@saga/CREATE_APP_PAGE", creteAppPageWorker);
};