import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';
import { IHistory } from './IHistory';

interface IState {
    isLoading: boolean;
    getLearningHistoryError: IError;
    learningHistory: IHistory[];
    learningHistoryCount: number;
};

const initialState: IState = {
    isLoading: false,
    getLearningHistoryError: null,
    learningHistory: [],
    learningHistoryCount: 0
};

const LearningHistorySlice = createSlice({
    name: 'learningHistory',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setLearningHisory: (state, action: PayloadAction<{list: IHistory[], count: number}>) => {
            state.learningHistory = action.payload.list;
            state.learningHistoryCount = action.payload.count;
        },
        setGetLearningnHistoryError: (state, action: PayloadAction<IError>) => {
            state.getLearningHistoryError = action.payload;
        },
    },
});

export const { setLoading, setLearningHisory, setGetLearningnHistoryError } = LearningHistorySlice.actions;
export const LearningHistoryReducer = LearningHistorySlice.reducer;