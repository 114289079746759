import type { FC } from 'react';
import { Card, CardContent, Stack } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../../locales/tokens';
import { ChartSeries } from '../../../presenter/useProcessChart';
import { ChartHeader } from '../../../../general/ui/components/chartHeader';
import { ChartBody } from '../chartBody';
import { Dayjs } from 'dayjs';
import { MuiDateRange } from '../../../../general/ui/components/muiDateRange';

interface IProps {
  chartSeries: ChartSeries;
  labels: string[];
  dates: { startDate: Dayjs | null; endDate: Dayjs | null; };
  handleRangeChange: (selectedDates: any, dateStrings: [string, string]) => void;
  disabledDate: (current: Dayjs) => boolean;
}

export const ProcessChart: FC<IProps> = ({ chartSeries, labels, dates, disabledDate, handleRangeChange }) => {
  const { t } = useTranslation();

  return (
    <Stack width="70%" m={3} spacing={1}>
      <Stack direction="row" justifyContent="flex-end">
        <MuiDateRange
          dates={dates}
          handleRangeChange={handleRangeChange}
          disabledDate={disabledDate}
        />
      </Stack>
      <Card elevation={2}>
        <ChartHeader title={t(tokens.analytics.analyticsProcess)} subTitle={t(tokens.analytics.sections)} icon={<InfoIcon />} />
        <CardContent>
          <ChartBody chartSeries={chartSeries} labels={labels} />
        </CardContent>
      </Card>
    </Stack>
  );
};