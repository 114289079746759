import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';

interface IState {
    isLoading: boolean;
    badgesList: any[];
    getAssignListError: IError;
    assignDeleteError: IError;
    assignList: any[];
    assignCount: number;
};

const initialState: IState = {
    isLoading: false,
    badgesList: [],
    getAssignListError: null,
    assignDeleteError: null,
    assignList: [],
    assignCount: 0
};

const BadgeSlice = createSlice({
    name: 'badge',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setBadges: (state, action: PayloadAction<any[]>) => {
            state.badgesList = action.payload;
        },
        setGetAssignListError: (state, action: PayloadAction<IError>) => {
            state.getAssignListError = action.payload;
        },
        setAssignDeleteError: (state, action: PayloadAction<IError>) => {
            state.assignDeleteError = action.payload;
        },
        setAssignList: (state, action: PayloadAction<any>) => {
            state.assignList = action.payload.rows;
            state.assignCount = action.payload.count;
        },
    },
});

export const { setLoading, setBadges, setGetAssignListError, setAssignList, setAssignDeleteError } = BadgeSlice.actions;
export const BadgeReducer = BadgeSlice.reducer;