import { FC, useMemo } from "react";
import { getStyles } from "./styles";
import { Box, Button, Card, CardContent, Stack, Typography, useMediaQuery, Theme, CircularProgress } from "@mui/material";
import { useForgotPassword } from "../presenters/useForgotPassword";
import { PhoneField } from "../../login/ui/components/phoneField";
import { tokens } from "../../../../locales/tokens";
import { useTranslation } from "react-i18next";
import { InfoModal } from "./components/infoModal";


export const ForgotPassword: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const { phone, onChangePhone, phoneValid, handleEnter, phoneInputRef, handleRecover, isShownModal, timerInSec, handleClose, handleOk, isHideError, isLoading } = useForgotPassword();

    return (
        <Box sx={styles.background}>
            <Card elevation={16} sx={mdUp ? styles.containerMd : styles.container}>
                <CardContent>
                    <Stack direction="column" spacing={1} alignItems='flex-start' m={1} position='relative'>
                        <Typography variant="h4" pb={1}>{t(tokens.auth.forgetPassword)}</Typography>
                        <Stack width='100%'>
                            <PhoneField
                                phoneInputRef={phoneInputRef}
                                phone={phone}
                                phoneValid={phoneValid}
                                isHideError={isHideError}
                                handleEnterPhone={handleEnter}
                                onChangePhone={onChangePhone}
                            />
                            {(!phoneValid && isHideError) ?
                                <Typography
                                    color="error"
                                    variant="caption"
                                    sx={styles.error}
                                >
                                    {t(tokens.auth.messages.userNotFound)}
                                </Typography>
                                :
                                null
                            }
                        </Stack>
                        <Button
                            color="primary"
                            fullWidth
                            variant="contained"
                            size="large"
                            sx={styles.recoverBtn}
                            onClick={handleRecover}
                            disabled={isLoading}
                        >
                            {isLoading ?
                            <CircularProgress size={20}/>
                            : t(tokens.auth.recoverPassword)}
                        </Button>
                    </Stack >
                </CardContent>
            </Card>
            <InfoModal
                isOpen={isShownModal}
                timerInSec={timerInSec}
                handleClose={handleClose}
                handleRecover={handleRecover}
                handleOk={handleOk}
            />
        </Box>
    )
};