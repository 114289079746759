import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../../paths";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { createProduct } from "../../../../store/product/useCases/createProduct/action";
import { selectProduct } from "../../../../store/product/repository/selector";
import { setProductError } from "../../../../store/product/repository/slice";

const initialForm = {
    name: '',
    files: [] as File[],
    is_hidden: true,
    price: '',
    quantity: '',
    description: '',
};

const initialFormErrors = {
    nameError: false,
    filesError: false,
    descriptionError: false,
    priceError: false,
    quantityError: false
};

export const useProductCreate = () => {
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const { t } = useTranslation();
    const [form, setForm] = useState(initialForm);
    const [formErrors, setFormErrors] = useState(initialFormErrors);
    const [isClick, setIsClick] = useState(false);
    const { isProductLoading, productError } = useSelector(selectProduct);

    useEffect(() => {
        if (!isProductLoading && isClick) {
            if (productError) {
                toast.error(productError);
                dispatch(setProductError(null));
            } else {
                toast.success(t(tokens.shop.messages.created));
                navigation(paths.dashboard.shop.index)
            }
            setIsClick(false);
        };
    }, [isProductLoading]);

    const getHtmlText = (description: string) => {
        var div = document.createElement("div");
        div.innerHTML = description;
        var text = div.textContent || div.innerText || "";
        return text;
    };

    const isValidForm = useCallback(() => {
        let valid = true;
        let errors = formErrors;
        const { name, files, price, quantity, description } = form;
        const descriptionText = getHtmlText(description);

        if (name.length) {
            errors = { ...errors, nameError: false };
        } else {
            valid = false;
            errors = { ...errors, nameError: true };
        };
        if (files.length) {
            errors = { ...errors, filesError: false };
        } else {
            valid = false;
            errors = { ...errors, filesError: true };
        };
        if (!isNaN(Number(price)) && Number(price) > 0) {
            errors = { ...errors, priceError: false };
        } else {
            valid = false;
            errors = { ...errors, priceError: true };
        };
        if (!isNaN(Number(quantity)) && Number(quantity) > 0) {
            errors = { ...errors, quantityError: false };
        } else {
            valid = false;
            errors = { ...errors, quantityError: true };
        };
        setFormErrors(errors);
        return valid;
    }, [form]);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file /* && file.size <= 1 * 1024 * 1024 */) {
            setForm(prev => ({ ...prev, files: [file] }));
            formErrors.filesError && setFormErrors({ ...formErrors, filesError: false });
        } /* else {
            toast.error(t(tokens.shop.messages.maxSizeFile));
        } */
    };

    const handleSetFiles = (items: any[]) => {
        setForm(prev => ({ ...prev, files: items }));
    };

    const handleFilesDrop = useCallback((newFiles: File[]): void => {
        setForm(prev => ({ ...prev, files: [...prev.files, ...newFiles] }));
        formErrors.filesError && setFormErrors({ ...formErrors, filesError: false });
    }, []);

    const handleDeleteImage = (name: string) => {
        setForm(prev => ({ ...prev, files: prev.files.filter(file => file.name !== name) }));
    };

    const handleFilesRemoveAll = useCallback((): void => {
        setForm(prev => ({ ...prev, files: [] }));
    }, []);

    const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, name: e.target.value });
        formErrors.nameError && setFormErrors({ ...formErrors, nameError: false });
    };

    const onChangePrice = (e: ChangeEvent<HTMLInputElement>) => {
        const regex = /^(\d*)$/;

        if (regex.test(e.target.value)) {
            setForm({ ...form, price: e.target.value });
            formErrors.priceError && setFormErrors({ ...formErrors, priceError: false });
        }
    };

    const onChangeAmount = (e: ChangeEvent<HTMLInputElement>) => {
        const regex = /^(\d*)$/;

        if (regex.test(e.target.value)) {
            setForm({ ...form, quantity: e.target.value });
            formErrors.quantityError && setFormErrors({ ...formErrors, quantityError: false });
        }
    };

    const onChangeDescription = (e: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, description: e.target.value });
        formErrors.descriptionError && setFormErrors({ ...formErrors, descriptionError: false });
    };

    const onTogglePublish = () => setForm(prev => ({ ...prev, is_hidden: !prev.is_hidden }));

    const onCreate = () => {
        const isValid = isValidForm();
        const data: any = {
            name: form.name,
            files: form.files,
            is_hidden: form.is_hidden,
            price: Number(form.price),
            quantity: Number(form.quantity),
        };
        if (form.description.length) data.description = form.description;
        if (isValid) {
            if (checkDescValid()) {
                setIsClick(true);
                dispatch(createProduct(data));
            }
        } else {
            toast.error(t(tokens.common.fillAllFields));
        };
    };

    const checkDescValid = () => {
        let valid = true;
        let errors = formErrors;
        const { name, description } = form;
        const descriptionText = getHtmlText(description);

        if (name.length > 255) {
            valid = false;
            errors = { ...errors, nameError: true };
            toast.error(t(tokens.shop.messages.maxNameLength));
        } else {
            errors = { ...errors, nameError: false };
        };
        if (descriptionText.length > 500) {
            valid = false;
            errors = { ...errors, descriptionError: true };
            toast.error(t(tokens.shop.messages.maxDescriptionLength));
        } else {
            errors = { ...errors, descriptionError: false };
        };
        setFormErrors(errors);
        return valid;
    };

    const onCancel = () => navigation(paths.dashboard.shop.index);

    return { form, formErrors, isClick, onChangeName, handleFileChange, handleFilesRemoveAll, handleDeleteImage, handleFilesDrop, onChangePrice, onChangeAmount, onChangeDescription, onTogglePublish, onCreate, onCancel, handleSetFiles };
};