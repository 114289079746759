import { Divider } from "@mui/material"
import { TreeItem } from "@mui/x-tree-view"
import { FC, Fragment } from "react"
import { TreeItemSectionLabel } from "../treeItemSectionLabel"

interface IProps { 
    node: any;
    key: string;
    renderUserTree: Function;
    handleStatus: (id: number, isBanned: boolean) => void;
};

export const TreeItemSection: FC<IProps> = ({ node, handleStatus, renderUserTree }) => {

    return (
        <>
            <Divider />
            <TreeItem key={node.name} nodeId={node.name + node.id} label={<TreeItemSectionLabel name={node.name} />}>
                <Divider />
                {Array.isArray(node.children) && node.children.length > 0 && node ?
                    <Fragment key={node.name}>
                        {renderUserTree(node.children, node.name, handleStatus)}
                    </Fragment>
                    :
                    null
                }
            </TreeItem>
        </>
    )
}