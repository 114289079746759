export const getStyles = () => {
    const styles = {
        progressWrapper: {
            height: "calc(100dvh - 75px - 16px - 42px - 80px - 32px - 16px)",

            justifyContent: 'center',
            alignItems: 'center'
        }
    };
    return styles;
};