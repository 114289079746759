import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { FC } from "react";

interface IProps {
    label: string;
    value: any;
    sortOptions: any[];
    isDisabled: boolean;
    onChange: (event: SelectChangeEvent<any>) => void;
};

export const ItemSelector: FC<IProps> = ({ label, onChange, sortOptions, value, isDisabled }) => {

    return (
        <FormControl fullWidth>
            <InputLabel id={label}>{label}</InputLabel>
            <Select
                labelId={label}
                id={label}
                value={value}
                label={label}
                onChange={onChange}
                MenuProps={{
                    disableScrollLock: true,
                }}
                disabled={isDisabled}
            >
                {
                    sortOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
};