import { Checkbox, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, Stack, SvgIcon, TextField, Typography } from "@mui/material";
import { ChangeEvent, FC } from "react";
import DeleteIcon from '@mui/icons-material/Delete';

interface IProps {
    type: any;
    replies: any[];
    repliesValid: any[];
    removeReply: (sortNumber: number) => void;
    onChangeReply: (event: ChangeEvent<HTMLInputElement>, index: number) => void;
    onChangeCorrectReply: (event: ChangeEvent<HTMLInputElement>, index: number) => void;
};

export const RepliesList: FC<IProps> = ({ type, replies, repliesValid, onChangeReply, onChangeCorrectReply, removeReply }) => {
    
    return (
        <>
            {replies?.map((reply: any, index: number) =>
                <Stack key={index} mt={2} pb={1} direction="row" alignItems="center" spacing={3}>
                    <Typography variant="h4" fontWeight={600}>{index + 1}</Typography>
                    <TextField
                        value={reply.title}
                        fullWidth
                        onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeReply(e, index)}
                        error={!repliesValid[index].isValid}
                    />
                    {type === 'Single' ?
                         <FormControl component="fieldset">
                            <RadioGroup
                                aria-labelledby="radio-buttons-group-label"
                                value={reply.isRight}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeCorrectReply(e, index)}
                                name="radio-buttons-group"
                            >
                                <Radio value={true} style={{ marginRight: 9 }}/>
                            </RadioGroup>
                        </FormControl>
                        :
                        <Checkbox
                            checked={reply.isRight}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeCorrectReply(e, index)}
                        />
                    }
                    <IconButton onClick={() => removeReply(reply.sortNumber)}>
                        <SvgIcon><DeleteIcon /></SvgIcon>
                    </IconButton>
                </Stack>
            )}
        </>
    )
};