import { Button, Dialog, IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from "react-i18next";
import { getStyles } from "./styles";
import { tokens } from "../../../../../../locales/tokens";
import { ItemSelector } from "../../../../../../ui-kit/itemSelector/itemSelector";
import { TaskAutocomplete } from "../../../../../content/courses/courseEdit/ui/components/taskAutocomplete";
import { useTaskCreate } from "../../../presenter/useTaskCreate";

interface IProps {
    positionId: string | undefined;
    isOpen: boolean;
    onClose: () => void;
    currentTask: any;
};

export const TaskModal: FC<IProps> = ({ isOpen, onClose, currentTask, positionId }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { closeAndClearData, type, typeOptions, changeType, task, handleChangeTask, taskDropdownList, changeTaskText, taskValid, handleTask,
        createClick, editClick } = useTaskCreate(onClose, currentTask, positionId);

    return (
        <Dialog open={isOpen} onClose={closeAndClearData} maxWidth='sm' fullWidth sx={{ borderRadius: '20px' }}>
            <Stack m={4} spacing={3}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" fontWeight={600}>
                        {currentTask ? t(tokens.course.editTask) : t(tokens.course.addTask)}
                    </Typography>
                    <IconButton onClick={closeAndClearData}>
                        <SvgIcon><ClearIcon /></SvgIcon>
                    </IconButton>
                </Stack>
                <Stack mt={3} spacing={1}>
                    <Stack width="100%">
                        <Typography variant="h6" mb={1}>{t(tokens.course.taskType) + "*"}</Typography>
                        <ItemSelector
                            label={t(tokens.course.taskType)}
                            value={type}
                            isDisabled={false}
                            sortOptions={typeOptions}
                            onChange={changeType}
                        />
                    </Stack>
                    <Stack width="100%">
                        <Typography variant="h6" mb={1} color={taskValid ? 'inherit' : 'error'}>
                            {t(tokens.course.searchTaskByType) + "*"}
                        </Typography>
                        <TaskAutocomplete
                            title={t(tokens.course.searchTaskByType)}
                            value={task}
                            handleChangeValue={handleChangeTask}
                            optionList={taskDropdownList}
                            isMultiline={false}
                            changeText={changeTaskText}
                            isDisabled={false}
                        />
                    </Stack>
                </Stack>
                <Stack direction='row' mt={3} spacing={3} pt={3}>
                    <Button
                        variant="contained"
                        sx={styles.createButton}
                        onClick={handleTask}
                        disabled={createClick || editClick}
                    >
                        {currentTask?.id ?
                            t(tokens.common.save)
                            :
                            t(tokens.common.add)
                        }
                    </Button>
                    <Button
                        variant="outlined"
                        sx={styles.cancelButton}
                        onClick={closeAndClearData}
                        disabled={createClick || editClick}
                    >
                        {t(tokens.common.cancel)}
                    </Button>
                </Stack>
            </Stack>
        </Dialog >
    )
};