import { Pagination, Stack, Table } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { BackupTableHead } from "../backupTableHead";
import { BackupTableBody } from "../backupTableBody";

interface IProps {
    page: number;
    pageAmount: number;
    backupList: any[];
    handlePageChange: (event: ChangeEvent<unknown>, page: number) => void;
};

export const BackupTable: FC<IProps> = ({ backupList, handlePageChange, page, pageAmount }) => {

    return (
        <>
            <Table>
                <BackupTableHead />
                <BackupTableBody
                    backupList={backupList}
                />
            </Table>
            <Stack direction='row' justifyContent='center' my={4}>
                <Pagination
                    page={page}
                    count={pageAmount || 1}
                    color="primary"
                    onChange={handlePageChange}
                />
            </Stack>
        </>
    )
};