import { Button, CircularProgress, Dialog, IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from "react-i18next";
import { getStyles } from "./styles";
import { tokens } from "../../../../../../../locales/tokens";
import { TextAutocomplete } from "../../../../../../../ui-kit/textAutocomplete";
import AddIcon from '@mui/icons-material/Add';
import { ImagePicker } from "../../../../../tests/testCreate/ui/components/imagePicker";
import { useQuestionCreate } from "../../../presenter/useQuestionCreate";
import { TextInputModal } from "../../../../../tests/testCreate/ui/components/textInputModal";
import { RepliesList } from "../repliesList";
import { TEST_TYPES } from "../../../../../../../config";
import { ScaleRepliesList } from "../scaleRepliesList";

interface IProps {
    quizId: any;
    isOpen: boolean;
    onClose: () => void;
    currentQuestion: any;
};

export const QuestionModal: FC<IProps> = ({ isOpen, onClose, currentQuestion, quizId }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { replies, typeOptions, createOrEditQuestion, type, typeValid, changeType, title, titleValid, onChangeTitle, addReply, removeReply, createQuestionClick,
        handleFileChange, imageSrc, handleClearImageClick, onChangeReply, closeAndClearData, questionId, repliesValid, editQuestionClick, fileInputRef
    } = useQuestionCreate(onClose, currentQuestion, quizId);

    const answersList = useMemo(() => {
        if (type?.value === TEST_TYPES.SINGLE || type?.value === TEST_TYPES.MULTIPLE) {
            return (
                <RepliesList
                    type={type}
                    replies={replies}
                    repliesValid={repliesValid}
                    removeReply={removeReply}
                    onChangeReply={onChangeReply}
                />
            );
        } else if (type?.value === TEST_TYPES.SCALE) {
            return (
                <ScaleRepliesList
                    replies={replies}
                    repliesValid={repliesValid}
                    onChangeReply={onChangeReply}
                />
            )
        } else {
            return null;
        }
    }, [type, replies, repliesValid]);

    return (
        <Dialog open={isOpen} onClose={closeAndClearData} maxWidth='md' fullWidth sx={{ borderRadius: '20px' }}>
            <Stack m={4} sx={styles.wrapper}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" fontWeight={600}>
                        {currentQuestion ? t(tokens.test.editQuestion) : t(tokens.test.createQuestion)}
                    </Typography>
                    <IconButton onClick={closeAndClearData}>
                        <SvgIcon><ClearIcon /></SvgIcon>
                    </IconButton>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="flex-start" mt={3} spacing={3}>
                    <Stack spacing={2} width="100%">
                        <Stack width="100%">
                            <Typography mb={1} color={typeValid ? 'inherit' : 'error'}>
                                {t(tokens.test.type)}*
                            </Typography>
                            <TextAutocomplete
                                title={t(tokens.documents.type) + '*'}
                                value={type}
                                handleChangeValue={changeType}
                                optionList={typeOptions}
                                isMultiline={false}
                                isDisabled={!!questionId}
                            />
                        </Stack>
                        <TextInputModal
                            title={t(tokens.test.title) + '*'}
                            label={t(tokens.test.title)}
                            isValid={titleValid}
                            value={title}
                            onChangeValue={onChangeTitle}
                        />
                    </Stack>
                    <Stack width="70%" alignItems="center">
                        <ImagePicker
                            fileInputRef={fileInputRef}
                            imageSrc={imageSrc}
                            handleFileChange={handleFileChange}
                            handleClearImageClick={handleClearImageClick}
                        />
                    </Stack>
                </Stack>
                <Stack pt={2} alignItems="flex-start">
                    <Button
                        startIcon={<AddIcon />}
                        sx={styles.addQuestionButton}
                        onClick={addReply}
                        disabled={type?.value === TEST_TYPES.FREE
                            || type?.value === TEST_TYPES.SCALE
                            || type === null
                        }
                    >
                        {t(tokens.test.addReply)}
                    </Button>
                </Stack>
                {replies.length > 0 &&
                    <Stack mt={2}>
                        {answersList}
                    </Stack>
                }
                <Stack direction='row' mt={3} spacing={3} pt={3}>
                    <Button
                        variant="contained"
                        sx={styles.createButton}
                        onClick={createOrEditQuestion}
                        disabled={(!(!!replies.length) && type?.value !== TEST_TYPES.FREE) || createQuestionClick || editQuestionClick}
                    >
                        {createQuestionClick || editQuestionClick
                            ? <CircularProgress size={15} />
                            : questionId ? t(tokens.common.save) : t(tokens.common.create)}
                    </Button>
                    <Button
                        variant="outlined"
                        sx={styles.cancelButton}
                        onClick={closeAndClearData}
                        disabled={createQuestionClick || editQuestionClick}
                    >
                        {t(tokens.common.cancel)}
                    </Button>
                </Stack>
            </Stack>
        </Dialog>
    )
};