import { IconButton, Stack, SvgIcon, TextField, Typography } from "@mui/material";
import { ChangeEvent, FC } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { TEST_TYPES } from "../../../../../../../config";

interface IProps {
    replies: any[];
    repliesValid: any[];
    onChangeReply: (event: ChangeEvent<HTMLInputElement>, index: number) => void;
};

export const ScaleRepliesList: FC<IProps> = ({ replies, repliesValid, onChangeReply }) => {

    return (
        <>
            {replies?.map((reply: any, index: number) => {
                const title = index === 0 ? "Від" : "До";
                return (
                    <Stack key={index} mt={2} pb={1} direction="row" alignItems="center" spacing={3}>
                        <Typography variant="h6" fontWeight={600} width={25}>{title}</Typography>
                        <TextField
                            value={reply.title}
                            fullWidth
                            onChange={(e: ChangeEvent<HTMLInputElement>) => onChangeReply(e, index)}
                            error={!repliesValid[index].isValid}
                        />
                    </Stack>
                )
            })}
        </>
    )
};