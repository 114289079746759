import { Box, Link } from "@mui/material";
import { FC, useMemo } from "react";
import { getStyles } from "./styles";
import { tokens } from "../../../../../../locales/tokens";
import { useTranslation } from "react-i18next";
import { paths } from "../../../../../../paths";

export const RecoverPassButton: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    return (
        <Box sx={styles.forgotPassword} >
            <Link
                href={paths.dashboard.auth.forgotPassword}
                underline="hover"
                variant="subtitle2"
                color="#676767"
            >
                {t(tokens.auth.messages.forgetPassword)}
            </Link>
        </Box>
    )
}