export const styles = {
    container: { 
        pl: 3, 
        pt: 4,
        height: '170px' 
    },
    box: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: '20px'
    },
    region: {
        display: 'flex', 
        flexDirection: 'row', 
        gap: 2
    }
}