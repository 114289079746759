import { TableCell, TableHead, TableRow } from "@mui/material";
import { Typography } from "antd";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../locales/tokens";

export const BackupTableHead: FC = () => {
    const { t } = useTranslation();

    return (
        <TableHead>
            <TableRow>
                <TableCell width="30%" align="center">
                    <Typography>{t(tokens.backup.backupId)}</Typography>
                </TableCell>
                <TableCell width="30%" align="center">
                    <Typography>{t(tokens.backup.backupKey)}</Typography>
                </TableCell>
                <TableCell width="30%" align="center">
                    <Typography>{t(tokens.backup.date)}</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    )
};