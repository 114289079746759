import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setDeleteAdvertError, setLoading } from "../../repository/slice";

function* deleteAdvertWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.delete, "/api/admin/announcement/delete/" + payload.id);
        if (isError) {
            yield put(setDeleteAdvertError(message));
        } else {
            yield put(setDeleteAdvertError(null));
        }
    } catch (error) {
        console.error("deleteAdvertWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* deleteAdvertWatcher() {
    yield takeLatest("@saga/DELETE_ADVERT", deleteAdvertWorker);
};
