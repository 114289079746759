import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { paths } from "../../../../paths";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { tokens } from "../../../../locales/tokens";
import { useTranslation } from "react-i18next";
import { getAppPageById } from "../../../../store/appPage/useCases/getAppPageById/action";
import { setAppPageError, setSelectedAppPage } from "../../../../store/appPage/repository/slice";
import { ContentState, EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { selectAppPage } from "../../../../store/appPage/repository/selector";
import draftToHtml from "draftjs-to-html";
import { updateAppPage } from "../../../../store/appPage/useCases/updateAppPage/action";
import htmlToDraft from "html-to-draftjs";
import { IError } from "../../../../types/error";

const initialForm = {
    name: '',
    icon: null as any,
    description: '',
    isHidden: true
};

const initialFormErrors = {
    nameError: false,
    iconError: false,
    descriptionError: false,
};


export const useAppPageEdit = () => {
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const { t } = useTranslation();
    const { id: pageId } = useParams();
    const [form, setForm] = useState(initialForm);
    const [iconSrc, setIconSrc] = useState('');
    const [formErrors, setFormErrors] = useState(initialFormErrors);
    const [isClick, setIsClick] = useState(false);
    const [initialContent, setInitialContent] = useState('{"blocks":[],"entityMap":{}}');
    const [editorState, setEditorState] = useState(() => {
        if (initialContent) {
            try {
                const parsedContent = JSON.parse(initialContent);
                const contentState = convertFromRaw(parsedContent);
                return EditorState.createWithContent(contentState);
            } catch (error) {
                console.error('Error parsing initial content:', error);
            }
        }
        return EditorState.createEmpty();
    });
    const { isAppPageLoading, selectedAppPage, appPageError } = useSelector(selectAppPage);
    const isDisabled = !!selectedAppPage?.deletedAt;

    useEffect(() => {
        if (pageId) {
            dispatch(getAppPageById({ id: Number(pageId) }));
        } else {
            toast.error('Something went wrong');
        };

        return () => { dispatch(setSelectedAppPage(null)) };
    }, []);

    useEffect(() => {
        if (selectedAppPage) {
            const initForm = { ...form };
            selectedAppPage.name && (initForm.name = selectedAppPage.name);
            selectedAppPage.icon && (initForm.icon = selectedAppPage.icon);
            setIconSrc(selectedAppPage.icon?.url || '');
            if (selectedAppPage.description) {
                selectedAppPage.description && (initForm.description = selectedAppPage.description);
                const contentBlocks = htmlToDraft(selectedAppPage.description);
                const contentState = ContentState.createFromBlockArray(contentBlocks.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState);
            };
            initForm.isHidden = selectedAppPage?.isHidden || false;
            setForm(initForm);
        };
    }, [selectedAppPage]);

    useEffect(() => {
        if (!isAppPageLoading && isClick) {
            if (appPageError) {
                handleServerError(appPageError);
                dispatch(setAppPageError(null));
            } else {
                toast.success(t(tokens.appPage.messages.updated));
                navigation(paths.dashboard.appPage.index)
            }
            setIsClick(false);
        };
    }, [isAppPageLoading]);

    const handleServerError = (error: IError) => {
        let errors = formErrors;
        if (error?.startsWith("value too long for type character varying(255)")) {
            toast.error(t(tokens.common.tooMuchSymbols));
            setFormErrors(error => ({...errors, nameError: true }));
        } else if (error?.startsWith("value too long for type character varying(8192)")) {
            toast.error(t(tokens.common.tooMuchSymbols));
            setFormErrors(error => ({...errors, descriptionError: true }))
        } else {
            toast.error(error);
        }
    };
    
    const getHtmlText = (description: string) => {
        var div = document.createElement("div");
        div.innerHTML = description;
        var text = div.textContent || div.innerText || "";
        return text;
    };

    const isValidForm = useCallback(() => {
        let valid = true;
        let errors = formErrors;
        const { name, icon, description } = form;
        const descriptionText = getHtmlText(description);

        if (name.length) {
            errors = { ...errors, nameError: false };
        } else {
            valid = false;
            errors = { ...errors, nameError: true };
        };
        if (icon) {
            errors = { ...errors, iconError: false };
        } else {
            valid = false;
            errors = { ...errors, iconError: true };
        };
        if (descriptionText.length <=1) {
            valid = false;
            errors = { ...errors, descriptionError: true };
        };
        setFormErrors(errors);
        return valid;
    }, [form]);

    const onUploadImage = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            // const maxSizeBytes = 20 * 1024 * 1024;
            // if (file.size > maxSizeBytes) {
            //     toast.error("Файл слишком большой. Максимальный размер: 20 MB.");
            //     return;
            // };

            const reader = new FileReader();
            reader.onload = (e) => {
                const newImageSrc = e.target?.result as string;
                setIconSrc(newImageSrc);
            };
            reader.readAsDataURL(file);
        }
        setForm({ ...form, icon: file });
        formErrors.iconError && setFormErrors({ ...formErrors, iconError: false });
    };

    const onDeleteImage = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIconSrc('');
        setForm({ ...form, icon: null });
    };

    const onChangeName = (e: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, name: e.target.value });
        formErrors.nameError && setFormErrors({ ...formErrors, nameError: false });
    };

    const onChangeDescription = (newEditorState: EditorState) => {
        setEditorState(newEditorState);
        const contentState = newEditorState.getCurrentContent();
        const htmlContent = draftToHtml(convertToRaw(contentState));
        setForm({ ...form, description: htmlContent });
        formErrors && setFormErrors({ ...formErrors, descriptionError: false });
    };

    const onTogglePublish = () => setForm(prev => ({ ...prev, isHidden: !prev.isHidden }));

    const onEdit = () => {
        const isValid = isValidForm();
        if (isValid) {
            setIsClick(true);
            dispatch(updateAppPage({ id: Number(pageId), ...form }));
        } else {
            toast.error(t(tokens.common.fillAllFields))
        }
    };

    const onCancel = () => navigation(paths.dashboard.appPage.index);

    return { form, formErrors, isClick, editorState, iconSrc, isDisabled, onChangeName, onUploadImage, onDeleteImage, onChangeDescription, onTogglePublish, onEdit, onCancel, pageId };
};