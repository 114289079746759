import { Box } from "@mui/material";
import { Dispatch, FC, useMemo } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { getStyles } from "./styles";
import { TreeCategory } from "../treeCategory";
import { ICategory } from "../../../../../../../types/category";

interface IProps {
    categories: ICategory[];
    setUpdateClick: Dispatch<boolean>;
    handleStatus: (id: number, isHidden: boolean) => void;
    capturedCategory: ICategory | null;
    setCapturedCategory: Dispatch<any>;
    defineCategoryId: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => void;
    swapSections: (direction: "UP" | "DOWN", index: number, firstSection: any) => void;
};

export const CategoryTable: FC<IProps> = ({ categories, handleStatus, setUpdateClick, capturedCategory, setCapturedCategory, swapSections, defineCategoryId }) => {
    const styles = useMemo(() => getStyles(), []);

    return (
            <Box sx={styles.userTable}>
                <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                // draggable
                >
                    <TreeCategory
                        nodes={categories}
                        keyCategory='categories'
                        handleStatus={handleStatus}
                        isPoster={true}
                        setUpdateClick={setUpdateClick}
                        capturedCategory={capturedCategory}
                        setCapturedCategory={setCapturedCategory}
                        swapSections={swapSections}
                        defineCategoryId={defineCategoryId}
                    />
                </TreeView>
            </Box>
    )
};