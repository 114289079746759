import { SagaIterator } from "redux-saga";
import { all, call, delay, put, takeEvery } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setUpdateTaskError } from "../../repository/slice";
import { getSingleCourse } from "../getSingleCourse/action";

function* updateTaskWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.put, `/api/admin/courses/${payload.courseId}/update-content`, payload.body);
        if (isError) {
            yield put(setUpdateTaskError(message));
        } else {
            yield put(setUpdateTaskError(null));
            yield put(getSingleCourse({ id: Number(payload.courseId) }));
        }
    } catch (error) {
        console.error("updateTaskWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* updateTaskWatcher() {
    yield takeEvery("@saga/UPDATE_TASK", updateTaskWorker);
};
