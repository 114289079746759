import { TableCell, TableRow, Typography } from "@mui/material";
import dayjs from "dayjs";
import { FC } from "react";

interface IProps {
    backup: any;
};

export const BackupTableItem: FC<IProps> = ({ backup }) => {
    const formattedDate = dayjs(backup.updatedAt).format('DD.MM.YYYY HH:mm:ss');

    return (
        <TableRow key={backup.id}>
            <TableCell width="30%" align="center">
                <Typography>{backup.id}</Typography>
            </TableCell>
            <TableCell width="30%" align="center">
                <Typography>{backup.key}</Typography>
            </TableCell>
            <TableCell width="30%" align="center">
                <Typography>{formattedDate}</Typography>
            </TableCell>
        </TableRow>
    )
};