import { FC } from "react";
import { UserChart } from "./components/userChart";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { useUserAnalytics } from "../presenter/useUserAnalytics";

export const UserAnalytics: FC = () => {
    const { t } = useTranslation();
    const { dates, handleRangeChange, disabledDate, test, handleChangeTest, changeTestText, testList } = useUserAnalytics();

    return (
        <Stack m={2}>
            <Typography variant="h4" fontWeight={600}>
                {t(tokens.analytics.analyticsUser)}
            </Typography>
            <Stack justifyContent="center" alignItems="center" mt={3}>
                <UserChart
                    test={test}
                    handleChangeTest={handleChangeTest}
                    changeTestText={changeTestText}
                    optionList={testList}
                    chartSeries={[
                        { name: t(tokens.analytics.result), data: [72, 40, 37, 51, 83] },
                    ]}
                    questionList={[
                        "Питання тесту №1",
                        "Питання тесту №2",
                        "Питання тесту №3",
                        "Питання тесту №4",
                        "Питання тесту №5",
                    ]}
                    dates={dates}
                    handleRangeChange={handleRangeChange}
                    disabledDate={disabledDate}
                />
            </Stack>
        </Stack>
    )
};