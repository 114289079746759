import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';

interface IState {
    isLoading: boolean;
    testList: any[];
    singleTest: any;
    testsCount: number;
    deleteTestError: IError;
    updateTestError: IError;
    uploadReportError: IError;
    createTestError: IError;
    getTestListError: IError;
    createQuestionError: IError;
    updateQuestionError: IError;
    deleteQuestionError: IError;
    deleteFileFromQuestionError: IError;
    questionList: any[];
    testId: number;
    questionId: number;
};

const initialState: IState = {
    isLoading: false,
    testList: [],
    singleTest: null,
    testsCount: 0,
    deleteTestError: null,
    updateTestError: null,
    uploadReportError: null,
    createTestError: null,
    getTestListError: null,
    createQuestionError: null,
    updateQuestionError: null,
    deleteQuestionError: null,
    deleteFileFromQuestionError: null,
    questionList: [],
    testId: 0,
    questionId: 0,
};

const TestSlice = createSlice({
    name: 'test',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setTestList: (state, action: PayloadAction<any>) => {
            state.testList = action.payload.testList;
            state.testsCount = action.payload.count;
        },
        setTestListError: (state, action: PayloadAction<IError>) => {
            state.getTestListError = action.payload;
        },
        setSingleTest: (state, action: PayloadAction<any>) => {
            state.singleTest = action.payload;
        },
        setDeleteTestError: (state, action: PayloadAction<IError>) => {
            state.deleteTestError = action.payload;
        },
        setUploadReportError: (state, action: PayloadAction<IError>) => {
            state.uploadReportError = action.payload;
        },
        setUpdateTestError: (state, action: PayloadAction<IError>) => {
            state.updateTestError = action.payload;
        },
        setCreateTestError: (state, action: PayloadAction<IError>) => {
            state.createTestError = action.payload;
        },
        createQuestionError: (state, action: PayloadAction<IError>) => {
            state.createQuestionError = action.payload;
        },
        updateQuestionError: (state, action: PayloadAction<IError>) => {
            state.updateQuestionError = action.payload;
        },
        setDeleteQuestionError: (state, action: PayloadAction<IError>) => {
            state.deleteQuestionError = action.payload;
        },
        setQuestionList: (state, action: PayloadAction<any[]>) => {
            state.questionList = action.payload;
        },
        setTestId: (state, action: PayloadAction<number>) => {
            state.testId = action.payload;
        },
        setQuestionId: (state, action: PayloadAction<number>) => {
            state.questionId = action.payload;
        },
        setDeleteFileFromQuestionError: (state, action: PayloadAction<IError>) => {
            state.deleteFileFromQuestionError = action.payload;
        },
    },
});

export const { setLoading, setTestList, setDeleteTestError, setUpdateTestError, setUploadReportError, setCreateTestError, setDeleteQuestionError,
    setSingleTest, setTestListError, setQuestionList, setTestId, createQuestionError, setQuestionId, updateQuestionError, setDeleteFileFromQuestionError
 } = TestSlice.actions;
export const TestReducer = TestSlice.reducer;