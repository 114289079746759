import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';
import { ITask } from '../../../modules/content/courses/courseEdit/presenter/types';
import { ICourse } from './ICourse';

interface IState {
    isLoading: boolean;
    courseList: ICourse[];
    singleCourse: ICourse | null;
    courseId: number;
    courseCount: number;
    deleteCourseError: IError;
    updateCourseError: IError;
    uploadReportError: IError;
    createCourseError: IError;
    getCourseListError: IError;
    createTaskError: IError;
    updateTaskError: IError;
    deleteTaskError: IError;
    taskList: ITask[];
    taskId: number;
};

const initialState: IState = {
    isLoading: false,
    courseList: [],
    singleCourse: null,
    courseId: 0,
    courseCount: 0,
    deleteCourseError: null,
    updateCourseError: null,
    uploadReportError: null,
    createCourseError: null,
    getCourseListError: null,
    createTaskError: null,
    updateTaskError: null,
    deleteTaskError: null,
    taskList: [],
    taskId: 0
};

const CourseSlice = createSlice({
    name: 'course',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setCourseList: (state, action: PayloadAction<any>) => {
            state.courseList = action.payload.list;
            state.courseCount = action.payload.count;
        },
        setCourseListError: (state, action: PayloadAction<IError>) => {
            state.getCourseListError = action.payload;
        },
        setSingleCourse: (state, action: PayloadAction<any>) => {
            state.singleCourse = action.payload;
        },
        setCourseId: (state, action: PayloadAction<number>) => {
            state.courseId = action.payload;
        },
        setDeleteCourseError: (state, action: PayloadAction<IError>) => {
            state.deleteCourseError = action.payload;
        },
        setUploadReportError: (state, action: PayloadAction<IError>) => {
            state.uploadReportError = action.payload;
        },
        setUpdateCourseError: (state, action: PayloadAction<IError>) => {
            state.updateCourseError = action.payload;
        },
        setCreateCourseError: (state, action: PayloadAction<IError>) => {
            state.createCourseError = action.payload;
        },

        setCreateTaskError: (state, action: PayloadAction<IError>) => {
            state.createTaskError = action.payload;
        },
        setUpdateTaskError: (state, action: PayloadAction<IError>) => {
            state.updateTaskError = action.payload;
        },
        setDeleteTaskError: (state, action: PayloadAction<IError>) => {
            state.deleteTaskError = action.payload;
        },
        setTaskList: (state, action: PayloadAction<ITask[]>) => {
            state.taskList = action.payload;
        },
        setTaskId: (state, action: PayloadAction<number>) => {
            state.taskId = action.payload;
        },
    },
});

export const { setLoading, setCourseId, setCourseList, setCourseListError, setCreateCourseError, setDeleteCourseError, setSingleCourse, 
    setUpdateCourseError, setUploadReportError, setCreateTaskError, setTaskId, setTaskList, setDeleteTaskError, setUpdateTaskError } = CourseSlice.actions;
export const CourseReducer = CourseSlice.reducer;