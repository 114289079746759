import { Box } from "@mui/material";
import { FC } from "react";
import { getStyles } from "./styles";
import Lottie from "lottie-react";
import loader from './loader.json';

export const PizzaLoader: FC = () => {
    const styles = getStyles();

    return (
        <Box sx={styles.backgraund}>
            <img src="/images/logo/logo.png" alt="logo"/>
            <Box sx={styles.loader}>
                <Lottie animationData={loader} loop={true} />
            </Box>
        </Box>
    )
};