import { FC, useState } from "react";
import { Stack } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import 'dayjs/locale/uk';
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/uk_UA';

dayjs.locale('uk');
const dateFormat = 'YYYY/MM/DD';

interface IProps {
    dates: { startDate: Dayjs | null; endDate: Dayjs | null; };
    handleRangeChange: (selectedDates: any, dateStrings: [string, string]) => void;
    disabledDate: (current: Dayjs) => boolean;
};

export const MuiDateRange: FC<IProps> = ({dates, disabledDate, handleRangeChange}) => {
    const { RangePicker } = DatePicker;

    return (
        <Stack width={300}>
            <RangePicker
                defaultValue={[
                    dayjs().startOf('month'),
                    dayjs().endOf('month')
                ]}
                format={dateFormat}
                locale={locale}
                onChange={handleRangeChange}
                disabledDate={disabledDate}
                size="large"
            />
        </Stack>
    )
};