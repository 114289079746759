import { FC, ReactNode, useContext } from "react";
import { SettingsContext } from "../providers/settings";
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";

interface ThemeManagerProps {
    children: ReactNode;
};

const ThemeManager: FC<ThemeManagerProps> = ({ children }) => {
    const { paletteMode } = useContext(SettingsContext);

    const lightTheme = createTheme({
        palette: {
            mode: "light",
            primary: {
                main: "#F8B043",
            },
            secondary: {
                main: "#cf9236",
            },
            text: {
                primary: '#000000',
                secondary: '#8E8D8D',
            },
            background: {
                default: "#ffffff",
            },
        },
    });

    const darkTheme = createTheme({
        palette: {
            mode: "dark",
            primary: {
                main: "#df9e3c",
            },
            secondary: {
                main: "#363535",
            },
            text: {
                primary: '#ffffff',
                secondary: '#8E8D8D', 
            },
            background: {
                default: "#363535",
            },
        },
    });

    const theme = paletteMode === "light" ? lightTheme : darkTheme;

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
};

export default ThemeManager;




