import { Autocomplete, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IUserOption, flatUsersList } from '../../../../../../utils/flatUsersList';
import { tokens } from '../../../../../../locales/tokens';

interface Props {
    isDisabled?: boolean;
    onSearch: (value: any) => void;
    users: any;
    onSearchUsers: (event: ChangeEvent<HTMLInputElement>) => void;
    handleUserChange: (value: any) => void;
    groupUsers?: IUserOption[];
};

export const SearchUserList: FC<Props> = ({ onSearch, users, onSearchUsers, groupUsers, handleUserChange, isDisabled }) => {
    const { t } = useTranslation();

    return (
        <Box sx={{ flexGrow: 1, mb: 2 , width: "100%"}}>
            <Autocomplete
                // disablePortal
                multiple
                value={groupUsers}
                limitTags={2}
                onChange={(e, value) => { handleUserChange(value); }}
                filterOptions={(option) => option}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                id="multiple-limit-tags"
                options={flatUsersList(users) as IUserOption[]}
                renderOption={(params, option): JSX.Element => {
                    return (<Typography {...params} key={option.id}>{option.name}</Typography>)
                }}
                renderInput={(params) => (
                    <TextField {...params} onChange={onSearchUsers} variant='outlined' fullWidth placeholder={t(tokens.notification.searchUsers)} sx={{ "& .MuiInputBase-input": { height: '25px' } }} />
                )}
                disabled={isDisabled}
            />
        </Box>
    );
};
