import { Stack, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { CSSProperties, FC, useMemo } from "react";
import { getStyles } from "./styles";

interface IProps {
    item: any;
};

export const LeaderboardTableItem: FC<IProps> = ({ item }) => {
    const styles = useMemo(() => getStyles(), []);

    return (
        <TableRow key={item.id}>
            <TableCell align="center" width="50%" padding="none">
                <Stack direction="row" spacing={2} alignItems="center" pl={3}>
                    <Stack width={30} height={30}>
                        <img alt='image' src={item.user.photo  || '/images/user/user.png'} style={styles.image as CSSProperties} />
                    </Stack>
                    <Typography variant="body2" sx={styles.name}>{item.user.first_name + " " + item.user.last_name}</Typography>
                </Stack>
            </TableCell >
            <TableCell align="center" width="50%">
                <Typography noWrap variant="body2">{item.points} </Typography>
            </TableCell>
        </TableRow>
    );
};