import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setTraineePositions, setTraineeTasksError } from "../../repository/slice";

function* getTraineePositionsWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<{ count: number, rows: any[] }> = yield call(requester.get, "/api/dynamic-destination/intern", payload);
        if (isError) {
            yield put(setTraineeTasksError(message));
        } else {
            yield put(setTraineeTasksError(null));
            yield put(setTraineePositions({rows: data.rows, count: data.count}));
        }
    } catch (error) {
        console.error("getTraineePositionsWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* getTraineePositionsWatcher() {
    yield takeLatest("@saga/GET_TRAINEE_POSITIONS", getTraineePositionsWorker);
};