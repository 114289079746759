import { Button, Stack, TextField, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { getStyles } from "./styles";
import { useRoleCreate } from "../presenters/useRoleCreate";

export const RoleCreate: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { form, formErrors, isClick, onChangeRoleName, onCreateRole, onCancel } = useRoleCreate();
    const { name } = form;
    const { roleNameError } = formErrors;

    return (
        <Stack m={2}>
            <Stack marginBottom={2} direction='row' justifyContent='space-between'>
                <Typography variant="h4" fontWeight={600}>{t(tokens.roles.title.createRole)}</Typography>
            </Stack>
            <Stack width={'50%'} >
                <Stack mt={4}>
                    <Typography variant="h6" mb={1} color={roleNameError ? 'error' : 'inherit'} >{t(tokens.roles.name)}*</Typography>
                    <TextField
                        type="text"
                        label={t(tokens.roles.name) + '*'}
                        value={name}
                        onChange={onChangeRoleName}
                        autoComplete="off"
                    />
                </Stack>
            </Stack>
            <Stack direction='row' mt={3} spacing={3}>
                <Button
                    variant="contained"
                    sx={styles.createButton}
                    onClick={onCreateRole}
                    disabled={isClick}
                >
                    {t(tokens.common.create)}
                </Button>
                <Button
                    variant="outlined"
                    sx={styles.cancelButton}
                    onClick={onCancel}
                >
                    {t(tokens.common.cancel)}
                </Button>
            </Stack>
        </Stack >
    )
};