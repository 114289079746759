export const getStyles = () => {
    const styles = {
        name: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            WebkitLineClamp: 2,
            lineHeight: 1.2,
            textAlign: "left",
            wordBreak: 'break-all'
        },
        image: { 
            objectFit: 'cover', 
            width: 35, 
            height: 35, 
            borderRadius: '50%'
        }
    };
    return styles;
};