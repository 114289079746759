import { TableBody } from "@mui/material";
import { FC } from "react";
import { QuizTableItem } from "../quizTableItem";

interface IProps {
    quizList: any[];
    handleStatus: (id: number, status: string) => void;
    handleDelete: (id: number) => void;
    handleUpload: (id: number, name: string) => void;
};

export const QuizTableBody: FC<IProps> = ({ quizList, handleDelete, handleStatus, handleUpload }) => {

    return (
        <TableBody>
            {quizList.map(quiz => (
                <QuizTableItem
                    quiz={quiz}
                    key={quiz.id}
                    handleStatus={handleStatus}
                    handleDelete={handleDelete}
                    handleUpload={handleUpload}
                />
            ))}
        </TableBody>
    )
};