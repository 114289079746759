import { Button, Checkbox, FormControlLabel, Stack, TextField, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { useAppPageEdit } from "../presenters/useAppPageEdit";
import { getStyles } from "./styles";
import TextEditor from "../../../../ui-kit/textEditor/textEditor";
import { ImagePicker } from "../../../../ui-kit/imagePicker";

export const AppPageEdit: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { } = useAppPageEdit();
    const { form, formErrors, isClick, editorState, iconSrc, isDisabled, onChangeName, onUploadImage, onDeleteImage, onChangeDescription, onTogglePublish, onEdit, onCancel, pageId } = useAppPageEdit();
    const { name, isHidden } = form;
    const { nameError, iconError, descriptionError } = formErrors;

    return (
        <Stack m={2}>
            <Stack marginBottom={2} direction='row' justifyContent='space-between'>
                <Typography variant="h4" fontWeight={600}>{t(tokens.appPage.title.edit)}</Typography>
            </Stack>
            <Stack width={'50%'} >
                <ImagePicker
                    disabled={isDisabled}
                    title={t(tokens.appPage.form.images)}
                    imageSrc={iconSrc}
                    isError={iconError}
                    accept={'.svg'}
                    onUploadImage={onUploadImage}
                    onDeleteImage={onDeleteImage}
                />
                <Stack mt={4}>
                    <Typography variant="h6" mb={1} color={nameError ? 'error' : 'inherit'} >{t(tokens.appPage.form.name)}*</Typography>
                    <TextField
                        disabled={isDisabled}
                        type="text"
                        label={t(tokens.appPage.form.name) + '*'}
                        value={name}
                        onChange={onChangeName}
                        autoComplete="off"
                    />
                </Stack>
            </Stack>
            <Stack mt={2}>
                <Typography variant="h6" mb={1} color={descriptionError ? 'error' : 'inherit'}>
                    {t(tokens.appPage.form.description)}*
                </Typography>
                <TextEditor
                    disabled={isDisabled}
                    editorState={editorState}
                    onEditorStateChange={onChangeDescription}
                    isToggleTable={true}
                />
            </Stack>
            <Stack justifyContent={'center'}>
                <div>
                    <FormControlLabel disabled={isDisabled} control={<Checkbox checked={!isHidden} onChange={onTogglePublish} />} label={t(tokens.documents.publish)} />
                </div>
            </Stack>
            {!isDisabled && <Stack direction='row' mt={3} spacing={3}>
                <Button
                    variant="contained"
                    sx={styles.createButton}
                    onClick={onEdit}
                    disabled={isClick}
                >
                    {t(tokens.common.save)}
                </Button>
                <Button
                    variant="outlined"
                    sx={styles.cancelButton}
                    onClick={onCancel}
                >
                    {t(tokens.common.cancel)}
                </Button>
            </Stack>}
        </Stack >
    )
};