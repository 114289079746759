import { Avatar, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import { FC, useMemo } from "react"
import { useUserStudyHistory } from "../presenters/useUserStudyHistory";
import { getStyles } from "./styles";
import { UserAchievements } from "./components/userAchievements";
import { HistoryTableList } from "./components/historyTableList";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { EmptyPage } from "../../../content/category/categoryEmpty/ui";
import { SearchInput } from "../../../../ui-kit/searchInput/searchInput";
import { BadgeModal } from "./components/badgeModal";

export const UserStudyHistory: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { imageSrc, singleUser, learningHistory, handlePageChange, pageAmount, currentPage, handleSearchTaskText, searchText, isProccessStudy,
        currentBadge, handleOpenBadgeModal, handleCloseQuestionModal, defineCurrentBadge, isBadgeModalOpen
    } = useUserStudyHistory();
    
    return (
        <Stack m={2}>
            <Typography variant="h5" fontWeight={600}>
                {t(tokens.users.studyHistory.studyHistory)}
            </Typography>
            <Stack direction='row' spacing={4} alignItems='flex-start' mt={2}>
                <Avatar
                    alt="User Avatar"
                    src={imageSrc}
                    sx={styles.avatar}
                />
                <UserAchievements user={singleUser} defineCurrentBadge={defineCurrentBadge} />
            </Stack>
            <Stack mt={2}>
                <div id="search-task"/>
                <SearchInput
                    searchText={searchText}
                    label={t(tokens.users.studyHistory.searchByTaskName)}
                    handleSearch={handleSearchTaskText}
                    size="small"
                />
            </Stack>
            {learningHistory?.length > 0 &&
                <HistoryTableList
                    historyList={learningHistory}
                    handlePageChange={handlePageChange}
                    pageAmount={pageAmount}
                    currentPage={currentPage}
                />
            }
            {(!learningHistory?.length && isProccessStudy) &&
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={styles.progress}
                >
                    <CircularProgress color="primary" />
                </Stack>
            }
            {(!learningHistory?.length && !isProccessStudy) &&
                <Stack>
                    <EmptyPage
                        title={t(tokens.users.studyHistory.emptyList)}
                        description={t(tokens.users.studyHistory.emptyListDesc)}
                    />
                </Stack>
            }
            <BadgeModal
                isOpen={isBadgeModalOpen}
                currentBadge={currentBadge}
                onClose={handleCloseQuestionModal}
            />
        </Stack>
    )
};