import type { ChangeEvent, FC } from 'react';
import { Card, CardContent, Stack } from '@mui/material';
import { ChartSeries } from '../../../presenter/useSingleTestChart';
import InfoIcon from '@mui/icons-material/Info';
import { useTranslation } from 'react-i18next';
import { ChartHeader } from '../../../../general/ui/components/chartHeader';
import { ChartBody } from '../../../../general/ui/components/chartBody';
import { TextAutocomplete } from '../../../../../../ui-kit/textAutocomplete';

interface IProps {
  chartSeries: ChartSeries;
  labels: string[];
  currentCourse: any;
  courseList: any[];
  changeCourse: (value: any) => void;
  changeCourseText: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const SingleCourseChart: FC<IProps> = ({ chartSeries, labels, courseList, currentCourse, changeCourse, changeCourseText }) => {
  const { t } = useTranslation();

  return (
    <Stack width="70%" m={3} spacing={1}>
      <Stack direction="row" justifyContent="flex-end">
        <TextAutocomplete
          optionList={courseList}
          value={currentCourse}
          handleChangeValue={changeCourse}
          title='Курс'
          isMultiline={false}
          size='small'
          changeText={changeCourseText}
        />
      </Stack>
      <Card elevation={2}>
        <ChartHeader title="Результат курса" subTitle={""} icon={<InfoIcon />} />
        <CardContent>
          <ChartBody chartSeries={chartSeries} labels={labels} />
        </CardContent>
      </Card>
    </Stack>

  );
};