import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setAppPageLoading, setAppPageError, setSelectedAppPage } from "../../repository/slice";
import { IAppPage } from "../../types/IAppPage";

function* getAppPageByIdWorker({ payload }: any): SagaIterator {
    try {
        yield put(setAppPageLoading(true));
        const { data, message, isError }: IResponse<IAppPage> = yield call(requester.get, "/api/admin/dynamic-pages/one/" + payload.id);
        if (isError) {
            yield put(setAppPageError(message));
        } else {
            yield put(setSelectedAppPage(data || null));
        }
    } catch (error) {
        console.error("getAppPageByIdWorker: ", error);
    } finally {
        yield put(setAppPageLoading(false));
    };
};

export function* getAppPageByIdWatcher() {
    yield takeLatest("@saga/GET_APP_PAGE_BY_ID", getAppPageByIdWorker);
};