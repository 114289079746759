import { useState } from "react";
import { z } from "zod";
import { requester } from "../../../../libs/requester/requester-axios";

const errorsSchema = z.object({
    name: z.string().min(2, { message: 'Им\'я повинно містити принаймні 2 символи' }),
    city: z.string(),
    email: z.string().email({ message: 'Введіть коректну електронну адресу' }),
    phone: z.string().min(10, { message: 'Телефонний номер повинен містити принаймні 10 цифр' }),
});

export const useUserForm = () => {
    const [isShownModal, setShownModal] = useState(true);
    const [name, setName] = useState('');
    const [city, setCity] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [feedback, setFeedback] = useState('');
    const [errors, setErrors] = useState({ name: '', city: '', email: '', phone: '' });

    const handleClose = () => {
        setShownModal(false);
    };

    const onChangeName = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setName(e.target.value);
        setErrors((prev) => ({ ...prev, name: '' }));
    };

    const onChangeCity = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setCity(e.target.value);
        setErrors((prev) => ({ ...prev, city: '' }));
    };

    const onChangeEmail = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setEmail(e.target.value);
        setErrors((prev) => ({ ...prev, email: '' }));
    };

    const onChangePhone = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const value = e.target.value;
        const regex = /^[0-9]*$/;
        if (regex.test(value)) {
            setPhone(e.target.value);
            setErrors((prev) => ({ ...prev, phone: '' }));
        }
    };

    const onChangefeedback = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setFeedback(e.target.value);
    };

    const handleForm = async () => {
        const result: any = errorsSchema.safeParse({ name, city, email, phone });
        if (result.success) {
            try {
                const response = await requester.get("/api/support", { full_name: name, city, email, phone, feedback });
                setShownModal(false);
            } catch (error) {
                console.warn('UserService -> signIn: ', error);
                return { isError: true, data: null, message: '' } as any;
            }
            setName('');
            setCity('');
            setEmail('');
            setPhone('');
            setFeedback('');
        } else {
            result.error.errors.map((item: any) => { setErrors((prev) => ({ ...prev, [item.path[0]]: item.message })); });
        }
    };

    const handleSuccessForm = () => {
        setShownModal(true);
    };

    return { isShownModal, handleClose, onChangeName, onChangeCity, onChangeEmail, onChangePhone, name, city, email, phone, errors, handleForm, feedback, onChangefeedback, handleSuccessForm }
};