import { Box } from "@mui/material";
import { FC, useMemo } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { renderUserTree } from "../../../../../../utils/renderUserTree";
import { getStyles } from "./styles";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";

interface IProps {
    orgStructure: any[];
    handleStatus: (id: number, isBanned: boolean) => void;
};

export const UserTree: FC<IProps> = ({ orgStructure, handleStatus }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    return (
        <Box sx={styles.userTable}>
            <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                defaultExpanded={[t(tokens.users.companyAdministration)]}
            >
                {renderUserTree(orgStructure, t(tokens.users.structure), handleStatus)}
            </TreeView>
        </Box>
    )
};