import { Stack, Typography } from "@mui/material";
import { FC } from "react";
import { tokens } from "../../../../../../locales/tokens";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { TextData } from "../textData";
import moment from "moment";

interface IProps {
    user: any;
};

export const UserInfo: FC<IProps> = ({ user }) => {
    const { t } = useTranslation();
    const formattedBirthDate = user?.birth_date ? dayjs(user.birth_date).format('DD.MM.YYYY') : t(tokens.common.noData);
    const specifiedDate = moment(user?.date_of_employment);
    const currentDate = moment().add(1, 'days')/* .startOf('day') */;

    function getYear(year: number) {
        if (year === 1) {
            return ' рік';
        } else if (year % 10 >= 2 && year % 10 <= 4 && (year % 100 < 10 || year % 100 >= 20)) {
            return ' роки';
        } else {
            return ' років';
        }
    };

    function getMonths(month: number) {
        if (month === 1) {
            return ' місяць';
        } else if (month % 10 >= 2 && month % 10 <= 4 && (month % 100 < 10 || month % 100 >= 20)) {
            return ' місяці';
        } else {
            return ' місяців';
        }
    };

    function getDays(day: number) {
        if (day === 1) {
            return ' день';
        } else if (day % 10 >= 2 && day % 10 <= 4 && (day % 100 < 10 || day % 100 >= 20)) {
            return ' дні';
        } else {
            return ' днів';
        }
    };

    const years = currentDate.diff(specifiedDate, 'years');
    const months = currentDate.diff(specifiedDate, 'months');
    const yearDifference = years + getYear(years);
    const adjustedMonths = months - (years * 12);
    const monthDifference = adjustedMonths + getMonths(adjustedMonths);
    const remainingDays = moment(specifiedDate).add(years, 'years').add(adjustedMonths, 'months');
    const dayDifference = currentDate.diff(remainingDays, 'days');
    const days = dayDifference ?  dayDifference + getDays(dayDifference) : '';
    const difference = yearDifference + ' ' + monthDifference + ' ' + days;

    return (
        <>
            {
                user ?
                    <Stack m={2} spacing={1} flex={1}>
                        <Typography variant="h6" fontWeight={600}>
                            {user.last_name + " " + user.first_name + " " + user.patronymic}
                        </Typography>
                        <Stack direction='row' alignItems='flex-start' pt={2} spacing={5}>
                            <Stack spacing={1} >
                                <TextData title={t(tokens.users.city)} value={user.city} />
                                <TextData title={t(tokens.users.birthday)} value={formattedBirthDate} />
                                <TextData title={t(tokens.users.phone)} value={`+${user.login}`} />
                                <TextData title={t(tokens.users?.email)} value={user.email} />
                            </Stack>
                            <Stack spacing={1}>
                                <TextData title={t(tokens.users.position)} value={user.position} />
                                <TextData title={t(tokens.users?.daysInCompany)} value={difference} />
                                <TextData title={t(tokens.users?.status)} value={user.status} />
                                {user.pizza_qty ? <TextData title={t(tokens.users.pizzaMade)} value={user.pizza_qty} /> : ""}
                            </Stack>
                        </Stack>
                    </Stack>
                    :
                    null}
        </>
    )
};