export const getStyles = () => {
    const styles = {
        svgIcon: {
            fontSize: 26
        },
        cell: {
            py: 1
        }
    };
    return styles;
};