import { Stack } from "@mui/material";
import { SearchInput } from "../../../../../../../ui-kit/searchInput/searchInput";
import { TabSelector } from "../../../../../../../ui-kit/tabSelector/tabSelector";
import { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../../locales/tokens";

interface IProps {
    searchText: string;
    tab: string;
    handleSearchCategoryText: (e: ChangeEvent<HTMLInputElement>) => void;
    handleTabsChange: (event: ChangeEvent<{}>, tab: string) => void;
};

export const CategoryFilters: FC<IProps> = ({handleSearchCategoryText, handleTabsChange, searchText, tab}) => {
    const { t } = useTranslation();

    const tabOptions = [
        { label: t(tokens.users.ordersHistory.documentCategory), value: "Documents" },
        { label: t(tokens.users.ordersHistory.knowledgeCategory), value: "Knowledge" },
        { label: t(tokens.users.ordersHistory.hidden), value: "Hidden" },
    ];

    return (
        <>
            <Stack mt={3}>
                <SearchInput
                    searchText={searchText}
                    label={t(tokens.category.searchByCategoryName)}
                    handleSearch={handleSearchCategoryText}
                    size="small"
                />
            </Stack>
            <Stack>
                <TabSelector
                    tab={tab}
                    tabOptions={tabOptions}
                    handleTabsChange={handleTabsChange}
                />
            </Stack>
        </>
    )
};