import { Button, CircularProgress, Dialog, IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import { useTranslation } from "react-i18next";
import { getStyles } from "./styles";
import { tokens } from "../../../../../../../locales/tokens";
import { useDayCreate } from "../../../presenter/useDayCreate";
import { ItemSelector } from "../../../../../../../ui-kit/itemSelector/itemSelector";
import { TextAutocomplete } from "../../../../../../../ui-kit/textAutocomplete";
import { TextInput } from "../../../../../tests/testCreate/ui/components/textInput";
import { ITask } from "../../../presenter/types";
import { TaskAutocomplete } from "../taskAutocomplete";

interface IProps {
    courseId: string | undefined;
    isOpen: boolean;
    onClose: () => void;
    currentTask: ITask | null;
};

export const DayModal: FC<IProps> = ({ isOpen, onClose, currentTask, courseId }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { closeAndClearData, type, typeOptions, changeType, task, handleChangeTask, taskDropdownList, changeTaskText, day, onChangeDay,
        dayValid, taskValid, handleTask, taskId, createClick, editClick
    } = useDayCreate(onClose, currentTask, courseId);

    return (
        <Dialog open={isOpen} onClose={closeAndClearData} maxWidth='sm' fullWidth sx={{ borderRadius: '20px' }}>
            <Stack m={4} spacing={3}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" fontWeight={600}>
                        {currentTask ? t(tokens.course.editTask) : t(tokens.course.createTask)}
                    </Typography>
                    <IconButton onClick={closeAndClearData}>
                        <SvgIcon><ClearIcon /></SvgIcon>
                    </IconButton>
                </Stack>
                <Stack mt={3} spacing={1}>
                    <Stack width="100%">
                        <TextInput
                            title={t(tokens.course.day) + "*"}
                            label="День"
                            isValid={dayValid}
                            value={day}
                            isMultiline={false}
                            onChangeValue={onChangeDay}
                        />
                    </Stack>
                    <Stack width="100%">
                        <Typography variant="h6" mb={1}>{t(tokens.course.taskType) + "*"}</Typography>
                        <ItemSelector
                            label={t(tokens.course.taskType)}
                            value={type}
                            isDisabled={!!taskId}
                            sortOptions={typeOptions}
                            onChange={changeType}
                        />
                    </Stack>
                    <Stack width="100%">
                        <Typography variant="h6" mb={1} color={taskValid ? 'inherit' : 'error'}>
                            {t(tokens.course.searchTaskByType) + "*"}
                        </Typography>
                        <TaskAutocomplete
                            title={t(tokens.course.searchTaskByType)}
                            value={task}
                            handleChangeValue={handleChangeTask}
                            optionList={taskDropdownList}
                            isMultiline={false}
                            changeText={changeTaskText}
                            isDisabled={!!taskId}
                        />
                    </Stack>
                </Stack>
                <Stack direction='row' mt={3} spacing={3} pt={3}>
                    <Button
                        variant="contained"
                        sx={styles.createButton}
                        onClick={handleTask}
                        disabled={createClick || editClick}
                    >
                        {createClick || editClick 
                        ? <CircularProgress size={15}/> 
                        : taskId ? t(tokens.common.save) : t(tokens.common.create)}
                    </Button>
                    <Button
                        variant="outlined"
                        sx={styles.cancelButton}
                        onClick={closeAndClearData}
                        disabled={createClick || editClick}
                    >
                        {t(tokens.common.cancel)}
                    </Button>
                </Stack>
            </Stack>
        </Dialog >
    )
};