import { Box, InputAdornment, OutlinedInput, SvgIcon } from "@mui/material"
import { ChangeEvent, FC, FormEvent } from "react"
import SearchIcon from '@mui/icons-material/Search';

interface IProps {
    size?: string;
    searchText: string;
    label: string;
    handleSearch: (e: ChangeEvent<HTMLInputElement>) => void;
};

export const SearchInput: FC<IProps> = ({ searchText, handleSearch, label, size }) => {
    const submitSearch = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
    };

    return (
        <Box component="form" sx={{ flex: 1 }} onSubmit={submitSearch}>
            <OutlinedInput
                value={searchText}
                fullWidth
                name="orderNumber"
                onChange={handleSearch}
                placeholder={label}
                startAdornment={(
                    <InputAdornment position="start">
                        <SvgIcon>
                            <SearchIcon />
                        </SvgIcon>
                    </InputAdornment>
                )}
                size={size ? "small" : "medium"}
            />
        </Box>
    )
};