import { FC, useMemo } from "react";
import { Modal } from "../../../../ui-kit/modal/modal";
import { useUserForm } from "../presenter/useUserForm";
import { Button, DialogContent, DialogTitle, Stack, TextField, Typography, Box } from "@mui/material";
import { getStyles } from "./styles";

export const UserForm: FC = () => {
    const styles = useMemo(() => getStyles(), []);
    const { isShownModal, handleClose, onChangeName, onChangeCity, onChangeEmail, onChangePhone, name, city, email, phone, errors, handleForm, feedback, onChangefeedback, handleSuccessForm } = useUserForm();
    return (
        <Box sx={styles.background}>
            <Modal isOpen={isShownModal} handleClose={() => { }} size='md'>
                <Stack direction="row" justifyContent="center" mt={2}><img src="/images/logo/logo.svg" width={150} height={50} /></Stack>
                <DialogTitle variant="h5" sx={styles.title}>ПІДТРИМКА КОРИСТУВАЧІВ</DialogTitle>
                <DialogContent sx={{ maxWidth: '500px' }}>
                    <Typography variant="body2" px={6} textAlign='center' pt={.5}>
                        Якщо у вас виникли запитання/скарги/пропозиції, ви можете поставити їх нижче, заповнивши форму.
                    </Typography>
                    <Stack spacing={1} px={3} pt={3}>
                        <TextField variant="outlined" label="Ваше ім'я" value={name} onChange={onChangeName} error={!!errors.name} helperText={!!errors.name && errors.name} size="small" />
                        <TextField variant="outlined" label="Місто" value={city} onChange={onChangeCity} error={!!errors.city} helperText={!!errors.city && errors.city} size="small" />
                        <TextField variant="outlined" label="Email" value={email} onChange={onChangeEmail} error={!!errors.email} helperText={!!errors.email && errors.email} size="small" />
                        <TextField variant="outlined" label="Телефон" value={phone} onChange={onChangePhone} error={!!errors.phone} helperText={!!errors.phone && errors.phone} size="small" />
                        <TextField variant="outlined" label="Коментар" value={feedback} onChange={onChangefeedback} multiline rows={3} />
                    </Stack>
                    <Stack sx={styles.buttonContainer}>
                        <Button variant="contained" size="large" sx={styles.button} onClick={handleForm}>Надіслати</Button>
                    </Stack>
                    <Typography textAlign="center" variant="subtitle2" fontWeight={600} fontSize={14} mt={2}>Телефон гарячої лінії 0800 334 455</Typography>
                </DialogContent>
            </Modal>
            <Modal isOpen={!isShownModal} handleClose={() => { }} size='md'>
                <Stack direction="row" justifyContent="center" mt={2}><img src="/images/logo/logo.svg" width={150} height={50} /></Stack>
                <DialogTitle variant="h6" sx={{ textAlign: 'center', fontWeight: 600, py: 2 }}>
                    Дякуємо за ваше звернення,  найближчим часом ми надамо вам відповідь на ваше запитання та відправимо його на вказанний спосіб звязку з вами. Всього найкращого !
                </DialogTitle>
                <Stack direction='row' justifyContent='center' pb={2}>
                    <Button variant="contained" size="large" sx={styles.okButton} onClick={handleSuccessForm}>Ок</Button>
                </Stack>
            </Modal>
        </Box>
    )
};
