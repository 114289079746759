export const styles = {
    card: {
        pl: 4,
        pt: 3,
        pb: 2,
        pr: 2
    },
    timeHeader: { 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center', 
        px: 2 
    }
}