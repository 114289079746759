import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setUpdateAdvertError } from "../../repository/slice";
import { addImage } from "../addImage/action";
import { deleteFile } from "../../../file/useCases/deleteFile/action";

function* updateAdvertWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.put, "/api/admin/announcement/update", payload.body);
        if (isError) {
            yield put(setUpdateAdvertError(message));
        } else {
            if (payload.image?.lastModified) {
                yield put(addImage({ id: payload.body.id, image: payload.image }));
            };            
            if (payload.deletedFileName) {
                yield put(deleteFile({ fileName: payload.deletedFileName }));
            };
            yield put(setUpdateAdvertError(null));
        }
    } catch (error) {
        console.error("updateAdvertWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* updateAdvertWatcher() {
    yield takeLatest("@saga/UPDATE_ADVERT", updateAdvertWorker);
};
