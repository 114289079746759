import { SagaIterator } from "redux-saga";
import { put, call, takeEvery } from "redux-saga/effects";
import { setErrorsGetNotificationById, setPushNotification, setPushNotificationLoading } from "../../repository/actions";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";

function* getNotificationByIdWorker({ payload }: any): SagaIterator {
    try {
        put(setPushNotificationLoading(true));
        const { data, isError, message }: IResponse<any> = yield call( requester.get, "/api/admin/notification/" + payload.id );

        if (isError) {
            yield put(setErrorsGetNotificationById(message));
        } else {
            yield put(setErrorsGetNotificationById(null));
            yield put(setPushNotification(data));
        }
    } catch (error) {
        console.error("getNotificationByIdWorker: ", error);
    } finally {
        yield put(setPushNotificationLoading(false));
    }
}

export function* getNotificationByIdWatcher() {
    yield takeEvery("@saga/GET_NOTIFICATION_BY_ID", getNotificationByIdWorker);
}
