import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { selectAnalytics } from "../../../../store/analytics/repository/selector";
import { useDebounce } from "../../../../hooks/useDebounce";
import { getDocuments } from "../../../../store/document/useCases/getDocuments/action";
import { selectDocument } from "../../../../store/document/repository/selector";
import { getSingleAdvertData } from "../../../../store/analytics/useCases/getSingleAdvertData/action";
import { getAdverts } from "../../../../store/advert/useCases/getAdverts/action";
import { selectAdvert } from "../../../../store/advert/repository/selector";

export const useSingleAdvertAnalytics = () => {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [currentAdvert, setCurrentAdvert] = useState<any>(null);
    const { singleAdvertData } = useSelector(selectAnalytics);
    const { advertList } = useSelector(selectAdvert);

    useEffect(() => {
        dispatch(getAdverts(combineRequestBody('')));
    }, []);

    useEffect(() => {
        currentAdvert && dispatch(getSingleAdvertData({ id: currentAdvert.id }));
    }, [currentAdvert]);

    const handleChangeAdvert = useCallback((document: any) => {
        setCurrentAdvert(document);
    }, [setCurrentAdvert]);

    const changeAdvertText = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
        debouncedHandleDocumentSeacrh(event.target.value);
    };

    const onSearchAdvert = (value: string) => {
        if (value.length) {
            dispatch(getAdverts(combineRequestBody(value)));
        } else {
            dispatch(getAdverts(combineRequestBody('')));
        }
    };

    const { debouncedWrapper: debouncedHandleDocumentSeacrh } = useDebounce(onSearchAdvert, 1000);

    const combineRequestBody = (value: string) => {
        const body: any = { page: 1, perPage: 15 };
        if (value) body.theme = value;
        return body;
    };

    const data = [singleAdvertData?.likesAmount || null, singleAdvertData?.dislikesAmount || null, singleAdvertData?.commentsAmount || null];

    return { advertList, changeAdvertText, handleChangeAdvert, currentAdvert, data }
};