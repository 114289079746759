import { FC, useState } from "react";
import { Box, Card, CardContent, Stack, Theme, useMediaQuery } from "@mui/material";
import { getStyles } from "./styles";
import { useAuthorization } from "../presenters/useAuth";
import { PhoneField } from "./components/phoneField";
import { LogInButton } from "./components/logInButton";
import { PasswordField } from "./components/passwordField";
import { RecoverPassButton } from "./components/recoverPassButton";
import { keyframes } from '@emotion/react';
import { PizzaLoader } from "../../../../ui-kit/pizza-loader/pizza-loader";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import TextEditor from "../../../../ui-kit/textEditor/textEditor";
import { EditorState, convertFromRaw } from "draft-js";

const changeSize = keyframes({ '0%': { transform: 'scale(1)', }, '50%': { transform: 'scale(1.1)', }, '100%': { transform: 'scale(1)', } })

export const Login: FC = () => {
    const { t } = useTranslation();
    const styles = getStyles();
    const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
    const { phone, onChangePhone, password, onChangePassword, handleLogin, isPassShown, onShowPassword, passwordInputRef,
        phoneValid, passwordValid, handleEnter, phoneInputRef, isShowLoader, isHideError, signInError, isLoading } = useAuthorization();

    return (
        <>
            <Box sx={styles.background}>
                <Card elevation={16} sx={mdUp ? styles.containerMd : styles.container}>
                    <CardContent sx={styles.content}>
                        <Stack spacing={1}>
                            <Box sx={{ ...styles.logoContainer, animation: `${changeSize} 5s infinite` }}>
                                <img src="/images/logo/logo_auth.png" style={styles.logo} alt="logo" />
                            </Box>
                            <PhoneField
                                phoneInputRef={phoneInputRef}
                                phone={phone}
                                phoneValid={phoneValid}
                                isHideError={isHideError}
                                handleEnterPhone={handleEnter}
                                onChangePhone={onChangePhone}
                            />
                            <PasswordField
                                signInError={signInError}
                                title={t(tokens.auth.password)}
                                passwordInputRef={passwordInputRef}
                                password={password}
                                onChangePassword={onChangePassword}
                                passwordValid={passwordValid}
                                isHideError={isHideError}
                                isPassShown={isPassShown}
                                handleEnterPassword={handleEnter}
                                onShowPassword={onShowPassword}
                            />
                        </Stack >
                        <LogInButton handleLogin={handleLogin} isLoading={isLoading} />
                        <RecoverPassButton />
                    </CardContent>
                </Card>
            </Box>
            {isShowLoader ? <PizzaLoader /> : null}
        </>
    )
};