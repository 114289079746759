import { Card, Divider, Stack, Typography } from "@mui/material";
import dayjs from "dayjs";
import { ChangeEvent, FC, SyntheticEvent, useCallback, useMemo } from "react";
import { UserAutocomplete } from "../../../../../../ui-kit/userAutocomplete";
import { TextAutocomplete } from "../../../../../../ui-kit/textAutocomplete";
import { t } from "i18next";
import { tokens } from "../../../../../../locales/tokens";
import { IOption, collectUserOptionList } from "../../../../../../ui-kit/userAutocomplete/collectOptionList";
import { IQuestion } from "../../../../../../store/generalQuestion/repository/IQuestion";
import { IAutocompleteItem } from "../../../presenter/types";
import { IUser } from "../../../../../../store/user/repository/IUser";
import { getStyles } from "./styles";
import { AccordionText } from "../accordionText";
import { ExpandableText } from "../expandableText";

interface IProps {
    question: IQuestion;
    users: IUser[];
    statusOptions: IAutocompleteItem[];
    changeQuestionStatus: (status: IAutocompleteItem, questionId: number) => void;
    onUserChange: (value: any, questionId: number) => void;
    changeUserText: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const QuestionCard: FC<IProps> = ({ onUserChange, users, changeUserText, question, changeQuestionStatus, statusOptions }) => {
    const styles = useMemo(() => getStyles(), []);
    const formattedDate = dayjs(question.updatedAt).format('DD.MM.YYYY');
    const changeUser = useCallback((e: SyntheticEvent<Element, Event>, value: IOption) => onUserChange(value, question.id), [question.id]);
    const changeStatus = useCallback((value: any) => changeQuestionStatus(value, question.id), [question.id]);
    const user = question.responsible ? collectUserOptionList([question.responsible])[0] : null;
    const currentStatus = statusOptions.find((status: any) => status.value === question?.status);
    const status = currentStatus ? { ...currentStatus, label: currentStatus.name } : null;

    return (
        <Card sx={{ mt: 1, borderRadius: '20px' }} elevation={3}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" p={2}>
                <Stack>
                    <Typography fontSize="15px" noWrap>
                        {question?.user?.last_name + " " + question?.user?.first_name + " " + question?.user?.patronymic}
                    </Typography>
                    <Typography fontSize="15px" color="GrayText">{formattedDate}</Typography>
                </Stack>
                <Stack direction="row" spacing={1} alignItems="center" width="50%">
                    <Stack flex={1}>
                        <TextAutocomplete
                            title={t(tokens.feedback.chooseDecision)}
                            value={status}
                            handleChangeValue={changeStatus}
                            optionList={statusOptions}
                            isMultiline={false}
                            isDisabled={true}
                        />
                    </Stack>
                    <Stack flex={1}>
                        <UserAutocomplete
                            title={t(tokens.feedback.assignResponsible)}
                            value={user}
                            handleChangeValue={changeUser}
                            optionList={users as IUser[]}
                            isMultiline={false}
                            changeText={changeUserText}
                            isDisabled={true}
                        />
                    </Stack>
                </Stack>
            </Stack>
            <Divider variant="fullWidth" />
            <Stack mb={1}>
                <ExpandableText text={question.message} />
            </Stack>
        </Card>
    )
};