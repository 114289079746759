import { FC } from "react";
import { ProductChart } from "./components/productChart";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { useProductAnalytics } from "../presenter/useProductAnalytics";

export const ProductAnalytics: FC = () => {
    const { t } = useTranslation();
    const { products, dates, handleRangeChange, disabledDate } = useProductAnalytics();

    return (
        <Stack m={2}>
            <Typography variant="h4" fontWeight={600}>
                {t(tokens.analytics.analyticsBadge)}
            </Typography>
            <Stack justifyContent="center" alignItems="center">
                <ProductChart
                    products={products}
                    dates={dates}
                    handleRangeChange={handleRangeChange}
                    disabledDate={disabledDate}
                />
            </Stack>
        </Stack>
    )
};