export const getStyles = () => {
    const styles = {
        createBtn: {
            borderRadius: '10px',
            color: '#FFFFFF',
            fontWeight: 600,
            backgroundColor: '#F1594E',
            textTransform: 'none',
            '&:hover': {
                backgroundColor: '#d85046'
            }
        },
        progressWrapper: {
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center'
        }
    };
    return styles;
};