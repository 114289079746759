import { IconButton, Stack, SvgIcon, Tooltip, Typography } from "@mui/material";
import { ChangeEvent, FC, RefObject, useCallback, useMemo } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import { getStyles } from "./styles";
import { useTranslation } from "react-i18next";
import "./styles.css";
import { tokens } from "../../../../../../../locales/tokens";

interface IProps {
    fileInputRef?: RefObject<HTMLInputElement>;
    imageSrc: string;
    isValid: boolean;
    handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleClearImageClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

export const ImagePicker: FC<IProps> = ({ imageSrc, handleFileChange, isValid, handleClearImageClick, fileInputRef }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    return (
        <>
            <Typography color={isValid ? 'inherit' : 'error'}>{t(tokens.adverts.cover)}*</Typography>
            <Typography variant="subtitle2" color={isValid ? 'GrayText' : 'error'}>
                ({t(tokens.adverts.forKnowledgeBase)})
            </Typography>
            <Stack mt={1} position="relative" >
                <label htmlFor="fileInput" className="image">
                    <img
                        src={imageSrc || "/images/user/uploadImage.png"}
                        alt="uploadImage"
                        width={112}
                        height={112}
                        className="image"
                    />
                    {imageSrc ?
                        <IconButton sx={styles.clearImage} onClick={handleClearImageClick}>
                            <Tooltip title={t(tokens.common.edit)} placement="right">
                                <SvgIcon><ClearIcon /></SvgIcon>
                            </Tooltip>
                        </IconButton>
                        : null
                    }
                </label>
                <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                    ref={fileInputRef}
                />
            </Stack>
        </>
    )
};