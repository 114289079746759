export const getStyles = () => {
    const styles = {
        imageBox: {
            position:"relative", 
            textAlign: "center", 
            width: 65,
            height: 65
        }, 
        fileImage: { 
            borderRadius: '15px', 
            objectFit: 'cover',
            cursor: 'pointer'
        },
        fileToolTip: { 
            position: 'absolute', 
            right: '-10px', 
            top: '-5px', 
            backgroundColor: 'lightgray', 
            ':hover': { 
                backgroundColor: 'darkgray' 
            } 
        }    
    };
    return styles;
};
