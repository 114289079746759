export const getStyles = () => {
    const styles = {
        createBtn: { 
            borderRadius: '10px', 
            color: '#FFFFFF',
            fontWeight: 600,
            backgroundColor: '#F1594E',
            textTransform: 'none',
            '&:hover':{
                backgroundColor: '#d85046'
            } 
        },
        progress: {
            position: 'relative', 
            height: '300px'
        }
    };
    return styles;
};