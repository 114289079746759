import { FC } from "react"
import { useSettings } from "../../hooks/useSettings";
import { VerticalLayout } from "./vertical-layout";
import { HorizontalLayout } from "./horizontal-layout";
import { Outlet } from "react-router-dom";
import { useTranslateNavSections } from "../../hooks/useTranslateNavSections";

export const Layout: FC = () => {
    const settings = useSettings();
    const sections = useTranslateNavSections();

    if (settings.layout === 'vertical') {
        return (
            <VerticalLayout sections={sections} navColor={settings.navColor} >
                <Outlet />
            </VerticalLayout>
        )
    }

    return (
        <HorizontalLayout sections={sections} navColor={settings.navColor}>
            <Outlet />
        </HorizontalLayout>
    )
};