import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading, setRecoverPasswordError } from "../../repository/slice";

function* recoverPasswordWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, isError, message }: IResponse<any> = yield call(requester.post, "/api/auth/forgot-password", payload);
        if (isError) {
            yield put(setRecoverPasswordError(message));
        } else {
            yield put(setRecoverPasswordError(null));
        }
    } catch (error) {
        console.error("recoverPasswordWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* recoverPasswordWatcher() {
    yield takeLatest("@saga/RECOVER_PASSWORD", recoverPasswordWorker);
};