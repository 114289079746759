import { FC } from "react";
import { useLeaderBoardAnalytics } from "../presenter/useLeaderBoardAnalytics";
import { LeaderBoardChart } from "./components/leaderBoardChart";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";

export const LeaderBoardAnalytics: FC = () => {
    const { t } = useTranslation();
    const { departmentList, changeDepartment, currentDepartment, leaderBoardData } = useLeaderBoardAnalytics();

    return (
        <Stack m={2}>
            <Typography variant="h4" fontWeight={600}>
                Таблиця результатів
            </Typography>
            <Stack justifyContent="center" alignItems="center">
                <LeaderBoardChart
                    usersData={leaderBoardData?.rows}
                    currentDepartment={currentDepartment}
                    departmentList={departmentList}
                    changeDepartment={changeDepartment}
                />
            </Stack>
        </Stack>
    )
};