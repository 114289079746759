import { Stack, Tooltip, Typography } from "@mui/material";
import { ChangeEvent, FC, RefObject } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../../locales/tokens";

interface IProps {
    fileInputRef?: RefObject<HTMLInputElement>;
    isDisabled?: boolean;
    type: string;
    handleFileChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const FileInput: FC<IProps> = ({ type, handleFileChange, isDisabled, fileInputRef }) => {
    const { t } = useTranslation();

    return (
        <Stack>
            <label htmlFor="fileInput">
                <Tooltip title={t(tokens.documents.addFile)} placement="bottom" arrow followCursor>
                    <img
                        src="/images/user/uploadImage.png"
                        alt="uploadImage"
                        width={112}
                        height={112}
                        style={{ cursor: 'pointer' }}
                    />
                </Tooltip>
            </label>
            <input
                type="file"
                id="fileInput"
                accept={
                    type === 'Video' ? 
                    "video/*" : "*/"
                }
                multiple
                style={{ display: 'none' }}
                onChange={handleFileChange}
                disabled={isDisabled}
                ref={fileInputRef}
            />
        </Stack>
    )
};