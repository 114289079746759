import { Stack } from "@mui/material"
import { FC } from "react"
import Chart from 'react-apexcharts'
import { ChartSeries } from "../../../presenter/useGeneralChart";
import { ChartDescItem } from "../chartDescItem";
import { useKnowledgeChart } from "../../../../knowledge/presenter/useKnowledgeChart";

interface IProps {
    chartSeries: ChartSeries;
    labels: string[];
};

export const ChartBody: FC<IProps> = ({ chartSeries, labels }) => {
    const chartOptions = useKnowledgeChart(labels);

    return (
        <Stack direction="row" alignItems="center">
            <Chart height={200} options={chartOptions} series={chartSeries} type="donut" />
            <Stack spacing={2} flex={1} pr={2}>
                {chartSeries.map((item, index) => (
                    <ChartDescItem
                        key={index}
                        item={item}
                        label={labels[index]}
                        color={chartOptions.colors![index]}
                    />
                ))}
            </Stack>
        </Stack>
    )
}