import { Autocomplete, Paper, TextField, Typography } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { IOption, collectOptionList } from "../../utils/collectOptionList";

interface IProps {
    size?: string;
    isDisabled?: boolean;
    title: string;
    value: any | null;
    handleChangeValue: any;
    optionList: any[];
    isMultiline: boolean;
    changeText?: (event: ChangeEvent<HTMLInputElement>) => void;
};

export const TextAutocomplete: FC<IProps> = ({ value, handleChangeValue, optionList, title, changeText, isMultiline, isDisabled, size }) => {
    
    return (
        <Autocomplete
            disablePortal
            multiple={isMultiline}
            fullWidth
            onChange={(e, value) => handleChangeValue(value) as IOption[]}
            value={value}
            options={collectOptionList(optionList)}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(params, option): JSX.Element => (
                <Typography {...params} key={option.id} p={1}>
                    {option.name}
                </Typography>
            )}
            renderInput={(params): JSX.Element => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={title}
                    onChange={changeText}
                />
            )}
            disabled={isDisabled}
            size={ size ? "small" : "medium"} 
        />
    )
};
