import { Clear as ClearIcon } from '@mui/icons-material';
import { Box, FormControl, IconButton, Stack, SvgIcon, Tooltip } from '@mui/material';
import React, { forwardRef, useEffect, useState } from 'react';
import { getStyles } from './styles';

export const ImageUpload = forwardRef<HTMLInputElement, any>(({ setValue, clearErrors, image, isDisabled, isEdit }: any, ref) => {
    const { clearImage } = getStyles();
    const [file, setFile] = useState<any>(undefined);

    useEffect(() => {
        image ? setFile(image) : setFile(undefined);
    }, [image]);

    const handleChangeFile = (e: any) => {
        const file = e.target.files && e.target.files[0];
        setFile(file);
        setValue('file', file);
        clearErrors('file');
    };

    const handleClearImage = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setFile(undefined);
        setValue('file', undefined);
    };

    return (
        <Stack mt={1} position="relative" width='fit-content'>
            <label className="image">
                {file ?
                    (!isDisabled && !isEdit)
                    && <IconButton sx={clearImage} onClick={handleClearImage}>
                        <Tooltip title="Видалити" placement="right">
                            <SvgIcon><ClearIcon /></SvgIcon>
                        </Tooltip>
                    </IconButton>
                    : null}
            </label>
            <FormControl component={"label"} htmlFor="file" sx={{}}>
                <Box
                    component={"img"}
                    src={file ? URL.createObjectURL(file) : "/images/user/uploadImage.png"}
                    alt="uploadImage"
                    width={112}
                    height={112}
                    className="image" />
                <Box
                    ref={ref}
                    disabled={isDisabled}
                    component={"input"}
                    type="file"
                    id="file"
                    name='file'
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleChangeFile} />
            </FormControl>
        </Stack>
    );
});
