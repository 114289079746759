export const getStyles = () => {
    const styles = {
        phone: {
            width: '100%',
            height: '80px',
        },
        borderError: {
            border: '1px solid red',
        },
        borderMask: {
            border: '1px solid #C5C5C5',
        },
        error: {
            pl: '10px',
            mt: '2px'
        }
    };

    return styles;
};