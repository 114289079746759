export const getStyles = () => {
    const styles = {
        btnSection: {
            alignItems: 'center',
            borderRadius: 1,
            display: 'flex',
            justifyContent: 'flex-start',
            pr: '16px',
            py: '6px',
            textAlign: 'left',
            width: '100%',
            '&:hover': {
                backgroundColor: 'secondary.main'
            },
            position: 'relative'
        },
        sectionIcon: {
            alignItems: 'center',
            color: 'var(--nav-item-icon-color)',
            display: 'inline-flex',
            justifyContent: 'center',
            mr: 2,
        },
        sectionTitle: {
            color: 'var(--nav-item-color)',
            flexGrow: 1,
            lineHeight: '24px',
            whiteSpace: 'nowrap',
        },
        arrows: { 
            color: 'var(--nav-item-chevron-color)', 
            fontSize: 16, 
            ml: 2 
        }
    }
    return styles;
}