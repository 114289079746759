import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';

interface IState {
    isLoading: boolean;
    advertList: any[];
    singleAdvert: any;
    advertsCount: number;
    deleteAdvertError: IError;
    updateAdvertError: IError;
    uploadAdvertReportError: IError;
    createAdvertError: IError;
};

const initialState: IState = {
    isLoading: false,
    advertList: [],
    singleAdvert: null,
    advertsCount: 0,
    deleteAdvertError: null,
    updateAdvertError: null,
    uploadAdvertReportError: null,
    createAdvertError: null,
};

const AdvertSlice = createSlice({
    name: 'advert',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setAdverts: (state, action: PayloadAction<any>) => {
            state.advertList = action.payload.adverts;
            state.advertsCount = action.payload.count;
        },
        setSingleAdvert: (state, action: PayloadAction<any>) => {
            state.singleAdvert = action.payload;
        },
        setDeleteAdvertError: (state, action: PayloadAction<IError>) => {
            state.deleteAdvertError = action.payload;
        },
        setUpdateAdvertError: (state, action: PayloadAction<IError>) => {
            state.updateAdvertError = action.payload;
        },
        setCreateAdvertError: (state, action: PayloadAction<IError>) => {
            state.createAdvertError = action.payload;
        },
        setUploadAdvertReportError: (state, action: PayloadAction<IError>) => {
            state.uploadAdvertReportError = action.payload;
        }
    },
});

export const { setLoading, setAdverts, setDeleteAdvertError, setUpdateAdvertError, setUploadAdvertReportError, setCreateAdvertError,
    setSingleAdvert } = AdvertSlice.actions;
export const AdvertReducer = AdvertSlice.reducer;