import { ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { useDebounce } from "../../../../hooks/useDebounce";
import { IUserListFilters } from "../../../../types/user";
import { getOrgStructure } from "../../../../store/orgStructure/useCases/getOrgStructure/action";
import { useDispatch, useSelector } from "react-redux";
import { selectOrgStructure } from "../../../../store/orgStructure/repository/selector";
import { selectUser } from "../../../../store/user/repository/selector";
import { getUsers } from "../../../../store/user/useCases/getUsers/action";
import { setUsers } from "../../../../store/user/repository/slice";
import { updateOrgStructure } from "../../../../store/orgStructure/useCases/updateOrgStructure/action";
import { updateUser } from "../../../../store/user/useCases/updateUser/action";
import toast from "react-hot-toast";
import { setOrgStructure } from "../../../../store/orgStructure/repository/slice";

export const useUserList = () => {
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const searchTextRef = useRef<HTMLInputElement | null>(null);
    const [filters, setFilters] = useState<IUserListFilters>({ page: 1, perPage: 10, searchText: '', sortBy: 'All' });
    const { orgStructure, getOrgStructureError, isLoadingStructure } = useSelector(selectOrgStructure);
    const { userList, updateUserError, isLoading, getUsersError } = useSelector(selectUser);
    const [editClick, setEditClick] = useState(false);
    const [isProccess, setProcess] = useState(false);

    useEffect(() => {
        dispatch(updateOrgStructure());
        dispatch(setUsers({users: [], count: 0}));
        setProcess(true);
        return () => {
            dispatch(setUsers({users: [], count: 0}));
            dispatch(setOrgStructure([]));
        }
    }, []);

    useEffect(() => {
        if (!isLoading && editClick) {
            if (updateUserError) {
                toast.error(updateUserError);
            } else {
                if (search && search?.length > 1) {
                    dispatch(setOrgStructure([]));
                    dispatch(getUsers({ name: search, ...getRequestBody(filters.sortBy) }));
                } else {
                    dispatch(setUsers({users: [], count: 0}));
                    dispatch(getOrgStructure(getRequestBody(filters.sortBy)));
                }
            }
            setEditClick(false);
        }
    }, [updateUserError, isLoading]);

    useEffect(() => {
        if (!isLoading && isProccess) {
            if (getUsersError) {
                toast.error(getUsersError)
            };
            setTimeout(() => setProcess(false), 50);
        }
    }, [getUsersError, isLoading]);

    useEffect(() => {
        if (!isLoadingStructure && isProccess) {
            if (getOrgStructureError) {
                toast.error(getOrgStructureError)
            };
            setTimeout(() => setProcess(false), 300);
        }
    }, [getOrgStructureError, isLoadingStructure]);

    const getRequestBody = (sortBy: string) => {
        return sortBy === 'Intern' ? { isIntern: true } : {};
    };

    const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, sortBy: string) => {
        setSearch(e.target.value);
        debouncedHandleUserSeacrh(e.target.value, sortBy);
    }, []);

    const handleSearchTextChange = useCallback((event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
    }, []);

    const onSearch = (value: any, sortBy: string) => {
        const query = value ? value : '';
        // setProcess(true);
        if (query && query?.length > 1) {
            dispatch(setOrgStructure([]));
            dispatch(getUsers({ name: query, ...getRequestBody(sortBy) }))
        } else {
            dispatch(setUsers({users: [], count: 0}));
            dispatch(getOrgStructure(getRequestBody(sortBy)));
        }
    };

    const { debouncedWrapper: debouncedHandleUserSeacrh } = useDebounce(onSearch, 1000);

    const handleStatus = (id: number, isBanned: boolean) => {
        dispatch(updateUser({ id, isBanned: !isBanned }));
        setEditClick(true);
    };

    const handleSortBy = (sortBy: string): void => {
        setFilters((prevState) => ({ ...prevState, sortBy }));
        setProcess(true);
        if (search.length > 0) {
            dispatch(setOrgStructure([]));
            dispatch(getUsers({ name: search, ...getRequestBody(sortBy) }))
        } else {
            dispatch(setUsers({users: [], count: 0}));
            dispatch(getOrgStructure({ ...getRequestBody(sortBy) }));
        }
    };

    return { searchTextRef, handleChangeSearch, handleSearchTextChange, orgStructure, handleStatus, handleSortBy, filters, userList, isProccess }
};