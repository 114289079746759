import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../locales/tokens";
import { useDispatch, useSelector } from "react-redux";
import { createQuestion } from "../../../../../store/test/useCases/createQuestion/action";
import { updateQuestion } from "../../../../../store/test/useCases/updateQuestion/action";
import { FILE_SIZE, TEST_TYPES } from "../../../../../config";
import { selectQuiz } from "../../../../../store/quiz/repository/selector";
import { addQuizQuestionImage } from "../../../../../store/quiz/useCases/questionAddImage/action";
import { deleteFileFromQuizQuestion } from "../../../../../store/quiz/useCases/deleteFileFromQuestion/action";
import { createQuizQuestion } from "../../../../../store/quiz/useCases/createQuestion/action";
import { updateQuizQuestion } from "../../../../../store/quiz/useCases/updateQuestion/action";
import { IError } from "../../../../../types/error";

export const useQuestionCreate = (onClose: () => void, currentQuestion: any, quizId: any) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [type, setType] = useState<any>(null);
    const [typeValid, setTypeValid] = useState<any>(true);
    const [title, setTitle] = useState('');
    const [titleValid, setTitleValid] = useState<any>(true);
    const [imageSrc, setImageSrc] = useState('');
    const [image, setImage] = useState<any>(null);
    const [replies, setReplies] = useState<any[]>([]);
    const [repliesValid, setRepliesValid] = useState<any[]>([]);
    const { questionList, questionId, isLoading, createQuestionError, updateQuestionError } = useSelector(selectQuiz);
    const [createQuestionClick, setCreateQuestionClick] = useState(false);
    const [editQuestionClick, setEditQuestionClick] = useState(false);
    const [removedRepliesIds, setRemovedRepliesIds] = useState<any[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    
    const typeOptions = [
        { id: 1, name: t(tokens.test.typeSingle), value: "Single" },
        { id: 2, name: t(tokens.test.typeMultiple), value: "Multiple" },
        { id: 4, name: t(tokens.test.typeFree), value: "Free" },
        { id: 5, name: t(tokens.test.typeScale), value: "Scale" },
    ];

    useEffect(() => {
        if (currentQuestion) {
            setType(currentQuestion.type);
            setTitle(currentQuestion.title);
            if (currentQuestion.replies.length > 0) {
                handleScaleTypeForInit(currentQuestion.type.value);
            };
            if (currentQuestion.image) {
                setImage(currentQuestion.image);
                setImageSrc(currentQuestion.image.url);
            };
        }
    }, [currentQuestion]);

    useEffect(() => {
        if (!isLoading && createQuestionClick) {
            if (createQuestionError) {
                handleServerError(createQuestionError)
            } else {
                toast.success(t(tokens.quiz.message.questionAdded));
                closeAndClearData();
            }
            setCreateQuestionClick(false);
        }
    }, [createQuestionError, isLoading]);

    useEffect(() => {
        if (!isLoading && editQuestionClick) {
            if (updateQuestionError) {
                handleServerError(updateQuestionError)
            } else {
                toast.success(t(tokens.quiz.message.questionUpdated));
                closeAndClearData();
            }
            setEditQuestionClick(false);
        }
    }, [updateQuestionError, isLoading]);

    const handleScaleTypeForInit = (type: string) => {
        if (type === TEST_TYPES.SCALE) {
            setReplies([currentQuestion.replies[0], currentQuestion.replies[currentQuestion.replies.length - 1]]);
            setRepliesValid([{ isValid: true, sortNumber: currentQuestion.replies[0].sortNumber }, { isValid: true, sortNumber: currentQuestion.replies[currentQuestion.replies.length - 1].sortNumber }])
        } else {
            setReplies(currentQuestion.replies);
            currentQuestion.replies.forEach((item: any) =>
                setRepliesValid(prevState => [...prevState, { isValid: true, sortNumber: item.sortNumber }]))
        }
    };

    const handleServerError = (error: IError) => {
        if (error?.startsWith("value too long for type character varying(255)")) {
            toast.error(t(tokens.common.tooMuchSymbols));
        } else {
            toast.error(error);
        }
    };

    const changeType = useCallback((type: any) => {
        setType(type);
        setTypeValid(true);
        if (type?.value === TEST_TYPES.FREE) {
            setReplies([]);
            setRepliesValid([]);
        } else if (type?.value === TEST_TYPES.SCALE) {
            setReplies([]);
            setRepliesValid([]);
            createNewReply();
            createNewReply();
        }
    }, [setType, replies]);

    const onChangeTitle = (event: ChangeEvent<HTMLInputElement>) => {
        setTitle(event.target.value);
        setTitleValid(true);
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            if (questionId) {
                dispatch(addQuizQuestionImage({ body: { questionId: questionId, image: file }, quizId: Number(quizId) }));
            };
            const reader = new FileReader();
            reader.onload = (e) => {
                const newImageSrc = e.target?.result as string;
                setImageSrc(newImageSrc);
            };
            reader.readAsDataURL(file);
            setImage(file);
        };
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        };
    };

    const handleClearImageClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setImageSrc('');
        setImage(null);
        if (questionId && 'filename' in image) {
            dispatch(deleteFileFromQuizQuestion({ fileName: image.filename, quizId: Number(quizId), questionId }));
        };
    };

    const addReply = () => {
        createNewReply();
    };

    const createNewReply = () => {
        const sortNumber = findMaxSortNumber(replies);
        setReplies(prevState => [...prevState, { title: '', sortNumber: sortNumber }]);
        setRepliesValid(prevState => [...prevState, { isValid: true, sortNumber: sortNumber }]);
    };

    const removeReply = (sortNumber: number) => {
        const filteredReplies = replies.filter((reply: any) => reply.sortNumber !== sortNumber);
        const updatedReplies = filteredReplies.map((reply, index) => {
            return {
                ...reply,
                sortNumber: index + 1
            };
        });
        setTimeout(() => setReplies(updatedReplies), 300);
        const removedReply: any = replies.find((reply: any) => 'id' in reply && reply.sortNumber === sortNumber);
        removedReply && setRemovedRepliesIds((prevState: any[]) => ([...prevState, removedReply.id]));

        const filteredRepliesValid = repliesValid.filter((reply: any) => reply.sortNumber !== sortNumber);
        const updatedRepliesValid = filteredRepliesValid.map((reply, index) => {
            return {
                ...reply,
                sortNumber: index + 1
            };
        });
        setTimeout(() => setRepliesValid(updatedRepliesValid), 300);
    };

    function findMaxSortNumber(replies: any[]) {
        if (replies.length === 0) {
            return 1;
        } else {
            let max = replies[0].sortNumber;
            for (let i = 1; i < replies.length; i++) {
                if (replies[i].sortNumber > max) {
                    max = replies[i].sortNumber;
                }
            }
            return max + 1;
        }
    };

    const onChangeReply = (event: ChangeEvent<HTMLInputElement>, index: number) => {
        if (type.value === TEST_TYPES.SCALE) {
            const regex = /^(?:10|[1-9])?$/;
            if (!regex.test(event.target.value)) {
                return;
            }
        };
        const updatedReplies = [...replies];
        const currentReply = { ...updatedReplies[index] };
        currentReply.title = event.target.value;
        updatedReplies[index] = currentReply;
        setReplies(updatedReplies);

        setRepliesValid(prevState => {
            const newState = [...prevState];
            newState[index] = {
                ...newState[index],
                isValid: true
            };
            return newState;
        });
    };

    const closeAndClearData = () => {
        onClose();
        setTitle('');
        setType(null);
        setReplies([]);
        setRepliesValid([]);
        setImage(null);
        setImageSrc('');
    };

    const validateData = () => {
        const data = [
            { field: type, setField: setTypeValid },
            { field: title, setField: setTitleValid },
        ];
        let allFieldsValid = true;
        for (const { field, setField } of data) {
            if (!field) {
                setField(false);
                allFieldsValid = false;
            }
        };

        if (replies?.length) {
            replies.forEach((reply: any, index: number) => {
                if (reply.title === '') {
                    setRepliesValid(prevState => {
                        const newState = [...prevState];
                        newState[index] = { ...newState[index], isValid: false };
                        return newState;
                    });
                    allFieldsValid = false;
                };
            })
        };

        return allFieldsValid;
    };

    const getRequestBody = () => {
        const body: any = { type: type.value, title };
        body.answers = getAnswers(replies);
        body.sortNumber = currentQuestion ? currentQuestion.sortNumber : questionList.length + 1;
        if (questionId) {
            body.id = questionId;
        } else {
            body.questionnaireId = Number(quizId);
            body.image = image ? image : null;
        }
        return body;
    };

    const getAnswers = (replies: any[]) => {
        if (type.value === TEST_TYPES.FREE) {
            return [{ title: TEST_TYPES.FREE, sortNumber: 1 }];
        } else if (type.value === TEST_TYPES.SCALE) {
            const newReplies = getScaleAnswers(replies);
            return newReplies;
        } else {
            return replies;
        }
    };

    const getScaleAnswers = (replies: any[]) => {
        const start = parseInt(replies[0].title, 10);
        const end = parseInt(replies[1].title, 10);

        const newAnswers = [];
        for (let i = start; i <= end; i++) {
            newAnswers.push({
                title: i.toString(),
                sortNumber: i - start + 1
            });
        };
        return newAnswers
    };

    const createOrEditQuestion = () => {
        const isPassedValidation = validateData();
        if (isPassedValidation) {
            handleQuestion();
        } else {
            toast.error(t(tokens.adverts.fillAllFields));
        };
    };

    const handleQuestion = () => {
        if (questionId) {
            validateRepliesAndEdit();
        } else {
            validateRepliesAndCreate();
        }
    };


    const validateRepliesAndCreate = () => {
        const isEnoughtReplies = (type.value === TEST_TYPES.MULTIPLE && replies.length > 1) || type.value !== TEST_TYPES.MULTIPLE;
        if (!isEnoughtReplies) {
            toast.error(t(tokens.test.needTwoQuestions));
            return;
        };
        createNewQuestion();
    };

    const createNewQuestion = () => {
        dispatch(createQuizQuestion({ body: getRequestBody(), quizId }));
        setCreateQuestionClick(true);
    };

    const validateRepliesAndEdit = () => {
        const isEnoughtReplies = (type.value === TEST_TYPES.MULTIPLE && replies.length > 1) || type.value !== TEST_TYPES.MULTIPLE;
        if (!isEnoughtReplies) {
            toast.error(t(tokens.test.needTwoQuestions));
            return;
        };
        editQuestion();
    };

    const editQuestion = () => {
        const newReplies = replies?.filter((answer: any) => !answer.id);
        const editedReplies = replies?.filter((answer: any) => answer.id);
        dispatch(updateQuizQuestion({ body: getRequestBody(), newReplies, editedReplies, removedRepliesIds, quizId }));
        setRemovedRepliesIds([]);
        setEditQuestionClick(true);
    };

    return {
        replies, typeOptions, createOrEditQuestion, type, typeValid, changeType, title, titleValid, onChangeTitle, addReply, removeReply, createQuestionClick, fileInputRef,
        handleFileChange, imageSrc, handleClearImageClick, onChangeReply, setReplies, closeAndClearData, questionId, repliesValid, setRepliesValid, editQuestionClick
    };
};