import { useMemo, type FC } from 'react';
import { Button, Card, CardContent, Stack, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../../../locales/tokens';
import { TextAutocomplete } from '../../../../../../ui-kit/textAutocomplete';
import { LeaderboardTable } from '../leaderboardTable';
import { ChartHeader } from '../../../../general/ui/components/chartHeader';
import { useNavigate } from 'react-router-dom';
import EastIcon from '@mui/icons-material/East';
import { paths } from '../../../../../../paths';
import InfoIcon from '@mui/icons-material/Info';
import { getStyles } from './styles';

interface IProps {
  usersData: any[];
  currentDepartment: any;
  departmentList: any[];
  changeDepartment: (department: any) => void;
}

export const LeaderBoardChart: FC<IProps> = ({ usersData, departmentList, changeDepartment, currentDepartment }) => {
  const { t } = useTranslation();
  const styles = useMemo(() => getStyles(), []);
  const navigate = useNavigate();
  
  return (
    <Stack width="50%" m={3} spacing={1}>
      <Stack direction="row" justifyContent="flex-end">
        <TextAutocomplete
          optionList={departmentList}
          value={currentDepartment}
          handleChangeValue={changeDepartment}
          title='Департамент'
          isMultiline={false}
          size='small'
        />
      </Stack>
      <Card elevation={2}>
        <ChartHeader title="Таблиця результатів" subTitle="" icon={<InfoIcon />} />
        <CardContent>
          <LeaderboardTable
            usersData={usersData}
          />
          <Button
            endIcon={<SvgIcon color="inherit" ><EastIcon /></SvgIcon>}
            variant='text'
            color='inherit'
            sx={styles.allProducts}
            // onClick={() => navigate(paths.dashboard.shop.index)}
          >
            <Typography variant='body2' fontWeight={600}>Всі користувачі</Typography>
          </Button>
        </CardContent>
      </Card>
    </Stack>

  );
};