import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setAdverts, setLoading } from "../../repository/slice";

function* getAdvertsWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.get, "/api/admin/announcement/get-list", payload);
        if (isError) {

        } else {
            yield put(setAdverts({adverts: data.rows, count: data.count}));
        }
    } catch (error) {
        console.error("getAdvertsWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* getAdvertsWatcher() {
    yield takeLatest("@saga/GET_ADVERTS", getAdvertsWorker);
};
