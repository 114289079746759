import { CircularProgress, Stack, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useOrdersList } from "../presenters/useOrdersList";
import { tokens } from "../../../../locales/tokens";
import { getStyles } from "./styles";
import { TabSelector } from "../../../../ui-kit/tabSelector/tabSelector";
import { OrdersTable } from "./components/ordersTable";
import { SearchInput } from "../../../../ui-kit/searchInput/searchInput";
import { EmptyPage } from "../../../content/category/categoryEmpty/ui";

export const OrdersList: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { activeTab, isOrderLoading, isRequestList, orderList, page, pageAmount, search, onSearch, onChangePage, onToggleTab, onProcessedOrder, scrollRef } = useOrdersList();

    const tabOptions = [
        { label: t(tokens.order.title.new), value: "NEW" },
        { label: t(tokens.order.title.processed), value: "PROCESSED" },
    ];

    return (
        <Stack m={2}>
            <Stack direction='row' justifyContent='space-between'>
                <Typography variant="h5" fontWeight={600}>{t(tokens.order.title.ordersHistory)}</Typography>
            </Stack>
            <Stack mt={3}>
                <SearchInput
                    searchText={search}
                    label={t(tokens.order.title.searchOrder)}
                    handleSearch={onSearch}
                    size="small"
                />
            </Stack>
            <TabSelector tab={activeTab} tabOptions={tabOptions} handleTabsChange={onToggleTab} />
            {orderList?.length > 0 &&
                <OrdersTable
                    scrollRef={scrollRef}
                    orders={orderList}
                    page={page}
                    pageAmount={pageAmount}
                    handlePageChange={onChangePage}
                    onProcessedOrder={onProcessedOrder}
                />
            }
            {(!orderList?.length && isOrderLoading) &&
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={styles.progressWrapper}
                >
                    <CircularProgress color="primary" />
                </Stack>
            }
            {(!orderList?.length && !isOrderLoading) &&
                <Stack pt={2}>
                    <EmptyPage
                        title={t(tokens.order.emptyListTitle)}
                        description={t(tokens.order.emptyListDescription)}
                    />
                </Stack>
            }
        </Stack>
    )
};