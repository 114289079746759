import { ChangeEvent, ReactNode, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateOrgStructure } from "../../../../../store/orgStructure/useCases/updateOrgStructure/action";
import { selectOrgStructure } from "../../../../../store/orgStructure/repository/selector";
import { paths } from "../../../../../paths";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { getBadges } from "../../../../../store/badge/useCases/getBadges/action";
import { selectBadge } from "../../../../../store/badge/repository/selector";
import toast from "react-hot-toast";
import { tokens } from "../../../../../locales/tokens";
import { createTest } from "../../../../../store/test/useCases/createTest/action";
import { selectTest } from "../../../../../store/test/repository/selector";
import { setQuestionId, setQuestionList, setSingleTest, setTestId } from "../../../../../store/test/repository/slice";
import { DropResult } from "react-beautiful-dnd";
import { getSingleTest } from "../../../../../store/test/useCases/getSingleTest/action";
import { deleteQuestion } from "../../../../../store/test/useCases/deleteQuestion/action";
import { updateQuestion } from "../../../../../store/test/useCases/updateQuestion/action";
import { deleteTest } from "../../../../../store/test/useCases/deleteTest/action";
import { updateTest } from "../../../../../store/test/useCases/updateTest/action";
import { IError } from "../../../../../types/error";
import { SelectChangeEvent } from "@mui/material";
import { getPositions } from "../../../../../store/user/useCases/getPositions/action";
import { selectUser } from "../../../../../store/user/repository/selector";

export const useTestCreate = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [theme, setTheme] = useState('');
    const [themeValid, setThemeValid] = useState(true);
    const [minPoints, setMinPoints] = useState('');
    const [minPointsValid, setMinPointsValid] = useState(true);
    const [attemptAmount, setAttemptAmount] = useState('');
    const [attemptAmountValid, setAttemptAmountValid] = useState(true);
    const [points, setPoints] = useState('');
    const [duration, setDuration] = useState('');
    const { orgStructure } = useSelector(selectOrgStructure);
    const [groupPermissions, setGroupPermissions] = useState<any[]>([]);
    const [groupPermissionsValid, setGroupPermissionsValid] = useState(true);
    const [receiver, setReceiver] = useState<any[]>([]);
    const [initiator, setInitiator] = useState<any>(null);
    const [initiatorValid, setInitiatorValid] = useState(true);
    const [publishChecked, setPublishChecked] = useState(false);
    const [notifyChecked, setNotifyChecked] = useState(false);
    const [createClick, setCreateClick] = useState(false);
    const [deleteClick, setDeleteClick] = useState(false);
    const [editClick, setEditClick] = useState(false);
    const [deadline, setDeadline] = useState<any>(null);
    const [deadlineValid, setDeadlineValid] = useState<any>(true);
    const [content, setContent] = useState('');
    const [contentValid, setContentValid] = useState(true);
    const [badge, setBadge] = useState<any>(null);
    const [badgeSeacrh, setBadgeSearch] = useState('');
    const [isQuestionModalOpen, setQuestionModalOpen] = useState(false);
    const { badgesList } = useSelector(selectBadge);
    const { questionList, singleTest, createTestError, deleteTestError, updateTestError, isLoading, testId, questionId } = useSelector(selectTest);
    const { positionsList } = useSelector(selectUser);
    const [currentQuestion, setCurrentQuestion] = useState<any>(null);
    const typeOptions = [
        { id: 1, name: t(tokens.test.typeSingle), value: "Single" },
        { id: 2, name: t(tokens.test.typeMultiple), value: "Multiple" },
        { id: 3, name: t(tokens.test.typeOrder), value: "Order" },
        { id: 4, name: t(tokens.test.typeFree), value: "Free" },
    ];
    const [departmentPermissions, setDepartmentPermissions] = useState<any[]>([]);
    const [positions, setPositions] = useState<any[]>([]);
    const [assignDepartment, setAssignDepartment] = useState<any[]>([]);
    const [assignPositions, setAssignPositions] = useState<any[]>([]);

    useEffect(() => {
        dispatch(updateOrgStructure());
        dispatch(getBadges({ type: 'ACTIVE' }));
        dispatch(getPositions());
        return () => {
            dispatch(setSingleTest(null));
            dispatch(setQuestionList([]));
            dispatch(setTestId(0));
        };
    }, []);

    useEffect(() => {
        if (!isLoading && createClick) {
            if (createTestError) {
                handleServerError(createTestError);
            } else {
                toast.success(t(tokens.test.message.testCreated));
                navigate(paths.dashboard.content.test.edit.replace(':id', String(testId)));
            }
            setCreateClick(false);
        }
    }, [createTestError, isLoading]);

    const handleServerError = (error: IError) => {
        if (error?.startsWith("value too long for type character varying(256)")) {
            toast.error(t(tokens.common.tooMuchSymbols));
            setThemeValid(false);
        } else if (error?.startsWith("value too long for type character varying(512)")) {
            toast.error(t(tokens.common.tooMuchSymbols));
            setContentValid(false);
        } else {
            toast.error(error);
        }
    };

    useEffect(() => {
        if (!isLoading && editClick) {
            if (updateTestError) {
                handleServerError(updateTestError);
            } else {
                toast.success(t(tokens.test.message.testUpdated));
            }
            setEditClick(false);
        }
    }, [updateTestError, isLoading]);

    useEffect(() => {
        if (testId) {
            dispatch(getSingleTest({ id: testId }));
        }
    }, [testId]);

    useEffect(() => {
        if (singleTest) {
            setTheme(singleTest.name);
            singleTest.initiator && setInitiator(singleTest.initiator.id);
            setGroupPermissions(singleTest.access.map((item: any) => item.id));
            setReceiver(singleTest.assigns.map((item: any) => item.id));
            singleTest.description && setContent(singleTest.description);
            setDeadline(new Date(singleTest.endDate));
            setAttemptAmount(singleTest.attempts);
            setMinPoints(singleTest.percents);
            singleTest.points && setPoints(singleTest.points);
            setPublishChecked(singleTest.status === 'Available' ? true : false);
            setNotifyChecked(singleTest.isInformed ? true : false);
            const newQuestions = singleTest.questions.map((question: any) => {
                const questionReplies = question.answers.map((answer: any) => ({
                    id: answer.id,
                    questionId: answer.questionId,
                    sortNumber: answer.sortNumber,
                    isRight: answer.isRight,
                    title: answer.title,
                }));
                const currentType: any = typeOptions.find((type: any) => type.value === question.type);

                return {
                    quizId: question.quizId,
                    id: question.id,
                    sortNumber: question.sortNumber,
                    title: question.title,
                    type: { ...currentType, label: currentType.name },
                    questionPoints: String(question.points),
                    replies: questionReplies,
                    image: question.image
                }
            });
            dispatch(setQuestionList(newQuestions));
        }
    }, [singleTest]);

    useEffect(() => {
        if (questionList.length && questionId) {
            const current = questionList.find((question: any) => question.id === questionId);
            setCurrentQuestion(current);
        }
    }, [questionList]);

    const onChangeTheme = (event: ChangeEvent<HTMLInputElement>) => {
        setTheme(event.target.value);
        setThemeValid(true);
    };

    const onChangeAttemptAmount = (event: ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        const regex = /^(\d*)$/;

        if (regex.test(input)) {
            setAttemptAmount(input);
            setAttemptAmountValid(true);
        }
    };

    const onChangePoints = (event: ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        const regex = /^(\d*)$/;

        if (regex.test(input)) {
            setPoints(input);
        }
    };

    const onChangeDuration = (event: ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        const regex = /^(100|[1-9]?[0-9])?$/;

        if (regex.test(input)) {
            setDuration(input);
        }
    };

    const onChangeMinPoints = (event: ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        const regex = /^(100|\d{1,2})?$/;

        if (regex.test(input)) {
            setMinPoints(input);
            setMinPointsValid(true);
        }
    };

    const onChangePermissions = (newValue: string[]) => {
        setGroupPermissions(newValue);
        setGroupPermissionsValid(true);
    };

    const onChangeDepartmentPermissions = (newValue: string[]) => {
        setDepartmentPermissions(newValue);
    };

    const onChangeAssignDepartment = (newValue: string[]) => {
        setAssignDepartment(newValue);
    };

    const onChangeReceiver = (newValue: string[]) => {
        setReceiver(newValue);
    };

    const onSelectInitiator = (selectedKeysValue: string, node: any) => {
        if ('children' in node) {
            return;
        }
        setInitiator(node);
        setInitiatorValid(true);
    };

    const onChangeInitiator = () => {
        if (initiator) {
            setInitiator(null);
        }
    };

    const handlePublishChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPublishChecked(event.target.checked);
    };

    const handleNotifyChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNotifyChecked(event.target.checked);
    };

    const handleChangeDeadline = useCallback((date: Date | null): void => {
        const currentDate = date?.valueOf() || null;
        setDeadline(currentDate);
        setDeadlineValid(true);
    }, [setDeadline]);

    const onChangeContent = (event: ChangeEvent<HTMLInputElement>) => {
        setContent(event.target.value);
        setContentValid(true);
    };

    const handleChangeBadge = useCallback((badge: any) => {
        setBadge(badge);
    }, [setBadge]);

    const changeBadgeText = (event: ChangeEvent<HTMLInputElement>) => {
        setBadgeSearch(event.target.value);
        debouncedHandleBadgeSeacrh(badgeSeacrh);
    };

    const onSearchBadge = (value: string) => {
        if (value.length) {
            dispatch(getBadges({ search: value, type: 'ACTIVE' }));
        } else {
            dispatch(getBadges({ type: 'ACTIVE' }));
        }
    };

    const { debouncedWrapper: debouncedHandleBadgeSeacrh } = useDebounce(onSearchBadge, 1000);

    const handleOpenQuestionModal = () => {
        setQuestionModalOpen(true);
    };

    const handleCloseQuestionModal = () => {
        setQuestionModalOpen(false);
        setCurrentQuestion(null);
        dispatch(setQuestionId(0));
    };

    const removeQuestion = (sortNumber: number) => {
        const removedQuestion = questionList.find((question: any) => question.sortNumber === sortNumber);
        dispatch(deleteQuestion({ id: removedQuestion.id, testId: testId }));
    };

    const defineCurrentQuestion = (index: number, questionId: number) => {
        setCurrentQuestion(questionList[index]);
        setQuestionModalOpen(true);
        dispatch(setQuestionId(questionId));
    };

    const reorder = (questions: any[], startIndex: number, endIndex: number) => {
        let result = Array.from(questions);
        const currentQuestion = result[startIndex];
        const destinationQuestion = result[endIndex];
        dispatch(updateQuestion({ body: { id: currentQuestion.id, sortNumber: destinationQuestion.sortNumber } }));

        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        result = result.map((question: any, index: number) => ({
            ...question,
            sortNumber: index + 1
        }))
        return result;
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        };
        const items = reorder(questionList, result.source.index, result.destination.index);
        dispatch(setQuestionList(items));
    };

    const validateData = () => {
        const data = [
            { field: theme, setField: setThemeValid },
            { field: initiator, setField: setInitiatorValid },
            // { field: groupPermissions.length, setField: setGroupPermissionsValid },
            // { field: deadline, setField: setDeadlineValid },
            { field: attemptAmount, setField: setAttemptAmountValid },
            { field: minPoints, setField: setMinPointsValid }
        ];
        let allFieldsValid = true;
        for (const { field, setField } of data) {
            if (!field) {
                setField(false);
                allFieldsValid = false;
            }
        };

        return allFieldsValid;
    };

    const cleanedGuiIds = (guiIds: string[]): string[] => {
        return guiIds.map(permission => {
            const [cleaned] = permission.split('%');
            return cleaned;
        });
    };    

    const getRequestBody = () => {
        const body: any = {
            name: theme,
            initiatorId: initiator.value,
            percents: Number(minPoints),
            attempts: Number(attemptAmount),
            acceptedTry: "Best"
        };
        if(deadline) body.endDate = new Date(deadline).toISOString();
        if(!!duration) body.days_to_deadline = Number(duration);
        body.assigns = receiver.length ? receiver : null;
        body.access = groupPermissions.length ? groupPermissions : null;
        body.badgeId = badge ? badge.id : null;
        body.description = content;
        body.points = points ? Number(points) : null;
        body.isInformed = notifyChecked;
        body.status = publishChecked ? "Available" : "Hidden";
        if(departmentPermissions.length) body.accessDepartmentIds = cleanedGuiIds(departmentPermissions);
        if(positions.length) body.accessPositionIds = positions.map(item => item.id);
        if(assignDepartment.length) body.assignDepartmentIds = cleanedGuiIds(assignDepartment);
        if(assignPositions.length) body.assignPositionIds = assignPositions.map(item => item.id); 
        if (testId) {
            body.id = testId;
        }
        return body;
    };

    const handleTest = () => {
        const isPassedValidation = validateData();
        if (isPassedValidation) {
            createOrEditTest();
        } else {
            toast.error(t(tokens.adverts.fillAllFields))
        };
    };

    const createOrEditTest = () => {
        if (testId) {
            editTest();
        } else {
            dispatch(createTest({ body: getRequestBody() }));
            setCreateClick(true);
        }
    };

    const editTest = () => {
        if (publishChecked && questionList.length > 0) {
            dispatch(updateTest({ body: getRequestBody() }));
            setEditClick(true);
        } else if (!publishChecked) {
            dispatch(updateTest({ body: getRequestBody() }));
            setEditClick(true);
        } else {
            toast.error(t(tokens.test.message.needAddOneQuestion));
        }
    };

    const cancelCreate = () => {
        navigate(paths.dashboard.content.test.list);
    };

    const handleChangePosition = (event: any, newValue: readonly any[]) => {
        setPositions([...newValue]);
    };

    const handleChangeAssignPosition = (event: any, newValue: readonly any[]) => {
        setAssignPositions([...newValue]);
    };

    return {
        theme, themeValid, onChangeTheme, orgStructure, groupPermissions, groupPermissionsValid, onChangePermissions, receiver, onChangeReceiver, handleTest,
        initiator, initiatorValid, onChangeInitiator, onSelectInitiator, publishChecked, handlePublishChange, notifyChecked, handleNotifyChange, cancelCreate,
        createClick, deadline, handleChangeDeadline, deadlineValid, attemptAmount, attemptAmountValid, onChangeAttemptAmount, onChangePoints, content,
        points, onChangeMinPoints, minPoints, minPointsValid, onChangeContent, badge, handleChangeBadge, badgesList, changeBadgeText, handleOpenQuestionModal,
        isQuestionModalOpen, questionList, removeQuestion, onDragEnd, defineCurrentQuestion, currentQuestion, handleCloseQuestionModal, testId, contentValid,
        onChangeDepartmentPermissions, departmentPermissions, handleChangePosition, positions, positionsList, onChangeAssignDepartment, assignDepartment, 
        handleChangeAssignPosition, assignPositions, onChangeDuration, duration
    }
};