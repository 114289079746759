import { Stack } from "@mui/material"
import { FC } from "react"
import Chart from 'react-apexcharts'
import { ChartSeries, useUserChart } from "../../../presenter/useUserChart";

interface IProps {
    chartSeries: ChartSeries;
    questionList: string[];
};

export const ChartBody: FC<IProps> = ({ chartSeries, questionList }) => {
    const chartOptions = useUserChart(questionList);

    return (
        <Stack direction="row" alignItems="center">
            <Chart
                height={300}
                width={750}
                options={chartOptions}
                series={chartSeries}
                type="bar"
            />
        </Stack>
    )
};