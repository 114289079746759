import { Button, CircularProgress, Stack, SvgIcon, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useProductsList } from "../presenters/useProductsList";
import { tokens } from "../../../../locales/tokens";
import AddIcon from '@mui/icons-material/Add';
import { getStyles } from "./styles";
import { Link } from "react-router-dom";
import { paths } from "../../../../paths";
import { TabSelector } from "../../../../ui-kit/tabSelector/tabSelector";
import { ProductsTable } from "./components/productsTable";

export const ProductsList: FC = () => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const { activeTab, isRequestList, productList, page, pageAmount, isProductLoading, onChangePage, onToggleTab, onDeleteProduct, onChangeProductVisibility, scrollRef } = useProductsList();

    const tabOptions = [
        { label: t(tokens.common.activeTab), value: "ACTIVE" },
        { label: t(tokens.common.archiveTab), value: "ARCHIVE" },
    ];

    return (
        <Stack m={2}>
            <Stack direction='row' justifyContent='space-between'>
                <Typography variant="h5" fontWeight={600}>{t(tokens.shop.navBar.list)}</Typography>
                <Button
                    startIcon={(<SvgIcon><AddIcon /></SvgIcon>)}
                    variant="contained"
                    sx={styles.createBtn}
                    component={Link}
                    to={paths.dashboard.shop.create}
                >
                    {t(tokens.common.create)}
                </Button>
            </Stack>
            <Stack mt={1}><TabSelector tab={activeTab} tabOptions={tabOptions} handleTabsChange={onToggleTab} /></Stack>
            {isProductLoading && isRequestList
                ? <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    sx={styles.progressWrapper}
                >
                    <CircularProgress color="primary" />
                </Stack>
                : <ProductsTable
                    scrollRef={scrollRef}
                    products={productList}
                    page={page}
                    pageAmount={pageAmount}
                    handlePageChange={onChangePage}
                    onChangeVisibility={onChangeProductVisibility}
                    onDelete={onDeleteProduct}
                />
            }
        </Stack>
    )
};