import { FC, useMemo } from "react";
import type { DropzoneOptions, FileWithPath } from "react-dropzone";
import { useDropzone } from "react-dropzone";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ClearIcon from '@mui/icons-material/Clear';
import { Avatar, Box, Button, IconButton, List, ListItem, ListItemIcon, ListItemText, Stack, SvgIcon, Tooltip, Typography } from "@mui/material";
import { bytesToSize } from "../../utils/bytes-to-size";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { tokens } from "../../locales/tokens";
import { getStyles } from "./styles";
import { useDispatch } from "react-redux";

export type File = FileWithPath;

interface IProps extends DropzoneOptions {
  caption?: string;
  isEdit: boolean;
  files?: any;
  setFiles?: Function;
  disabled?: boolean;
  onRemove?: (name: string) => void;
  onRemoveAll?: () => void;
  onUpload?: () => void;
  updateFiles?: any;
};

export const FileDropzone: FC<IProps> = ({ caption, isEdit, files = [], setFiles, disabled, onRemove, onRemoveAll, onUpload, updateFiles, ...other }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone(other);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const styles = useMemo(() => getStyles(isDragActive), [isDragActive]);

  const reorder = (images: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(images);

    if (isEdit) {
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);
      const updatedList = result.map((image, index) => ({
        ...image,
        sortNumber: index + 1,
      }));
      
      dispatch(updateFiles({ id: removed.id, sortNumber: images[endIndex].sortNumber }));
      return updatedList;
    };

    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    };
    const items = reorder(files, result.source.index, result.destination.index);
    setFiles && setFiles(items);
  };

  const hasAnyFiles = files.length > 0;
  
  if (disabled) {
    return (
      <List >
        {files.map((file: any, index: number) => {
          const fileName = file?.name || file?.fileName;
          return (
            <ListItem key={file.path} sx={styles.item}>
              <ListItemIcon>
                <Box component="img" sx={styles.itemBox} alt="Big image" src={file?.url ? file?.url : URL.createObjectURL(file)} />
              </ListItemIcon>
              <ListItemText primary={file?.filename || fileName} primaryTypographyProps={{ variant: "subtitle2" }} secondary={file?.size && bytesToSize(file.size)} />
            </ListItem>
          );
        })}
      </List>
    )
  } else {
    return (
      <div>
        <Box sx={styles.container} {...getRootProps()}>
          <input {...getInputProps()} />
          <Stack alignItems="center" direction="row" spacing={2}>
            <Avatar sx={styles.avatar} >
              <SvgIcon fontSize="large"><UploadFileIcon /></SvgIcon>
            </Avatar>
            <Stack >
              <Typography sx={styles.description} variant="h6">
                <span>{t(tokens.documents.imagesDNDTitleP1)}</span> {t(tokens.documents.imagesDNDTitleP2)}
              </Typography>
              {/* {caption &&
                <Typography color="text.secondary" variant="body2">
                  {caption}
                </Typography>
              } */}
            </Stack>
          </Stack>
        </Box>
        {hasAnyFiles && (
          <Box sx={{ mt: 2 }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided: any, snapshot: any) => (
                  <List ref={provided.innerRef} {...provided.droppableProps}>
                    {files.map((file: any, index: number) => {
                      const fileName = file?.name || file?.fileName;
                      return (
                        <Draggable key={file?.filename || fileName} draggableId={`${file?.filename || fileName}-id`} index={index}>
                          {(provided: any, snapshot: any) => (
                            <ListItem key={file.path} sx={styles.item} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <ListItemIcon>
                                <Box component="img" sx={styles.itemBox} alt="Big image" src={file?.url ? file?.url : URL.createObjectURL(file)} />
                              </ListItemIcon>
                              <ListItemText primary={file?.filename || fileName} primaryTypographyProps={{ variant: "subtitle2" }} secondary={file?.size && bytesToSize(file.size)} />
                              <Tooltip title={t(tokens.common.delete)}>
                                <IconButton edge="end" onClick={() => onRemove?.(file?.filename || file.name)}>
                                  <SvgIcon><ClearIcon /></SvgIcon>
                                </IconButton>
                              </Tooltip>
                            </ListItem>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </DragDropContext>
            <Stack alignItems="center" direction="row" justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
              {onRemoveAll && (
                <Button color="inherit" onClick={onRemoveAll} size="small" type="button">
                  {t(tokens.common.deleteAll)}
                </Button>
              )}
              {onUpload && (
                <Button onClick={onUpload} size="small" type="button" variant="contained">
                  {t(tokens.common.upload)}
                </Button>
              )}
            </Stack>
          </Box>
        )}
      </div>
    );
  }
};

