import { IconButton, Stack, SvgIcon, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import { getStyles } from "./styles";
import { useNavigate } from "react-router-dom";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";
import { paths } from "../../../../../../paths";
import { useDispatch } from "react-redux";
import { IAppPage } from "../../../../../../store/appPage/types/IAppPage";
import { setSelectedAppPage } from "../../../../../../store/appPage/repository/slice";

interface IProps {
    page: IAppPage;
    onChangeVisibility: (id: number, isHidden: boolean) => void;
    onDelete: (id: number) => void;
};

export const AppPageTableItem: FC<IProps> = ({ page, onChangeVisibility, onDelete }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);

    const handleEdit = useCallback(() => {
        dispatch(setSelectedAppPage(page));
        navigate(paths.dashboard.appPage.edit.replace(':id', String(page.id)));
    }, []);

    const handleVisibility = useCallback(() => onChangeVisibility(page.id, !page.isHidden), [page]);

    const handleDelete = useCallback(() => onDelete(page.id), []);

    return (
        <TableRow hover key={page.id}>
            <TableCell align="left" sx={styles.cell}>
                <Stack direction={'row'} alignItems={'center'}>
                    <img
                        src={page.icon?.url || "/images/user/uploadImage.png"}
                        alt="AppPageIcon"
                        width={45}
                        height={45}
                        className="image"
                    />
                    <Typography ml={1} variant="body2">{page.name}</Typography>
                </Stack>
            </TableCell>
            <TableCell align="right" sx={styles.cell}>
                <Stack direction='row' alignItems='center' spacing={1} justifyContent='flex-end'>
                    {!page.deletedAt &&
                        <IconButton size="small" onClick={handleVisibility}>
                            {page.isHidden ?
                                <Tooltip title={t(tokens.documents.notPublish)}>
                                    <SvgIcon style={styles.svgIcon}><VisibilityOffOutlinedIcon /></SvgIcon>
                                </Tooltip>
                                :
                                <Tooltip title={t(tokens.documents.publish)} >
                                    <SvgIcon style={styles.svgIcon}><VisibilityOutlinedIcon /></SvgIcon>
                                </Tooltip>
                            }
                        </IconButton>
                    }
                    <IconButton size="small" onClick={handleEdit}>
                        <Tooltip title={page.deletedAt ? t(tokens.appPage.show) : t(tokens.common.edit)}>
                            <SvgIcon style={styles.svgIcon}><EditOutlinedIcon /></SvgIcon>
                        </Tooltip>
                    </IconButton>
                    {!page.deletedAt &&
                        <IconButton size="small" onClick={handleDelete}>
                            <Tooltip title={t(tokens.common.delete)}>
                                <SvgIcon style={styles.svgIcon}><DeleteForeverIcon /></SvgIcon>
                            </Tooltip>
                        </IconButton>
                    }
                </Stack>
            </TableCell>
        </TableRow>
    )
};