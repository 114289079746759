import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setLoading } from "../../repository/slice";

function* addImageWorker({ payload }: any): SagaIterator {
    try {
        const formData = new FormData();
        for (const [key, value] of Object.entries(payload as Object)) {
            const isValue = value !== null && value !== undefined && value !== "";
            if (isValue) {
                if (Array.isArray(value)) {
                    value.forEach(file => {
                        formData.append(key, file);
                    });
                } else {
                    formData.append(key, value);
                }
            }
        }; 
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.post, "/api/admin/announcement/add-image", formData);
        if (isError) {

        } else {
            
        }
    } catch (error) {
        console.error("addImageWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* addImageWatcher() {
    yield takeLatest("@saga/ADD_IMAGE", addImageWorker);
};
