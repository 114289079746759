import { IconButton, Stack, SvgIcon, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import { FC, useCallback, useMemo } from "react";
import { getStyles } from "./styles";
import { tokens } from "../../../../../locales/tokens";
import { Link } from "react-router-dom";
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useTranslation } from "react-i18next";

interface IProps {
    advert: any;
    handleStatus: (id: number, status: string) => void;
    handleDelete: (id: number) => void;
    handleUploadReport: (id: number, name: string) => void;
};

export const AdvertTableItem: FC<IProps> = ({ advert, handleStatus, handleDelete, handleUploadReport }) => {
    const { t } = useTranslation();
    const styles = useMemo(() => getStyles(), []);
    const formattedDate = dayjs(advert.updatedAt).format('DD.MM.YYYY');
    const changeStatus = useCallback(() => handleStatus(advert.id, advert.status), [advert.id, advert.status]);
    const deleteDocument = useCallback(() => handleDelete(advert.id), [advert.id]);
    const uploadReport = useCallback(() => handleUploadReport(advert.id, advert.content), [advert.id, advert.content]);

    const typeOptions: any = {
        News: t(tokens.adverts.news),
        Promo: t(tokens.adverts.promo),
        Event: t(tokens.adverts.event),
        CEO_News: t(tokens.adverts.newsCeo),
    };

    const isDisabledReport = useCallback(() => {
        // const isFamiliarize = advert.familiarizes?.some((item: any) => item.AnnouncementFamiliarize.status === "Familiarize");
        if (advert.isFamiliarize || advert.likesAmount || advert.dislikesAmount) {
            return false;
        };
        return true;
    }, [advert]);

    return (
        <TableRow hover key={advert.id}>
            <TableCell align="left" width='50%' sx={styles.cell}>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <img src={advert.image?.url || '/images/user/uploadImage.png'} width={45} height={45} alt="advert_logo" style={{ objectFit: 'cover' }} />
                    <Typography variant="body2">{advert.theme}</Typography>
                </Stack>
            </TableCell>
            <TableCell align="center" width='10%' sx={styles.cell}>
                <Typography variant="body2">{typeOptions[advert.type]}</Typography>
            </TableCell>
            <TableCell align="center" width='20%' sx={styles.cell}>
                <Typography variant="body2">
                    {formattedDate}
                </Typography>
            </TableCell>
            <TableCell align="right" width='20%' sx={styles.cell}>
                <Stack direction='row' alignItems='center' spacing={1} justifyContent='flex-end'>
                    <IconButton size="small" onClick={uploadReport} disabled={isDisabledReport()}>
                        <Tooltip title={t(tokens.documents.uploadReport)}>
                            <SvgIcon style={styles.svgIcon}><SimCardDownloadOutlinedIcon /></SvgIcon>
                        </Tooltip>
                    </IconButton>
                    {!advert.deleted_at ?
                        <IconButton size="small" onClick={changeStatus}>
                            {advert.status === 'Hidden' ?
                                <Tooltip title={t(tokens.documents.notPublish)}>
                                    <SvgIcon style={styles.svgIcon}><VisibilityOffOutlinedIcon /></SvgIcon>
                                </Tooltip>
                                :
                                <Tooltip title={t(tokens.documents.publish)} >
                                    <SvgIcon style={styles.svgIcon}><VisibilityOutlinedIcon /></SvgIcon>
                                </Tooltip>
                            }
                        </IconButton>
                        : null}
                    <IconButton size="small" component={Link} to={`/advert-edit/${advert.id}`} disabled={advert.type === 'CEO_News'}>
                        <Tooltip title={advert.deleted_at ? t(tokens.common.watch) : t(tokens.common.edit)}>
                            <SvgIcon style={styles.svgIcon}><EditOutlinedIcon /></SvgIcon>
                        </Tooltip>
                    </IconButton>
                    {!advert.deleted_at ?
                        <IconButton size="small" onClick={deleteDocument}>
                            <Tooltip title={t(tokens.common.delete)}>
                                <SvgIcon style={styles.svgIcon}><DeleteForeverIcon /></SvgIcon>
                            </Tooltip>
                        </IconButton>
                        :
                        null}
                </Stack>
            </TableCell>
        </TableRow>
    )
};