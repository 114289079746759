import { Dispatch, FC, Fragment } from "react";
import { TreeCategorySection } from "../treeCategorySection";
import { TreeCategoryItem } from "../treeCategoryItem";
import { ICategory } from "../../../../../../../types/category";

export interface ITreeProps {
    nodes: ICategory[];
    keyCategory: string;
    handleStatus: (id: number, isHidden: boolean) => void;
    isPoster: boolean;
    setUpdateClick: Dispatch<boolean>;
    capturedCategory: ICategory | null;
    setCapturedCategory: Dispatch<any>;
    swapSections: (direction: "UP" | "DOWN", index: number, firstSection: any, sectionIndex?: number | undefined) => void;
    sectionIndex?: number;
    defineCategoryId: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number) => void; 
};

export const TreeCategory: FC<ITreeProps> = ({ handleStatus, isPoster, keyCategory, nodes, capturedCategory, setCapturedCategory, setUpdateClick, swapSections, sectionIndex, defineCategoryId }) => {

    return (
        <>
            {nodes?.map((node, index) => {

                return (
                    <Fragment key={node.createdAt + keyCategory + node.sortNumber}>
                        {node.children?.length > 0 ?
                            <TreeCategorySection
                                key={node.name + node.id}
                                node={node}
                                renderCategoryTree={TreeCategory}
                                handleStatus={handleStatus}
                                isPoster={isPoster}
                                setUpdateClick={setUpdateClick}
                                capturedCategory={capturedCategory}
                                setCapturedCategory={setCapturedCategory}
                                swapSections={swapSections}
                                index={index}
                                sectionIndex={sectionIndex}
                                defineCategoryId={defineCategoryId}
                            />
                            :
                            <TreeCategoryItem
                                key={node.name + node.id}
                                node={node}
                                handleStatus={handleStatus}
                                isPoster={isPoster}
                                setUpdateClick={setUpdateClick}
                                capturedCategory={capturedCategory}
                                setCapturedCategory={setCapturedCategory}
                                swapSections={swapSections}
                                index={index}
                                sectionIndex={sectionIndex}
                                defineCategoryId={defineCategoryId}
                            />
                        }
                    </Fragment>
                )
            })
            }
        </>
    )
};