import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getTestList } from "../../../../store/test/useCases/getTestList/action";
import { useDebounce } from "../../../../hooks/useDebounce";
import { getCourseList } from "../../../../store/course/useCases/getCourseList/action";
import { selectCourse } from "../../../../store/course/repository/selector";
import { getSingleCourseData } from "../../../../store/analytics/useCases/getSingleCourseData/action";
import { selectAnalytics } from "../../../../store/analytics/repository/selector";

export const useSingleCourseAnalytics = () => {
    const dispatch = useDispatch();
    const [searchText, setSearchText] = useState('');
    const [currentCourse, setCurrentCourse] = useState<any>(null);
    const { courseList } = useSelector(selectCourse);
    const { singleCourseData } = useSelector(selectAnalytics);

    useEffect(() => {
        dispatch(getCourseList(getRequestBody(searchText)));
    }, []);

    useEffect(() => {
        currentCourse && dispatch(getSingleCourseData({id: currentCourse.id}));
    }, [currentCourse])

    const changeCourseText = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
        debouncedHandleCourseSeacrh(e.target.value);
    };

    const onSearchCourse = (value: string) => {
        const search = value ? value : '';
        if (search.length !== 1) {
            dispatch(getTestList(getRequestBody(value)));
        }
    };

    const { debouncedWrapper: debouncedHandleCourseSeacrh } = useDebounce(onSearchCourse, 1000);

    const changeCourse = useCallback((value: any) => {
        setCurrentCourse(value);
    }, [setCurrentCourse]);

    const getRequestBody = (value: string) => {
        const body: any = { page: 1, size: 15 };
        if (value) body.search = value;
        return body;
    };

    const labels = singleCourseData?.statusStatistic.map((item: {amount: number, status: string}) => item.status) || [];
    const chartSeries = singleCourseData?.statusStatistic.map((item: {amount: number, status: string}) => item.amount) || [];

    return { courseList, currentCourse, changeCourse, changeCourseText, labels, chartSeries }
};