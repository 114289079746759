import { ChangeEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { TFunction } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "../../../../hooks/useDebounce";
import { tokens } from "../../../../locales/tokens";
import { paths } from "../../../../paths";
import { setPushNotification } from "../../../../store/pushNotification/repository/actions";
import { selectPushNotifications } from "../../../../store/pushNotification/repository/selectors";
import { getPushNotificationById } from "../../../../store/pushNotification/useCases/getNotificationById/actions";
import { updateNotification } from "../../../../store/pushNotification/useCases/updateNotification/actions";
import { selectUser } from "../../../../store/user/repository/selector";
import { getRoles } from "../../../../store/role/useCases/getRoles/action";
import { getUsers } from "../../../../store/user/useCases/getUsers/action";
import { useNavigate, useParams } from "react-router-dom";
import { selectRole } from "../../../../store/role/repository/selector";
import { FILE_SIZE } from "../../../../config";
import { IError } from "../../../../types/error";

export const useNotificationEdit = (t: TFunction<"translation", undefined>) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const { notificationById, errorsUpdateNotification, isLoading } = useSelector(selectPushNotifications);
    const { userList } = useSelector(selectUser);
    const { roleList } = useSelector(selectRole);
    const [updateClick, setUpdateClick] = useState(false);
    const [searchUsers, setSearchUsers] = useState('');
    const [checked, setChecked] = useState(true);
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [jsonData, setJsonData] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [audience, setAudience] = useState('');
    const [userId, setUserId] = useState('');
    const [country, setCountry] = useState("");
    const [region, setRegion] = useState("");
    const [status, setStatus] = useState("");
    const [group, setGroup] = useState<any[]>([]);
    const [time, setTime] = useState('');
    const [image, setImage] = useState<any>(null);
    const [imageSrc, setImageSrc] = useState('');
    const [imageType, setImageType] = useState('Link');
    const [initialized, setInitialized] = useState(false);
    const [titleValid, setTitleValid] = useState(true);
    const [textValid, setTextValid] = useState(true);
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [navLinkValid, setNavLinkValid] = useState(true);
    const [imageUrlValid, setImageUrlValid] = useState(true);
    const [user, setUser] = useState<any>(null);
    const [imageSrcValid, setImageSrcValid] = useState(true);

    useEffect(() => {
        id && dispatch(getPushNotificationById({ id: Number(id) }));
        return () => {
            dispatch(setPushNotification(null));
        }
    }, [id]);

    useEffect(() => {
        if (notificationById) {
            setTitle(notificationById.title);
            setText(notificationById.text);
            setJsonData(notificationById.navigation_url || '');
            setImageUrl(notificationById.image_url || '');
            setAudience(notificationById.audienceType);
            if (notificationById.userId) setUserId(notificationById.userId || '');
            if (notificationById.city) setRegion(notificationById.city || '');
            if (notificationById.role) setStatus(notificationById.role || '');
            if (notificationById.group) setGroup(notificationById.group.map((user: any) => (
                { label: user.first_name + " " + user.last_name, name: user.first_name + " " + user.last_name, id: user.id }
            )));
            if (notificationById.time) setTime(notificationById.time);
            if (!notificationById.time) setChecked(false);
            if (notificationById.user) setUser({ id: notificationById.user.id, name: notificationById.user.first_name + " " + notificationById.user.last_name, label: notificationById.user.first_name + " " + notificationById.user.last_name });
            if (notificationById.deleted_at) {
                setIsDeleted(true);
            };
            setInitialized(true);
        }
    }, [notificationById]);

    useEffect(() => {
        dispatch(getUsers({ page: 1, perPage: 20 }));
        dispatch(getRoles());
    }, []);

    useEffect(() => {
        if (imageType && !initialized) {
            setImage(null);
            setImageSrc('');
            setImageUrl('');
        };
        setInitialized(false);
    }, [imageType]);

    useEffect(() => {
        if (!isLoading && updateClick) {
            if (errorsUpdateNotification) {
                handleServerError(errorsUpdateNotification);
            } else {
                toast.success(t(tokens.notification.pushUpdated));
                navigate(paths.dashboard.notification.index);

            }
            setUpdateClick(false);
        }
    }, [errorsUpdateNotification, isLoading]);

    const handleServerError = (error: IError) => {
        if (error?.startsWith("value too long for type character varying(255)")) {
            toast.error(t(tokens.common.tooMuchSymbols));
            setTitleValid(false);
        } else if (error?.startsWith("value too long for type character varying(2000)")) {
            toast.error(t(tokens.common.tooMuchSymbols));
            setTextValid(false);
        } else {
            toast.error(error);
        }
    };

    const onSearchUsers = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchUsers(event.target.value);
        debouncedHandleUsersSelect(event.target.value);
    };

    const onSearch = (value: any) => {
        if (value === '') {
            dispatch(getUsers({ page: 1, perPage: 20 }));
            return;
        } else {
            getUsersByName(value);
        };
    };

    const getUsersByName = (value: any) => {
        if (!isNumber(value)) {
            dispatch(getUsers({ name: value, page: 1, perPage: 20 }));
            return
        } else {
            dispatch(getUsers({ id: Number(value), page: 1, perPage: 20 }));
            return
        }
    };

    const handleUserChange = (value: any) => {
        if (!isNumber(searchUsers)) {
            setGroup(value);
        } else {
            setGroup(prevState => [...prevState, ...value]);
        }
        setSearchUsers('');
        dispatch(getUsers({ page: 1, perPage: 20 }));
    };

    const isNumber = (value: string) => {
        return /^\d+/.test(value);
    };

    const { debouncedWrapper: debouncedHandleUsersSelect } = useDebounce(onSearch, 1000);

    const onChangeSwitch = (event: ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        if (event.target.checked === false) setTime('');
    };

    const onChangeTime = (newDateTime: any) => {
        setTime(newDateTime);
    };

    const onAcceptTime = () => {
        toast.success(t(tokens.notification.timeIsSet))
    };

    const onChangTitle = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setTitle(e.target.value);
        setTitleValid(true);
    };

    const onChangText = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setText(e.target.value);
        setTextValid(true);
    };

    const onChangJsonData = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setJsonData(e.target.value);
        setNavLinkValid(true);
    };

    const onChangImageUrl = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setImageUrl(e.target.value);
        setImageUrlValid(true);
    };

    const onChangeAudience = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setAudience(e.target.value);
    };

    const onChangeUserId = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setUserId(e.target.value);
    };

    const onChangeCountry = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setCountry(e.target.value);
    };

    const onChangeRegion = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setRegion(e.target.value);
    };

    const onChangeStatus = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setStatus(e.target.value);
    };

    const onUpdateNotification = async () => {
        const isDataValid = validateFields();
        if (isDataValid) {
            updatePushNotification();
        } else {
            toast.error(t(tokens.notification.fillFields))
        }
    };

    const validateFields = () => {
        const data = [
            { field: title, setField: setTitleValid },
            { field: text, setField: setTextValid },
        ];
        let allFieldsValid = true;
        for (const { field, setField } of data) {
            if (!field) {
                setField(false);
                allFieldsValid = false;
            }
        };
        return allFieldsValid;
    };

    const updatePushNotification = () => {
        const isValidLinks = validateLinks();
        if (isValidLinks) {
            dispatch(updateNotification(getRequestBody()));
            setUpdateClick(true);
        };
    };

    const validateLinks = () => {
        let allFieldsValid = true;
        if (imageUrl) {
            const urlRegex = /(https?:\/\/[^\s]+\.[a-z]{2,})/gi;
            if (!urlRegex.test(imageUrl)) {
                toast.error(t(tokens.notification.imageUrlNotCorrect));
                setImageUrlValid(false);
                allFieldsValid = false;
            }
        };
        if (jsonData) {
            const urlRegex = /(https?:\/\/[^\s]+\.[a-z]{2,})/gi;
            if (!urlRegex.test(jsonData)) {
                toast.error(t(tokens.notification.linkNotCorrect));
                setNavLinkValid(false);
                allFieldsValid = false;
            }
        };

        return allFieldsValid;
    };

    const getRequestBody = () => {
        const body: any = { id: Number(id), title, text, audienceType: audience };
        imageUrl ? body.image_url = imageUrl : body.image_url = 'null';
        body.navigation_url = jsonData.length ? jsonData : 'null';
        if (user) body.userId = user.id;
        if (region) body.city = region;
        if (status) body.role = status;
        if (image && imageSrc) body.image = image;
        body.time = time ? new Date(time).toISOString() : null;
        if (group.length) body.group = group.map(user => user.id);
        return body;
    };

    const imageTypeOptions: { label: string, value: string }[] = [
        { label: t(tokens.notification.link), value: 'Link' },
        { label: t(tokens.notification.image), value: 'Image' },
    ];

    const onChangeImageType = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setImageType(e.target.value);
        setImageSrcValid(true);
        setImageUrlValid(true);
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            // const maxSizeBytes = FILE_SIZE["1MB"];
            // if (file.size > maxSizeBytes) {
            //     toast.error(t(tokens.notification.maxSizeFile));
            //     setImageSrcValid(false);
            //     return;
            // };

            const reader = new FileReader();
            reader.onload = (e) => {
                const newImageSrc = e.target?.result as string;
                setImageSrc(newImageSrc);
            };
            reader.readAsDataURL(file);
        }
        setImage(file);
        setImageSrcValid(true);
    };

    const handleClearImageClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setImageSrc('');
        setImage(null);
    };

    const cancelCreate = () => {
        navigate(paths.dashboard.notification.index);
    };


    const onUserChange = (value: any) => {
        setUser(value);
    };

    return {
        title, text, jsonData, imageUrl, audience, userId, onChangeCountry, country, onChangeRegion, onChangeStatus, roleList, handleUserChange, user,
        onChangeAudience, onChangImageUrl, onUpdateNotification, onChangTitle, onChangeSwitch, onChangeTime, checked, group, region, imageUrlValid, imageSrcValid,
        onChangText, onChangJsonData, onChangeUserId, onAcceptTime, time, onSearch, onSearchUsers, userList, status, imageType, onChangeImageType, onUserChange,
        imageTypeOptions, imageSrc, handleFileChange, handleClearImageClick, updateClick, titleValid, textValid, cancelCreate, isDeleted, navLinkValid
    };
};