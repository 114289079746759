import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../locales/tokens";

export const AccessTableHead: FC = () => {
    const { t } = useTranslation();

    return (
        <Stack direction="row" mb={1}>
            <Typography variant="body1" fontWeight={600} pl={15} width='55%' textAlign="left">
                {t(tokens.common.access)}
            </Typography>
            <Typography variant="body1" fontWeight={600} pr={3} width='15%' textAlign="center">
                Дата доступу
            </Typography>
            <Typography variant="body1" fontWeight={600} pr={9} width='30%' textAlign="right">
                {t(tokens.common.actions)}
            </Typography>
        </Stack>
    )
};

