import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { tokens } from "../../../../locales/tokens";
import { ChangeEvent, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { updateOrgStructure } from "../../../../store/orgStructure/useCases/updateOrgStructure/action";
import { selectOrgStructure } from "../../../../store/orgStructure/repository/selector";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../../paths";
import toast from "react-hot-toast";
import { createSingleAdvert } from "../../../../store/advert/useCases/createAdvert/action";
import { selectAdvert } from "../../../../store/advert/repository/selector";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { FILE_SIZE } from "../../../../config";
import { IError } from "../../../../types/error";
import { getPositions } from "../../../../store/user/useCases/getPositions/action";
import { selectUser } from "../../../../store/user/repository/selector";
import { transformTable } from "../../../../utils/transformToggleTable";

export const useAdvertCreate = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [type, setType] = useState('');
    const [theme, setTheme] = useState('');
    const [initiator, setInitiator] = useState<any>(null);
    const [groupPermissions, setGroupPermissions] = useState<any[]>([]);
    const [receiver, setReceiver] = useState<any[]>([]);
    const [publishChecked, setPublishChecked] = useState(false);
    const [notifyChecked, setNotifyChecked] = useState(false);
    const [imageSrc, setImageSrc] = useState('');
    const [image, setImage] = useState<File | null>(null);
    const [link, setLink] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [upToDate, setUpToDate] = useState(null);
    const { orgStructure } = useSelector(selectOrgStructure);
    const [typeValid, setTypeValid] = useState(true);
    const [themeValid, setThemeValid] = useState(true);
    const [imageValid, setImageValid] = useState(true);
    const [initiatorValid, setInitiatorValid] = useState(true);
    const [receiverValid, setReceiverValid] = useState(true);
    const [startDateValid, setStartDateValid] = useState(true);
    const [upToDateValid, setUpToDateValid] = useState(true);
    const [createClick, setCreateClick] = useState(false);
    const [contentValid, setContentValid] = useState(true);
    const { isLoading, createAdvertError } = useSelector(selectAdvert);
    const { positionsList } = useSelector(selectUser);
    const [initialContent, setInitialContent] = useState('{"blocks":[],"entityMap":{}}');
    const [editorState, setEditorState] = useState(() => {
        if (initialContent) {
            try {
                const parsedContent = JSON.parse(initialContent);
                const contentState = convertFromRaw(parsedContent);
                return EditorState.createWithContent(contentState);
            } catch (error) {
                console.error('Error parsing initial content:', error);
            }
        }
        return EditorState.createEmpty();
    });
    const [departmentPermissions, setDepartmentPermissions] = useState<any[]>([]);
    const [positions, setPositions] = useState<any[]>([]);
    const [assignDepartment, setAssignDepartment] = useState<any[]>([]);
    const [assignPositions, setAssignPositions] = useState<any[]>([]);

    const advertTypeOptions = [
        { label: t(tokens.adverts.news), value: "News" },
        { label: t(tokens.adverts.event), value: "Event" },
        { label: t(tokens.adverts.promo), value: "Promo" },
    ];

    useEffect(() => {
        dispatch(updateOrgStructure());
        dispatch(getPositions());
    }, []);

    useEffect(() => {
        if (type) {
            setStartDate(null);
            setUpToDate(null);
            setLink('');
        }
    }, [type]);

    useEffect(() => {
        if (!isLoading && createClick) {
            if (createAdvertError) {
                handleServerError(createAdvertError);
            } else {
                toast.success(t(tokens.adverts.messages.advertCreated));
                navigate(paths.dashboard.advert.index);
            }
            setCreateClick(false);
        }
    }, [createAdvertError, isLoading]);

    const handleServerError = (error: IError) => {
        if (error?.startsWith("value too long for type character varying(255)")) {
            toast.error(t(tokens.common.tooMuchSymbols));
            setThemeValid(false);
        } else if (error?.startsWith("value too long for type character varying(20000)")) {
            toast.error(t(tokens.common.tooMuchSymbols));
            setContentValid(false);
        } else {
            toast.error(error);
        }
    };

    const changeType = useCallback((event: SelectChangeEvent<any>) => {
        setType(event.target.value);
        setTypeValid(true);
    }, [setType]);

    const onChangeTheme = (event: ChangeEvent<HTMLInputElement>) => {
        setTheme(event.target.value);
        setThemeValid(true);
    };

    const onSelectInitiator = (selectedKeysValue: string, node: any) => {
        if ('children' in node) {
            return;
        }
        setInitiator(node);
        setInitiatorValid(true);
    };

    const onChangeInitiator = () => {
        if (initiator) {
            setInitiator(null);
        }
    };

    const onChangePermissions = (newValue: string[]) => {
        setGroupPermissions(newValue);
    };

    const onChangeDepartmentPermissions = (newValue: string[]) => {
        setDepartmentPermissions(newValue);
    };

    const onChangeAssignDepartment = (newValue: string[]) => {
        setAssignDepartment(newValue);
    };

    const onChangeReceiver = (newValue: string[]) => {
        setReceiver(newValue);
        setReceiverValid(true);
    };

    const handlePublishChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPublishChecked(event.target.checked);
    };

    const handleNotifyChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNotifyChecked(event.target.checked);
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];

        if (file) {
            // const maxSizeBytes = FILE_SIZE["1MB"];
            // if (file.size > maxSizeBytes) {
            //     toast.error(t(tokens.adverts.fileTooBig));
            //     return;
            // };

            const reader = new FileReader();
            reader.onload = (e) => {
                const newImageSrc = e.target?.result as string;
                setImageSrc(newImageSrc);
            };
            reader.readAsDataURL(file);
        }
        setImage(file);
        setImageValid(true);
    };

    const handleClearImageClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setImageSrc('');
        setImage(null);
    };

    const onChangeLink = (event: ChangeEvent<HTMLInputElement>) => {
        setLink(event.target.value);
    };

    const getHtml = useCallback(() => {
        const contentState = editorState.getCurrentContent();
        const htmlContent = draftToHtml(convertToRaw(contentState));
    
        const updatedHtml = htmlContent.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;/g, '');
        const updatedContent = transformTable(updatedHtml);
    
        // console.log("updatedHtml----->", updatedHtml);
        // console.log("updatedContent----->", updatedContent);
    
        return updatedContent;
    }, [editorState]);

    const createAdvert = () => {
        const isPassedValidation = validateData();
        if (isPassedValidation) {
            dispatch(createSingleAdvert(getRequestBody()));
            setCreateClick(true);
        } else {
            toast.error(t(tokens.adverts.fillAllFields))
        }
    };

    const validateData = () => {
        const html = getHtml();
        const content = html.length !== 8;
        const data = [
            { field: type, setField: setTypeValid },
            { field: theme, setField: setThemeValid },
            { field: content, setField: setContentValid },
            { field: image, setField: setImageValid },
            { field: initiator, setField: setInitiatorValid },
            // { field: receiver.length, setField: setReceiverValid },
        ];
        if (type === 'Event') {
            data.push({ field: startDate, setField: setStartDateValid });
            data.push({ field: upToDate, setField: setUpToDateValid });
        };

        let allFieldsValid = true;
        for (const { field, setField } of data) {
            if (!field) {
                setField(false);
                allFieldsValid = false;
            }
        };

        return allFieldsValid;
    };

    const cleanedGuiIds = (guiIds: string[]): string[] => {
        return guiIds.map(permission => {
            const [cleaned] = permission.split('%');
            return cleaned;
        });
    };  

    const getRequestBody = () => {
        const body: any = { type, theme, image, initiatorId: initiator.value };
        body.status = publishChecked ? "Available" : "Hidden";
        if (type === 'Event') {
            body.eventStartDate = new Date(Number(startDate)).toISOString();
            body.eventEndDate = new Date(Number(upToDate)).toISOString();
        }
        if (type === 'News') {
            body.url = link;
        }
        if (receiver.length) body.assigns = receiver;
        if (groupPermissions.length) body.access = groupPermissions;
        if(departmentPermissions.length) body.accessDepartmentIds = cleanedGuiIds(departmentPermissions);
        if(positions.length) body.accessPositionIds = positions.map(item => item.id);
        if(assignDepartment.length) body.assignDepartmentIds = cleanedGuiIds(assignDepartment);
        if(assignPositions.length) body.assignPositionIds = assignPositions.map(item => item.id); 
        const html = getHtml();
        if (html.length > 8) body.content = html;
        if (notifyChecked) body.isInformed = notifyChecked;
        return body;
    };

    const cancelCreate = () => {
        navigate(paths.dashboard.advert.index);
    };

    const handleStartDateChange = useCallback((date: Date | null): void => {
        const currentDate: any = date?.valueOf() || null;
        setStartDate(currentDate);
        setStartDateValid(true);
    }, [setStartDate]);

    const handleUpToDateChange = useCallback((date: Date | null): void => {
        const currentDate: any = date?.valueOf() || null;
        setUpToDate(currentDate);
        setUpToDateValid(true);
    }, [setUpToDate]);

    const onEditorStateChange = (newEditorState: EditorState) => {
        setEditorState(newEditorState);
        setContentValid(true);
    };

    const handleChangePosition = (event: any, newValue: readonly any[]) => {
        setPositions([...newValue]);
    };

    const handleChangeAssignPosition = (event: any, newValue: readonly any[]) => {
        setAssignPositions([...newValue]);
    };

    return {
        advertTypeOptions, type, changeType, theme, onChangeTheme, initiator, onChangeInitiator, onSelectInitiator, orgStructure,
        groupPermissions, onChangePermissions, onChangeReceiver, receiver, publishChecked, notifyChecked, handlePublishChange,
        handleNotifyChange, createAdvert, cancelCreate, handleFileChange, imageSrc, link, onChangeLink, startDate, createClick,
        upToDate, handleStartDateChange, handleUpToDateChange, typeValid, themeValid, imageValid, initiatorValid, receiverValid,
        startDateValid, upToDateValid, handleClearImageClick, editorState, setEditorState, onEditorStateChange, contentValid,
        onChangeDepartmentPermissions, departmentPermissions, positionsList, positions, handleChangePosition, onChangeAssignDepartment, 
        assignDepartment, handleChangeAssignPosition, assignPositions
    }
};