export const getStyles = () => {
    const styles = {
        account: {
            alignItems: 'center',
            display: 'flex',
            borderWidth: 2,
            borderStyle: 'solid',
            borderColor: 'divider',
            height: 45,
            width: 45,
            borderRadius: '50%'
        },
        avatar: {
            height: 40,
            width: 40
        },
        menuItem: { 
            borderRadius: 1, 
            px: 1, 
            py: 0.5 
        },
        logout: {
            width: '100%',
            height: '40px',
            backgroundColor: '#F25A4F',
            color: '#FFFFFF',
            '&:hover':{
                backgroundColor: '#cc4339'
            }
        },
    };
    return styles;
}