import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setChangePassError, setLoading } from "../../repository/slice";

function* changePasswordWorker({ payload }: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { data, message, isError }: IResponse<any> = yield call(requester.put, "/api/user/update", payload);
        if (isError) {
            yield put(setChangePassError(message));
        } else {
            yield put(setChangePassError(null));
            const user = JSON.parse(localStorage.getItem('user') as string);
            user.need_change_password = false;
            localStorage.setItem('user', JSON.stringify(user));
        }
    } catch (error) {
        console.error("changePasswordWorker: ", error);
    } finally {
        yield put(setLoading(false));
    };
};

export function* changePasswordWatcher() {
    yield takeLatest("@saga/CHANGE_PASSWORD", changePasswordWorker);
};
