import { IError } from "../../../types/error";

interface IInitialState {
  isLoading: boolean;
  errorsGetPushNotifications: IError;
  errorsCreatePushNotification: IError;
  errorsGetNotificationById: IError;
  errorsSendNotification: IError;
  errorsDeletePushNotifications: IError;
  errorsUpdateNotification: IError;
  notifications: any[];
  notificationById: any;
  count: number;
};

const initialState: IInitialState = {
    isLoading: false,
    errorsGetPushNotifications: null,
    errorsCreatePushNotification: null,
    errorsGetNotificationById: null,
    errorsSendNotification: null,
    errorsDeletePushNotifications: null,
    errorsUpdateNotification: null,
    notifications: [],
    notificationById: null,
    count: 0
};

export function pushNotificationReducer(state: IInitialState = initialState, action: any): IInitialState {
  const { type, payload } = action;
  switch (type) {
    case 'SET_PUSH_LOADING':
      return { ...state, isLoading: payload };
    case 'SET_ERRORS_GET_NOTIFICATIONS':
      return { ...state, errorsGetPushNotifications: payload };
    case 'SET_ERRORS_GET_NOTIFICATION_BY_ID':
      return { ...state, errorsGetNotificationById: payload };
    case 'SET_ERRORS_DELETE_NOTIFICATION':
      return { ...state, errorsDeletePushNotifications: payload };
    case 'SET_ERRORS_CREATE_NOTIFICATION':
      return { ...state, errorsCreatePushNotification: payload };
    case 'SET_ERRORS_SEND_NOTIFICATION':
      return { ...state, errorsSendNotification: payload };
    case 'SET_ERRORS_UPDATE_NOTIFICATION':
      return { ...state, errorsUpdateNotification: payload };
    case 'SET_PUSH_NOTIFICATIONS':
      return { ...state, notifications: payload };
    case 'SET_PUSH_NOTIFICATION':
      return { ...state, notificationById: payload };
    case 'SET_COUNT':
      return { ...state, count: payload };
   
    default:
      return state;
  }
};
