import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";

export const ProductTableHeader: FC = () => {
    const { t } = useTranslation();

    return (
        <TableHead>
            <TableRow>
                <TableCell align="center">
                    <Typography fontWeight={600} variant="body2">{t(tokens.analytics.name)}</Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography fontWeight={600} variant="body2">{t(tokens.analytics.price)}</Typography>
                </TableCell>
                <TableCell align="center">
                    <Typography fontWeight={600} variant="body2">{t(tokens.analytics.amount)}</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    )
};