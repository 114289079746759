import { Stack, Typography } from "@mui/material"
import { CheckboxAutocompleteSingle } from "../../../../../../../ui-kit/checkboxAutocompleteSingle"
import { FC } from "react";
import { CheckboxAutocompleteMultiple } from "../../../../../../../ui-kit/checkboxAutocompleteMultiple";

interface IProps {
    isDisabled?: boolean;
    testId?: any;
    isValid: boolean;
    title: string;
    label: string;
    value: any[];
    orgStructure: any[];
    onChangePermissions: (newValue: string[]) => void;
};

export const AutocompleteMultiline: FC<IProps> = ({ isValid, label, onChangePermissions, orgStructure, title, value, testId, isDisabled }) => {

    return (
        <Stack width="100%" minWidth="49%">
            <Typography variant="h6" mb={1} color={isValid ? 'inherit' : 'error'}>
                {title}
            </Typography>
            <CheckboxAutocompleteMultiple
                title={label}
                value={value}
                optionList={orgStructure}
                onChange={onChangePermissions}
                isDisabled={!!testId || isDisabled}
            />
        </Stack>
    )
};