import { TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { FC } from "react"
import { tokens } from "../../../../../../locales/tokens";
import { useTranslation } from "react-i18next";

interface IProps {

};

export const HistoryTableHead: FC<IProps> = () => {
    const { t } = useTranslation();

    return (
        <TableHead>
            <TableRow >
                <TableCell align="center"  width="25%">
                    <Typography fontWeight={600}>{t(tokens.users.studyHistory.exercise)}</Typography>
                </TableCell>
                <TableCell align="center"  width="10%">
                    <Typography fontWeight={600}>{t(tokens.users.studyHistory.type)}</Typography>
                </TableCell>
                <TableCell align="center"  width="15%">
                    <Typography fontWeight={600}>{t(tokens.users.studyHistory.assignmentDate)}</Typography>
                </TableCell>
                <TableCell align="center"  width="15%">
                    <Typography fontWeight={600}>{t(tokens.users.studyHistory.completionDate)}</Typography>
                </TableCell>
                <TableCell align="center"  width="15%">
                    <Typography fontWeight={600}>{t(tokens.users.studyHistory.completionTermin)}</Typography>
                </TableCell>
                <TableCell align="center"  width="10%">
                    <Typography fontWeight={600}>{t(tokens.users.status)}</Typography>
                </TableCell>
                <TableCell align="center"  width="10%">
                    <Typography fontWeight={600}>{t(tokens.users.studyHistory.result)}</Typography>
                </TableCell>
            </TableRow>
        </TableHead>
    )
};