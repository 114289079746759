import { useEffect, useMemo, useState } from 'react';
import { useAuth } from '../hooks/useAuth';
import { PrivateRoutes } from './privateRoutes';
import { PublicRoutes } from './publicRoutes';
import { ChangePassword } from '../modules/auth/changePassword/ui';
import { useSelector } from 'react-redux';
import { selectRedirect403 } from '../store/redirect/repository/selector';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetLogoutTo403, resetRedirect } from '../store/redirect/repository/slice';
import { paths } from '../paths';
import { setLogout } from '../store/auth/repository/slice';

function Router() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoggedIn, setLoggedIn } = useAuth();
    const [isChangePassRequired, setChangePassRequired] = useState(false);
    const [userId, setUserId] = useState(0);
    const { redirectTo403, logoutTo403 } = useSelector(selectRedirect403);

    useEffect(() => {
        if (localStorage.getItem('user')) {
            const user = JSON.parse(localStorage.getItem('user') as string);
            setTimeout(() => {
                setChangePassRequired(user.need_change_password);
                setUserId((user.id));
            }, 1200);
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (redirectTo403) {
            navigate(paths.dashboard.access.index);
            dispatch(resetRedirect());
        };
    }, [redirectTo403, navigate]);

    useEffect(() => {
        if (logoutTo403) {
            dispatch(setLogout());
            localStorage.removeItem("accessToken");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("user");
            setLoggedIn(false);
            navigate('/');
            dispatch(resetLogoutTo403());
        };
    }, [logoutTo403, navigate]);

    const isShowChangePass = useMemo(() => isLoggedIn && isChangePassRequired, [isLoggedIn, isChangePassRequired]);

    return (
        <>
            {isLoggedIn ? <PrivateRoutes /> : <PublicRoutes />}
            {isShowChangePass ? <ChangePassword id={userId} /> : null}
        </>
    )
};

export default Router;