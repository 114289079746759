import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';

interface IState {
    isLoadingStructure: boolean;
    orgStructure: any[];
    getOrgStructureError: IError;
};

const initialState: IState = {
    isLoadingStructure: false,
    orgStructure: [],
    getOrgStructureError: null,
};

const OrgStructureSlice = createSlice({
    name: 'orgStructure',
    initialState,
    reducers: {
        setLoadingStructure: (state, action: PayloadAction<boolean>) => {
            state.isLoadingStructure = action.payload;
        },
        setOrgStructure: (state, action: PayloadAction<any>) => {
            state.orgStructure = action.payload;
        },
        setGetOrgStructureError: (state, action: PayloadAction<IError>) => {
            state.getOrgStructureError = action.payload;
        }
    },
});

export const { setLoadingStructure, setOrgStructure, setGetOrgStructureError } = OrgStructureSlice.actions;
export const OrgStructureReducer = OrgStructureSlice.reducer;