import { BorderRight } from "@mui/icons-material";

export const styles = {
    box: { 
        display: 'flex', 
        flexDirection: 'row', 
        alignItems: 'center', 
        mt: 2, 
        mb: 4,
    },
    btnEdit: { 
        borderRadius: 0, 
        borderRight: '1px solid black', 
        borderLeft: '1px solid black',
        color: '#ffffff',
        textTransform: 'none'  
    },
    btnLeft: {
        color: '#ffffff',
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px',
        textTransform: 'none'
    },
    btnRight: {
        color: '#ffffff',
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px',
        textTransform: 'none'
    },
}