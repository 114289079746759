import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setRoles, setRoleLoading, setRoleError } from "../../repository/slice";
import { IRole } from "../../types/IRole";

function* getRolesWorker({ payload }: any): SagaIterator {
    try {
        yield put(setRoleLoading(true));
        const { data, message, isError }: IResponse<{ count: number, rows: IRole[] }> = yield call(requester.get, "/api/role/get-list", payload);
        if (isError) {
            yield put(setRoleError(message));
        } else {
            yield put(setRoles(data));
        }
    } catch (error) {
        console.error("getRolesWorker: ", error);
    } finally {
        yield put(setRoleLoading(false));
    };
};

export function* getRolesWatcher() {
    yield takeLatest("@saga/GET_ROLES", getRolesWorker);
};