export const getStyles = () => {
    const styles = {
        error: {
            pl: '10px',
            mt: '2px'
        },
        password: {
            '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                    borderColor: '#C5C5C5',
                },
                '&.Mui-focused fieldset': {
                    borderColor: '#F8B043',
                },
                '&.Mui-error fieldset': {
                    borderColor: 'red'
                },
            }
        }
    };

    return styles;
};