import { SagaIterator } from "redux-saga";
import { put, call, takeEvery } from "redux-saga/effects";
import { setErrorsDeletePushNotification, setPushNotificationLoading } from "../../repository/actions";
import { getPushNotifications } from "../getPushNotifications/actions";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";

function* deletePushNotificationWorker({ payload }: any): SagaIterator {
  try {
    yield put(setPushNotificationLoading(true));
    const { data, isError, message }: IResponse<any> = yield call( requester.delete, "/api/admin/notification/" + payload.id);   
    if (isError) {
      yield put(setErrorsDeletePushNotification(message));
    } else {
      yield put(setErrorsDeletePushNotification(null));
    }
  } catch (error) {
    console.error("deletePushNotificationWorker: ", error);
  } finally {
    yield put(setPushNotificationLoading(false));
  }
};

export function* deletePushNotificationWatcher() {
  yield takeEvery("@saga/DELETE_PUSH_NOTIFICATION", deletePushNotificationWorker);
};
