import { Autocomplete, TextField, Typography } from "@mui/material";
import { ChangeEvent, FC } from "react";
import { IOption, collectOptionList } from "../../../utils/collectOptionList";
import { useModalAutocomplete } from "../presenter/useModalAutocomlete";

interface IProps {
    title: string;
    value: any | null;
    handleChangeValue: any;
    optionList: any[];
    isMultiline: boolean;
    categoryId: number;
};

export const ModalAutocomplete: FC<IProps> = ({ value, handleChangeValue, optionList, title, isMultiline, categoryId }) => {
    const { changeCategoryText } = useModalAutocomplete();

    return (
        <Autocomplete
            disabled={categoryId ? true : false}
            disablePortal
            multiple={isMultiline}
            fullWidth
            onChange={(e, value) => handleChangeValue(value) as IOption[]}
            value={value}
            options={collectOptionList(optionList)}
            renderOption={(params, option): JSX.Element => (
                <Typography {...params} key={option.id}>
                    {option.name}
                </Typography>
            )}
            renderInput={(params): JSX.Element => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={title}
                    onChange={changeCategoryText}
                    sx={{
                        '.MuiAutocomplete-input': {
                            cursor: categoryId ? 'not-allowed' : 'default'
                        }
                    }}
                />
            )}
            ListboxProps={
                {
                    style: {
                        maxHeight: '150px',
                    }
                }
            }
        />
    )
};