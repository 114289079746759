import { ChangeEvent, FormEvent, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "../../../hooks/useDebounce";
import { selectAssign } from "../../../store/assign/repository/selector";
import { deleteAccess } from "../../../store/access/useCases/deleteAccess/action";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { getAssignList } from "../../../store/assign/useCases/getAssignList/action";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../locales/tokens";
import { deleteAssign } from "../../../store/assign/useCases/deleteAssign/action";

export const useEditAssign = (type: string) => {
    const { id } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const searchTextRef = useRef<HTMLInputElement | null>(null);
    const [pageAmount, setPageAmount] = useState(0);
    const [filters, setFilters] = useState({ page: 1, perPage: 30, searchText: '', sortBy: 'User' });
    const scrollRef = useRef<HTMLDivElement>(null);
    const { assignList, assignCount, isLoading, assignDeleteError, getAssignListError } = useSelector(selectAssign);
    const [deleteClick, setDeleteClick] = useState(false);
    const [isProccess, setProcess] = useState(false);

    useEffect(() => {
        if(id) {
            dispatch(getAssignList({ 
                content_id: Number(id), 
                content_type: type, 
                search: filters.searchText, 
                type: filters.sortBy, 
                page: filters.page, 
                perPage: filters.perPage 
            }));
            setProcess(true);
        }
    }, [filters]);

    useEffect(() => {
        if (assignCount) {
            setPageAmount(Math.ceil(assignCount / filters.perPage));
        }
    }, [assignCount]);

    useEffect(() => {
        if (!isLoading && isProccess) {
            if (getAssignListError) {
                toast.error(getAssignListError)
            };
            setTimeout(() => setProcess(false), 400);
        }
    }, [getAssignListError, isLoading]);

    useEffect(() => {
        if (!isLoading && deleteClick) {
            if (assignDeleteError) {
                toast.error(assignDeleteError)
            } else {
                toast.success(t(tokens.assign.assignRemoved));
                dispatch(getAssignList({ 
                    content_id: Number(id), 
                    content_type: type, 
                    search: filters.searchText, 
                    type: filters.sortBy, 
                    page: 1, 
                    perPage: filters.perPage 
                }));
            }
            setDeleteClick(false);
        }
    }, [assignDeleteError, isLoading]);

    const handleSortBy = (sortBy: string): void => {
        setFilters((prevState) => ({ ...prevState, sortBy, page: 1 }));
        scrollTopList();
        setProcess(true);
    };

    const handleChangeSearch = useCallback((e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, sortBy: string) => {
        setSearch(e.target.value);
        debouncedHandleUserSeacrh(e.target.value, sortBy);
    }, []);

    const handleSearchTextChange = useCallback((event: FormEvent<HTMLFormElement>): void => {
        event.preventDefault();
    }, []);

    const onSearch = (value: any, sortBy: string) => {
        const query = value ? value : '';
        setFilters((prevState) => ({ ...prevState, searchText: query, page: 1 }));
        scrollTopList();
        setProcess(true);
    };

    const { debouncedWrapper: debouncedHandleUserSeacrh } = useDebounce(onSearch, 800);

    const handleDelete = (assignId: number) => {
        const body: any = { 
            type: filters.sortBy,
            content_id: Number(id),
            content_type: type,
        };
        if (filters.sortBy === 'User') body.id = assignId;
        if (filters.sortBy !== 'User') body.guid = assignId;
        dispatch(deleteAssign(body));
        setDeleteClick(true);
    };

    const handlePageChange = useCallback((event: ChangeEvent<unknown>, page: number): void => {
        setFilters((prevState) => ({ ...prevState, page }));
        scrollTopList();
    }, [setFilters]);

    const scrollTopList = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        };
    };

    const [searchTitle, setSearchTitle] = useState('');

    useEffect(()=>{
        const type = filters.sortBy;
        if(type === "User"){
            setSearchTitle(t(tokens.access.searchUsers));
        } else if(type === "Department"){
            setSearchTitle(t(tokens.access.serachDepartment));
        }else{
            setSearchTitle(t(tokens.access.searchPosition));
        }
    }, [filters.sortBy]);

    return {
        filters, searchTextRef, handleSortBy, handleChangeSearch, handleSearchTextChange, handleDelete, pageAmount, handlePageChange,
        scrollRef, assignList, searchTitle, isProccess
    };
};