export interface IItem {
    id: number;
    name: string;
    value?: string;
    isKnowledgeCategory: boolean;
};

export interface IOption {
    label: string;
    name: string;
    id: number;
    value: any;
    isKnowledgeCategory?: boolean;
};

export const collectOptionList: Function = (options: IItem[]) => {
    const result = options?.reduce((result: IOption[], option: IItem) => {
        const newOption: IOption = {
            label: option.name,
            name: option.name,
            id: option.id,
            value: option.value || option.name,
            ...(option.isKnowledgeCategory ? { isKnowledgeCategory: option.isKnowledgeCategory } : {})
        };
        return [...result, newOption];
    }, []);
    return result;
};

export const collectAdvertOptionList: Function = (options: any[]) => {
    const result = options.reduce((result: IOption[], option: any) => {
        const newOption: IOption = {
            label: option.theme,
            name: option.theme,
            id: option.id,
            value: option.value || option.name,
        };
        return [...result, newOption];
    }, []);
    return result;
};