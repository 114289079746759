import { Box, IconButton, Stack, SvgIcon, Tooltip, Typography } from "@mui/material";
import { CSSProperties, FC, useCallback, useMemo } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import { getStyles } from "./styles";

interface IProps {
    file: any;
    type: string;
    handleToggleVideoFile: () => void;
    handleDeleteImage: (name: string) => void;
};

export const FileDocument: FC<IProps> = ({ file, type, handleDeleteImage, handleToggleVideoFile }) => {
    const styles = useMemo(() => getStyles(), []);

    return (
        <Stack key={file.size + file.name} alignItems="center">
            <Box sx={styles.imageBox} >
                <Tooltip
                    title={type === 'Video' ? 'Показати відео' : ''}
                    arrow
                    followCursor
                    onClick={handleToggleVideoFile}
                >
                    <img
                        src={
                            type === 'File' ?
                                "/images/user/document.png"
                                : "/images/user/video.png"
                        }
                        alt="image"
                        width={65}
                        height={65}
                        style={styles.fileImage as CSSProperties}
                    />
                </Tooltip>
                <IconButton
                    size="small"
                    sx={styles.fileToolTip}
                    onClick={() => handleDeleteImage(file.name)}
                >
                    <SvgIcon sx={{ fontSize: '14px' }}>
                        <ClearIcon />
                    </SvgIcon>
                </IconButton>
            </Box>
            <Tooltip title={file.name}>
                <Typography variant="subtitle2">
                    {file.name.length > 10
                        ? `${file.name.slice(0, 10)}...`
                        : file.name
                    }
                </Typography>
            </Tooltip>
        </Stack>
    )
};