import { ChangeEvent, ReactNode, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { updateOrgStructure } from "../../../../../store/orgStructure/useCases/updateOrgStructure/action";
import { getBadges } from "../../../../../store/badge/useCases/getBadges/action";
import { selectOrgStructure } from "../../../../../store/orgStructure/repository/selector";
import { selectBadge } from "../../../../../store/badge/repository/selector";
import { useDebounce } from "../../../../../hooks/useDebounce";
import { paths } from "../../../../../paths";
import toast from "react-hot-toast";
import { tokens } from "../../../../../locales/tokens";
import { selectCourse } from "../../../../../store/course/repository/selector";
import { getSingleCourse } from "../../../../../store/course/useCases/getSingleCourse/action";
import { setSingleCourse, setTaskId, setTaskList } from "../../../../../store/course/repository/slice";
import { updateCourse } from "../../../../../store/course/useCases/updateCourse/action";
import { deleteTask } from "../../../../../store/course/useCases/deleteTask/action";
import { IBadge, IInitiator } from "../../courseCreate/presenter/types";
import { IRequestBody, ITask } from "./types";
import { IError } from "../../../../../types/error";
import { SelectChangeEvent } from "@mui/material";
import { getPositions } from "../../../../../store/user/useCases/getPositions/action";
import { selectUser } from "../../../../../store/user/repository/selector";

export const useCourseEdit = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [theme, setTheme] = useState('');
    const [themeValid, setThemeValid] = useState(true);
    const { orgStructure } = useSelector(selectOrgStructure);
    const [badge, setBadge] = useState<IBadge | null>(null);
    const [badgeSeacrh, setBadgeSearch] = useState('');
    const { badgesList } = useSelector(selectBadge);
    const [content, setContent] = useState('');
    const [contentValid, setContentValid] = useState(true);
    const [initiator, setInitiator] = useState<IInitiator | null>(null);
    const [initiatorValid, setInitiatorValid] = useState(true);
    const [groupPermissions, setGroupPermissions] = useState<string[]>([]);
    const [groupPermissionsValid, setGroupPermissionsValid] = useState(true);
    const [receiver, setReceiver] = useState<string[]>([]);
    const [deadline, setDeadline] = useState<number | null>(null);
    const [deadlineValid, setDeadlineValid] = useState<any>(true);
    const [publishChecked, setPublishChecked] = useState(false);
    const [notifyChecked, setNotifyChecked] = useState(false);
    const [editClick, setEditClick] = useState(false);
    const [points, setPoints] = useState('');
    const [duration, setDuration] = useState('');
    const [isAvalableNextDay, setAvailableNextDay] = useState<boolean>(false);
    const { updateCourseError, isLoading, singleCourse, taskList } = useSelector(selectCourse);
    const { positionsList } = useSelector(selectUser);
    const [isTaskModalOpen, setTaskModalOpen] = useState(false);
    const [currentTask, setCurrentTask] = useState<ITask | null>(null);
    const [isDeleted, setIsDeleted] = useState<boolean>(false);
    const [departmentPermissions, setDepartmentPermissions] = useState<any[]>([]);
    const [positions, setPositions] = useState<any[]>([]);
    const [assignDepartment, setAssignDepartment] = useState<any[]>([]);
    const [assignPositions, setAssignPositions] = useState<any[]>([]);

    useEffect(() => {
        dispatch(updateOrgStructure());
        dispatch(getBadges({ type: 'ACTIVE' }));
        dispatch(getPositions());
        return () => {
            dispatch(setSingleCourse(null));
        }
    }, []);

    useEffect(() => {
        id && dispatch(getSingleCourse({ id }));
    }, [id]);

    useEffect(() => {
        if (singleCourse) {
            setTheme(singleCourse.name);
            setInitiator(singleCourse.initiator.id);
            // setGroupPermissions(singleCourse.access.map((item: any) => item.id));
            // setReceiver(singleCourse.assigns.map((item: any) => item.id));
            setContent(singleCourse.description || "");
            setPublishChecked(singleCourse.status === 'Available' ? true : false);
            setNotifyChecked(singleCourse.isInformed ? true : false);
            setAvailableNextDay(!singleCourse.openNextDayImmediately);
            setBadge(singleCourse.badge ? { ...singleCourse.badge, label: singleCourse.badge.name } : null);
            setPoints(singleCourse.points ? String(singleCourse.points) : '');
            if (Array.isArray(singleCourse.content)) {
                dispatch(setTaskList(singleCourse.content));
            };
            if (!!singleCourse.deletedAt) {
                setIsDeleted(true);
            }
        }
    }, [singleCourse]); 

    useEffect(() => {
        if (!isLoading && editClick) {
            if (updateCourseError) {
                handleServerError(updateCourseError);
            } else {
                toast.success(t(tokens.course.message.courseUpdated));
                navigate(paths.dashboard.content.course.list);
            }
            setEditClick(false);
        }
    }, [updateCourseError, isLoading]);

    const handleServerError = (error: IError) => {
        if (error?.startsWith("value too long for type character varying(255)")) {
            toast.error(t(tokens.common.tooMuchSymbols));
            setThemeValid(false);
        } else if (error?.startsWith("value too long for type character varying(500)")) {
            setContentValid(false);
            toast.error(t(tokens.common.tooMuchSymbols));
        } else {
            toast.error(error);
        }
    };
    
    const onChangeTheme = (event: ChangeEvent<HTMLInputElement>) => {
        setTheme(event.target.value);
        setThemeValid(true);
    };

    const handleChangeBadge = useCallback((badge: IBadge) => {
        setBadge(badge);
    }, [setBadge]);

    const changeBadgeText = (event: ChangeEvent<HTMLInputElement>) => {
        setBadgeSearch(event.target.value);
        debouncedHandleBadgeSeacrh(badgeSeacrh);
    };

    const onSearchBadge = (value: string) => {
        if (value.length) {
            dispatch(getBadges({ search: value, type: 'ACTIVE' }));
        } else {
            dispatch(getBadges({ type: 'ACTIVE' }));
        }
    };

    const { debouncedWrapper: debouncedHandleBadgeSeacrh } = useDebounce(onSearchBadge, 1000);

    const onChangeContent = (event: ChangeEvent<HTMLInputElement>) => {
        setContent(event.target.value);
        setContentValid(true);
    };

    const onChangeDuration = (event: ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        const regex = /^(100|[1-9]?[0-9])?$/;

        if (regex.test(input)) {
            setDuration(input);
        }
    };

    const onSelectInitiator = (selectedKeysValue: string, node: any) => {
        if ('children' in node) {
            return;
        }
        setInitiator(node);
        setInitiatorValid(true);
    };

    const onChangeInitiator = () => {
        if (initiator) {
            setInitiator(null);
        }
    };

    const onChangePermissions = (newValue: string[]) => {
        setGroupPermissions(newValue);
        setGroupPermissionsValid(true);
    };

    const onChangeDepartmentPermissions = (newValue: string[]) => {
        setDepartmentPermissions(newValue);
    };

    const onChangeAssignDepartment = (newValue: string[]) => {
        setAssignDepartment(newValue);
    };

    const onChangeReceiver = (newValue: string[]) => {
        setReceiver(newValue);
    };

    const handleChangeDeadline = useCallback((date: Date | null): void => {
        const currentDate = date?.valueOf() || null;
        setDeadline(currentDate);
        setDeadlineValid(true);
    }, [setDeadline]);

    const handlePublishChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPublishChecked(event.target.checked);
    };

    const handleNotifyChange = (event: ChangeEvent<HTMLInputElement>) => {
        setNotifyChecked(event.target.checked);
    };

    const onChangePoints = (event: ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value;
        const regex = /^(\d*)$/;

        if (regex.test(input)) {
            setPoints(input);
        }
    };

    const handleChangeNextDay = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAvailableNextDay(event.target.checked);
    };

    const handleOpenDayModal = () => {
        setTaskModalOpen(true);
    };

    const handleCloseDayModal = () => {
        setTaskModalOpen(false);
        setCurrentTask(null);
        dispatch(setTaskId(0));
    };

    const cancelCreate = () => {
        navigate(paths.dashboard.content.course.list);
    };

    const handleCourse = () => {
        const isPassedValidation = validateData();
        if (isPassedValidation) {
            createNewCourse();
        } else {
            toast.error(t(tokens.adverts.fillAllFields))
        };
    };

    const validateData = () => {
        const data = [
            { field: theme, setField: setThemeValid },
            { field: initiator, setField: setInitiatorValid },
            // { field: groupPermissions.length, setField: setGroupPermissionsValid },
            // { field: deadline, setField: setDeadlineValid },
        ];
        let allFieldsValid = true;
        for (const { field, setField } of data) {
            if (!field) {
                setField(false);
                allFieldsValid = false;
            }
        };

        return allFieldsValid;
    };

    const createNewCourse = () => {
        dispatch(updateCourse({ body: getRequestBody(), id: Number(id) }));
        setEditClick(true);
    };

    const cleanedGuiIds = (guiIds: string[]): string[] => {
        return guiIds.map(permission => {
            const [cleaned] = permission.split('%');
            return cleaned;
        });
    };    

    const getRequestBody = () => {
        const body: IRequestBody = {
            // id: Number(id),
            name: theme,
            openNextDayImmediately: !isAvalableNextDay,
            needInform: notifyChecked,
            status: publishChecked ? "Available" : "Hidden",
            initiatorId: initiator?.value
        };
        // if (deadline) body.deadline = new Date(deadline as number).toISOString();
        // if(!!duration) body.days_to_deadline = Number(duration);
        body.deadline = deadline ? new Date(deadline).toISOString() : null;
        body.days_to_deadline = !!duration ? Number(duration) : null;

        body.description = content ? content : null;
        body.points = points ? Number(points) : null;
        body.badgeId = badge ? badge.id : null;
        if (receiver.length) body.assigns = receiver;
        if (groupPermissions.length) body.access = groupPermissions;
        if(departmentPermissions.length) body.accessDepartmentIds = cleanedGuiIds(departmentPermissions);
        if(positions.length) body.accessPositionIds = positions.map(item => item.id);
        if(assignDepartment.length) body.assignDepartmentIds = cleanedGuiIds(assignDepartment);
        if(assignPositions.length) body.assignPositionIds = assignPositions.map(item => item.id); 
        return body;
    };

    const removeTask = (idTask: number) => {
        dispatch(deleteTask({ body: { id: idTask, courseId: Number(id) } }));
    };

    const defineCurrentTask = (index: number, taskId: number) => {
        setCurrentTask(taskList[index]);
        setTaskModalOpen(true);
        dispatch(setTaskId(taskId));
    };

    const handleChangePosition = (event: any, newValue: readonly any[]) => {
        setPositions([...newValue]);
    };

    const handleChangeAssignPosition = (event: any, newValue: readonly any[]) => {
        setAssignPositions([...newValue]);
    };

    return {
        theme, onChangeTheme, themeValid, badge, badgesList, handleChangeBadge, changeBadgeText, content, contentValid, onChangeContent, receiver,
        onChangeInitiator, onSelectInitiator, initiator, initiatorValid, orgStructure, groupPermissions, groupPermissionsValid, onChangePermissions,
        onChangeReceiver, handleChangeDeadline, deadline, deadlineValid, handlePublishChange, publishChecked, handleNotifyChange, handleOpenDayModal,
        notifyChecked, handleCourse, cancelCreate, editClick, onChangePoints, points, isAvalableNextDay, handleChangeNextDay, isTaskModalOpen,
        handleCloseDayModal, id, currentTask, taskList, defineCurrentTask, removeTask, isDeleted, onChangeDepartmentPermissions,
        departmentPermissions, positionsList, positions, handleChangePosition, onChangeAssignDepartment, assignDepartment, handleChangeAssignPosition, 
        assignPositions, onChangeDuration, duration
    }
};