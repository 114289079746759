import { FC } from "react";
import { SingleCourseChart } from "./components/singleCourseChart";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { useSingleCourseAnalytics } from "../presenter/useSingleCourseAnalytics";

export const SingleCourseAnalytics: FC = () => {
    const { t } = useTranslation();
    const { courseList, currentCourse, changeCourse, changeCourseText, labels, chartSeries } = useSingleCourseAnalytics();

    return (
        <Stack m={2}>
            <Typography variant="h4" fontWeight={600}>
                Результат курса
            </Typography>
            <Stack justifyContent="center" alignItems="center">
                <SingleCourseChart
                    chartSeries={chartSeries}
                    labels={labels}
                    currentCourse={currentCourse}
                    courseList={courseList}
                    changeCourse={changeCourse}
                    changeCourseText={changeCourseText}
                />
            </Stack>
        </Stack>
    )
};