export const getStyles = () => {
    const styles = {
        button: { 
            borderRadius: '10px', 
            color: '#FFFFFF',
            fontWeight: 600,
            backgroundColor: '#F1594E',
            textTransform: 'none',
            width: '200px',
            mt: 2,
            '&:hover':{
                backgroundColor: '#d85046'
            } 
        },
        backupId: {
            mt: 1
        },
        tabs: {
            py: 2,
            "& .MuiTabs-indicator": {
                backgroundColor: "#F1594E",
            }
        },
        tab: {
            "&.Mui-selected": {
                color: "#F1594E",
            },
        }
    };
    return styles;
};