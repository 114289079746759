import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';
import { IRole } from '../types/IRole';

interface IState {
    isRoleLoading: boolean;
    roleList: IRole[];
    selectedRole: IRole | null;
    rolesCount: number;
    roleError: IError;
};

const initialState: IState = {
    isRoleLoading: false,
    roleList: [],
    selectedRole: null,
    rolesCount: 0,
    roleError: null,
};

const RoleSlice = createSlice({
    name: 'roles',
    initialState,
    reducers: {
        setRoleLoading: (state, action: PayloadAction<boolean>) => {
            state.isRoleLoading = action.payload;
        },
        setRoles: (state, action: PayloadAction<{ rows: IRole[], count: number }>) => {
            state.roleList = action.payload.rows;
            state.rolesCount = action.payload.count;
        },
        setSelectedRole: (state, action: PayloadAction<IRole | null>) => {
            state.selectedRole = action.payload;
        },
        setRoleError: (state, action: PayloadAction<IError>) => {
            state.roleError = action.payload;
        },
    },
});

export const { setRoleLoading, setRoles, setSelectedRole, setRoleError } = RoleSlice.actions;
export const RoleReducer = RoleSlice.reducer;