import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IError } from '../../../types/error';

interface IState {
    isLoading: boolean;
    categoryList: any[];
    categoriesCount: number;
    updateCategoryError: IError;
    createCategoryError: IError;
    singleCategory: any;
    parentCategoriesList: any[];
};

const initialState: IState = {
    isLoading: false,
    categoryList: [],
    categoriesCount: 0,
    updateCategoryError: null,
    createCategoryError: null,
    singleCategory: null,
    parentCategoriesList: []
};

const CategorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setCategories: (state, action: PayloadAction<any>) => {
            state.categoryList = action.payload.categories;
            state.categoriesCount = action.payload.count;
        },
        setParentCategories: (state, action: PayloadAction<any>) => {
            state.parentCategoriesList = action.payload.parentCategories;
        },
        setSingleCategory: (state, action: PayloadAction<any>) => {
            state.singleCategory = action.payload;
        },
        setUpdateCategoryError: (state, action: PayloadAction<IError>) => {
            state.updateCategoryError = action.payload;
        },
        setCreateCategoryError: (state, action: PayloadAction<IError>) => {
            state.createCategoryError = action.payload;
        },
    },
});

export const { setLoading, setCategories, setUpdateCategoryError, setCreateCategoryError, setSingleCategory, setParentCategories } = CategorySlice.actions;
export const CategoryReducer = CategorySlice.reducer;