import type { ChangeEvent, FC } from 'react';
import { Card, CardContent, Stack, Typography } from '@mui/material';
import { ChartSeries } from '../../../presenter/useUserChart';
import InfoIcon from '@mui/icons-material/Info';
import { ChartBody } from '../chartBody';
import { useTranslation } from 'react-i18next';
import { ChartHeader } from '../../../../general/ui/components/chartHeader';
import { AdvertAutocomplete } from '../../../../../../ui-kit/advertAutocomplete';

interface IProps {
  currentAdvert: any;
  handleChangeAdvert: (document: any) => void;
  changeAdvertText: (event: ChangeEvent<HTMLInputElement>) => void;
  optionList: any[];
  chartSeries: ChartSeries;
  questionList: string[];
};

export const SingleAdvertChart: FC<IProps> = ({ chartSeries, questionList, changeAdvertText, currentAdvert, handleChangeAdvert, optionList }) => {
  const { t } = useTranslation();

  return (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="flex-end" spacing={1}>
        <Stack width="400px">
          <AdvertAutocomplete
            optionList={optionList}
            value={currentAdvert}
            handleChangeValue={handleChangeAdvert}
            title='Оголошення'
            isMultiline={false}
            size='small'
            changeText={changeAdvertText}
          />
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={3}>
        <Stack spacing={1} pt={5}>
          {questionList.map((question: string, index: number) =>
            <Typography key={index} variant='body2' py="20px">{question}</Typography>
          )}
        </Stack>
        <Card elevation={2}>
          <ChartHeader title="Cтатистика оголошення" subTitle={""} icon={<InfoIcon />} />
          <CardContent>
            <ChartBody chartSeries={chartSeries} questionList={questionList} />
          </CardContent>
        </Card>
      </Stack>
    </Stack>
  );
};