import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const pizzaWayAPI = createApi({
    reducerPath: "pizzaWayAPI",
    baseQuery: fetchBaseQuery(),
    tagTypes: [
        'Badge'
    ],
    endpoints: (builder) => ({}),
});
