import { SagaIterator } from "redux-saga";
import { call, put, takeLatest } from "redux-saga/effects";
import { IResponse } from "../../../../libs/requester/IResponse";
import { requester } from "../../../../libs/requester/requester-axios";
import { setRoleLoading, setRoleError, setSelectedRole } from "../../repository/slice";
import { IRole } from "../../types/IRole";

function* getRoleByIdWorker({ payload }: any): SagaIterator {
    try {
        yield put(setRoleLoading(true));
        const { data, message, isError }: IResponse<{ count: number, rows: IRole[] }> = yield call(requester.get, "/api/role/get-list", payload);
        if (isError) {
            yield put(setRoleError(message));
        } else {
            const currentRole = data.rows.find(role => role.id === payload.id);
            yield put(setSelectedRole(currentRole || null));
        }
    } catch (error) {
        console.error("creteRoleWorker: ", error);
    } finally {
        yield put(setRoleLoading(false));
    };
};

export function* getRoleByIdWatcher() {
    yield takeLatest("@saga/GET_ROLE_BY_ID", getRoleByIdWorker);
};