import { Stack, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { CSSProperties, FC, useMemo } from "react";
import { getStyles } from "./styles";

interface IProps {
    product: any;
};

export const ProductTableItem: FC<IProps> = ({ product }) => {
    const styles = useMemo(() => getStyles(), []);

    return (
        <TableRow key={product.id}>
            <TableCell align="center" width="50%" padding="none">
                <Stack direction="row" spacing={2} alignItems="center">
                    <Stack width={30} height={30}>
                        <img alt='image' src={product.image.fileUrl} style={styles.image as CSSProperties} />
                    </Stack>
                    <Tooltip title={product.name}>
                        <Typography variant="body2" sx={styles.name}>{product.name}</Typography>
                    </Tooltip>
                </Stack>
            </TableCell >
            <TableCell align="center" width="25%">
                <Typography noWrap variant="body2">{product.price}</Typography>
            </TableCell>
            <TableCell align="center" width="25%">
                <Typography noWrap variant="body2">{product.amount} </Typography>
            </TableCell>
        </TableRow>
    );
};