import { FC } from "react";
import { ProcessChart } from "./components/processChart";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../locales/tokens";
import { useProcessAnalytics } from "../presenter/useProcessAnalytics";

export const ProcessAnalytics: FC = () => {
    const { t } = useTranslation();
    const { dates, handleRangeChange, disabledDate } = useProcessAnalytics();

    return (
        <Stack m={2}>
            <Typography variant="h4" fontWeight={600}>
                {t(tokens.analytics.myProcess)}
            </Typography>
            <Stack justifyContent="center" alignItems="center">
                <ProcessChart
                    chartSeries={[4, 1, 1]}
                    labels={[t(tokens.analytics.feedback), t(tokens.analytics.personalReserve), t(tokens.analytics.checkin)]}
                    dates={dates}
                    handleRangeChange={handleRangeChange}
                    disabledDate={disabledDate}
                />
            </Stack>
        </Stack>
    )
};