import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { tokens } from "../../../../../../locales/tokens";

interface IProps {
    userRoles: string[];
    rolesList: any[];
    handleChangeRole: (event: SelectChangeEvent<string[]>, child: ReactNode) => void;
};

export const UserRole: FC<IProps> = ({ userRoles, rolesList, handleChangeRole }) => {
    const { t } = useTranslation();

    return (
        <Stack direction='row' alignItems='center' width="500px" mt={2}>
            <Typography flex={1} variant="body1" width="200px">{t(tokens.users.userRoles)}</Typography>
            <FormControl sx={{ flex: 2 }}>
                <InputLabel id="demo-simple-select-role">{t(tokens.users.userRoles)}</InputLabel>
                <Select
                    fullWidth
                    onChange={handleChangeRole}
                    value={userRoles}
                    multiple
                    renderValue={(selected) => selected.join(', ')}
                    id="demo-simple-select-role"
                    label={t(tokens.users.userRoles)}
                >
                    {rolesList?.map((userRole: any, index) => (
                        <MenuItem value={userRole} key={userRole.id}>
                            <Checkbox checked={userRoles?.includes(userRole.name)} />
                            <ListItemText primary={userRole.name} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Stack>
    )
};